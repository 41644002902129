import React from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import styles from './Answers.module.sass';
import CheckExplanation from '@/components/App/Admin/Results/Cuts/CutsChart/Answers/CheckExplanation';
import { getClearClassNames } from '@/helpers/helpers';
import { calcOptionPercents } from '@/components/App/Admin/Results/Cuts/helpers';

function Answers({ answerText, answerStat }) {
  const optionPercents = calcOptionPercents(answerStat);

  // if answers >= 7 ---> reduce font-size
  const areThereManyAnswers = answerStat.length >= 7;

  const renderAnswers = () => answerStat.map((value, i) => {
    const key = `answer-${i}`;

    return (
      <div key={key} className={styles.answer}>
        <div className={styles['wrap-label-answer']}>
          <div className={styles['wrap-value']}>
            <div className={styles['value-placeholder']}>
              <span className={`icon-employee ${styles['icon-employee']}`} />
            </div>
            <div className={styles['count-actors']}>{value}</div>
            <div className={styles.percent}>
              {`${Math.round(optionPercents[i])}%`}
            </div>
          </div>
          <div className={styles['case-title']}>
            <CheckExplanation tooltipID={`explanation-${i}`}>
              {parse(answerText[i])}
            </CheckExplanation>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div
      className={getClearClassNames([
        styles['wrap-answers'],
        areThereManyAnswers && styles['reduce-answers'],
      ])}
    >
      {renderAnswers()}
    </div>
  );
}

export default Answers;

Answers.defaultProps = {
  answerText: [],
  answerStat: [],
};

Answers.propTypes = {
  answerText: PropTypes.arrayOf(PropTypes.string),
  answerStat: PropTypes.arrayOf(PropTypes.number),
};