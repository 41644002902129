import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import getFileNameByResponseHeader from '@/helpers/parseFileName';
import { makeFileLinkAndDownload } from '@/helpers/helpers';

const useFileDownload = () => {
  const { headers } = useSelector((state) => state.admin.authentication);
  const downloadFile = useCallback(
    async (args) => {
      try {
        const response = await axios.post('/api/admin/download', args, {
          headers,
          responseType: 'blob',
        });
        return {
          blob: response.data,
          fileName: getFileNameByResponseHeader(response.headers),
        };
      } catch (err) {
        return err;
      }
    },
    [headers],
  );

  return useCallback(
    ({ args }) => {
      downloadFile(args)
        .then((response) => makeFileLinkAndDownload(response))
        .catch(() => null);
    },
    [downloadFile],
  );
};

export default useFileDownload;