import React, { Dispatch, SetStateAction } from 'react';
import HeaderLogo from '@/components/Common/BaseTemplate/Header/HeaderLogo/HeaderLogo';

interface OpenSidebarButtonProps {
  toggleOpen: Dispatch<SetStateAction<boolean>>
}

export default function OpenSidebarButton({ toggleOpen }: OpenSidebarButtonProps) {
  const handleClick = () => toggleOpen((prevState) => !prevState);
  return (
    <button
      type="button"
      onClick={handleClick}
    >
      <HeaderLogo />
    </button>
  );
}