import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import modalStyles from '../BUnitEmailModalSelect.module.sass';
import CustomSelect from '@/components/App/Admin/BUnitsSection/CustomSelect';

function SelectMailTemplatesLang({
  languagesId,
  currentTemplateLang,
  setCurrentTemplateLang,
}) {
  const { languages } = useSelector(
    (state) => state.admin.superset.supersetFields,
  );

  const getLangContentByTable = (langId) => {
    const contentByTable = languages[langId];
    return `${contentByTable.native} / ${contentByTable.english}`;
  };
  const selectLang = (langValue) => {
    setCurrentTemplateLang(langValue.key);
  };

  if (!currentTemplateLang) return null;

  const values = languagesId.map((langId) => ({
    name: getLangContentByTable(langId),
    key: langId,
  }));

  return (
    <CustomSelect
      activeValue={{
        name: getLangContentByTable(currentTemplateLang),
        key: currentTemplateLang,
      }}
      values={values}
      mainBtnClassNames={modalStyles['select-button']}
      selectBtnClassNames={modalStyles['select-option']}
      onClickAction={selectLang}
    />
  );
}

SelectMailTemplatesLang.propTypes = {
  languagesId: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentTemplateLang: PropTypes.string,
  setCurrentTemplateLang: PropTypes.func.isRequired,
};

SelectMailTemplatesLang.defaultProps = {
  currentTemplateLang: undefined,
};

export default SelectMailTemplatesLang;