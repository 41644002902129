import React from 'react';
import { useSelector } from 'react-redux';
import LinkTabs from '@/components/App/Admin/UIElements/LinkTabs';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';

function AdminSupersetTabs() {
  const tNLS = useNLS();
  const { supersetId, hasTree, countUnits } = useSelector(selectSuperset);
  const checkPermission = useCheckPermission();

  const isTreeHidden = () => {
    if (!hasTree) return true;
    if (!checkPermission('view_tree')) return true;
    return !checkPermission('create_units') && countUnits === 0;
  };

  return (
    <LinkTabs>
      {[
        {
          id: 'index',
          title: tNLS('tab.manage'),
          link: `/admin/project/${supersetId}`,
          end: true,
        },
        {
          id: 'projectSettings',
          title: tNLS('tab.settings'),
          link: `/admin/project/${supersetId}/projectSettings`,
          end: true,
        },
        {
          id: 'addingQuestions',
          title: tNLS('tab.add.questions'), // todo NLS
          link: `/admin/project/${supersetId}/addingQuestions`,
          isHide: true,
          // !checkPermission('get_custom_questions'),
        },
        {
          id: 'unitsTree',
          title: tNLS('tab.tree'),
          link: `/admin/project/${supersetId}/unitsTree`,
          isHide: isTreeHidden(),
        },
        {
          id: 'results',
          title: tNLS('tab.results'),
          link: `/admin/project/${supersetId}/results`,
          isHide: !checkPermission('view_results'),
        },
      ]}
    </LinkTabs>
  );
}

export default AdminSupersetTabs;