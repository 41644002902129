import { createSlice } from '@reduxjs/toolkit';
import {
  loadFactorCuts,
  setActiveTypeFS,
} from '@/store/actions/admin/superset/process/cuts/factorsCuts';
import { clearCutsData } from '@/store/actions/admin/superset/process/cuts/cuts';

const initialState = {
  cuts: [],
  typeFS: null, // percentile or absolute,
};

const factorsCutsSlice = createSlice({
  name: 'admin/superset/process/cuts/factorsCuts',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(loadFactorCuts.fulfilled, (state, { payload: cuts }) => {
        state.cuts = cuts ?? [];
      })
      .addCase(setActiveTypeFS, (state, { payload: typeFS }) => {
        state.typeFS = typeFS;
      })
      .addCase(clearCutsData, () => initialState);
  },
});

export default factorsCutsSlice.reducer;