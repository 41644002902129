import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './AdminWidgets.module.sass';
import BtnDropDown from '@/components/Common/Elements/BtnDropDown';
import { adminLogoutAndClearData } from '@/store/actions/admin';
import ArrowIcon from '@/components/Common/Elements/cssIcons/ArrowIcon';
import { selectAdmin } from '@/store/selectors/admin/authentication';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';

function AdminWidgets() {
  const dispatch = useDispatch();
  const admin = useSelector(selectAdmin);
  const checkPermission = useCheckPermission();

  if (!admin) return null;

  return (
    <div className={styles['header-admin-widgets']}>
      <div className={styles['header-admin-personal']}>
        <BtnDropDown classNames={styles['profile-dropdown']}>
          <button
            type="button"
            className={`${styles['header-admin-profile']} ${styles['btn-profile-dropdown']} btn-without-style`}
          >
            <span className={styles['profile-name']}>
              {admin.name || admin.email}
            </span>
            <ArrowIcon
              classNames={styles['profile-name-arrow-icon']}
              direction="bottom"
            />
          </button>
          <div className={styles['profile-dropdown-content']}>
            <ul className={styles['list-menu']}>
              <li className={styles['item-menu']}>
                <Link to="/admin/projects" className={styles['link-item-menu']}>
                  <span className={`${styles['item-text']} icon-projects`}>
                    Projects
                  </span>
                </Link>
              </li>
              <li className={styles['item-menu']}>
                <Link to="/admin/profile" className={styles['link-item-menu']}>
                  <span className={`${styles['item-text']} icon-gear`}>
                    Account
                  </span>
                </Link>
              </li>
              {checkPermission('log') && (
                <li className={styles['item-menu']}>
                  <Link
                    to="/admin/loginfo"
                    className={styles['link-item-menu']}
                  >
                    <span className={styles['item-text']}>Logs</span>
                  </Link>
                </li>
              )}
              <li className={styles['item-menu']}>
                <button
                  type="button"
                  className={`${styles['btn-item-menu']} btn-without-style`}
                  onClick={() => dispatch(adminLogoutAndClearData())}
                >
                  <span className={`${styles['item-text']} icon-exit`}>
                    Log out
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </BtnDropDown>
      </div>
    </div>
  );
}

export default AdminWidgets;