import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './HeaderCriteria.module.sass';
import ArrowIcon from '@/components/Common/Elements/cssIcons/ArrowIcon';
import useNLS from '@/components/App/Admin/hooks/hooks';
import {
  selectCutsByItemIDForActiveTypeCuts,
  selectSelectedCriteria,
} from '@/store/selectors/admin/superset/process/cuts/cuts';
import { QUESTIONS_CUTS } from '@/components/App/Admin/Results/Cuts/constants';

function HeaderCriteria({ toggleCriteriaList, isVisibleCriteria }) {
  const { itemID, typeCuts } = useParams();
  const tNLS = useNLS();
  const selectedCriteria = useSelector(selectSelectedCriteria);
  const hiddenAnswers = useSelector((state) => selectCutsByItemIDForActiveTypeCuts(state, itemID, typeCuts))?.hiddenAnswers;

  return (
    <div className={styles['wrap-header-criteria']}>
      <div className={styles['control-selected-criteria']}>
        <div className={styles['label-control']}>
          <span className={`icon-menu ${styles['icon-menu']}`} />
          <span>
            {`${tNLS('cuts.make.sample.cut')}. ${tNLS(
              'cuts.crits.selected',
            )} ${String(Object.keys(selectedCriteria).length)} `}
          </span>
        </div>
      </div>
      {typeCuts === QUESTIONS_CUTS && !!tNLS('cuts.didnt.answer') && (
        <div className={styles['info-hidden-respondents']}>
          <span className={`icon-hidden ${styles['icon-hidden']}`} />
          {`${tNLS('cuts.didnt.answer')} ${hiddenAnswers} `}
        </div>
      )}
      <div
        role="button"
        tabIndex={0}
        className={styles['toggler-criteria']}
        onClick={() => toggleCriteriaList((prev) => !prev)}
        onKeyDown={() => {}}
      >
        <ArrowIcon
          classNames={styles['toggle-criteria-icon']}
          direction={isVisibleCriteria ? 'top' : 'bottom'}
        />
      </div>
    </div>
  );
}

HeaderCriteria.propTypes = {
  toggleCriteriaList: PropTypes.func.isRequired,
  isVisibleCriteria: PropTypes.bool.isRequired,
};

export default HeaderCriteria;