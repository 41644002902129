// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".link-to-back-to-prev-page--nKNhG a{display:flex;align-items:center;font-size:1.125rem;gap:0.2777777778em;text-decoration:none;color:var(--admin-main-font-color);font-weight:500;transition:color .3s ease 0s}.link-to-back-to-prev-page--nKNhG a:hover{color:var(--admin-hover-main-text)}.link-to-back-to-prev-page--nKNhG a:hover .arrow-icon-left--4lpEg::before,.link-to-back-to-prev-page--nKNhG a:hover .arrow-icon-left--4lpEg::after{background:var(--admin-hover-main-text)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/UIElements/LinkToBackToPrevPage/LinkToBackToPrevPage.module.sass"],"names":[],"mappings":"AAGE,oCACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,kBAAA,CACA,oBAAA,CACA,kCAAA,CACA,eAAA,CACA,4BAAA,CACA,0CACE,kCAAA,CAEE,mJAEE,uCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link-to-back-to-prev-page": "link-to-back-to-prev-page--nKNhG",
	"arrow-icon-left": "arrow-icon-left--4lpEg"
};
export default ___CSS_LOADER_EXPORT___;
