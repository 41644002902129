import React from 'react';
import './DepartmentUnitControlByType.sass';
import DepartmentUnitInput from './DepartmentUnitInput/DepartmentUnitInput';
import DepartmentUnitButton from './DepartmentUnitButton/DepartmentUnitButton';
import { ToggleIsInputType, ToggleOpenType } from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/DepartmentUnit/DepartmentUnit';

interface DepartmentUnitControlByTypeProps {
  isInput: boolean;
  toggleInput: ToggleIsInputType;
  toggleOpen: ToggleOpenType;
}

function DepartmentUnitControlByType({
  isInput, toggleInput, toggleOpen,
}: DepartmentUnitControlByTypeProps) {
  // const { unit, id } = useContext(newBUnitContext);
  return (
    isInput
      ? (
        <DepartmentUnitInput
          closeInput={() => toggleInput(false)}
        />
      ) : (
        <DepartmentUnitButton
          toggleOpen={toggleOpen}
        />
      )
  );
}

export default DepartmentUnitControlByType;