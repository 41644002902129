import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoadResults from '@/components/App/Admin/Results/LoadResults';
import InitCuts from '@/components/App/Admin/Results/Cuts/InitCuts';
import CutsDistributor from '@/components/App/Admin/Results/Cuts/CutsDistributor';

function Results() {
  return (
    <Routes>
      <Route path="/" element={<LoadResults />}>
        <Route index element={<Navigate to="cuts" replace />} />

        <Route path="cuts" element={<InitCuts />}>
          <Route path=":typeCuts/:itemID" element={<CutsDistributor />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default Results;