import { createSlice } from '@reduxjs/toolkit';
import {
  loadAdminSettings,
  setAssessmentSettings,
  setDownloadableSettings,
} from '@/store/actions/admin/superset/settings';
import { SupersetSettings } from '@/store/types/admin/superset/settings';

const initialState: SupersetSettings = {};

const settingsSlice = createSlice({
  name: 'admin/superset/settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        loadAdminSettings.fulfilled,
        (state, { payload: settings }) => settings,
      )
      .addCase(setAssessmentSettings.fulfilled, (settings, { payload }) => {
        const { settingId, value } = payload;
        const updatedSetting = settings[settingId];
        updatedSetting.value = value;
      })
      .addCase(
        setDownloadableSettings,
        (state, { payload: settings }) => settings,
      );
  },
});

export default settingsSlice.reducer;