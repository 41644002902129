import React from 'react';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectWebinarsList } from '@/store/selectors/admin/webinars';
import WebinarListLink from './WebinarLink/WebinarListLink';
import styles from './WebinarsFilteredList.module.sass';
import CreateNewWebinarLink from './CreateNewWebinarLink/CreateNewWebinarLink';

export default function WebinarsFilteredList() {
  const { webinarsList }: { webinarsList: WebinarsList } = useAdminSelector(selectWebinarsList);
  return (
    <ul className={styles['webinars-list']}>
      <CreateNewWebinarLink />
      {Object.values(webinarsList).map((webinar) => (
        <WebinarListLink
          key={`${webinar.webinarKey}`}
          webinar={webinar}
        />
      ))}
    </ul>
  );
}