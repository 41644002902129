import { combineReducers } from '@reduxjs/toolkit';
import authentication from './authentication';
import supersets from './supersets';
import logs from './logs';
import supersetReducers from '@/store/reducers/admin/superset';

const adminReducers = combineReducers({
  authentication,
  supersets,
  superset: supersetReducers,
  logs,
});

export default adminReducers;