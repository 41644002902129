// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-general-link--mIKKt{display:flex;gap:0.9375rem}.wrap-general-link--mIKKt:not(:last-child){margin-bottom:1rem}.wrap-general-link--mIKKt .block-with-link--ZX3CZ{line-height:1.3425rem;color:var(--admin-main-font-color)}.wrap-general-link--mIKKt .block-with-link--ZX3CZ .link-to-start-survey--30Wjr{color:var(--secondary-grey)}.wrap-general-link--mIKKt .btn-copy-link--OxYz\\+{align-self:flex-end}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Superset/HeaderSuperset/GeneralLink/GeneralLink.module.sass"],"names":[],"mappings":"AAEA,0BACE,YAAA,CACA,aAAA,CACA,2CACE,kBAAA,CACF,kDACE,qBAAA,CACA,kCAAA,CACA,+EACE,2BAAA,CACJ,iDACE,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-general-link": "wrap-general-link--mIKKt",
	"block-with-link": "block-with-link--ZX3CZ",
	"link-to-start-survey": "link-to-start-survey--30Wjr",
	"btn-copy-link": "btn-copy-link--OxYz+"
};
export default ___CSS_LOADER_EXPORT___;
