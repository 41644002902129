import React from "react";
import styles from "./SuperItemRow.module.sass"
interface SuperItemRowProps {
    children: React.ReactNode;
    className?: string;
}
const SuperItemRow: React.FC<SuperItemRowProps> = ({ children, className }) => {
    return (
        <div className={`${styles['super-item-row']} ${className}`}>
            {children}
        </div>
    )
}

export default SuperItemRow;

