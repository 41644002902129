import React, { useMemo } from 'react';
import styles from './BUnitSpecDropdown.module.sass';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectUnitsSpecs } from '@/store/selectors/admin/superset/unitsTree';
import { isOneStringEnterAnother } from '@/helpers/helpers';
import CardSelectDropdown
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitSpec/CardSelectDropdown/CardSelectDropdown';

interface BUnitSpecDropdownProps {
  inputValue: string;
  currentSpec: string;
  handleUpdateSpec: (arg: string) => void;
  unitSpecs: string[];
  inModal?: boolean
}

function BUnitSpecDropdown({
  currentSpec, inputValue, handleUpdateSpec, unitSpecs, inModal = false,
}: BUnitSpecDropdownProps) {
  const unitsSpecs: string[] = useAdminSelector(selectUnitsSpecs);

  const filteredSpecs = useMemo(() => [inputValue, ...unitsSpecs].filter((spec) => {
    if (!spec.trim()) return false;
    if (spec === currentSpec) return false;
    if (unitSpecs.includes(spec)) return false;
    return isOneStringEnterAnother(spec, inputValue);
  }), [currentSpec, inputValue, unitSpecs, unitsSpecs]);
  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // предотвращает потерю фокуса
  };

  if (!filteredSpecs.length) return null;

  return (
    <CardSelectDropdown classNames={`${inModal && styles['in-modal']}`}>
      {filteredSpecs.map((spec) => (
        <button
          key={`dropdown${spec}`}
          className={styles['select-spec-btn']}
          type="button"
          onMouseDown={handleMouseDown}
          onClick={() => {
            handleUpdateSpec(spec);
          }}
        >
          <span>
            {spec}
            {!unitsSpecs.includes(spec) && (
              <sup className={styles.plus}>new</sup>
            )}
          </span>
        </button>
      ))}
    </CardSelectDropdown>
  );
}

export default BUnitSpecDropdown;