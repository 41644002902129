import React, { useState } from 'react';
import styles from './ResultUploadingTemplates.module.sass';
import { selectUploadResourcesInfo } from '@/store/selectors/admin/superset/projects';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import UploadingTemplateInfo
  from '@/components/App/Admin/ListSupersets/ModalCreateSuperset/FormUploadingResources/ResultUploading/ResultUploadingTemplates/UploadingTemplateInfo/UploadingTemplateInfo';
import UploadingTemplateButtons
  from '@/components/App/Admin/ListSupersets/ModalCreateSuperset/FormUploadingResources/ResultUploading/ResultUploadingTemplates/UploadingTemplateButtons/UploadingTemplateButtons';
import Preloader from '@/components/App/Admin/SupersetSettings/Preloader';
import { SupersetId } from '@/store/types/admin/supersets';

interface ResultUploadingTemplatesProps {
  onSuccess: (supersetId: SupersetId | void) => void;
}

function ResultUploadingTemplates({ onSuccess }: ResultUploadingTemplatesProps) {
  const { errs, templateCode } = useAdminSelector(selectUploadResourcesInfo);

  const [inputValue, setInputValue] = useState('');
  const { commitTemplateStatus, creatingProjectStatus } = useAdminSelector((state) => state.admin.superset.projects);
  const { createSupersetStatus } = useAdminSelector((state) => state.admin.supersets);

  if (commitTemplateStatus === 'loading'
     || creatingProjectStatus === 'loading'
      || createSupersetStatus === 'loading') return <Preloader />;

  return (
    <div className={styles['wrap-result-uploading-templates']}>
      <div className={styles['wrap-uploading-success']}>
        <UploadingTemplateInfo
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
        <UploadingTemplateButtons
          withErrors={errs && !!errs.length}
          templateCode={templateCode}
          inputValue={inputValue}
          onSuccess={onSuccess}
        />
      </div>
    </div>
  );
}

export default ResultUploadingTemplates;