// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".units-menu-btn--vJUFp{height:32px;padding:2px 16px;margin:4px 0 4px 0;border:none;border-radius:8px;font-family:\"Roboto\",sans-serif;font-size:16px}.units-menu-btn--vJUFp:not(:first-child){margin:4px 0 4px 8px}.units-menu-btn--vJUFp.confirm--2Zt-9{color:#fff;background:#2279fc}.units-menu-btn--vJUFp.reject--ht2ls{background:var(--admin-background-interactive-elements);color:var(--admin-color-font-interactive-elements)}.units-menu-btn--vJUFp.reject--ht2ls:hover{background:var(--admin-hover-background-interactive-elements);color:var(--admin-color-font-hover-interactive-elements)}.units-menu-btn--vJUFp.not-active--c9Cj5{border:1px solid var(--secondary-color);color:var(--secondary-color);background:var(--content-block-bg)}.units-menu-btn--vJUFp:hover{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnitsBlueBtn/BUnitsMenuBtn.module.sass"],"names":[],"mappings":"AAAA,uBACE,WAAA,CACA,gBAAA,CACA,kBAAA,CACA,WAAA,CACA,iBAAA,CACA,+BAAA,CACA,cAAA,CACA,yCACE,oBAAA,CACF,sCACE,UAAA,CACA,kBAAA,CACF,qCACE,uDAAA,CACA,kDAAA,CACA,2CACE,6DAAA,CACA,wDAAA,CACJ,yCACE,uCAAA,CACA,4BAAA,CACA,kCAAA,CACF,6BACE,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"units-menu-btn": "units-menu-btn--vJUFp",
	"confirm": "confirm--2Zt-9",
	"reject": "reject--ht2ls",
	"not-active": "not-active--c9Cj5"
};
export default ___CSS_LOADER_EXPORT___;
