// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-option-label--t-MFU{display:flex;flex-direction:column;gap:0.625rem}.wrap-option-label--t-MFU .description--iYiYr{color:#85878e;font-size:0.875rem}.checkpoints-modal-content--yKjH8{margin:0 0 1.5rem 0}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/LoadResults/CalculateResults/CalculateResultsModal/CalculateResultsModalContent/CalculateResultsModalContent.module.sass"],"names":[],"mappings":"AAEA,0BACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,8CACE,aAAA,CACA,kBAAA,CAEJ,kCACE,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-option-label": "wrap-option-label--t-MFU",
	"description": "description--iYiYr",
	"checkpoints-modal-content": "checkpoints-modal-content--yKjH8"
};
export default ___CSS_LOADER_EXPORT___;
