import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ListOfCriteria.module.sass';
import Select from '@/components/Common/form/Select';
import { setSelectedCriteria } from '@/store/actions/admin/superset/process/cuts/cuts';
import {
  selectAmountsActorsOptions,
  selectCriteria,
  selectSelectedCriteria,
} from '@/store/selectors/admin/superset/process/cuts/cuts';
import Checkmark from '@/components/Common/Elements/cssIcons/Checkmark';
import MacrosReplacer from '@/components/App/Admin/MacrosReplacer';
import useNLS from '@/components/App/Admin/hooks/hooks';

function ListOfCriteria() {
  const dispatch = useDispatch();
  const criteria = useSelector(selectCriteria);
  const selectedCriteria = useSelector(selectSelectedCriteria);
  const amountsActorsOptions = useSelector(selectAmountsActorsOptions);
  const tNLS = useNLS();

  // TODO сейчас Select компонент работает идиотски (для инит выбранных значений пришлось писать эту фукнцию). Надо бы заменить компонент Select на готовый.
  const getSelectedDataInFormatSelect = (criteriaID) => {
    const format = [];

    if (selectedCriteria[criteriaID]) {
      selectedCriteria[criteriaID].forEach((isSelected, i) => {
        if (isSelected) {
          format.push({
            label: criteria.find(({ id }) => id === criteriaID).cases[i].text,
            value: i,
          });
        }
      });
    }
    return format;
  };

  const onChangeSelectedCriteria = (criteriaID, selectedValues) => {
    const updateSelectedCriteria = { ...selectedCriteria };

    const currentCriteria = criteria.find(({ id }) => id === criteriaID);
    const selectedOptions = new Array(currentCriteria.cases.length).fill(false);

    if (selectedValues.length > 0) {
      selectedValues.forEach(({ value: selectedOptionIndx }) => {
        selectedOptions[selectedOptionIndx] = true;
      });

      updateSelectedCriteria[criteriaID] = selectedOptions;
    } else {
      delete updateSelectedCriteria[criteriaID];
    }

    dispatch(setSelectedCriteria(updateSelectedCriteria));
  };

  const customOption = ({ label, checked, amountActors }) => (
    <div className={styles['custom-criteria-option']}>
      {checked && (
        <Checkmark
          externalClassNames={{
            checkmarkStyle: styles.checkmark,
          }}
        />
      )}
      <div className={styles.label}>{label}</div>
      {checked && amountActors >= 0 && (
        <div className={styles['wrap-amount-actors']}>
          <span className={`icon-employee ${styles['icon-user']}`} />
          {amountActors}
        </div>
      )}
    </div>
  );

  return (
    <div className={styles['criteria-list']}>
      {criteria.map(({ id, title, cases }) => {
        if (cases && cases.length > 0) {
          return (
            <div key={id} className={styles['wrap-criteria']}>
              <div className={styles.title}>
                <MacrosReplacer>{title}</MacrosReplacer>
              </div>
              <Select
                id={`criteriaOptions-${id}`}
                name={`criteriaOptions-${id}`}
                multiple
                externalClasses={{
                  wrapSelect: styles['wrap-criteria-select'],
                }}
                customOption={customOption}
                onChange={(selectedValues) => onChangeSelectedCriteria(id, selectedValues)}
                value={getSelectedDataInFormatSelect(id)}
                options={[
                  {
                    label: tNLS('cuts.select.option'),
                    placeholder: true,
                  },
                  ...cases.map(({ text }, i) => ({
                    label: text,
                    value: i,
                    amountActors: amountsActorsOptions[id]
                      ? amountsActorsOptions[id][i]
                      : null,
                  })),
                ]}
              />
            </div>
          );
        }

        return null;
      })}
    </div>
  );
}

export default ListOfCriteria;