import React from 'react';

interface TextBlockProps {
    block: ArticleTextBlock;
    className?: string;
}

export const TextBlock: React.FC<TextBlockProps> = ({ block, className }) => {

    return (
        <textarea
            defaultValue={block.content}
            className={className}
        // onChange={(v) => v}
        />
    );
}
