// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".webinars-header--cOAX7{display:flex;gap:0.75rem;padding:0 0 1.5rem 0}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SuperAdmin/Webinars/WebinarsList/WebinarsFilteredList/WebinarsListHeader/WebinarsListHeader.module.sass"],"names":[],"mappings":"AAEA,wBACE,YAAA,CACA,WAAA,CACA,oBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"webinars-header": "webinars-header--cOAX7"
};
export default ___CSS_LOADER_EXPORT___;
