import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { selectAdmin } from '@/store/selectors/admin/authentication';
import {
  loadLogsCurrentPage,
  loadLogsNextPage,
  loadLogsPrevPage,
  setNextPage,
  setPrevPage,
} from '@/store/actions/admin/logs';
import styles from './LogInfo.module.sass';
import selectLogs from '@/store/selectors/admin/logs';
import Preloader from '@/components/App/Admin/SupersetSettings/Preloader';
import Input from '@/components/Common/form/Input';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';

const padTo2Digits = (num) => num.toString().padStart(2, '0');

const getCurrentDate = () => {
  const today = new Date();

  return [
    padTo2Digits(today.getDate()),
    padTo2Digits(today.getMonth() + 1),
    padTo2Digits(today.getFullYear()),
  ].join('.');
};

const formatDate = (dateStr) => {
  if (!dateStr) {
    return '';
  }

  const date = new Date(dateStr.split('-'));

  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getFullYear()),
  ].join('.');
};

const dateStrToInputDateFormat = (date) => (date ? date.split('.').reverse().join('-') : null);

const getKey = (iterator) => iterator;

const INITIAL_LOAD_LOGS_PARAMS = {
  pageNum: 0,
  pageSize: 50,
  rangeBegin: null,
  rangeEnd: getCurrentDate(),
};

function LogInfo() {
  const dispatch = useDispatch();
  const { isSuper } = useSelector(selectAdmin);
  const {
    logs: { currentPageLogs, nextPageLogs, prevPageLogs },
    status,
  } = useSelector(selectLogs);
  const { rangeBegin, rangeEnd } = INITIAL_LOAD_LOGS_PARAMS;
  const { register, watch, reset } = useForm({
    defaultValues: {
      rangeBegin: dateStrToInputDateFormat(rangeBegin),
      rangeEnd: dateStrToInputDateFormat(rangeEnd),
    },
  });
  const [pageNumber, setPageNumber] = useState(
    INITIAL_LOAD_LOGS_PARAMS.pageNum,
  );
  const [rangeDate, setRangeDate] = useState({
    rangeBegin: INITIAL_LOAD_LOGS_PARAMS.rangeBegin,
    rangeEnd: INITIAL_LOAD_LOGS_PARAMS.rangeEnd,
  });

  useEffect(() => {
    if (isSuper) {
      dispatch(loadLogsCurrentPage(INITIAL_LOAD_LOGS_PARAMS));
    }
  }, [dispatch, isSuper]);

  useEffect(() => {
    const watchSubscription = watch((dateRangeValues) => {
      Object.keys(dateRangeValues).forEach((fieldName) => {
        dateRangeValues[fieldName] = dateRangeValues[fieldName] || null;
      });

      dispatch(
        loadLogsCurrentPage({
          ...INITIAL_LOAD_LOGS_PARAMS,
          ...dateRangeValues,
        }),
      );

      setRangeDate(dateRangeValues);
    });

    return () => {
      watchSubscription.unsubscribe();
    };
  }, [watch, dispatch]);

  useEffect(() => {
    if (isSuper) {
      dispatch(
        loadLogsNextPage({
          ...INITIAL_LOAD_LOGS_PARAMS,
          ...rangeDate,
          pageNum: pageNumber - 1,
        }),
      );

      dispatch(
        loadLogsPrevPage({
          ...INITIAL_LOAD_LOGS_PARAMS,
          ...rangeDate,
          pageNum: pageNumber + 1,
        }),
      );
    }
  }, [pageNumber, rangeDate, dispatch, isSuper]);

  if (!isSuper) {
    return <Navigate to="/admin/projects" />;
  }

  if (status === 'idle') {
    return <Preloader />;
  }

  const onSetLastLogs = () => {
    reset({}, { keepDefaultValues: true });
    setPageNumber(INITIAL_LOAD_LOGS_PARAMS.pageNum);
    setRangeDate({
      rangeBegin: INITIAL_LOAD_LOGS_PARAMS.rangeBegin,
      rangeEnd: INITIAL_LOAD_LOGS_PARAMS.rangeEnd,
    });
  };

  const onPrevPage = () => {
    dispatch(setPrevPage());
    setPageNumber(pageNumber + 1);
  };

  const onNextPage = () => {
    dispatch(setNextPage());
    setPageNumber(pageNumber - 1);
  };

  return (
    <div className={styles['wrap-logs-info']}>
      <div className={styles['control-panel']}>
        <form>
          <Input
            register={register}
            registerOptions={{
              setValueAs: formatDate,
            }}
            type="date"
            name="rangeBegin"
            id="fromDate"
            label="С какой даты начать:"
          />
          <Input
            register={register}
            registerOptions={{
              setValueAs: formatDate,
            }}
            type="date"
            name="rangeEnd"
            id="toDate"
            label="С какой даты закончить:"
          />

          <CustomButton
            styleTypeBtn="btn-grey btn-large"
            onClick={onSetLastLogs}
          >
            К последним логам
          </CustomButton>
        </form>
      </div>
      <table className={styles['table-logs-info']}>
        <thead>
          <tr>
            <th>Время</th>
            <th>Ошибка</th>
            <th>Usecase</th>
            <th>Repository</th>
          </tr>
        </thead>
        <tbody>
          {currentPageLogs.map((log, i) => {
            const {
              date,
              usecase,
              repository,
              usecaseFunc,
              repositoryFunc,
              usecaseFile,
              repositoryFile,
              usecaseLine,
              repositoryLine,
              errorMessage,
            } = log;

            return (
              <tr key={getKey(i)}>
                <td>{`${date}`}</td>
                <td>{errorMessage}</td>
                <td>
                  {usecase
                    && `${usecase}, ${usecaseFile}, ${usecaseFunc}(${usecaseLine})`}
                </td>
                <td>
                  {repository
                    && `${repository}, ${repositoryFile}, ${repositoryFunc}(${repositoryLine})`}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={styles['wrap-btns-switcher-pages']}>
        <CustomButton
          styleTypeBtn="btn-grey btn-blue-modal"
          classNames={styles['btn-switcher-pages']}
          onClick={onPrevPage}
          disabled={prevPageLogs.length === 0}
        >
          Prev
        </CustomButton>
        <CustomButton
          styleTypeBtn="btn-grey btn-blue-modal"
          classNames={styles['btn-switcher-pages']}
          onClick={onNextPage}
          disabled={pageNumber === 0 || nextPageLogs.length === 0}
        >
          Next
        </CustomButton>
      </div>
    </div>
  );
}

export default LogInfo;