import React from 'react';
import { useSelector } from 'react-redux';
import styles from './HeaderSuperset.module.sass';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { selectAdminSettingsValue } from '@/store/selectors/admin/superset/settings';
import {
  OPTION_BU_LINK,
  OPTION_PERSONAL_LINK,
  USE_UNITS_SETTING_ID,
} from '@/constants/admin';
import GeneralLink from '@/components/App/Admin/Superset/HeaderSuperset/GeneralLink';
import LinksForOtherSurveys from '@/components/App/Admin/Superset/HeaderSuperset/LinksForOtherSurveys';
import AssessmentStatus from '@/components/App/Admin/Superset/HeaderSuperset/AssessmentStatus';

function HeaderSuperset() {
  const { company, name, extra: extraLinks } = useSelector(selectSuperset);
  // eslint-disable-next-line
  const useUnitsSettingValue = useSelector((state) => selectAdminSettingsValue(state, [USE_UNITS_SETTING_ID]),)[USE_UNITS_SETTING_ID]?.value;

  return (
    <header className={styles['header-superset']}>
      <div className={styles.company}>{company}</div>
      <div className={styles.name}>
        {name}
        {' '}
        <AssessmentStatus />
      </div>
      <div className={styles['wrap-links-survey']}>
        {useUnitsSettingValue !== OPTION_BU_LINK
          && useUnitsSettingValue !== OPTION_PERSONAL_LINK && (
            <>
              <GeneralLink />
              <GeneralLink isDemo />
            </>
        )}
        {Object.keys(extraLinks).length > 0 && <LinksForOtherSurveys />}
      </div>
    </header>
  );
}

export default HeaderSuperset;