import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import styles from './BtnSNADirection.module.sass';
import { getClearClassNames } from '@/helpers/helpers';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { loadUnitCuts } from '@/store/actions/admin/superset/process/cuts/unitsCuts';
import { selectSNADirection } from '@/store/selectors/admin/superset/process/cuts/unitsCuts';

function BtnSNADirection() {
  const dispatch = useDispatch();
  const SNADirection = useSelector(selectSNADirection);
  const tNLS = useNLS();

  const onChangeDirectionSNA = () => {
    dispatch(
      loadUnitCuts({
        direction: !SNADirection,
      }),
    );
  };

  return (
    <>
      <Tooltip id="btnSwitchSNADirection" />
      <button
        type="button"
        className={getClearClassNames([
          'btn-without-style',
          styles['btn-switch-sna-direction'],
          SNADirection ? styles.outgoing : styles.incoming,
        ])}
        data-tooltip-id="btnSwitchSNADirection"
        data-tooltip-content={
          SNADirection
            ? tNLS('cuts.switch.outbound')
            : tNLS('cuts.switch.inbound')
        }
        onClick={onChangeDirectionSNA}
      >
        {SNADirection ? (
          <span
            className={`${styles['icon-direction']} ${styles.outgoing} icon-outgoing`}
          />
        ) : (
          <span
            className={`${styles['icon-direction']} ${styles.incoming} icon-incoming`}
          />
        )}
      </button>
    </>
  );
}

export default BtnSNADirection;