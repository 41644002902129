// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".select-button--\\+4Eei{height:32px;margin:0 0 1.25rem 0;white-space:nowrap}.select-button--\\+4Eei.card--A454C{width:100%;margin:0 0.5rem 0 0}.select-option--2tLMP{height:32px;white-space:nowrap}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitTransferModal/SelectTargetDepartment/SelectTargetDepartment.module.sass"],"names":[],"mappings":"AAEA,uBACE,WAAA,CACA,oBAAA,CACA,kBAAA,CACA,mCACE,UAAA,CACA,mBAAA,CACJ,sBACE,WAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select-button": "select-button--+4Eei",
	"card": "card--A454C",
	"select-option": "select-option--2tLMP"
};
export default ___CSS_LOADER_EXPORT___;
