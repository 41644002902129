import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '@/components/Common/BaseTemplate/MainContentContainer/AdminSupersetContainer/AdminSupersetContainer.module.sass';
import BtnSwitchLangs from '@/components/App/Admin/UIElements/BtnSwitchLangs';
import ProhibitInteractivity from '@/components/App/Admin/SupersetSettings/ProhibitInteractivity';
import BtnCheckboxSlider from '@/components/App/Admin/UIElements/BtnCheckboxSlider';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { updateSuperset } from '@/store/actions/admin';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';
import CopyContentBtn from '@/components/Common/CopyContentBtn';

function AdminSupersetContainerControls() {
  const dispatch = useDispatch();
  const { supersetId, paused } = useSelector(selectSuperset);
  const checkPermission = useCheckPermission();

  const onToggleStatusPausedProject = () => {
    dispatch(
      updateSuperset({
        supersetID: supersetId,
        updatedSuperset: {
          superset: { paused: !paused },
        },
      }),
    );
  };

  return (
    <div className={styles['wrap-right-controls']}>
      <BtnSwitchLangs />
      {checkPermission() && (
        <ProhibitInteractivity>
          <CopyContentBtn
            strToCopy={supersetId}
            message="SupersetID copied to clipboard"
          />
          <BtnCheckboxSlider
            onChange={onToggleStatusPausedProject}
            checked={!paused}
            externalClassNames={styles['btn-toggle-superset']}
          />
        </ProhibitInteractivity>
      )}
    </div>
  );
}

export default AdminSupersetContainerControls;