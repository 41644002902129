import React, { useMemo } from 'react';
import { UnitId } from '@/store/types/user/units';
import { TreeUnit } from '@/store/types/admin/superset/unitsTree';
import EmployeeUnit from './EmployeeUnit/EmployeeUnit';
import DepartmentUnit from './DepartmentUnit/DepartmentUnit';
import BUnitContext, {
  BUnitContextProps,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import useLocalStorageByAdmin from '@/components/App/Admin/BUnitsSection/UnitsTree/hooks/adminLocalStorageSaver';

interface NewBUnitProps {
  id: UnitId;
  unit: TreeUnit;
  index?: number;
}

function BUnit({
  id, unit, index = 0,
}: NewBUnitProps) {
  const contextValue: BUnitContextProps = useMemo(
    () => ({ id, unit, index }),
    [id, index, unit],
  );

  const [writeLocal, readLocal] = useLocalStorageByAdmin<UnitId[]>('expandedUnits');

  const toggleChildrenVisibility = () => {
    const expandedUnits = readLocal() || [];

    if (expandedUnits?.includes(id)) {
      writeLocal(expandedUnits.filter((ID) => ID !== id));
      return;
    }

    writeLocal([...expandedUnits, id]);
  };

  return (
    <BUnitContext.Provider value={contextValue}>
      { unit.isunit === 0
        ? <EmployeeUnit />
        : <DepartmentUnit toggleChildren={toggleChildrenVisibility} />}
    </BUnitContext.Provider>
  );
}

export default BUnit;