import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Tooltip.module.sass';

function Tooltip({ text, children, tooltipClassNames }) {
  const [showTooltip, toggleTooltip] = useState(false);
  const handleMouseEnter = () => {
    toggleTooltip(true);
  };

  const handleMouseLeave = () => {
    toggleTooltip(false);
  };
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={styles['tooltip-container']}
    >
      {children}
      {showTooltip && (
        <div className={`${tooltipClassNames} ${styles.tooltip}`}>{text}</div>
      )}
    </div>
  );
}

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  tooltipClassNames: PropTypes.string,
};

Tooltip.defaultProps = {
  tooltipClassNames: '',
};

export default Tooltip;