import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ArrowIcon from '@/components/Common/Elements/cssIcons/ArrowIcon';
import styles from './LinkToBackToPrevPage.module.sass';

function LinkToBackToPrevPage({ classNames, children: text, to }) {
  return (
    <div className={`${styles['link-to-back-to-prev-page']} ${classNames}`}>
      <Link to={to}>
        <ArrowIcon classNames={styles['arrow-icon-left']} direction="left" />
        {text}
      </Link>
    </div>
  );
}

LinkToBackToPrevPage.defaultProps = {
  classNames: '',
};

LinkToBackToPrevPage.propTypes = {
  classNames: PropTypes.string,
  children: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default LinkToBackToPrevPage;