import React from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import styles from './AddingQuestionsHeader.module.sass';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import useNLS from '@/components/App/Admin/hooks/hooks';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';

function AddingQuestionsHeader({ answers, createQuestionToggleModal }) {
  const tNLS = useNLS();
  const checkPermission = useCheckPermission();
  return (
    <div className={styles['header-adding-questions']}>
      <div className={styles['main-title']}>{tNLS('customq.header')}</div>

      <div className={styles.instructions}>
        {tNLS('customq.description')}
        {answers && (
          <ul className={styles['wrap-list-answers']}>
            {answers.map((answer, index) => {
              const key = `answer-${index}`;

              return <li key={key}>{parse(answer)}</li>;
            })}
          </ul>
        )}
      </div>
      {checkPermission('add_custom_questions') && (
        <CustomButton
          styleTypeBtn="btn-grey btn-large"
          classNames={styles['btn-on-add-question']}
          onClick={createQuestionToggleModal}
        >
          {tNLS('customq.button.add')}
        </CustomButton>
      )}
    </div>
  );
}

AddingQuestionsHeader.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.string).isRequired,
  createQuestionToggleModal: PropTypes.func.isRequired,
};

export default AddingQuestionsHeader;