import { combineReducers } from '@reduxjs/toolkit';
import cuts from './cuts';
import questionsCuts from './questionsCuts';
import unitsCuts from './unitsCuts';
import factorsCuts from './factrosCuts';
import fourD from './fourD';

const cutsReducers = combineReducers({
  cuts,
  questionsCuts,
  unitsCuts,
  factorsCuts,
  fourD,
});

export default cutsReducers;