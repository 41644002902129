import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './EditableResizeableInput.module.sass';
import { getClearClassNames } from '@/helpers/helpers';

function EditableResizeableInput({
  id,
  name,
  defaultValue = '',
  label = null,
  icon = null,
  register = null,
  registerOptions = {},
  externalClasses = '',
  validationError = '',
  disabled = false,
  notInteractive = false,
  ...props
}) {
  const { ref, onBlur, ...restRegisterFields } = {
    ...register(name, registerOptions),
  };
  const inputRef = useRef(null);
  const initValueRef = useRef(defaultValue);

  const resizeInput = (e) => {
    const input = e.target;

    input.style.width = 0;
    input.style.width = `${input.scrollWidth}px`;
  };

  const clearIfEscape = (e) => {
    if (e.key === 'Escape') {
      e.stopPropagation();
      e.preventDefault();
      e.target.value = defaultValue;
      resizeInput(e);
    }
  };

  const initWidthByPlaceholder = (input) => {
    const { placeholder } = input;
    input.value = placeholder;
    input.style.width = `${input.scrollWidth}px`;
    input.value = '';
  };

  useEffect(() => {
    const initValue = initValueRef.current;
    const input = inputRef.current;

    input.style.width = 0;

    if (initValue) {
      input.style.width = `${input.scrollWidth}px`;
    } else {
      initWidthByPlaceholder(input);
    }
  }, []);

  return (
    <div
      className={getClearClassNames([
        styles['wrap-editable-resizeable-field'],
        externalClasses.wrapField,
        styles['not-interactive'],
      ])}
    >
      {validationError && (
        <span className={styles['validation-error']}>{validationError}</span>
      )}
      <label
        className={getClearClassNames([styles['label-input']])}
        htmlFor={id}
      >
        {label && <span className={styles['label-text']}>{label}</span>}
        <input
          id={id}
          type="text"
          className={getClearClassNames([externalClasses.input])}
          ref={(e) => {
            ref(e);
            inputRef.current = e;
          }}
          defaultValue={defaultValue}
          onBlur={(e) => {
            if (e.target.value === '') {
              initWidthByPlaceholder(e.target);
            }

            onBlur(e);
          }}
          onKeyUp={(e) => {
            clearIfEscape(e);
          }}
          onInput={resizeInput}
          {...restRegisterFields}
          disabled={notInteractive || disabled}
          {...props}
        />
        {!notInteractive && (
          <span className={styles['icon-label']}>{icon && icon}</span>
        )}
      </label>
    </div>
  );
}

EditableResizeableInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  icon: PropTypes.element,
  externalClasses: PropTypes.shape({
    wrapField: PropTypes.string,
    input: PropTypes.string,
  }),
  register: PropTypes.func,
  registerOptions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  disabled: PropTypes.bool,
  notInteractive: PropTypes.bool,
  validationError: PropTypes.string,
};

export default EditableResizeableInput;