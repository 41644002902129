import React from 'react';
import PropTypes from 'prop-types';
import styles from './BUnitTypeRadioCase.module.sass';

function BUnitTypeRadioCase({
  id,
  children,
  isChecked,
  onCheck,
  inputClassNames,
  textClassNames,
  labelClassNames,
}) {
  return (
    <label className={labelClassNames} htmlFor={id}>
      <input
        type="radio"
        id={id}
        className={inputClassNames}
        checked={isChecked}
        onChange={onCheck}
      />
      <span className={textClassNames}>{children}</span>
    </label>
  );
}

BUnitTypeRadioCase.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  inputClassNames: PropTypes.string,
  textClassNames: PropTypes.string,
  labelClassNames: PropTypes.string,
};

BUnitTypeRadioCase.defaultProps = {
  inputClassNames: styles['radio-input'],
  textClassNames: styles['input-text'],
  labelClassNames: styles['radio-label'],
};

export default BUnitTypeRadioCase;