import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProcessing from '@/axiosConfig';
import { AdminState } from '@/store/admin';
import { webinarsSlise } from '@/components/App/Webinar/CreateWebinarPage/reducers/webinar';
import { CreateWebinarFieldsType } from '@/components/App/Webinar/CreateWebinarPage/types/webinarPageTypes';

export const getWebinarsList = createAsyncThunk(
  'admin/super/webinar',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosProcessing.get<WebinarType[]>('/api/admin/super/webinars');

      dispatch(webinarsSlise.actions.parceWebinar(response.data));

      return null;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const editWebinar = createAsyncThunk(
  'admin/super/createWebinar',
  async (data: CreateWebinarFieldsType, { rejectWithValue, getState }) => {
    const state = getState() as AdminState;
    const { headers } = state.admin.authentication;
    try {
      await axiosProcessing.post('/api/admin/super/webinar/store', data, { headers });

      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);