// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".unit-card--Ge4lr{margin:0;padding:0.75rem 0 0 0.5rem}.how-to-table--h6EO8{display:grid;grid-template-columns:max-content max-content;grid-column-gap:12px}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCard.module.sass"],"names":[],"mappings":"AAEA,kBACE,QAAA,CACA,0BAAA,CAEF,qBACE,YAAA,CACA,6CAAA,CACA,oBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unit-card": "unit-card--Ge4lr",
	"how-to-table": "how-to-table--h6EO8"
};
export default ___CSS_LOADER_EXPORT___;
