// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".units-type-container--srPI0{display:flex;flex-direction:column;margin-bottom:0.75rem;gap:0.25rem;grid-column:1/-1}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/CreateBUnitSection/CreateBUnitModal/BUnitType/BUnitType.module.sass"],"names":[],"mappings":"AAEA,6BACE,YAAA,CACA,qBAAA,CACA,qBAAA,CACA,WAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"units-type-container": "units-type-container--srPI0"
};
export default ___CSS_LOADER_EXPORT___;
