import React, { useContext } from 'react';
import { Tooltip } from 'react-tooltip';
import styles from './ActionButton.module.sass';
import newBUnitContext from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import useNLS from '@/components/App/Admin/hooks/hooks';

enum TooltipNlsByType {
  'pdf' = 'tree.highlight.download.personal.report',
  'link' = 'tree.highlight.open.personal.link',
  'pencil' = '',
  'trash' = 'tree.highlight.delete.employee',
}
interface ActionButtonProps {
  handleActionClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  buttonType: keyof typeof TooltipNlsByType;
  className?: string;
  isHidden?: boolean;
}

function ActionButton({
  handleActionClick, buttonType, className = '', isHidden = false,
}: ActionButtonProps) {
  const { id } = useContext(newBUnitContext);
  const tNLS = useNLS();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isHidden) return;
    handleActionClick(event);
  };

  return (
    <>
      {
       !isHidden && (
       <Tooltip
         id={`${id}-${buttonType}`}
         place="top"
         content={tNLS(TooltipNlsByType[buttonType])}
       />
       )
      }
      <button
        className={`${styles['action-button']} ${className} ${
          isHidden && styles.hidden
        } btn-without-style`}
        type="button"
        disabled={isHidden}
        onClick={handleClick}
        data-tooltip-id={`${id}-${buttonType}`}
      >
        {' '}
        <span className={`${styles['button-icon']} icon-${buttonType}`} />
      </button>
    </>
  );
}

export default ActionButton;