import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getWebinarsList } from '@/store/actions/admin/webinar';
import { mskToInputFormat } from '@/helpers/helpers';

const initialState: WebinarsInitialState = {
  webinarsList: {},
  loadingWebinarsStatus: 'idle',
};

export const webinarsSlise = createSlice({
  name: 'admin/super/webinars',
  initialState,
  reducers: {
    parceWebinar: (state, { payload }: PayloadAction<WebinarType[]>) => {
      state.webinarsList = payload.reduce(
        (acc, webinar) => ({
          ...acc,
          [webinar.webinarKey]: { ...webinar, startTime: mskToInputFormat(webinar.startTime) },
        }),
        {},
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWebinarsList.pending, (state) => {
        state.loadingWebinarsStatus = 'loadind';
      })
      .addCase(getWebinarsList.fulfilled, (state) => {
        state.loadingWebinarsStatus = 'received';
      })
      .addCase(getWebinarsList.rejected, (state) => {
        state.loadingWebinarsStatus = 'received';
      });
  },
});

const { reducer } = webinarsSlise;

export default reducer;