import React from 'react';
import styles from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/SelectMenu.module.sass';
import BUnitsSelectMenuActions from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/BUnitSelectMenuOptionsSelectors/BUnitsSelectMenuActions/BUnitsSelectMenuActions';
import BUnitsSelectMenuOptions from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/BUnitsSelectMenuOptions/BUnitsSelectMenuOptions';
import useNLS from '@/components/App/Admin/hooks/hooks';
import {
  BUnitSelectAction,
  SetCurrentSelectActionType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/useSelectAction';
import {
  BUnitSelectOption,
  SetCurrentSelectOptionType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/useSelectOption';
import getSelectActionsAndOptionsWithEnums from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/helpers';

interface BUnitsSelectMenuSelectorsProps {
  currentSelectAction: BUnitSelectAction;
  currentSelectOption: BUnitSelectOption;
  setCurrentSelectAction: SetCurrentSelectActionType;
  setCurrentSelectOption: SetCurrentSelectOptionType;
}
function BUnitsSelectMenuSelectors({
  currentSelectAction,
  setCurrentSelectAction,
  currentSelectOption,
  setCurrentSelectOption,
}: BUnitsSelectMenuSelectorsProps) {
  const tNLS = useNLS();
  const { selectOptions, selectActions } = getSelectActionsAndOptionsWithEnums(tNLS);

  return (
    <div className={styles['select-buttons']}>
      <BUnitsSelectMenuActions
        currentSelectAction={currentSelectAction}
        setCurrentSelectAction={setCurrentSelectAction}
        selectActions={selectActions}
      />
      <BUnitsSelectMenuOptions
        currentSelectOption={currentSelectOption}
        selectOptions={selectOptions}
        setCurrentSelectOption={setCurrentSelectOption}
      />
    </div>
  );
}

export default BUnitsSelectMenuSelectors;