// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loader--sb8a\\+{font-size:10px;margin:50px auto;text-indent:-9999em;width:11em;height:11em;border-radius:50%;background:#565964;background:linear-gradient(to right, #565964 10%, rgba(255, 255, 255, 0) 42%);position:relative;animation:load3--AHKgF 1s infinite linear;transform:translateZ(0)}.loader--sb8a\\+:before{width:50%;height:50%;background:#565964;border-radius:100% 0 0 0;position:absolute;top:0;left:0;content:\"\"}.loader--sb8a\\+:after{background:var(--main-bg);width:75%;height:75%;border-radius:50%;content:\"\";margin:auto;position:absolute;top:0;left:0;bottom:0;right:0}@keyframes load3--AHKgF{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SupersetSettings/Preloader/Preloader.module.sass"],"names":[],"mappings":"AAAA,gBACE,cAAA,CACA,gBAAA,CACA,mBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,kBAAA,CAKA,6EAAA,CACA,iBAAA,CAEA,yCAAA,CAGA,uBAAA,CACA,uBACE,SAAA,CACA,UAAA,CACA,kBAAA,CACA,wBAAA,CACA,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACF,sBACE,yBAAA,CACA,SAAA,CACA,UAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,KAAA,CACA,MAAA,CACA,QAAA,CACA,OAAA,CAWJ,wBACE,GAEE,sBAAA,CACF,KAEE,wBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "loader--sb8a+",
	"load3": "load3--AHKgF"
};
export default ___CSS_LOADER_EXPORT___;
