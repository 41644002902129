import React from 'react';
import styles from './ErrorUploadingTemplate.module.sass';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectUploadResourcesInfo } from '@/store/selectors/admin/superset/projects';
import useNLS from '@/components/App/Admin/hooks/hooks';
import UploadingErrorsAndWarns
  from '@/components/App/Admin/ListSupersets/ModalCreateSuperset/FormUploadingResources/ResultUploading/ResultUploadingTemplates/UploadingErrorsAndWarns/UploadingErrorsAndWarns';

function ErrorUploadingTemplate() {
  const { errs } = useAdminSelector(selectUploadResourcesInfo);
  const tNLS = useNLS();
  return (
    <>
      <div className={styles['error-title']}>
        {tNLS('template.upload.error')}
      </div>
      <UploadingErrorsAndWarns content={errs} />
    </>
  );
}

export default ErrorUploadingTemplate;