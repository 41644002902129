import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import Modal from '@/components/Common/Modal';
import Content from '@/components/Common/Modal/Content';
import SelectMailTemplatesLang from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitEmailModal/SelectMailTemplatesLang/SelectMailTemplatesLang';
import BUnitTemplatesContent from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitEmailModal/BUnitTemplatesContent/BUnitTemplatesContent';
import WrapBtns from '@/components/Common/Modal/WrapBtns';
import styles from './BunitEmailModal.module.sass';
import BUnitSelectEmailTemplate from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitEmailModal/BUnitSelectEmailTemplate/BUnitSelectEmailTemplate';
import { selectActiveLangIDsSortedByPriority } from '@/store/selectors/admin/superset/projects';
import BUnitsMenuBtn from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitsBlueBtn/BUnitsMenuBtn';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import useNLS from '@/components/App/Admin/hooks/hooks';
import SelectMenuTitle from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/BUnitSelectMenuOptionsSelectors/SelectMenuTitle/SelectMenuTitle';
import { makeUnitsGroupOperations } from '@/store/actions/admin/superset/unitsTree';
import { addNotification } from '@/store/reducers/notify';

function BUnitEmailModal({ isShowingModal, toggleModal }) {
  const [currentTemplateLang, setCurrentTemplateLang] = useState(undefined);
  const [currentTemplateId, setCurrentTemplateId] = useState(undefined);
  const tNLS = useNLS();
  const { supersetId } = useSelector(selectSuperset);
  const dispatch = useDispatch();
  const { languages } = useSelector(
    (state) => state.admin.superset.supersetFields,
  );
  const { mailTemplates } = useSelector(
    (state) => state.admin.superset.supersetFields,
  );
  const activeProjectLangsId = useSelector(selectActiveLangIDsSortedByPriority);

  useEffect(() => {
    if (currentTemplateLang) return;
    if (Object.keys(mailTemplates).length === 1) {
      setCurrentTemplateLang(Object.keys(mailTemplates)[0]);
      return;
    }
    for (let i = 0; i < activeProjectLangsId.length; i += 1) {
      const langId = activeProjectLangsId[i];
      if (mailTemplates[langId]) {
        setCurrentTemplateLang(langId);
        return;
      }
    }
    const sortedLangsId = Object.keys(languages).sort(
      (A, B) => languages[A].priority - languages[B].priority,
    );
    for (let i = 0; i < sortedLangsId.length; i += 1) {
      const langId = sortedLangsId[i];
      if (mailTemplates[langId]) {
        setCurrentTemplateLang(langId);
        return;
      }
    }
  }, [activeProjectLangsId, currentTemplateLang, languages, mailTemplates]);

  useEffect(() => {
    if (!currentTemplateLang) return;
    if (
      mailTemplates[currentTemplateLang]
      && mailTemplates[currentTemplateLang][currentTemplateId]
    ) return;
    const templatesByLangId = Object.keys(mailTemplates[currentTemplateLang]);
    setCurrentTemplateId(templatesByLangId[0]);
  }, [currentTemplateId, currentTemplateLang, mailTemplates]);

  const sendEmail = () => {
    dispatch(
      makeUnitsGroupOperations({
        supersetId,
        operationType: 'sendEmail',
        langId: currentTemplateLang,
        template: currentTemplateId,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        toggleModal(false);
        dispatch(
          addNotification({
            message: '',
            type: 'success',
          }),
        );
      })
      .catch(() => {
        toggleModal(false);
        dispatch(
          addNotification({
            message: '',
            type: 'error',
          }),
        );
      });
  };

  return (
    <Modal
      isShowing={isShowingModal}
      hide={() => toggleModal(false)}
      title="Выберите шаблон"
      externalClassNames={{
        contentClass: styles['email-modal-content'],
        modal: styles['email-modal'],
      }}
    >
      <Content>
        <SelectMenuTitle />
        {mailTemplates && Object.keys(mailTemplates).length > 1 && (
          <SelectMailTemplatesLang
            languagesId={Object.keys(mailTemplates)}
            currentTemplateLang={currentTemplateLang}
            setCurrentTemplateLang={setCurrentTemplateLang}
          />
        )}
        {currentTemplateId && (
          <BUnitSelectEmailTemplate
            mailTemplates={mailTemplates[currentTemplateLang]}
            currentTemplateId={currentTemplateId}
            setCurrentTemplateId={setCurrentTemplateId}
          />
        )}
        {currentTemplateId && (
          <BUnitTemplatesContent
            templatesByLang={mailTemplates[currentTemplateLang]}
            currentTemplateId={currentTemplateId}
            currentTemplateLang={currentTemplateLang}
          />
        )}
        <div className={styles['email-modal-buttons']}>
          <BUnitsMenuBtn
            type="reject"
            additionalClassNames={`${styles['decline-button']} ${styles['modal-button']}`}
            handleButtonClick={() => toggleModal(false)}
          >
            {tNLS('mail.tmpl.cancel')}
          </BUnitsMenuBtn>
          <BUnitsMenuBtn
            additionalClassNames={styles['modal-button']}
            handleButtonClick={() => {
              sendEmail();
            }}
          >
            {tNLS('mail.tmpl.send')}
          </BUnitsMenuBtn>
        </div>
      </Content>
      <WrapBtns />
    </Modal>
  );
}

BUnitEmailModal.propTypes = {
  isShowingModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
export default BUnitEmailModal;