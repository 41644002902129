import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getResultsByRatings,
  loadRatingsFourD, setScoreType,
  setSelectedParams,
} from '@/store/actions/admin/superset/process/cuts/fourD';
import {
  FourDSliceInitialState, Rating, RatingResult, SelectedParams,
} from '@/store/types/admin/superset/cuts/fourD';
import { ABSOLUTE_SCORE_TYPE, PERCENTILE_SCORE_TYPE } from '@/components/App/Admin/Results/Cuts/FourD/constants';

const initialState: FourDSliceInitialState = {
  ratings: [],
  selectedParams: null,
  ratingsResults: null,
  scoreType: PERCENTILE_SCORE_TYPE,
};

const fourDSlice = createSlice({
  name: 'admin/superset/process/cuts/fourD',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadRatingsFourD.fulfilled, (
      state,
      action: PayloadAction<Rating[]>,
    ) => {
      state.ratings = action.payload;
    }).addCase(getResultsByRatings.fulfilled, (
      state,
      action: PayloadAction<RatingResult[]>,
    ) => {
      state.ratingsResults = action.payload;
    }).addCase(setSelectedParams, (state, action: PayloadAction<SelectedParams>) => {
      state.selectedParams = action.payload;
    }).addCase(setScoreType, (state) => {
      state.scoreType = state.scoreType === PERCENTILE_SCORE_TYPE ? ABSOLUTE_SCORE_TYPE : PERCENTILE_SCORE_TYPE;
    });
  },
});

export default fourDSlice.reducer;