import React, {
  ReactNode, useEffect, useState,
} from 'react';
import useMeasure, { Options } from 'react-use-measure';
import { useSpring, a } from '@react-spring/web';
import styles from './AnimatedContainerByHeight.module.sass';
import { getClearClassNames } from '@/helpers/helpers';

interface UnitAnimatedContainerByHeightProps {
  children?: ReactNode;
  isOpen: boolean;
  measureOptions?: Options;
  durationInMs?: number;
  classNames?: string;
  toggleAnimationState?: (arg: boolean) => void
}

function AnimatedContainerByHeight({
  children, isOpen, measureOptions = {}, durationInMs = 150, classNames = '', toggleAnimationState,
}: UnitAnimatedContainerByHeightProps) {
  const [mesRef, { height: viewHeight }] = useMeasure(measureOptions);
  const [isShowing, toggleShowing] = useState<boolean>(isOpen);
  const [isAnimation, toggleAnimation] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) toggleShowing(true);
  }, [isOpen]);

  const animationProps = useSpring({
    height: isOpen ? viewHeight : 0,
    opacity: isOpen ? 1 : 0,
    config: {
      duration: durationInMs,
    },
    onStart: () => {
      toggleAnimation(true);
      if (toggleAnimationState) toggleAnimationState(true);
    },
    onRest: () => {
      toggleAnimation(false);
      if (!isOpen) toggleShowing(false);
      if (toggleAnimationState) toggleAnimationState(false);
    },
  });

  return (
    <a.div
      className={getClearClassNames([
        styles['main-container'],
        isAnimation && styles.animated,
      ])}
      style={animationProps}
    >
      {isShowing
        && (
        <div
          style={{ position: 'relative' }}
          className={getClearClassNames([
            classNames,
          ])}
          ref={mesRef}
        >
          {children}
        </div>
        )}
    </a.div>
  );
}

export default AnimatedContainerByHeight;