import React, { ButtonHTMLAttributes, useContext } from 'react';
import styles from './NewBUnitButton.module.sass';
import newBUnitContext from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';

interface NewBUnitButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {

}

function NewBUnitButton({ ...buttonProps }: NewBUnitButtonProps) {
  const { unit } = useContext(newBUnitContext);

  return (
    <button
      type="button"
      {...buttonProps}
      className={`${styles['unit-button']} ${buttonProps.className}`}
    >
      {unit.name}
    </button>
  );
}

export default NewBUnitButton;