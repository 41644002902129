import {
  useCallback, useEffect, useState,
} from 'react';
import useLogarithmicDelay from './logarifmTimer';

interface UseLongPolingProps <F, T> {
  action: (args: T | undefined) => Promise<F>;
  args?: T;
  startInterval?: number;
  successfulCallback?: (arg: F | undefined) => void;
  errorCallback?: (arg: F | undefined) => void;
  finallyCallback?: () => void;
  isSubscribeAvailable?: boolean;
}

const useLongPooling = <PromiseResult, Args>({
  action, // экшн, который вызывает запрос
  args, // аргументы для запроса
  startInterval = 0, // время, через которое подписываемся после завершения запроса
  successfulCallback = () => null, // функцию, исполняюаяся после выполнения запроса
  errorCallback = () => null, // коллбэк при ошибке
  finallyCallback = () => null,
  isSubscribeAvailable = true,
}: UseLongPolingProps<PromiseResult, Args>) => {
  const {
    delay, resetDelay, increaseIteration,
  } = useLogarithmicDelay({ baseDelay: startInterval, grouthRate: 3 });
  const [isSubscribed, toggleSubscription] = useState<boolean>(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);

  const callAction = useCallback(
    () => {
      action(args)
        .then((response) => {
          if (delay !== startInterval) resetDelay();
          if (successfulCallback) successfulCallback(response);
        })
        .catch((err) => {
          increaseIteration();
          if (errorCallback) errorCallback(err);
        })
        .finally(() => {
          toggleSubscription(false);
          if (finallyCallback) finallyCallback();
        });
    },
    [action, args, delay, errorCallback, finallyCallback, increaseIteration, resetDelay, startInterval, successfulCallback],
  );

  const subscribe = useCallback(
    () => {
      setTimer(undefined);
      if (!isSubscribeAvailable) {
        toggleSubscription(false);
        return;
      }
      callAction();
    },
    [isSubscribeAvailable, callAction],
  );

  useEffect(() => {
    if (isSubscribed) return;
    if (timer) return;
    if (!isSubscribeAvailable) return;
    setTimer(setTimeout(() => {
      toggleSubscription(true);
      subscribe();
    }, delay));
  }, [subscribe, isSubscribeAvailable, isSubscribed, startInterval, timer, delay]);

  const handleSubscribe = useCallback(() => {
    if (isSubscribed) return;
    toggleSubscription(true);
    subscribe();
  }, [subscribe, isSubscribed]);

  return {
    isSubscribed,
    handleSubscribe,
    isDelayed: delay > startInterval,
  };
};

export default useLongPooling;