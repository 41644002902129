import React, { useState } from 'react';
import PropTypes from 'prop-types';
import modalStyles from '../BUnitEmailModalSelect.module.sass';
import CustomSelect from '@/components/App/Admin/BUnitsSection/CustomSelect';
import CustomSelectOption from '@/components/App/Admin/BUnitsSection/CustomSelect/CustomSelectOption/CustomSelectOption';

function BUnitSelectEmailTemplate({
  mailTemplates,
  currentTemplateId,
  setCurrentTemplateId,
}) {
  const templatesArray = Object.keys(mailTemplates);
  const [isOpen, toggleOpen] = useState(false);
  const selectCurrentTemplate = (selectedTemplate) => {
    setCurrentTemplateId(selectedTemplate);
    toggleOpen(false);
  };

  if (!mailTemplates[currentTemplateId]) return null;

  return (
    <CustomSelect
      onClickAction={selectCurrentTemplate}
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      mainBtnClassNames={modalStyles['select-button']}
      activeValue={mailTemplates[currentTemplateId].Title}
    >
      {templatesArray.map((template) => (
        <CustomSelectOption
          key={template}
          optionClassNames={modalStyles['select-option']}
          onClick={() => selectCurrentTemplate(template)}
        >
          {mailTemplates[template].Title}
        </CustomSelectOption>
      ))}
    </CustomSelect>
  );
}

BUnitSelectEmailTemplate.propTypes = {
  mailTemplates: PropTypes.objectOf(
    PropTypes.shape({
      ID: PropTypes.string,
      Title: PropTypes.string,
    }),
  ).isRequired,
  currentTemplateId: PropTypes.string.isRequired,
  setCurrentTemplateId: PropTypes.func.isRequired,
};
export default BUnitSelectEmailTemplate;