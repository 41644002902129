// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".unit-card-buttons--fkheT{display:flex;justify-content:flex-end}.unit-card-buttons--fkheT .unit-menu-button--Iz77j{height:1.75rem;width:7.5rem;font-size:1rem;border-radius:8px;padding:0.125rem 0.75rem}.unit-card-buttons--fkheT .unit-menu-button--Iz77j.main--D6stI{margin-right:8px;background:var(--admin-btn-blue);color:#fff}.unit-card-buttons--fkheT .unit-menu-button--Iz77j.main--D6stI.disabled--eUToc{background:none;border:var(--secondary-color) solid 1px;color:var(--secondary-color)}.unit-card-buttons--fkheT .unit-menu-button--Iz77j.secondary--4f0u8{background:var(--admin-background-interactive-elements);color:var(--admin-btn-color)}.unit-card-buttons--fkheT .unit-menu-button--Iz77j.secondary--4f0u8:hover{background:var(--admin-hover-background-interactive-elements)}.b-unit-card-form--eSJxy{display:grid;grid-template-columns:min-content 1fr;align-items:center;width:100%;grid-gap:1rem 1rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitCardFields.module.sass"],"names":[],"mappings":"AAEA,0BACE,YAAA,CACA,wBAAA,CACA,mDACE,cAAA,CACA,YAAA,CACA,cAAA,CACA,iBAAA,CACA,wBAAA,CACA,+DACE,gBAAA,CACA,gCAAA,CACA,UAAA,CACA,+EACE,eAAA,CACA,uCAAA,CACA,4BAAA,CACJ,oEACE,uDAAA,CACA,4BAAA,CACA,0EACE,6DAAA,CAER,yBACE,YAAA,CACA,qCAAA,CACA,kBAAA,CACA,UAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unit-card-buttons": "unit-card-buttons--fkheT",
	"unit-menu-button": "unit-menu-button--Iz77j",
	"main": "main--D6stI",
	"disabled": "disabled--eUToc",
	"secondary": "secondary--4f0u8",
	"b-unit-card-form": "b-unit-card-form--eSJxy"
};
export default ___CSS_LOADER_EXPORT___;
