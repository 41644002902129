import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import useModal from '@/hooks/modal';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import Modal from '@/components/Common/Modal';
import { unsetAdmin } from '@/store/actions/admin';
import useNLS from '@/components/App/Admin/hooks/hooks';

function ConfirmUnsetAdmin({
  isShowingModal,
  toggleModal,
  supersetID,
  unsetAdminData,
}) {
  const { WrapBtns } = useModal();
  const dispatch = useDispatch();
  const tNLS = useNLS();

  const onUnsetAdmin = () => {
    dispatch(
      unsetAdmin({
        supersetID,
        unsetAdminForm: unsetAdminData,
      }),
    );

    toggleModal();
  };

  return (
    <Modal
      isShowing={isShowingModal}
      hide={toggleModal}
      title={tNLS('admin.confirm.unset.message')}
      types="modal-small"
    >
      <WrapBtns>
        <CustomButton
          onClick={() => toggleModal()}
          styleTypeBtn="btn-grey-modal"
        >
          {tNLS('admin.confirm.unset.cancel')}
        </CustomButton>
        <CustomButton onClick={onUnsetAdmin} styleTypeBtn="btn-blue-modal">
          {tNLS('admin.confirm.unset.confirm')}
        </CustomButton>
      </WrapBtns>
    </Modal>
  );
}

ConfirmUnsetAdmin.defaultProps = {
  unsetAdminData: null,
};

ConfirmUnsetAdmin.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  isShowingModal: PropTypes.bool.isRequired,
  supersetID: PropTypes.string.isRequired,
  unsetAdminData: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.number,
  }),
};

export default ConfirmUnsetAdmin;