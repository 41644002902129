import React from 'react';
import { Link } from 'react-router-dom';

interface LogoContainerProps {
  children: React.ReactNode
  path?: string
}

function LogoContainer({ children, path }: LogoContainerProps) {
  if (!path) return children;

  return (
    <Link
      className="link-without-style"
      to={path}
    >
      {children}
    </Link>
  );
}

export default LogoContainer;