import React, { ChangeEvent, ReactNode, useRef } from 'react';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';
import styles from './UploadingInput.module.sass';
import FilesForUploadList from '../../../../../FilesForUploadList/FilesForUploadList';
import FilesDragNDropContainer from './FilesDragNDrop';
import UploadingInputLabel from './UploadingInputLabel/UploadingInputLabel';

interface UploadingInputProps<T extends FieldValues> extends UseFormReturn<T> {
  acceptFilesExt?: string[];
  name: Path<T>;
  title?: string | ReactNode
}

export default function UploadingInput<FormFields extends FieldValues>({
  acceptFilesExt, title, name, ...formProps
}: UploadingInputProps<FormFields>) {
  const { setValue, watch } = formProps;
  const inputRef = useRef<HTMLInputElement>(null);

  const existingFiles = watch(name) as File[] || [];

  const addFiles = (files: FileList) => {
    const newFiles = Array.from(files)
      .filter((file) => {
        if (!acceptFilesExt) return true;
        return acceptFilesExt.includes(file.type);
      });
    const updatedFiles = [...existingFiles, ...newFiles];
    // @ts-ignore
    setValue(name, updatedFiles);
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files) return;
    addFiles(files);
  };

  return (
    <FilesDragNDropContainer
      className={styles['upload-drop-container']}
      onFilesAdded={addFiles}
    >
      <input
        ref={inputRef}
        className={styles['files-hidden-input']}
        type="file"
        multiple
        onChange={onFileChange}
        id={`${name}-file-input`}
        {...(acceptFilesExt ? { accept: acceptFilesExt.join(',') } : {})}
      />
      <UploadingInputLabel
        text={title}
        htmlFor={`${name}-file-input`}
      />
      <FilesForUploadList
      <FormFields>
        name={name}
        {...formProps}
      />
    </FilesDragNDropContainer>
  );
}