import React from 'react';
import styles from './UnitsInformationBlock.module.sass';
import {
  getFinishedCount,
  getFinishedRespondentsPercent, getNotStartedCount, getRespondentsCount, getStartedCount, getUnitsCount,
} from '@/components/App/Admin/BUnitsSection/BUnitsSectionHeader/UnitsHeaderInformation/getUnitsInfo';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { useAdminSelector } from '@/store/hooks/adminRedux';

export enum InformationTypes {
  TOTAL = 'total',
  FINISHED = 'finished',
  STARTED = 'started',
  RESPONDENTS = 'respondents',
  DIDNT_START = 'didnt.start',
}

interface UnitsInformationBlockProps {
  type: InformationTypes
}

function UnitsInformationBlock({ type }: UnitsInformationBlockProps) {
  const tNLS = useNLS();
  const { unitsArray: units } = useAdminSelector(
    (state) => state.admin.superset.unitsTree,
  );

  const nlsKeyByType = {
    [InformationTypes.TOTAL]: 'tree.total.participants',
    [InformationTypes.FINISHED]: 'tree.participants.finished',
    [InformationTypes.STARTED]: 'tree.participants.started',
    [InformationTypes.RESPONDENTS]: 'tree.participants.respondents',
    [InformationTypes.DIDNT_START]: 'tree.participants.didnt.start',
  };

  const valueByType = {
    [InformationTypes.TOTAL]: getUnitsCount(units),
    [InformationTypes.FINISHED]: `${getFinishedCount(units)} (${getFinishedRespondentsPercent(units)}%)`,
    [InformationTypes.STARTED]: getStartedCount(units),
    [InformationTypes.RESPONDENTS]: getRespondentsCount(units),
    [InformationTypes.DIDNT_START]: getNotStartedCount(units),
  };

  return (
    <div className={styles['information-block']}>
      <span className={styles.title}>
        {`${tNLS(nlsKeyByType[type])} `}
      </span>
      <span className={styles.number}>
        {valueByType[type]}
      </span>
    </div>
  );
}

export default UnitsInformationBlock;