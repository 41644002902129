import { createSlice } from '@reduxjs/toolkit';
import { SupersetsInitialState } from '@/store/types/admin/supersets';
import {
  clearSupersets,
  clearSupersetsForCopy,
  createSuperset, deleteSuperset, getSupersetInfo,
  getSupersets,
  getSupersetsForCopy, updateSupersetName, updateSupersetStage,
} from '@/store/actions/admin';

const initialState: SupersetsInitialState = {
  supersets: {},
  supersetsForCopy: {},
  allAdminsSupersets: {},
  copySupersetsStatus: 'idle',
  createSupersetStatus: 'idle',
  status: 'idle',
};

const supersetsSlice = createSlice({
  name: 'admin/supersets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSuperset.pending, (state) => {
        state.createSupersetStatus = 'loading';
      })
      .addCase(createSuperset.fulfilled, (state, { payload }) => {
        state.createSupersetStatus = 'idle';
        state.supersets[payload.supersetId] = payload;
      })
      .addCase(getSupersets.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSupersets.fulfilled, (state, { payload }) => {
        state.supersets = payload;
        state.status = 'received';
      })
      .addCase(getSupersetsForCopy.pending, (state) => {
        state.copySupersetsStatus = 'loading';
      })
      .addCase(getSupersetsForCopy.rejected, (state) => {
        state.copySupersetsStatus = 'received';
      })
      .addCase(getSupersetsForCopy.fulfilled, (state, { payload }) => {
        state.supersetsForCopy = payload;
        state.copySupersetsStatus = 'received';
      })
      .addCase(clearSupersets, () => initialState)
      .addCase(clearSupersetsForCopy, (state) => {
        state.supersetsForCopy = {};
        state.copySupersetsStatus = 'idle';
      })
      .addCase(updateSupersetStage.fulfilled, (state, { payload }) => {
        const { supersetId, stage } = payload;
        state.supersets[supersetId].stage = stage;
      })
      .addCase(updateSupersetName.fulfilled, (state, { payload }) => {
        const { supersetID, name } = payload;
        state.supersets[supersetID].name = name;
      })
      .addCase(getSupersetInfo.fulfilled, (state, { payload }) => {
        // @ts-ignore
        const { information, supersetID } = payload;
        // @ts-ignore
        state.supersets[supersetID].information = information;
      })
      .addCase(deleteSuperset.fulfilled, (state, { payload }) => {
        const { supersetID } = payload;
        delete state.supersets[supersetID];
      });
  },
});

export default supersetsSlice.reducer;