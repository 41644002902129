// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".message-no-templates--T0z3Y{color:var(--admin-main-font-color)}.wrap-select-superset-templates--2PBZp{width:18.75rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SupersetSettings/SectionTemplate/SectionTemplate.module.sass"],"names":[],"mappings":"AAEA,6BACE,kCAAA,CACF,uCACE,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message-no-templates": "message-no-templates--T0z3Y",
	"wrap-select-superset-templates": "wrap-select-superset-templates--2PBZp"
};
export default ___CSS_LOADER_EXPORT___;
