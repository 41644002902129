// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form--iPD6o{display:flex;flex-direction:column;gap:2.25rem}.form--iPD6o .buttons-container--Jc6RV{display:flex;flex-direction:row}.form--iPD6o .buttons-container--Jc6RV .reject--gKpRl{background:var(--admin-btn-bg)}.form--iPD6o .buttons-container--Jc6RV .reject--gKpRl:hover{background:var(--admin-btn-hover)}", "",{"version":3,"sources":["webpack://./src/components/App/Webinar/CreateWebinarPage/CreateWebinarForm/CreateWebinarForm.module.sass"],"names":[],"mappings":"AAEA,aACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,uCACE,YAAA,CACA,kBAAA,CACA,sDACE,8BAAA,CACA,4DACE,iCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "form--iPD6o",
	"buttons-container": "buttons-container--Jc6RV",
	"reject": "reject--gKpRl"
};
export default ___CSS_LOADER_EXPORT___;
