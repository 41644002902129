import React from 'react';
import { TextBlock } from './ArticleTextBlock/ArticleTextBlock';

interface BlockProps {
    block: ArticleTextBlock;
    className?: string;
}

export const Block: React.FC<BlockProps> = ({ block }) => {
    return <TextBlock block={block} />;
};
