import React from 'react';
import FilesForDownload
  from '@/components/Common/BaseTemplate/MainContentContainer/AdminSupersetContainer/ButtonsPanel/SupersetMenuModal/FilesForDownload/FilesForDownload';
import Content from '@/components/Common/Modal/Content';
import Modal from '@/components/Common/Modal';

interface Props {
  isOpen: boolean;
  toggleOpen: (arg: boolean) => null;
}

function FilesForDownloadModal({ isOpen, toggleOpen }: Props) {
  return (
    // @ts-ignore
    <Modal
      isShowing={isOpen}
      hide={() => {
        toggleOpen(false);
      }}
      title="Available Files"
    >
      <Content>
        <FilesForDownload />
      </Content>
    </Modal>
  );
}

export default FilesForDownloadModal;