// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tooltip---oPVd{position:absolute}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Superset/HeaderSuperset/AssessmentStatus/StatusElement/Tooltip/Tooltip.module.sass"],"names":[],"mappings":"AAAA,gBACE,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "tooltip---oPVd"
};
export default ___CSS_LOADER_EXPORT___;
