import React, { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styles from './AdminMainListLink.module.sass';

interface AdminMainListLinkProps extends LinkProps {
  linkTitle: string | ReactNode;
  date?: string;
  metaInfo?: ReactNode;
}

export default function AdminMainListLink({
  linkTitle, date, metaInfo, ...linkProps
}: AdminMainListLinkProps) {
  return (
    <ul className={styles.container}>
      <div className={styles.information}>
        <div className={styles['main-info']}>
          <Link
            className={styles.title}
            {...linkProps}
          >
            {linkTitle}
          </Link>
          <div className={styles.date}>{date}</div>
        </div>
        {metaInfo && <div className={styles.meta}>{metaInfo}</div>}
      </div>
    </ul>
  );
}