import React, {
  useContext, useState,
} from 'react';
import styles from './RoleSelect.module.sass';
import BUnitContext, { BUnitContextProps } from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import useNLS from '@/components/App/Admin/hooks/hooks';
import CardSelectDropdown
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitSpec/CardSelectDropdown/CardSelectDropdown';
import ArrowIcon from '@/components/Common/Elements/cssIcons/ArrowIcon';
import RoleSelectOption
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitHowToElement/RoleSelect/RoleSelectOption/RoleSelectOption';
import { UnitId } from '@/store/types/user/units';
import useOutsideClick from '@/hooks/checkOutsideClick';

interface RoleSelectProps {
  rolesList?: string[];
  currentRole: string;
  changeRole: (newRole: string) => void;
  roleUnitId: UnitId;
}

function RoleSelect({
  rolesList = [],
  currentRole,
  changeRole,
  roleUnitId,
}: RoleSelectProps) {
  const { id } = useContext(BUnitContext) as Required<BUnitContextProps>;
  const [isOpen, toggleOpen] = useState(false);
  const selectRef = useOutsideClick<() => void, HTMLDivElement>(() => toggleOpen(false), isOpen);
  const tNLS = useNLS();

  const handleChangeRole = (newRole: string) => {
    toggleOpen(false);
    changeRole(newRole);
  };

  return (
    <div className={styles['select-container']} ref={selectRef}>
      <div
        role="listbox"
        aria-expanded={isOpen}
        tabIndex={0}
        className={styles['role-select']}
        onKeyDown={() => {}}
        onClick={() => toggleOpen((prevState) => !prevState)}
      >
        {tNLS(`tree.card.role.${currentRole}`) || currentRole}
        <ArrowIcon classNames={styles.arrow} />
      </div>
      {isOpen
        && (
        <CardSelectDropdown>
          {rolesList
            .filter((role) => role !== currentRole)
            .map((role) => (
              <RoleSelectOption
                key={`${id}-card-option-${role}-${roleUnitId}`}
                role={role}
                changeRole={handleChangeRole}
              />
            ))}
        </CardSelectDropdown>
        )}
    </div>
  );
}

export default RoleSelect;