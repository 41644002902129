import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function Redirect({ path }) {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`${path}`);
  }, [navigate, path]);

  return null;
}

Redirect.propTypes = {
  path: PropTypes.string.isRequired,
};

export default Redirect;