import { createSlice } from '@reduxjs/toolkit';
import {
  clearCheckpoints,
  getCheckpoints,
  loadStatusProcessorResult,
  startProcessor,
} from '@/store/actions/admin/superset/process/process';

const initialState = {
  evaluatedAt: null,
  hasEvaluated: false,
  isProcessorRunning: false,
  received: false,
  checkpoints: undefined,
};

const processSlice = createSlice({
  name: 'admin/superset/process',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(loadStatusProcessorResult.fulfilled, (state, { payload }) => ({
        ...payload,
        received: true,
      }))
      .addCase(startProcessor.fulfilled, (state) => {
        state.isProcessorRunning = true;
      })
      .addCase(getCheckpoints.fulfilled, (state, { payload }) => {
        state.checkpoints = payload;
      })
      .addCase(clearCheckpoints, (state) => {
        state.checkpoint = undefined;
      });
  },
});

export default processSlice.reducer;