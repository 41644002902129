import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import {
  clearSuperset,
  clearSupersets,
} from '@/store/actions/admin/superset/supersets';
import axiosInstance from '@/axiosConfig';
import { AdminState } from '@/store/admin';
import {
  Admin,
  AdminSignInForm,
  AdminSignUpForm,
  AuthAdminByTokenActionArgs, ChangeAdminPasswordActionArgs,
} from '@/store/types/admin/authentication';

export const getCSRF = createAsyncThunk(
  'authentication/getCSRF',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<{ message: string }>('/api/admin/csrf');

      return { 'x-csrf-token': response.data.message };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const adminAuth = createAsyncThunk(
  'authentication/adminAuth',
  async (form: AdminSignInForm, { getState, rejectWithValue }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;
      const response = await axiosInstance.post<Admin>(
        '/api/admin/authorization',
        form,
        {
          headers,
        },
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const adminRegister = createAsyncThunk(
  'authentication/adminRegister',
  async (form: AdminSignInForm, { getState, rejectWithValue }) => {
    const state = getState() as AdminState;
    const { headers } = state.admin.authentication;

    try {
      await axiosInstance.post('/api/admin/register', form, { headers });

      return null;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const adminSignIn = createAsyncThunk(
  'authentication/signIn',
  async (form: AdminSignInForm, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as AdminState;
    const { headers } = state.admin.authentication;
    if (!headers) {
      try {
        unwrapResult(await dispatch(getCSRF()));
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }

    try {
      return unwrapResult(await dispatch(adminAuth(form)));
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const adminSignUp = createAsyncThunk(
  'authentication/signUp',
  async (form: AdminSignUpForm, { dispatch, rejectWithValue }) => {
    try {
      unwrapResult(await dispatch(getCSRF()));
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
    try {
      unwrapResult(await dispatch(adminRegister(form.form)));

      form.navigateSettings.navigate(form.navigateSettings.url);
      return null;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getAdmin = createAsyncThunk(
  'authentication/getAdmin',
  async (_, { dispatch, getState, rejectWithValue }) => {
    let adminData: Admin = null;

    try {
      const { data: responseAdminData } = await axiosInstance.get<Admin>(
        '/api/admin/getAdminData',
      );
      adminData = responseAdminData;
    } catch (err) {
      return rejectWithValue({ errorCode: err.response.status });
    }
    const state = getState() as AdminState;
    const { headers } = state.admin.authentication;

    if (!headers) {
      try {
        unwrapResult(await dispatch(getCSRF()));

        return adminData;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }

    return adminData;
  },
);

export const adminLogout = createAsyncThunk(
  'authentication/adminLogout',
  async (_, { rejectWithValue }) => {
    try {
      await axiosInstance.get('/api/admin/logout');

      return null;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const adminLogoutAndClearData = createAsyncThunk(
  'authentication/adminLogoutAndClearData',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      unwrapResult(await dispatch(adminLogout()));

      dispatch(clearSupersets());
      dispatch(clearSuperset());
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
    return null;
  },
);

export const authAdminByToken = createAsyncThunk(
  'authentication/authAdminByInviteToken',
  async ({ adminId, time, token }: AuthAdminByTokenActionArgs, { rejectWithValue }) => {
    try {
      await axiosInstance.get(`/api/admin/enter/${adminId}/${time}/${token}`);

      return null;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateAdmin = createAsyncThunk(
  'authentication/updateAdmin',
  async (form: Admin, { getState, rejectWithValue }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;
      await axiosInstance.put('/api/admin/updateAdmin', form, { headers });

      return form;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const changeAdminPassword = createAsyncThunk(
  'authentication/changeAdminPassword',
  async (form: ChangeAdminPasswordActionArgs, { getState, rejectWithValue }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;
      await axiosInstance.put('/api/admin/password', form, { headers });

      return null;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const adminCreateNewPassword = createAsyncThunk(
  'authentication/adminCreateNewPassword',
  async (form: ChangeAdminPasswordActionArgs, { dispatch, rejectWithValue }) => {
    try {
      unwrapResult(await dispatch(changeAdminPassword(form)));

      return null;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const adminRestorePassword = createAsyncThunk(
  'authentication/adminRestorePassword',
  async (form, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as AdminState;
    let { headers } = state.admin.authentication;

    if (!headers) {
      try {
        headers = unwrapResult(await dispatch(getCSRF()));
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }

    try {
      await axiosInstance.post('/api/admin/restore', form, {
        headers,
      });

      return null;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);