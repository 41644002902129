// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".role-select-option--IoZDL{font-size:1rem;padding:0.25rem 0.5rem;text-align:left;border-radius:0.5rem}.role-select-option--IoZDL:hover{background:var(--admin-hover-background-interactive-elements);cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitHowToElement/RoleSelect/RoleSelectOption/RoleSelectOption.module.sass"],"names":[],"mappings":"AAEA,2BACE,cAAA,CACA,sBAAA,CACA,eAAA,CACA,oBAAA,CACA,iCACE,6DAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"role-select-option": "role-select-option--IoZDL"
};
export default ___CSS_LOADER_EXPORT___;
