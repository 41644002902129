import React from 'react';
import {
  selectSelectedUnits,
  selectUnitsTreeData,
} from '@/store/selectors/admin/superset/unitsTree';
import UnitForChange from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitTransferModal/UnitsForTransferList/UnitForTransfer/UnitForChange';
import styles from './UnitsForTransferList.module.sass';
import { UnitId } from '@/store/types/user/units';
import { useAdminSelector } from '@/store/hooks/adminRedux';

interface UnitsForChangeListProps {
  actionType: 'transfer' | 'addSpec'
}
function UnitsForChangeList({ actionType }: UnitsForChangeListProps) {
  const selectedUnits = useAdminSelector(selectSelectedUnits);
  const { units } = useAdminSelector(selectUnitsTreeData);

  return (
    <div>
      {selectedUnits.length && (
        <ul className={styles['transfer-units-list']}>
          {selectedUnits.map((unitId: UnitId) => (
            <UnitForChange
              key={`${unitId}-${actionType}`}
              name={units[unitId].name}
              departments={units[unitId].departments}
              spec={units[unitId].spec}
              id={unitId}
              actionType={actionType}
            />
          ))}
        </ul>
      )}
    </div>
  );
}

export default UnitsForChangeList;