import React from 'react';
import { Tooltip } from 'react-tooltip';
import PropTypes from 'prop-types';
import { getClearClassNames } from '@/helpers/helpers';
import styles from './StatisticsValues.module.sass';
import useNLS from '@/components/App/Admin/hooks/hooks';
import {
  calcAverageScore,
  calcOptionPercents,
  calcPercentageOfMaxScore,
} from '@/components/App/Admin/Results/Cuts/helpers';

function StatisticsValues({
  itemID,
  answerValues,
  answerValuesOthers,
  answerCodes,
  externalClassNames,
}) {
  const tNLS = useNLS();

  const areThereDataForUnselected = !answerValuesOthers.every(
    (stat) => stat === 0,
  );

  const optionPercents = calcOptionPercents(answerValues);
  const averageScore = calcAverageScore(answerCodes, optionPercents);
  let averageScoreForUnselected = null;

  if (areThereDataForUnselected) {
    const optionPercentsForUnselected = calcOptionPercents(answerValuesOthers);
    averageScoreForUnselected = calcAverageScore(
      answerCodes,
      optionPercentsForUnselected,
    );
  }

  const percentageOfMaxScore = calcPercentageOfMaxScore(
    averageScore,
    answerCodes,
  );

  let percentageOfMaxScoreForUnselected = null;

  if (areThereDataForUnselected) {
    percentageOfMaxScoreForUnselected = calcPercentageOfMaxScore(
      averageScoreForUnselected,
      answerCodes,
    );
  }

  return (
    <div
      className={getClearClassNames([
        styles['wrap-statistics-values'],
        externalClassNames.wrapStatisticsValues,
      ])}
    >
      <Tooltip id={`averageScoreTooltip-${itemID}`}>
        <div className={styles['wrap-tooltip-statistics-values']}>
          {!averageScoreForUnselected && (
            <div className={styles['wrap-for-selected']}>
              {`${tNLS('cuts.scales.abs.score')}: `}
              <span className={styles.value}>{averageScore}</span>
            </div>
          )}

          {averageScoreForUnselected && (
            <>
              <div className={styles['wrap-for-selected']}>
                {`${tNLS('cuts.scales.abs.score.selected')}: `}
                <span className={styles.value}>{averageScore}</span>
              </div>
              <div className={styles['wrap-for-unselected']}>
                {`${tNLS('cuts.scales.abs.score.other')}: `}
                <span className={styles['for-unselected-value']}>
                  {averageScoreForUnselected}
                </span>
              </div>
            </>
          )}
        </div>
      </Tooltip>

      <div
        className={`${styles['statistics-value']} ${styles['average-score']}`}
        data-tooltip-id={`averageScoreTooltip-${itemID}`}
      >
        <span
          className={getClearClassNames([
            'icon-average-score',
            styles['icon-type-of-value'],
            externalClassNames.iconValue,
          ])}
        />

        <div
          className={getClearClassNames([
            styles.values,
            externalClassNames.wrapValues,
          ])}
        >
          <span className={styles.value}>{averageScore}</span>
          {averageScoreForUnselected && (
            <span className={styles['for-unselected-value']}>
              {averageScoreForUnselected}
            </span>
          )}
        </div>
      </div>

      <Tooltip id={`relativePercentTooltip-${itemID}`}>
        <div className={styles['wrap-tooltip-statistics-values']}>
          {!percentageOfMaxScoreForUnselected && (
            <div className={styles['wrap-for-selected']}>
              {`${tNLS('cuts.scales.abs.perc')}: `}
              <span className={styles.value}>
                {`${percentageOfMaxScore} %`}
              </span>
            </div>
          )}

          {percentageOfMaxScoreForUnselected && (
            <>
              <div className={styles['wrap-for-selected']}>
                {`${tNLS('cuts.scales.abs.perc.selected')}: `}
                <span className={styles.value}>
                  {`${percentageOfMaxScore} %`}
                </span>
              </div>
              <div className={styles['wrap-for-unselected']}>
                {`${tNLS('cuts.scales.abs.perc.other')}: `}
                <span className={styles['for-unselected-value']}>
                  {`${percentageOfMaxScoreForUnselected} %`}
                </span>
              </div>
            </>
          )}
        </div>
      </Tooltip>

      <div
        className={`${styles['statistics-value']} ${styles['relative-percent']}`}
        data-tooltip-id={`relativePercentTooltip-${itemID}`}
      >
        <span
          className={getClearClassNames([
            'icon-relative-percent',
            styles['icon-type-of-value'],
            externalClassNames.iconValue,
          ])}
        />
        <div
          className={getClearClassNames([
            styles.values,
            externalClassNames.wrapValues,
          ])}
        >
          <span className={styles.value}>{`${percentageOfMaxScore} %`}</span>
          {percentageOfMaxScoreForUnselected && (
            <span className={styles['for-unselected-value']}>
              {`${percentageOfMaxScoreForUnselected} %`}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

StatisticsValues.defaultProps = {
  externalClassNames: {},
};

StatisticsValues.propTypes = {
  itemID: PropTypes.string.isRequired,
  answerValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  answerValuesOthers: PropTypes.arrayOf(PropTypes.number).isRequired,
  answerCodes: PropTypes.arrayOf(PropTypes.number).isRequired,
  externalClassNames: PropTypes.shape({
    wrapStatisticsValues: PropTypes.string,
    wrapValues: PropTypes.string,
    iconValue: PropTypes.string,
  }),
};

export default StatisticsValues;