// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".status-element--LlbN0{text-align:right}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Superset/HeaderSuperset/AssessmentStatus/StatusElement/StatusElement.module.sass"],"names":[],"mappings":"AAAA,uBACE,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status-element": "status-element--LlbN0"
};
export default ___CSS_LOADER_EXPORT___;
