import { selectActiveLangIDsSortedByPriority } from '@/store/selectors/admin/superset/projects';

export const selectAdminSettings = (state) => state.admin.superset.settings;

export const selectAdminSettingsValue = (state, settingsIDs) => {
  const { settings } = state.admin.superset;

  if (Object.keys(settings).length === 0) {
    return {};
  }

  const settingsValue = {};

  settingsIDs.forEach((settingID) => {
    const value = settings[settingID] ? settings[settingID].value : 1;
    settingsValue[settingID] = { value };
  });

  return settingsValue;
};

export const selectProcessedAdminSettings = (state) => {
  const { settings } = state.admin.superset;
  const activePriorityLangIDs = selectActiveLangIDsSortedByPriority(state);
  const [priorityActiveLangID, ...restActivePriorityLangIDs] = activePriorityLangIDs;

  if (
    Object.keys(settings).length === 0
    || activePriorityLangIDs.length === 0
  ) {
    return [];
  }

  const groupSettingsByLangs = {};

  activePriorityLangIDs.forEach((langID) => {
    groupSettingsByLangs[langID] = [];
  });

  const addSettingWithContentByActiveLang = (
    settingLangs,
    langID,
    settingFields,
  ) => {
    const settingContent = settingLangs[langID];
    groupSettingsByLangs[langID].push({
      ...settingFields,
      ...settingContent,
    });
  };

  const getGroupAdminSetting = (cases) => {
    if (!cases) {
      return 'wrapperGroupCheckboxes';
    }

    if (cases.length === 1) {
      return 'checkbox';
    }

    if (cases.length > 1) {
      return 'groupRadio';
    }

    return null;
  };

  Object.keys(settings).forEach((settingID) => {
    const { langs: settingLangs, ...settingFields } = settings[settingID];

    if (priorityActiveLangID in settingLangs) {
      addSettingWithContentByActiveLang(settingLangs, priorityActiveLangID, {
        settingId: settingID,
        ...settingFields,
      });
      return;
    }

    // если для активного и приоритетного языка нет контента в параметрах -->
    // перебрать все активные языки до того момента пока не найдем его в текущем параметре
    if (!(priorityActiveLangID in settingLangs)) {
      for (let i = 0; i < restActivePriorityLangIDs.length; i += 1) {
        const nextActiveLangID = restActivePriorityLangIDs[i];

        if (nextActiveLangID in settingLangs) {
          addSettingWithContentByActiveLang(settingLangs, nextActiveLangID, {
            settingId: settingID,
            ...settingFields,
          });
          break;
        }
      }
    }
  });

  // сортируем параметры (их контент) внутри каждого активного языка
  Object.keys(groupSettingsByLangs).forEach((langID) => {
    const settingsForCurrentLangID = groupSettingsByLangs[langID];

    settingsForCurrentLangID.sort((settingA, settingB) => {
      if (settingA.nOrder > settingB.nOrder) return 1;
      return -1;
    });
  });

  // объединяем все языки в один массив в порядке приоритетности
  const orderedSettings = [];
  activePriorityLangIDs.forEach((langID) => {
    orderedSettings.push(...groupSettingsByLangs[langID]);
  });

  // определить тип параметров и объединение в группы
  const settingsWithTypes = [];
  let isSettingInGroup = false;
  let currentGroupSetting = null;

  orderedSettings.forEach((setting) => {
    const type = getGroupAdminSetting(setting.cases);
    const settingWithType = {
      ...setting,
      type,
    };

    if (type === 'wrapperGroupCheckboxes') {
      isSettingInGroup = true;
      settingWithType.groupChildren = [];
      currentGroupSetting = settingWithType;
      return;
    }

    if (type === 'checkbox' && isSettingInGroup) {
      currentGroupSetting.groupChildren.push(settingWithType);
      return;
    }

    if (
      isSettingInGroup
      && type !== 'checkbox'
      && type !== 'wrapperGroupCheckboxes'
    ) {
      // вставляем группу с дочерними элементами
      settingsWithTypes.push(currentGroupSetting);

      // очищаем временные данные для определения заполнения группы
      isSettingInGroup = false;
      currentGroupSetting = {};
    }

    settingsWithTypes.push({
      ...settingWithType,
      type,
    });
  });

  // если orderedSettings последним элементов является элемент группы
  // тогда в цикле группа не будет добавлена
  // добавляем вручную
  if (isSettingInGroup) {
    settingsWithTypes.push(currentGroupSetting);
    isSettingInGroup = false;
    currentGroupSetting = null;
  }

  return settingsWithTypes;
};