import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  loadLogsCurrentPage,
  loadLogsNextPage,
  loadLogsPrevPage,
  setNextPage,
  setPrevPage,
} from '@/store/actions/admin/logs';

const initialState: LogsInitialState = {
  logs: {
    currentPageLogs: [],
    nextPageLogs: [],
    prevPageLogs: [],
  },
  status: 'idle',
};

const logsSlice = createSlice({
  name: 'admin/logs',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(loadLogsCurrentPage.fulfilled, (state, { payload: logs }: PayloadAction<Log[]>) => {
        state.logs.currentPageLogs = logs;
        state.status = 'received';
      })
      .addCase(loadLogsNextPage.fulfilled, (state, { payload: logs }: PayloadAction<Log[]>) => {
        state.logs.nextPageLogs = logs;
      })
      .addCase(loadLogsPrevPage.fulfilled, (state, { payload: logs }: PayloadAction<Log[]>) => {
        state.logs.prevPageLogs = logs;
      })
      .addCase(setPrevPage, (state) => {
        state.logs.currentPageLogs = state.logs.prevPageLogs;
        state.logs.prevPageLogs = [];
      })
      .addCase(setNextPage, (state) => {
        state.logs.currentPageLogs = state.logs.nextPageLogs;
        state.logs.nextPageLogs = [];
      });
  },
});

export default logsSlice.reducer;