import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@/components/Common/Modal';
import Content from '@/components/Common/Modal/Content';
import CustomInput from '@/components/App/Admin/BUnitsTree/UnitsTree/CustomInput/CustomInput';
import styles from './CreateCheckpointModal.module.sass';
import { createCheckpoint } from '@/store/actions/admin/superset/process/process';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';

function CreateCheckpointModal({ isOpen, toggleModal }) {
  const [checkpointTitle, setCheckpointTitle] = useState('');
  const [checkpointDescription, setCheckpointDescription] = useState('');
  const { supersetId } = useSelector(selectSuperset);

  const dispatch = useDispatch();

  const clearFields = () => {
    setCheckpointTitle('');
    setCheckpointDescription('');
  };

  const confirmHandleClick = () => {
    dispatch(
      createCheckpoint({
        supersetId,
        title: checkpointTitle,
        description: checkpointDescription,
      }),
    );
    clearFields();
  };

  return (
    <Modal
      isShowing={isOpen}
      hide={() => toggleModal(false)}
      title="Create Checkpoint"
    >
      <Content>
        <div className={styles['input-content']}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="checkpoint-title" className={styles['input-title']}>
            Title:
          </label>
          <CustomInput
            id="checkpoint-title"
            inputValue={checkpointTitle}
            onChange={(value) => setCheckpointTitle(value)}
            containerClassNames={styles['input-container']}
            inputClassNames={styles.input}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            htmlFor="checkpoint-description"
            className={styles['input-title']}
          >
            Description:
          </label>
          <CustomInput
            inputValue={checkpointDescription}
            onChange={(value) => setCheckpointDescription(value)}
            containerClassNames={styles['input-container']}
            inputClassNames={styles.input}
            id="checkpoint-description"
          />
        </div>
        <div className={styles['buttons-container']}>
          <CustomButton
            styleTypeBtn="btn-grey btn-large"
            onClick={clearFields}
            // disabled={!(checkpointTitle || checkpointDescription)}
          >
            Clear fields
          </CustomButton>
          <CustomButton
            styleTypeBtn="btn-blue btn-large"
            onClick={confirmHandleClick}
            disabled={!(checkpointTitle || checkpointDescription)}
          >
            Create checkpoint
          </CustomButton>
        </div>
      </Content>
    </Modal>
  );
}

CreateCheckpointModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default CreateCheckpointModal;