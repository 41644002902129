// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".btn-copy-content--weQZo{color:var(--admin-btn-color);background:var(--admin-btn-bg);padding:0 0.9285714286em;height:2.4285714286em;line-height:2.4285714286em;font-size:0.875rem;border-radius:100px;transition:background-color .3s ease 0s}.btn-copy-content--weQZo:hover{background:var(--admin-btn-hover);color:var(--admin-btn-hover-color)}.btn-copy-content--weQZo:active{background:var(--admin-btn-bg);color:var(--admin-btn-color)}.btn-copy-content--weQZo .icon-link--yITa-:before{font-size:18px}", "",{"version":3,"sources":["webpack://./src/components/Common/CopyContentBtn/CopyContentBtn.module.sass"],"names":[],"mappings":"AAEA,yBACE,4BAAA,CACA,8BAAA,CACA,wBAAA,CACA,qBAAA,CACA,0BAAA,CACA,kBAAA,CACA,mBAAA,CACA,uCAAA,CACA,+BACE,iCAAA,CACA,kCAAA,CACF,gCACE,8BAAA,CACA,4BAAA,CAEA,kDACE,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn-copy-content": "btn-copy-content--weQZo",
	"icon-link": "icon-link--yITa-"
};
export default ___CSS_LOADER_EXPORT___;
