import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { selectCurrentLangID } from '@/store/selectors/admin/superset/projects';
import { getSupersetEvents } from '@/store/actions/admin';
import SupersetEventsList from '@/components/App/Admin/Superset/SupersetEvens/SupersetEventsList/SupersetEventsList';

function SupersetEvents() {
  const { supersetId, stage } = useSelector(selectSuperset);
  const currentLangID = useSelector(selectCurrentLangID);
  const { events } = useSelector(
    (state) => state.admin.superset.supersetFields,
  );
  const [isEventsLoaded, toggleLoaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (events[currentLangID] && isEventsLoaded) return;
    dispatch(
      getSupersetEvents({
        supersetId,
        langId: currentLangID,
      }),
    ).then(() => toggleLoaded(true));
  }, [dispatch, events, isEventsLoaded, currentLangID, supersetId]);

  useEffect(() => {
    if (!isEventsLoaded) return;
    toggleLoaded(false);
    // eslint-disable-next-line
  }, [stage]);

  useEffect(() => {});

  if (!events[currentLangID]) return null;
  return (
    <section>
      <SupersetEventsList
        events={events[currentLangID]}
        langId={currentLangID}
      />
    </section>
  );
}

export default SupersetEvents;