// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".files-section--o9Qgy{width:80%}.files-section--o9Qgy .files-buttons-list--vbHGy{display:flex;flex-direction:column;gap:0.5rem}", "",{"version":3,"sources":["webpack://./src/components/Common/BaseTemplate/MainContentContainer/AdminSupersetContainer/ButtonsPanel/SupersetMenuModal/FilesForDownload/FilesSection/FilesSection.module.sass"],"names":[],"mappings":"AAEA,sBACE,SAAA,CACA,iDACE,YAAA,CACA,qBAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"files-section": "files-section--o9Qgy",
	"files-buttons-list": "files-buttons-list--vbHGy"
};
export default ___CSS_LOADER_EXPORT___;
