import React from 'react';
import styles from './DeleteRestoreButton.module.sass';

interface DeleteRestoreButtonProps {
  isRestoreButton: boolean;
  deleteHowTo: () => void;
  restoreHowTo: () => void;
}

function DeleteRestoreButton({ isRestoreButton, deleteHowTo, restoreHowTo }: DeleteRestoreButtonProps) {
  const iconClass = isRestoreButton ? `icon-revert ${styles.revert}` : 'icon-trash';

  const handleButtonClick = () => {
    if (isRestoreButton) {
      restoreHowTo();
      return;
    }
    deleteHowTo();
  };

  return (
    <button
      type="button"
      className={styles['how-to-control-btn']}
      onClick={handleButtonClick}
    >
      <span className={`${styles.icon} ${iconClass}`} />
    </button>
  );
}

export default DeleteRestoreButton;