import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import styles from './BUnitCardInput.module.sass';
import useNLS from '@/components/App/Admin/hooks/hooks';
import B_UNIT_FIELDS
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitCardSection/sectionsByType';
import { VALIDATION_REGEX } from '@/components/App/Admin/Results/Cuts/constants';

interface BUnitCardInputProps {
  register: UseFormRegister<any>;
  defaultValue?: string;
  inputError?: string;
  cardFieldType: CardFieldType
  unitId?: string;
}

export enum CardFieldType {
  Name = 'name',
  Email = 'email',
  JobTitle = 'jobTitle',
}

function BUnitCardInput({
  inputError = '', register, cardFieldType, unitId = '', defaultValue = '',
}: BUnitCardInputProps) {
  const tNLS = useNLS();

  const validation = {
    name: {
      required: tNLS('tree.card.mandatory') || 'err',
    },
    email: {
      pattern: VALIDATION_REGEX.EMAIL,
      message: tNLS('tree.card.incorrect.email') || 'err',
    },
    jobTitle: {},
  };

  return (
    <>
      <label
        className={styles.title}
        htmlFor={`card-${unitId}-${cardFieldType}`}
      >
        {B_UNIT_FIELDS.getTitleText(cardFieldType, tNLS)}
      </label>
      <div className={styles['input-container']}>
        {inputError && <span className={styles.error}>{inputError}</span>}
        <input
          id={`card-${unitId}-${cardFieldType}`}
          className={styles.input}
          defaultValue={defaultValue}
          type={cardFieldType}
          autoComplete="off"
          {...register(cardFieldType, validation[cardFieldType])}
        />
      </div>
    </>
  );
}

export default BUnitCardInput;