import React, { useContext } from 'react';
import useModal from '@/hooks/modal';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';
import BUnitContext from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import ActionButton from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitActionButtons/ActionButton/ActionButton';
import DeleteUnitModal from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitActionButtons/DeleteUnitActionButton/DeleteUnitModal/DeleteUnitModal';

function DeleteUnitActionButton() {
  const { unit, id } = useContext(BUnitContext);
  const { isShowingModal, toggleStatusShowingModal } = useModal();
  const checkPermission = useCheckPermission();

  const isButtonHidden = () => {
    if (unit.children && unit.children.length) return true;
    return Number(id) <= 0;
  };

  if (!checkPermission('modify_units')) return null;

  return (
    <>
      <ActionButton
        buttonType="trash"
        handleActionClick={() => toggleStatusShowingModal(true)}
        isHidden={isButtonHidden()}
      />
      <DeleteUnitModal
        isShowingModal={isShowingModal}
        toggleModal={toggleStatusShowingModal}
      />
    </>
  );
}

export default DeleteUnitActionButton;