import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from '@/components/Common/BaseTemplate/Header/Header.module.sass';

function PasswordWarning() {
  const admin = useSelector((state) => state.admin.authentication.admin);

  if (!admin) return null;

  if (!admin.newUser) return null;

  return (
    <div className={styles['warning-admin-without-password']}>
      <Link to="/admin/profile" className="link-without-style">
        You need to create a password
      </Link>
    </div>
  );
}

export default PasswordWarning;