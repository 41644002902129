import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Criteria.module.sass';
import SlideToggleContent from '@/components/Common/SlideToggleContent';

import HeaderCriteria from '@/components/App/Admin/Results/Cuts/WrapperCuts/Criteria/HeaderCriteria';
import ListOfCriteria from '@/components/App/Admin/Results/Cuts/WrapperCuts/Criteria/ListOfCriteria';
import {
  selectAreThereCriteria,
  selectSelectedCriteria,
} from '@/store/selectors/admin/superset/process/cuts/cuts';
import { selectCurrentLangID } from '@/store/selectors/admin/superset/projects';
import useFetchCriteria from '@/components/App/Admin/Results/Cuts/WrapperCuts/Criteria/useFetchCriteria';
import { loadCuts } from '@/store/actions/admin/superset/process/cuts/questionsCuts';
import { loadActorsAmount } from '@/store/actions/admin/superset/process/cuts/cuts';
import { loadUnitCuts } from '@/store/actions/admin/superset/process/cuts/unitsCuts';
import { loadFactorCuts } from '@/store/actions/admin/superset/process/cuts/factorsCuts';
import { selectQuestionsItems } from '@/store/selectors/admin/superset/process/cuts/questionsCuts';
import { selectUnitsItems } from '@/store/selectors/admin/superset/process/cuts/unitsCuts';
import { selectHasFactorsCuts } from '@/store/selectors/admin/superset/process/cuts/factorsCuts';
import { selectHasFourD } from '@/store/selectors/admin/superset/process/cuts/fourD';
import { getResultsByRatings } from '@/store/actions/admin/superset/process/cuts/fourD';

function Criteria() {
  const dispatch = useDispatch();
  const hasCriteriaReceived = useFetchCriteria();
  const areThereCriteria = useSelector(selectAreThereCriteria);
  const [isVisibleCriteria, toggleCriteriaList] = useState(false);
  const selectedCriteria = useSelector(selectSelectedCriteria);
  const currentLangID = useSelector(selectCurrentLangID);
  const hasQuestionsItems = useSelector(selectQuestionsItems).length > 0;
  const hasUnitsItems = useSelector(selectUnitsItems).length > 0;
  const hasFactorsCuts = useSelector(selectHasFactorsCuts);
  const hasFourD = useSelector(selectHasFourD);
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (hasQuestionsItems) {
      dispatch(loadCuts());
    }

    if (hasUnitsItems) {
      dispatch(loadUnitCuts());
    }

    if (hasFactorsCuts) {
      dispatch(loadFactorCuts());
    }

    if (hasFourD) {
      dispatch(getResultsByRatings());
    }

    dispatch(loadActorsAmount());
  }, [
    hasQuestionsItems,
    hasUnitsItems,
    hasFactorsCuts,
    selectedCriteria,
    hasFourD,
    currentLangID,
    dispatch,
  ]);

  if (!hasCriteriaReceived || !areThereCriteria) {
    return null;
  }

  return (
    <div className={styles['wrap-criteria-list']}>
      <HeaderCriteria
        toggleCriteriaList={toggleCriteriaList}
        isVisibleCriteria={isVisibleCriteria}
      />

      <SlideToggleContent isVisible={isVisibleCriteria}>
        <ListOfCriteria />
      </SlideToggleContent>
    </div>
  );
}

export default Criteria;