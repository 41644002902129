import { createContext, Dispatch, SetStateAction } from 'react';

export interface BUnitCardContextProps {
  isOpen?: boolean;
  toggleOpen?: Dispatch<SetStateAction<boolean>>;
  handleButtonClickInModal?: () => void;
  modalHandleClose?: () => void;
  toggleUpdated?: Dispatch<SetStateAction<boolean>>
}

const UnitCardContext = createContext<BUnitCardContextProps>({});

export default UnitCardContext;