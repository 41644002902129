import React, { useEffect, useRef, useState } from 'react';
import PropTypes, { element } from 'prop-types';

// todo вынести функционал закрытия окна по клику на страницу в отдельный hook
function BtnDropDown({
  children,
  classNames,
  isOpen: externalIsOpen,
  setIsOpen: externalSetIsOpen,
}) {
  const [internalIsOpen, internalSetIsOpen] = useState(false);

  // Determine whether to use internal or external state
  const isControlled = externalIsOpen !== undefined && externalSetIsOpen !== undefined;
  const isOpen = isControlled ? externalIsOpen : internalIsOpen;
  const setIsOpen = isControlled ? externalSetIsOpen : internalSetIsOpen;

  const dropDownRef = useRef(null);
  const dropdownButtonRef = useRef(null);

  const toggleDropDown = () => {
    setIsOpen(!isOpen);
  };

  let [button, dropDownList] = children;

  button = React.cloneElement(button, {
    onClick: toggleDropDown,
    ref: dropdownButtonRef,
  });
  dropDownList = React.cloneElement(dropDownList, { ref: dropDownRef });

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        dropDownRef.current !== null
        && !dropDownRef.current.contains(e.target)
        && !dropdownButtonRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      window.addEventListener('click', pageClickEvent);
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isOpen, setIsOpen]);

  return (
    <div className={`dropdown ${classNames}`}>
      {button}
      {isOpen && dropDownList}
    </div>
  );
}

BtnDropDown.defaultProps = {
  children: [],
  classNames: '',
  isOpen: undefined,
  setIsOpen: undefined,
};

BtnDropDown.propTypes = {
  children: PropTypes.arrayOf(element),
  classNames: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default BtnDropDown;