import { useCallback, useContext } from 'react';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';
import { UnitId } from '@/store/types/user/units';
import newBUnitContext from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';

interface UpdateForm {
  ID: string,
  name?: string;
  departments?: string[];
}
const useUpdateUnitName = () => {
  const { units } = useAdminSelector(selectUnitsTreeData);
  const { unit } = useContext(newBUnitContext);

  const createUpdateForm = (unitId: UnitId, newName: string | null = null, newDepartments: UnitId[] | null = null) => {
    if (Number(unitId) <= 0) return undefined;
    const updateForm: UpdateForm = { ID: unitId };
    if (newName) updateForm.name = newName;
    if (newDepartments) updateForm.departments = newDepartments;

    return updateForm;
  };

  const getNewDepartments = useCallback((newValue: string, unitId: UnitId) => {
    if (Number(unitId) <= 0) return [];
    const updatingUnit = units[unitId];
    const departments = [...updatingUnit.departments];
    const { level } = unit;
    departments[level] = newValue;

    return departments;
  }, [unit, units]);

  const updateUnit = useCallback((newName: string, unitId: UnitId, form: UpdateForm[] = []) => {
    const updatedUnit = units[unitId];
    const updateForm = createUpdateForm(unitId, null, getNewDepartments(newName, unitId));

    if (updatedUnit.isunit === 0) return [...form, updateForm as UpdateForm];

    if (!updatedUnit.children?.length) {
      if (updateForm) return [...form, updateForm];
      return [...form];
    }

    let newForms: UpdateForm[] = [];
    for (let i = 0; i < updatedUnit.children.length; i += 1) {
      const childId = updatedUnit.children[i];
      newForms = updateUnit(newName, childId, newForms);
    }

    return [...form, ...newForms];
  }, [getNewDepartments, units]);

  return updateUnit;
};

export default useUpdateUnitName;