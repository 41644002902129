import armeniaLangIcon from '../../static/img/admin/langs/armenia.svg';
import germanyLangIcon from '../../static/img/admin/langs/germany.svg';
import russiaLangIcon from '../../static/img/admin/langs/russia.svg';
import UKLangIcon from '../../static/img/admin/langs/united kingdom.svg';
import franceLangIcon from '../../static/img/admin/langs/france.svg';
import сzechLangIcon from '../../static/img/admin/langs/czech republic.svg';

// roles of the admin to the superset
export const CREATOR = 0;
export const FULL_ADMIN = 1;
export const OBSERVER_WITH_ACCESS_RESULTS = 2;
export const OBSERVER = 3;
// имеет доступ к шаблону. Шаблон будет доступен в списке выбора шаблонов суперсета.
export const CAN_COPY_SUPERSET = -1;

// superset stages
export const CREATION = 0;
export const SETUP = 1;
export const TESTING = 2;
export const DURING_SURVEY_PROCESS = 3;
export const FEEDBACK = 4;
export const ARCHIVE = 5;

// order lang
export const ORDER_RU = 1;
export const ORDER_ENG = 2;
export const ORDER_FR = 3;
export const ORDER_DE = 4;
export const ORDER_ARM = 5;

export const iconsLang = {
  am: armeniaLangIcon,
  de: germanyLangIcon,
  ru: russiaLangIcon,
  en: UKLangIcon,
  fr: franceLangIcon,
  cz: сzechLangIcon,
};

// params superset settings
export const TYPE_BUSINESS_UNITS = 'settings_bunits';
export const USE_TICKETS_SETTING_ID = 'usetickets';
export const USE_UNITS_SETTING_ID = 'useunits';
export const USE_FEEDBACK_SETTING_ID = 'usefeedback';

// type business units settings
export const OPTION_EMPLOYEES_CHOOSE_THEMSELVES_BU = 2;
export const OPTION_BU_LINK = 3;
export const OPTION_BU_CODE_TICKET = 4;
export const OPTION_PERSONAL_LINK = 5;

export const OPTIONS_WHEN_UNITS_ARE_USED = {
  [OPTION_EMPLOYEES_CHOOSE_THEMSELVES_BU]: true,
  [OPTION_BU_LINK]: true,
  [OPTION_BU_CODE_TICKET]: true,
  [OPTION_PERSONAL_LINK]: true,
};

// limit tickets
export const SUPERSET_LIMIT_TICKETS = 10000;
export const UNIT_LIMIT_TICKETS = 1000;

// type of uploading resources

// templates and 360
export const RESOURCE_TYPE_TEMPLATE = 'template';

// pictures and zip
export const RESOURCE_TYPE_RESOURCES = 'resources';