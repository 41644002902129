import { useCallback } from 'react';
import axios from 'axios';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import getFileNameByResponseHeader from '@/helpers/parseFileName';
import { makeFileLinkAndDownload } from '@/helpers/helpers';

const useDownloadWebinarRegistersList = () => {
  const { headers } = useAdminSelector((state) => state.admin.authentication);

  const downloadFile = useCallback(async () : Promise<{ blob: Blob, fileName: string }> => {
    try {
      const response = await axios.get('/api/admin/super/webinar/download-registrations', {
        headers,
        responseType: 'blob',
      });

      return {
        blob: response.data,
        fileName: getFileNameByResponseHeader(response.headers),
      };
    } catch (e) {
      // @ts-ignore
      return e;
    }
  }, [headers]);

  return useCallback(() => {
    downloadFile()
      .then((resp) => makeFileLinkAndDownload(resp))
      .catch(() => {});
  }, [downloadFile]);
};

export default useDownloadWebinarRegistersList;