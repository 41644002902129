import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { selectAdmin } from '@/store/selectors/admin/authentication';

const useCheckPermission = () => {
  const admin = useSelector(selectAdmin);
  const { admins } = useSelector(
    (state) => state.admin.superset.supersetFields,
  );

  return useCallback(
    (key) => {
      if (admin.isSuper) return true;
      if (!key) return false;
      const { permissions } = admins[admin.adminId]
        ? admins[admin.adminId]
        : { permissions: {} };
      return !!permissions[key];
    },
    [admin, admins],
  );
};

export default useCheckPermission;