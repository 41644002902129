// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-header-criteria--CQAhl{display:flex;justify-content:space-between;border-bottom:1px solid var(--dark-grey-line-color);padding-bottom:0.75rem;margin-top:1.875rem}.wrap-header-criteria--CQAhl .control-selected-criteria--Q5OTD .label-control--de-2Z{display:flex;align-items:center;gap:0.625rem;font-family:\"Inter Medium\",sans-serif}.wrap-header-criteria--CQAhl .control-selected-criteria--Q5OTD .label-control--de-2Z .icon-menu--XfBNj:before{color:var(--icon-color)}.wrap-header-criteria--CQAhl .info-hidden-respondents--198XY{display:flex;align-items:center;gap:0.625rem}.wrap-header-criteria--CQAhl .info-hidden-respondents--198XY .icon-hidden--Voslr:before{color:var(--icon-color)}.wrap-header-criteria--CQAhl .toggler-criteria--6VpUH{cursor:pointer}.wrap-header-criteria--CQAhl .toggler-criteria--6VpUH .toggle-criteria-icon--crZBT:before,.wrap-header-criteria--CQAhl .toggler-criteria--6VpUH .toggle-criteria-icon--crZBT:after{background:var(--icon-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/WrapperCuts/Criteria/HeaderCriteria/HeaderCriteria.module.sass"],"names":[],"mappings":"AAEA,6BACE,YAAA,CACA,6BAAA,CACA,mDAAA,CACA,sBAAA,CACA,mBAAA,CAEE,qFACE,YAAA,CACA,kBAAA,CACA,YAAA,CACA,qCAAA,CAEE,8GACE,uBAAA,CACR,6DACE,YAAA,CACA,kBAAA,CACA,YAAA,CAEE,wFACE,uBAAA,CACN,sDACE,cAAA,CAEE,mLAEE,4BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-header-criteria": "wrap-header-criteria--CQAhl",
	"control-selected-criteria": "control-selected-criteria--Q5OTD",
	"label-control": "label-control--de-2Z",
	"icon-menu": "icon-menu--XfBNj",
	"info-hidden-respondents": "info-hidden-respondents--198XY",
	"icon-hidden": "icon-hidden--Voslr",
	"toggler-criteria": "toggler-criteria--6VpUH",
	"toggle-criteria-icon": "toggle-criteria-icon--crZBT"
};
export default ___CSS_LOADER_EXPORT___;
