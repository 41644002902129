import React, { HTMLAttributes, ReactNode, ForwardedRef } from 'react';
import styles from './WebinarInputContainer.module.sass';
import { getClearClassNames } from '@/helpers/helpers';

interface WebinarInputContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  additionalClassNames?: string;
  labelClassNames?: string;
  title?: string;
  id: string;
}

const WebinarInputContainer = React.forwardRef<HTMLDivElement, WebinarInputContainerProps>(
  ({
    children, title, additionalClassNames, labelClassNames, id, ...divProps
  }, ref: ForwardedRef<HTMLDivElement>) => (
    <label
      className={
        getClearClassNames([
          styles['webinar-label'],
          labelClassNames,
        ])
      }
      htmlFor={id}
    >
      {title && <div className={styles.title}>{title}</div>}
      <div
        ref={ref}
        className={getClearClassNames([
          styles['input-container'],
          additionalClassNames,
        ])}
        {...divProps}
      >
        {children}
      </div>
    </label>
  ),
);

export default WebinarInputContainer;