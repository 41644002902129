// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title--O3RHl{text-align:right;color:var(--secondary-color)}.title--O3RHl:hover{cursor:pointer}.input-container--yqEEs{position:relative;border-radius:0.5rem;background:var(--admin-background-interactive-elements);border:.5px solid var(--secondary-color);margin:0 0.75rem 0 0;padding:0.125rem 0.5rem 0.125rem 1rem;width:100%}.input-container--yqEEs .error--\\+bCH8{position:absolute;top:-16px;left:0;color:var(--error-color);font-size:0.625rem}.input-container--yqEEs .input--JJI96{font-size:1rem;height:100%;width:100%;outline:none}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitCardSection/BUnitCardInput/BUnitCardInput.module.sass"],"names":[],"mappings":"AAEA,cACE,gBAAA,CACA,4BAAA,CACA,oBACE,cAAA,CAGJ,wBACE,iBAAA,CACA,oBAAA,CACA,uDAAA,CACA,wCAAA,CACA,oBAAA,CACA,qCAAA,CACA,UAAA,CACA,uCACE,iBAAA,CACA,SAAA,CACA,MAAA,CACA,wBAAA,CACA,kBAAA,CACF,sCACE,cAAA,CACA,WAAA,CACA,UAAA,CACA,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "title--O3RHl",
	"input-container": "input-container--yqEEs",
	"error": "error--+bCH8",
	"input": "input--JJI96"
};
export default ___CSS_LOADER_EXPORT___;
