import {
  Dispatch, SetStateAction, useCallback, useEffect, useState,
} from 'react';
import { getSupersetUnits } from '@/store/actions/admin/superset/unitsTree';
import parseUnits from '@/components/Common/UnitsTree/parseUnits';
import { BackendUnit, TreeUnits } from '@/store/types/admin/superset/unitsTree';
import { UnitId } from '@/store/types/user/units';
import { unitTreeSlice } from '@/store/reducers/admin/superset/unitsTree';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import useLocalStorageByAdmin from '@/components/App/Admin/BUnitsSection/UnitsTree/hooks/adminLocalStorageSaver';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';

const useLoadUnits = (): [boolean, boolean, Dispatch<SetStateAction<boolean>>] => {
  const [treeLoaded, toggleTreeLoaded] = useState<boolean>(false);
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const dispatch = useAdminDispatch();
  const [writeLocal, readLocal] = useLocalStorageByAdmin<UnitId[]>('expandedUnits');
  const { supersetId } = useAdminSelector(selectSuperset);

  const loadUnits = useCallback(() => {
    dispatch(getSupersetUnits({ supersetId, flag: 'all' }))
      .then((response) => {
        if (!response.payload) {
          return;
        }

        const { units, order, roots } = parseUnits(response.payload as BackendUnit[]) as { units: TreeUnits, order: UnitId[], roots: UnitId[] };
        const expandedUnits = readLocal();
        if (expandedUnits) {
          Object.keys(units).forEach((id) => {
            if (expandedUnits.includes(id)) units[id].exp = 1;
            else units[id].exp = 0;
          });
        } else {
          const newExpanded = Object.keys(units).filter((id) => !!units[id].exp);
          writeLocal(newExpanded);
        }
        dispatch(unitTreeSlice.actions.makeUnitsTree({ units, order, roots }));
        toggleTreeLoaded(true);
        toggleLoading(true);
      })
      .catch(() => {
        toggleLoading(false);
      })
      .finally(() => {
        toggleTreeLoaded(true);
        toggleLoading(false);
      });
  }, [dispatch, readLocal, supersetId, writeLocal]);

  useEffect(() => {
    if (treeLoaded || isLoading) return;
    loadUnits();
  }, [isLoading, loadUnits, treeLoaded]);

  return [treeLoaded, isLoading, toggleTreeLoaded];
};

export default useLoadUnits;