import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getBlocks,
  getPages,
  changeCurrentPage,
} from '@/store/actions/user/pages/pages';

const initialState:PagesInitialState = {
  pages: {},
  parsePagesStatus: 'idle',
  currentPage: 0,
  finishPage: undefined,
  blocks: {},
  agreementPage: false,
  confirmedPage: false,
  isChanged: false,
  snaItemsByPages: {},
  surveyStartPage: undefined,
  degreeItemsByPages: {},
  variables: {
    $$resume: 0,
    $$feedback: 0,
    $$actor: 0,
    $$confirmed: 0,
    $$accepted: 0,
  },
  updatedVariable: '',
  itemDeps: {},
};

export const pagesSlice = createSlice({
  name: 'pages',
  initialState,
  // тут что-то локальное и не асинхронное
  reducers: {
    clearPagesState: (state) => {
      state.pages = {};
      state.currentPage = 0;
    },
    finishSurvey: (state) => {
      state.currentPage = state.finishPage as number;
    },
    changeCurrentPage: (state, { payload }: PayloadAction<{ currentPage: number }>) => {
      state.currentPage = payload.currentPage;
    },
    setVariable: (state, { payload }: PayloadAction<Variables>) => {
      state.variables = { ...state.variables, ...payload };
      const [updatedVariable] = Object.keys(payload);
      state.updatedVariable = updatedVariable;
    },
    catchUserAction: (state) => {
      state.isChanged = true;
    },
    deleteUpdatedVariable: (state) => {
      state.updatedVariable = '';
    },
    setSurveyStartPage: (state, { payload }: PayloadAction<{ startPage: number }>) => {
      state.surveyStartPage = payload.startPage;
    },
  },
  // а тут всякие запросы и асинхронные экшны
  extraReducers: (builder) => {
    builder
      .addCase(getPages.pending, (state) => {
        state.parsePagesStatus = 'loading';
      })
      .addCase(getPages.fulfilled, (state, { payload }: PayloadAction<GetPagesPayload>) => {
        const pages: Pages = {};
        const actionPages = payload.response.pages;
        const { itemDeps } = payload.response;
        const { isFinish } = payload;
        if (isFinish) {
          const finishPageIndex = Object.keys(actionPages).length - 1;
          const finishPage = actionPages[finishPageIndex];
          state.pages = { 0: finishPage };
          state.itemDeps = itemDeps;
          return;
        }
        Object.keys(actionPages).forEach((pageKey) => {
          let page = actionPages[pageKey];
          if (pageKey === '0') {
            const buttonsForms: ButtonsForm[] | [] = page.forms.filter(
              ({ type }) => type === 'buttons',
            ) as ButtonsForm[];
            buttonsForms.forEach(({ buttons }) => {
              const acceptedForm = buttons.find(
                (button) => button[0] === 'accept',
              );
              const confirmedForm = buttons.find(
                (button) => button[0] === 'confirm',
              );
              if (acceptedForm) {
                state.agreementPage = true;
                page = { ...page, isAgreement: true };
              }
              if (confirmedForm) {
                state.confirmedPage = true;
                page = { ...page, isConfirmed: true };
              }
            });
          }
          if (
            page.forms.filter(
              (form) => form.type !== 'buttons' && form.type !== 'text',
            ).length > 0
          ) {
            page = { ...page, withAnswers: true };
          }
          const pageItems: PageItems = {};
          page.forms.forEach((form) => {
            if (!('items' in form)) return;
            form.items.forEach((item) => {
              pageItems[item.id] = {
                itemCond: item.cond,
                formCond: form.cond,
                visible: true,
              };
            });
          });
          const snaForm: SnaForm | undefined = page.forms.find(({ type }) => type === 'sna') as SnaForm;
          if (snaForm) {
            const snaItemsByPage: PageSnaItems = {};
            snaForm.items.forEach(({ id, self, subtype }) => {
              snaItemsByPage[id] = { id, self, subtype };
            });
            state.snaItemsByPages[pageKey] = snaItemsByPage;
            page = { ...page, isSna: true };
          }
          const degreeForm = page.forms.find(
            ({ type }) => type === '360degree',
          ) as DegreeForm;
          if (degreeForm) {
            const degreeFormItems: DegreePageItems = {};
            const degreeItems: DegreeItem[] = degreeForm.items ? [...degreeForm.items] : [];
            const degreeCItems = degreeForm.citems
              ? [...degreeForm.citems]
              : [];
            [...degreeItems, ...degreeCItems].forEach((item) => {
              if (!item.id) return;
              degreeFormItems[item.id] = { id: item.id, self: item.self };
            });
            state.degreeItemsByPages[pageKey] = { ...degreeFormItems };
            page = { ...page, isBlock360: true };
          }
          const testForm = page.forms.find(({ type }) => type === 'test');
          if (testForm) page = { ...page, isTest: true };
          if (page.forms.filter((form) => form.type === 'units').length > 0) {
            page = { ...page, isUnits: true };
          }
          pages[pageKey] = { ...page, pageItems };
        });
        state.pages = pages;
        state.surveyStartPage = Object.keys(pages).length - 1;
        state.itemDeps = itemDeps;
        state.finishPage = Object.keys(pages).length - 1;
        state.parsePagesStatus = 'received';
      })
      .addCase(changeCurrentPage.fulfilled, (state, { payload }: PayloadAction<ChangeCurrentPagePayload>) => {
        state.currentPage = payload.currentPage;
      })
      .addCase(getBlocks.fulfilled, (state, { payload }: PayloadAction<Blocks>) => {
        state.blocks = payload;
      });
  },
});

const { reducer } = pagesSlice;
export default reducer;