import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { authAdminByToken } from '@/store/actions/admin';

function AdminCreatePassword() {
  const { adminId, time, restoreToken } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(authAdminByToken({ adminId, time, token: restoreToken }))
      .then(unwrapResult)
      .then(() => {
        navigate('/admin/profile', { replace: true });
      })
      .catch(() => null);
  }, [dispatch, adminId, time, restoreToken, navigate]);

  return null;
}

export default AdminCreatePassword;