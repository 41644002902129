import { useSelector } from 'react-redux';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { CREATOR, FULL_ADMIN } from '@/constants/admin';

// eslint-disable-next-line react/prop-types
function RequireRoleChangeAccess({ children }) {
  const { role } = useSelector(selectSuperset);

  if (role && !role.includes(CREATOR) && !role.includes(FULL_ADMIN)) {
    return null;
  }

  return children;
}

export default RequireRoleChangeAccess;