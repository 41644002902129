import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import FallbackSupersetSection from '@/components/App/Admin/SupersetSettings/FallbackSupersetSection';
import { loadSuperset } from '@/store/actions/admin';

function RestartFieldActivity({ resetErrorBoundary }) {
  const dispatch = useDispatch();
  const { id: supersetId } = useParams();

  const onResetBoundary = () => {
    dispatch(loadSuperset({ supersetId }));
    resetErrorBoundary();
  };

  return <FallbackSupersetSection onReset={onResetBoundary} />;
}

RestartFieldActivity.propTypes = {
  resetErrorBoundary: PropTypes.func.isRequired,
};

export default RestartFieldActivity;