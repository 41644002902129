import { selectCurrentLangID } from '@/store/selectors/admin/superset/projects';

const getStoreContext = (state) => state.admin.superset.process.cuts.unitsCuts;

export const selectUnitsCuts = (state) => getStoreContext(state).cuts;

export const selectUnitsItems = (state) => {
  const { items } = getStoreContext(state);
  const currentLangID = selectCurrentLangID(state);

  if (Object.keys(items).length === 0) {
    return [];
  }

  return items[currentLangID];
};

export const selectFirstItemUnitsCuts = (state) => selectUnitsItems(state)[0];

export const selectItemByIDUnitsCuts = (state, itemID) => {
  const items = selectUnitsItems(state);
  const indxItem = items.findIndex((item) => item.id === itemID);

  return { ...items[indxItem], number: indxItem + 1 };
};

export const selectSNADirection = (state) => getStoreContext(state).SNADirection;

export const selectSelfAssessmentStatus = (state) => getStoreContext(state).self;