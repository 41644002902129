// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".description-preview--LWQI3{display:flex;flex-direction:column;width:100%;gap:1.25rem}.description-preview--LWQI3 .title--VxyNo{align-self:baseline}", "",{"version":3,"sources":["webpack://./src/components/App/Webinar/CreateWebinarPage/CreateWebinarForm/WebinarContentSection/WebinarContentPreview/WebinarDescriptionPreview/WebinarDescriptionPreview.module.sass"],"names":[],"mappings":"AAEA,4BACE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,WAAA,CACA,0CACE,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description-preview": "description-preview--LWQI3",
	"title": "title--VxyNo"
};
export default ___CSS_LOADER_EXPORT___;
