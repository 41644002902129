import { setCurrentLanguageID } from '@/store/actions/admin';
import { selectAdmin } from '@/store/selectors/admin/authentication';
import { selectSupersetProjects } from '@/store/selectors/admin/superset/projects';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';

// TODO добавить хэш для localstorage
const getActualStructForCurrentAdminAndSuperset = (
  langID,
  adminID,
  supersetID,
  projects,
) => {
  const adminsToSelectedLangID = JSON.parse(
    localStorage.getItem('adminsToSelectedLangID'),
  );

  // if there's no data in localStorage
  if (!adminsToSelectedLangID) {
    return {
      [adminID]: {
        [supersetID]: langID,
      },
    };
  }

  // If there's no current adminID --> so the admin didn't log into the superset at all;
  if (!(adminID in adminsToSelectedLangID)) {
    return {
      ...adminsToSelectedLangID,
      [adminID]: {
        [supersetID]: langID,
      },
    };
  }

  // If there's no supersetID --> so the admin didn't log into the current superset;
  if (!(supersetID in adminsToSelectedLangID[adminID])) {
    const copyAdminsToLangID = { ...adminsToSelectedLangID };

    copyAdminsToLangID[adminID] = {
      ...copyAdminsToLangID[adminID],
      [supersetID]: langID,
    };

    return copyAdminsToLangID;
  }

  // if there's no langID in superset --> so the project was deleted and need to set new langID;
  const selectedLangID = adminsToSelectedLangID[adminID][supersetID];
  const doesSelectedLangIDExistInProject = !!Object.keys(projects).find(
    (projectID) => {
      const { langId: projectLangID } = projects[projectID];

      return selectedLangID === projectLangID;
    },
  );

  if (!doesSelectedLangIDExistInProject) {
    const copyAdminsToLangID = { ...adminsToSelectedLangID };
    copyAdminsToLangID[adminID][supersetID] = langID;

    return copyAdminsToLangID;
  }

  // the path from adminID->supersetID is exist, so set selected langID
  adminsToSelectedLangID[adminID][supersetID] = langID;

  return adminsToSelectedLangID;
};

const saveLangIDMiddleware = (store) => (next) => (action) => {
  if (action.type !== setCurrentLanguageID.type) return next(action);

  const state = store.getState();

  const { adminId } = selectAdmin(state);
  const { supersetId } = selectSuperset(state);
  const projects = selectSupersetProjects(state);
  const langID = action.payload;

  const currentStructAdminsToLangID = getActualStructForCurrentAdminAndSuperset(
    langID,
    adminId,
    supersetId,
    projects,
  );

  localStorage.setItem(
    'adminsToSelectedLangID',
    JSON.stringify(currentStructAdminsToLangID),
  );

  return next(action);
};

export default saveLangIDMiddleware;