// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkmark--ihESF{position:relative;display:inline-block;width:0.8125rem;height:0.8125rem}.checkmark--ihESF::before,.checkmark--ihESF::after{border-radius:10px}.checkmark--ihESF::before{position:absolute;left:0;top:50%;height:50%;width:1.5px;background-color:#fff;content:\"\";transform:translateX(0.3125rem) rotate(-45deg);transform-origin:left bottom}.checkmark--ihESF::after{position:absolute;left:0;bottom:0;height:1.5px;width:100%;background-color:#fff;content:\"\";transform:translateX(0.3125rem) rotate(-45deg);transform-origin:left bottom}", "",{"version":3,"sources":["webpack://./src/components/Common/Elements/cssIcons/Checkmark/Checkmark.module.sass"],"names":[],"mappings":"AAEA,kBACE,iBAAA,CACA,oBAAA,CACA,eAAA,CACA,gBAAA,CACA,mDAEE,kBAAA,CACF,0BACE,iBAAA,CACA,MAAA,CACA,OAAA,CACA,UAAA,CACA,WAAA,CACA,qBAAA,CACA,UAAA,CACA,8CAAA,CACA,4BAAA,CACF,yBACE,iBAAA,CACA,MAAA,CACA,QAAA,CACA,YAAA,CACA,UAAA,CACA,qBAAA,CACA,UAAA,CACA,8CAAA,CACA,4BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkmark": "checkmark--ihESF"
};
export default ___CSS_LOADER_EXPORT___;
