import React from 'react';
import PropTypes from 'prop-types';
import useModal from '@/hooks/modal';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import Modal from '@/components/Common/Modal';

function ModalConfirm({
  title,
  isShowingModal,
  toggleModal,
  onConfirm,
  confirmText,
  cancelText,
}) {
  const { WrapBtns } = useModal();

  return (
    <Modal
      isShowing={isShowingModal}
      hide={toggleModal}
      title={title}
      types="modal-small"
    >
      <WrapBtns>
        <CustomButton
          onClick={toggleModal}
          styleTypeBtn="btn-grey-modal"
        >
          {cancelText}
        </CustomButton>
        <CustomButton
          onClick={onConfirm}
          styleTypeBtn="btn-blue-modal"
        >
          {confirmText}
        </CustomButton>
      </WrapBtns>
    </Modal>
  );
}

ModalConfirm.propTypes = {
  title: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isShowingModal: PropTypes.bool.isRequired,
  confirmText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
};

export default ModalConfirm;