import React from 'react';
import PropTypes from 'prop-types';
import SupersetEvent from '@/components/App/Admin/Superset/SupersetEvens/SupersetEventsList/SupersetEvent/SupersetEvent';

function SupersetEventsList({ events, langId }) {
  return (
    <ul>
      {events.map(({ message, eventId, createdAt }) => (
        <SupersetEvent
          key={`${eventId}-${createdAt}`}
          message={message}
          createdAt={createdAt}
          langId={langId}
          eventId={eventId}
        />
      ))}
    </ul>
  );
}

SupersetEventsList.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      eventId: PropTypes.number,
      message: PropTypes.string,
    }),
  ).isRequired,
  langId: PropTypes.string.isRequired,
};

export default SupersetEventsList;