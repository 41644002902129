// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notify-container--hfrH0{position:absolute;right:0}.notify-container--hfrH0 .notify--NBy4a{position:relative}.notify-container--hfrH0.hidden--Wuja9{box-shadow:none}.notify-container--hfrH0 .notify-content--Uq3iX{display:flex;font-size:0.875rem;flex-direction:column}.notify-container--hfrH0 .notify-content--Uq3iX .superset-link--8oikU{width:fit-content;font-size:1rem;text-decoration:underline;text-align:left;padding:0;color:var(--secondary-color)}.notify-container--hfrH0 .notify-content--Uq3iX .superset-link--8oikU:hover{cursor:pointer}.notify-container--hfrH0 .close-button--Coh8t{position:absolute;background:var(--content-block-bg);border-radius:50%;padding:0;margin:0;top:-8px;left:-8px;font-size:16px}.notify-container--hfrH0 .close-button--Coh8t .icon--mAuJr:before{color:var(--secondary-color)}", "",{"version":3,"sources":["webpack://./src/components/Common/NotificationsBar/NotificationContainer/NotificationContainer.module.sass"],"names":[],"mappings":"AAEA,yBACE,iBAAA,CACA,OAAA,CACA,wCACE,iBAAA,CAEF,uCACE,eAAA,CACF,gDACE,YAAA,CACA,kBAAA,CACA,qBAAA,CACA,sEACE,iBAAA,CACA,cAAA,CACA,yBAAA,CACA,eAAA,CACA,SAAA,CACA,4BAAA,CACA,4EACE,cAAA,CAEN,8CACE,iBAAA,CACA,kCAAA,CACA,iBAAA,CACA,SAAA,CACA,QAAA,CACA,QAAA,CACA,SAAA,CACA,cAAA,CAEE,kEACE,4BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notify-container": "notify-container--hfrH0",
	"notify": "notify--NBy4a",
	"hidden": "hidden--Wuja9",
	"notify-content": "notify-content--Uq3iX",
	"superset-link": "superset-link--8oikU",
	"close-button": "close-button--Coh8t",
	"icon": "icon--mAuJr"
};
export default ___CSS_LOADER_EXPORT___;
