import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Modal from '@/components/Common/Modal';
import Content from '@/components/Common/Modal/Content';
import { deleteUnit } from '@/store/actions/admin/superset/unitsTree';
import styles from './DeleteUnitModal.module.sass';
import BUnitsMenuBtn from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitsBlueBtn/BUnitsMenuBtn';
import useNLS from '@/components/App/Admin/hooks/hooks';
import BUnitContext from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';

function DeleteUnitModal({ isShowingModal, toggleModal }) {
  const dispatch = useDispatch();
  const { id, unit } = useContext(BUnitContext);
  const tNLS = useNLS();

  const handleDeleteUnit = () => {
    dispatch(deleteUnit({ unitId: id }));
    toggleModal(false);
  };

  return (
    <Modal
      isShowing={isShowingModal}
      hide={() => toggleModal(false)}
      title={tNLS(`tree.delete.${unit.isunit === 0 ? 'person' : 'dep'}.title`)}
    >
      <Content>
        <div>
          {tNLS(`tree.delete.${unit.isunit === 0 ? 'person' : 'dep'}.text`)}
        </div>
        <div className={styles['buttons-container']}>
          <BUnitsMenuBtn
            type="reject"
            handleButtonClick={() => toggleModal(false)}
          >
            {tNLS('tree.delete.button.cancel')}
          </BUnitsMenuBtn>
          <BUnitsMenuBtn
            handleButtonClick={() => {
              handleDeleteUnit();
            }}
          >
            {tNLS('tree.delete.button.go')}
          </BUnitsMenuBtn>
        </div>
      </Content>
    </Modal>
  );
}

DeleteUnitModal.propTypes = {
  isShowingModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default DeleteUnitModal;