import React, { useState } from 'react';
import '../BUnitStyles.sass';
import styles from './EmployeeUnit.module.sass';
import BUnitCard from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCard';
import AnimatedContainerByHeight from '@/components/Common/AnimatedContainerByHeight/AnimatedContainerByHeight';
import EmployeeUnitFields from './EmployeeUnitFields/EmployeeUnitFields';
import { getClearClassNames } from '@/helpers/helpers';

function EmployeeUnit() {
  const [isCardOpen, toggleCard] = useState(false);

  return (
    <div className={getClearClassNames([
      isCardOpen && styles['with-card'],
    ])}
    >
      <EmployeeUnitFields isCardOpen={isCardOpen} toggleCard={toggleCard} />
      <AnimatedContainerByHeight
        durationInMs={100}
        isOpen={isCardOpen}
        classNames={styles['card-container']}
      >
        <BUnitCard closeCard={() => toggleCard(false)} />
      </AnimatedContainerByHeight>
    </div>
  );
}

export default EmployeeUnit;