import {
  Dispatch, SetStateAction, useMemo, useState,
} from 'react';
import useLocalStorageByAdmin from '@/components/App/Admin/BUnitsSection/UnitsTree/hooks/adminLocalStorageSaver';
import useNLS from '@/components/App/Admin/hooks/hooks';
import {
  getBUnitsOperations,
  getOperationByType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/helpers';
import { useAdminSelector } from '@/store/hooks/adminRedux';

// todo пределать, так как в данном случае действие зависит от того, как оно называется на бэке
export enum UnitActionsKeysByOperationType {
  closeAccess = 'tree.menu.close.access',
  openAccess = 'tree.menu.open.access',
  closeOldLinks = 'tree.menu.close.links',
  returnToFirst = 'tree.menu.return.2first',
  sendEmail = 'tree.menu.send.invitations',
  deactivateProtocols = 'tree.menu.deactivate.protocols',
  activateProtocols = 'tree.menu.activate.protocols',
  deleteProtocols = 'tree.menu.delete.protocols',
  transferUnits = 'tree.menu.move',
  runProcessor = 'tree.menu.run.processor',
  addSpec = 'tree.menu.add.specs',
}

export interface BUnitGroupAction {
  name: string;
  operationType: keyof typeof UnitActionsKeysByOperationType;
}

export type SetBUnitActiveActionType = Dispatch<SetStateAction<BUnitGroupAction>>;

const useBUnitGroupAction = (): [BUnitGroupAction, SetBUnitActiveActionType, BUnitGroupAction[]] => {
  const [, readFromLocal] = useLocalStorageByAdmin<BUnitGroupAction>('unitsActions');
  const tNls = useNLS();
  const { mailTemplates } = useAdminSelector(
    (state) => state.admin.superset.supersetFields,
  );
  const bUnitsOperations = useMemo<BUnitGroupAction[]>(() => getBUnitsOperations(mailTemplates, tNls), [mailTemplates, tNls]);
  const [activeAction, setActiveAction] = useState<BUnitGroupAction>(
    () => readFromLocal() || getOperationByType('closeAccess', tNls),
  );

  return [activeAction, setActiveAction, bUnitsOperations];
};

export default useBUnitGroupAction;