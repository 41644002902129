import defaultNLS from '@/../static/defaultData/defaultNLS.json';
import { PlayerState } from '@/store/player';
import defaultUserErrorsNls from '@/../static/defaultData/defaultUserErrorsNls.json';

export const selectActorProfile = (state: PlayerState) => state.user.actor.profile || {};

export const selectActorProject = (state: PlayerState) => state.user.actor.project;

export const selectProjectNLS = (state: PlayerState) => {
  const project = selectActorProject(state);

  const NLS = project?.NLS || {};

  return { ...defaultNLS, ...defaultUserErrorsNls, ...NLS };
};