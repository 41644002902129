// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content-container--kZAgl{background:var(--content-block-bg);border-radius:20px;padding:1.25rem;height:fit-content}", "",{"version":3,"sources":["webpack://./src/components/App/Webinar/WebinarContentContainer/WebinarContentContainer.module.sass"],"names":[],"mappings":"AAEA,0BACE,kCAAA,CACA,kBAAA,CACA,eAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-container": "content-container--kZAgl"
};
export default ___CSS_LOADER_EXPORT___;
