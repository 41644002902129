// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".files-list--jsJXF{display:flex;flex-direction:column;flex-wrap:wrap;max-height:100%;gap:0.375rem}.files-list--jsJXF .file--CoDdQ{text-align:right}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SuperAdmin/SuperUploading/FilesForUploadList/FilesForUploadList.module.sass"],"names":[],"mappings":"AAEA,mBACE,YAAA,CACA,qBAAA,CACA,cAAA,CACA,eAAA,CACA,YAAA,CACA,gCACE,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"files-list": "files-list--jsJXF",
	"file": "file--CoDdQ"
};
export default ___CSS_LOADER_EXPORT___;
