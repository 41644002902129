import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Superset.module.sass';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import {
  ARCHIVE,
  DURING_SURVEY_PROCESS,
  FEEDBACK,
  SETUP,
  TESTING,
} from '@/constants/admin';
import useModal from '@/hooks/modal';
import Modal from '@/components/Common/Modal';
import { updateSupersetStage } from '@/store/actions/admin';
import HeaderSuperset from '@/components/App/Admin/Superset/HeaderSuperset';
import { selectAdminSettings } from '@/store/selectors/admin/superset/settings';
import useNLS from '@/components/App/Admin/hooks/hooks';
import InviteInfo from '@/components/App/Admin/Superset/InviteInfo';
import SupersetEvents from '@/components/App/Admin/Superset/SupersetEvens/SupersetEvents';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';

function Superset() {
  const tNLS = useNLS();
  const dispatch = useDispatch();
  const { supersetId, stage: activeStage } = useSelector(selectSuperset);
  const settings = useSelector(selectAdminSettings);
  const [stages, setStages] = useState([]);
  const checkPermission = useCheckPermission();

  const {
    isShowingModal,
    toggleStatusShowingModal,
    onShowingModalData,
    WrapBtns,
  } = useModal();

  useEffect(() => {
    setStages(() => {
      let newStages = [
        {
          stage: SETUP,
          title: tNLS('stage1.title'),
          description: tNLS('stage1.desc'),
        },
        {
          stage: TESTING,
          title: tNLS('stage2.title'),
          description: tNLS('stage2.desc'),
        },
      ];

      if (settings.usefeedback) {
        switch (settings.usefeedback.value) {
          case 1:
            newStages = [
              ...newStages,
              {
                stage: DURING_SURVEY_PROCESS,
                title: tNLS('stage3a.title'),
                description: tNLS('stage3a.desc'),
              },
            ];
            break;
          case 2:
            newStages = [
              ...newStages,
              {
                stage: DURING_SURVEY_PROCESS,
                title: tNLS('stage3b.title'),
                description: tNLS('stage3b.desc'),
              },
              {
                stage: FEEDBACK,
                title: tNLS('stage4b.title'),
                description: tNLS('stage4b.desc'),
              },
            ];
            break;
          case 3:
            newStages = [
              ...newStages,
              {
                stage: DURING_SURVEY_PROCESS,
                title: tNLS('stage3с.title'),
                description: tNLS('stage3с.desc'),
              },
              {
                stage: FEEDBACK,
                title: tNLS('stage4с.title'),
                description: tNLS('stage4с.desc'),
              },
            ];
            break;
          default:
            break;
        }
      }

      newStages = [
        ...newStages,
        {
          stage: ARCHIVE,
          title: tNLS('stage5.title'),
          description: tNLS('stage5.desc'),
        },
      ];

      return newStages;
    });
  }, [tNLS, settings.usefeedback]);

  const onChangeStage = (stage) => {
    dispatch(
      updateSupersetStage({
        supersetID: supersetId,
        updatedSuperset: {
          superset: {
            newStage: stage, // todo hardcode. Проблема со стадиями
            prevStage: activeStage,
          },
        },
      }),
    );
    toggleStatusShowingModal(false);
  };
  const activeStageNum = stages.findIndex(({ stage }) => stage === activeStage);
  const canSwich = (currentStageNum) => (stages[currentStageNum + 1] !== undefined
      || stages[currentStageNum - 1] !== undefined)
    && (currentStageNum === activeStageNum + 1
      || currentStageNum === activeStageNum - 1)
    && checkPermission('change_stage');
  return (
    <>
      <div className={styles['content-superset-stages']}>
        <HeaderSuperset />

        <section
          className={`${styles['superset-section']} ${styles['section-superset-stages']}`}
        >
          <h2
            className={`${styles['title-section']} ${styles['title-section-stages']}`}
          >
            {tNLS('stages.title')}
          </h2>
          <ul className={styles['list-stages']}>
            {stages.map(({ stage, title, description }, stageNum) => {
              const isStageAvailableForSwitching = canSwich(
                stageNum,
                activeStageNum,
              );

              return (
                <li key={stage} className={styles['item-stage']}>
                  <div
                    className={`${styles['wrap-item-stage']}
                 ${stage === activeStage ? styles['active-stage'] : ''} 
                 ${
                   isStageAvailableForSwitching
                     ? styles['available-for-switching']
                     : ''
                 }`}
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => {}}
                    onClick={
                      isStageAvailableForSwitching
                        ? () => toggleStatusShowingModal(stage)
                        : () => {}
                    }
                  >
                    <div className={styles['wrap-stage-number']}>
                      <span className={styles['stage-number']}>
                        {stageNum + 1}
                      </span>
                      <span className={styles.line} />
                    </div>
                    <span className={styles['stage-name']}>{title}</span>
                    <p className={styles['stage-description']}>{description}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        </section>
        <InviteInfo />
        {checkPermission('view_project_events') && <SupersetEvents />}
      </div>
      <Modal
        isShowing={isShowingModal}
        hide={toggleStatusShowingModal}
        title={tNLS('stages.modal.title')}
        types="modal-small"
      >
        <WrapBtns>
          <CustomButton
            onClick={toggleStatusShowingModal}
            styleTypeBtn="btn-grey-modal"
          >
            {tNLS('stages.modal.btns.cancel')}
          </CustomButton>
          <CustomButton
            onClick={() => onChangeStage(onShowingModalData)}
            styleTypeBtn="btn-blue-modal"
          >
            {tNLS('stages.modal.btns.confirm')}
          </CustomButton>
        </WrapBtns>
      </Modal>
    </>
  );
}

export default Superset;