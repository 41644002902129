// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".unit-container--MKC3d{position:relative;display:flex;justify-content:space-between;align-items:baseline;border-radius:0.75rem;padding:0.25rem 0.5rem;transition:background linear 300ms}.unit-container--MKC3d:hover,.unit-container--MKC3d.backlight--sgQdE{background:var(--admin-hover-background-interactive-elements)}.actor--6jSAJ{justify-content:space-between}.actor--6jSAJ .radius--CcvuW{position:absolute;bottom:50%;right:100%;width:1.875rem;height:3.75rem;border-radius:0 0 0 0.75rem;border-bottom:1px solid #838999;border-left:1px solid #838999}.actor--6jSAJ:first-child .radius--CcvuW{height:1.875rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContainer/BUnitContainer.module.sass"],"names":[],"mappings":"AAEA,uBACE,iBAAA,CACA,YAAA,CACA,6BAAA,CACA,oBAAA,CACA,qBAAA,CACA,sBAAA,CACA,kCAAA,CACA,qEACE,6DAAA,CAEJ,cACE,6BAAA,CACA,6BACE,iBAAA,CACA,UAAA,CACA,UAAA,CACA,cAAA,CACA,cAAA,CACA,2BAAA,CACA,+BAAA,CACA,6BAAA,CAEA,yCACE,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unit-container": "unit-container--MKC3d",
	"backlight": "backlight--sgQdE",
	"actor": "actor--6jSAJ",
	"radius": "radius--CcvuW"
};
export default ___CSS_LOADER_EXPORT___;
