import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './BUnitsSearch.module.sass';
import BUnitSearchList from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitsSearch/BUnitSearchList/BUnitSearchList';
import useDebounce from '@/hooks/debounce';
import { selectNLS } from '@/store/selectors/admin/superset/supersets';
import { selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';
import useDropdown from '@/hooks/useDropdown';

export default function BUnitsSearch({ setSearchingValue }) {
  const { units } = useSelector(selectUnitsTreeData);
  const [inputValue, setInputValue] = useState('');
  const NLS = useSelector(selectNLS);
  const { isOpen, toggleOpen, dropdownContainerRef } = useDropdown();

  const setInputValueWithDebounce = useDebounce(setInputValue, 200);

  const getSearchingUnitLocation = (id) => {
    const path = [];
    let parentId = units[id].parent;
    while (parentId) {
      path.push(parentId);
      parentId = units[parentId].parent;
    }
    setSearchingValue({ id, path });
    toggleOpen(false);
  };

  return (
    <div className={styles['search-units-section']}>
      <div
        className={`${styles['input-container']} ${
          isOpen && inputValue.length >= 2 && styles.active
        }`}
        ref={dropdownContainerRef}
      >
        <input
          type="text"
          className={styles['search-units-input']}
          placeholder={NLS['tree.search.placeholder']}
          onKeyUp={(event) => {
            if (!isOpen) toggleOpen(true);
            setInputValueWithDebounce(event.target.value);
          }}
          onClick={() => toggleOpen((prevState) => !prevState)}
        />
        {isOpen && inputValue.length >= 2 && (
          <BUnitSearchList
            getSearchingUnitLocation={getSearchingUnitLocation}
            value={inputValue}
          />
        )}
      </div>
    </div>
  );
}

BUnitsSearch.propTypes = {
  setSearchingValue: PropTypes.func.isRequired,
};