import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './FallbackSupersetSection.module.sass';
import { addNotification } from '@/store/reducers/notify';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';

function FallbackSupersetSection({ onReset }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      addNotification({
        type: 'error',
        title: 'Oops',
        message: 'Something went wrong',
      }),
    );
  }, [dispatch]);

  return (
    <div className={styles['wrap-fallback-superset-section']}>
      <div className={styles['wrap-message']}>
        <span className={`icon-error ${styles['icon-error']}`}>
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
        </span>
        Something went wrong
        <CustomButton styleTypeBtn="btn-grey btn-large" onClick={onReset}>
          Try restarting
        </CustomButton>
      </div>
    </div>
  );
}

FallbackSupersetSection.defaultProps = {
  onReset: () => {},
};

FallbackSupersetSection.propTypes = {
  onReset: PropTypes.func,
};

export default FallbackSupersetSection;