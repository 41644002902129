import { createSlice } from '@reduxjs/toolkit';
import {
  loadUnitCuts,
  setUnitsCutsItems,
} from '@/store/actions/admin/superset/process/cuts/unitsCuts';
import { clearCutsData } from '@/store/actions/admin/superset/process/cuts/cuts';

const initialState = {
  cuts: {},
  items: {},
  SNADirection: false,
  self: true,
};

const unitsCutsSlice = createSlice({
  name: 'admin/superset/process/cuts/unitsCutsSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(loadUnitCuts.fulfilled, (state, { payload }) => {
        const { SNACuts, direction, self } = payload;
        state.cuts = SNACuts;
        state.SNADirection = direction;
        state.self = self;
      })
      .addCase(setUnitsCutsItems, (state, { payload: items }) => {
        state.items = items;
      })
      .addCase(clearCutsData, () => initialState);
  },
});

export default unitsCutsSlice.reducer;