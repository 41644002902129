import { createSlice } from '@reduxjs/toolkit';
import {
  loadCuts,
  setQuestionsCutsItems,
} from '@/store/actions/admin/superset/process/cuts/questionsCuts';
import { clearCutsData } from '@/store/actions/admin/superset/process/cuts/cuts';

const initialState = {
  cuts: {},
  items: {},
};

const questionsCutsSlice = createSlice({
  name: 'admin/superset/process/cuts/questionsCuts',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(loadCuts.fulfilled, (state, { payload: cuts }) => {
        // if no data about cuts --> show empty the histogram
        if (Object.keys(cuts).length === 0) {
          state.cuts = {};
        } else {
          state.cuts = cuts;
        }
      })
      .addCase(setQuestionsCutsItems, (state, { payload: items }) => {
        state.items = items;
      })
      .addCase(clearCutsData, () => initialState);
  },
});

export default questionsCutsSlice.reducer;