import React from 'react';
import { useParams } from 'react-router-dom';
import {
  FACTORS_CUTS,
  FOUR_D,
  QUESTIONS_CUTS,
  UNITS_CUTS,
} from '@/components/App/Admin/Results/Cuts/constants';
import QuestionsCuts from '@/components/App/Admin/Results/Cuts/QuestionsCuts';
import FactorsCuts from '@/components/App/Admin/Results/Cuts/FactorsCuts';
import FourD from '@/components/App/Admin/Results/Cuts/FourD';

function CutsDistributor() {
  const { typeCuts } = useParams();

  const renderCurrentCuts = () => {
    switch (typeCuts) {
      case QUESTIONS_CUTS:
      case UNITS_CUTS:
        return <QuestionsCuts />;
      case FACTORS_CUTS:
        return <FactorsCuts />;
      case FOUR_D:
        return <FourD />;
      default:
        return null;
    }
  };

  return renderCurrentCuts();
}

export default CutsDistributor;