import React from 'react';
import styles from './ButtonsPanel.module.sass';
import FilesForDownloadButton from '@/components/Common/BaseTemplate/MainContentContainer/AdminSupersetContainer/ButtonsPanel/FilesForDownloadButtons/FilesForDownloadButton';

function ButtonsPanel() {
  return (
    <div className={styles['buttons-panel-container']}>
      <FilesForDownloadButton />
    </div>
  );
}

export default ButtonsPanel;