import React from 'react';
import styles from './HeaderWidgetsContainer.module.sass';
import ThemeSwitcher from '@/components/Common/BaseTemplate/Header/ThemeSwitcher';

interface HeaderWidgetsContainerProps {
  children?: React.ReactNode
}

function HeaderWidgetsContainer({ children = undefined }: HeaderWidgetsContainerProps) {
  return (
    <div className={styles['widgets-container']}>
      <ThemeSwitcher />
      {!!children && children}
    </div>
  );
}

export default HeaderWidgetsContainer;