import React, { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import ModalConfirm from '@/components/App/Admin/UIElements/ModalConfirm';
import {
  deleteQuestion,
  editingQuestions,
} from '@/store/actions/admin/superset/addingQuestions';
import { addNotification } from '@/store/reducers/notify';
import useNLS from '@/components/App/Admin/hooks/hooks';

function ModalDeleteQuestion({
  deletedQuestionData,
  confirmRemovingQuestionToggleModal,
  confirmRemovingQuestionIsShowingModal,
  questions,
}) {
  const tNLS = useNLS();
  const { id: supersetID } = useParams();
  const dispatch = useDispatch();

  const confirmDeletingQuestion = useCallback(() => {
    const { itemId, index } = deletedQuestionData;
    dispatch(
      deleteQuestion({
        supersetID,
        itemId,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(
          addNotification({
            title: 'Успех!',
            type: 'success',
            message: tNLS('customq.message.item.deleted'),
          }),
        );

        const [, ...questionsWithChangedOrder] = questions.slice(index);

        dispatch(
          editingQuestions({
            supersetID,
            changedQuestions: questionsWithChangedOrder.map((q) => ({
              itemId: q.itemId,
              norder: q.norder - 1,
              isEnabled: q.isEnabled,
            })),
          }),
        );

        confirmRemovingQuestionToggleModal();
      })
      .catch(() => null);
  }, [
    confirmRemovingQuestionToggleModal,
    deletedQuestionData,
    dispatch,
    questions,
    supersetID,
    tNLS,
  ]);

  return (
    <ModalConfirm
      title={tNLS('customq.confirm.deletion')}
      toggleModal={confirmRemovingQuestionToggleModal}
      onConfirm={() => confirmDeletingQuestion()}
      isShowingModal={confirmRemovingQuestionIsShowingModal}
      confirmText={tNLS('customq.modal.delete.button.delete')}
      cancelText={tNLS('customq.modal.delete.button.cancel')}
    />
  );
}

export default ModalDeleteQuestion;

ModalDeleteQuestion.propTypes = {
  deletedQuestionData: PropTypes.shape({
    itemId: PropTypes.string,
    index: PropTypes.number,
  }),
  questions: PropTypes.arrayOf(PropTypes.string).isRequired,
  confirmRemovingQuestionToggleModal: PropTypes.func.isRequired,
  confirmRemovingQuestionIsShowingModal: PropTypes.bool.isRequired,
};

ModalDeleteQuestion.defaultProps = {
  deletedQuestionData: undefined,
};