import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { pagesSlice } from '@/store/reducers/user/pages/pages';
import { protocolsSlice } from '@/store/reducers/user/protocols';
import { sendAnswers } from '@/store/actions/user/protocols';
import {
  AcceptActorAgreementActionArgs, ActorLoginActionArgs,
  ActorProfile, ActorSignInActionArgs,
  GetActorActionArgs,
  GetActorByPersonalLinksActionArgs, LoadNlsActionArgs, SelectProjectActionArgs, SendSnaCurrentItemActionArgs,
  UpdateActorProfileActionArgs, UpdateUnitActionArgs,
} from '@/store/types/user/actor';
import axiosProcessing from '@/axiosConfig';

export const getActor = createAsyncThunk(
  'actor/auth',
  async (data: GetActorActionArgs, { rejectWithValue }) => {
    try {
      const {
        unitId, secret, seed, teamCode,
      } = data;
      let path = `/api/actor/auth/${teamCode}`;
      path = unitId ? `${path}/${unitId}` : path;
      path = secret ? `${path}/${secret}` : path;
      path = seed ? `${path}/${seed}` : path;
      const response = await axiosProcessing.get<ActorProfile>(path);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getActorByPersonalLink = createAsyncThunk(
  'actor/authByPersonalLink',
  async (data: GetActorByPersonalLinksActionArgs, { rejectWithValue }) => {
    try {
      const { personalLink } = data;
      const response = await axiosProcessing.get<ActorProfile>(`/api/actor/auth/${personalLink}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateActorProject = createAsyncThunk(
  'actor/updateActorProject',
  async (data: UpdateActorProfileActionArgs, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.put<ActorProfile>('/api/actor/auth', data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const acceptActorAgreement = createAsyncThunk(
  'actor/acceptActorAgreement',
  async (data: AcceptActorAgreementActionArgs, { rejectWithValue }) => {
    const { agreement } = data;
    try {
      const response = await axiosProcessing.put<ActorProfile>('/api/actor/auth', {
        agreement,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateUnit = createAsyncThunk(
  'actor/updateUnit',
  async (data: UpdateUnitActionArgs, { rejectWithValue }) => {
    const { unitId } = data;

    try {
      await axiosProcessing.put<ActorProfile>('/api/actor/auth', data);
      return unitId;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const actorLogin = createAsyncThunk(
  'actor/login',
  async (data: ActorLoginActionArgs, { rejectWithValue }) => {
    const {
      teamCode,
      projectId,
      code,
      inviteCode,
      supersetId,
      serverId,
      blowFish,
      langId,
    } = data;

    let registerPath = '/api/actor/auth/register';
    let authPath = '/api/actor/auth';

    if (inviteCode !== undefined) {
      registerPath = `${registerPath}/${inviteCode}/${supersetId}/${serverId}/${blowFish}`;
      authPath = `${authPath}/${inviteCode}/${supersetId}/${serverId}/${blowFish}`;
    }
    try {
      const response = !code
        ? await axiosProcessing.post(registerPath, {
          webid: teamCode,
          projectid: projectId,
          langId,
        })
        : await axiosProcessing.post(authPath, {
          code: code.replaceAll(' ', ''),
          webid: teamCode,
          projectid: projectId,
        });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const actorSignIn = createAsyncThunk(
  'actor/signin',
  async (data: ActorSignInActionArgs, { rejectWithValue }) => {
    const { actorCode } = data;

    try {
      const response = await axiosProcessing.post<ActorProfile>('/api/actor/auth', {
        code: parseInt(actorCode, 10),
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const selectProject = createAsyncThunk(
  'actor/selectProject',
  async (data: SelectProjectActionArgs) => {
    const { project, teamCode } = data;
    return { ...project, teamCode };
  },
);

export const loadNLS = createAsyncThunk(
  'actor/loadNLS',
  async (data: LoadNlsActionArgs, { rejectWithValue }) => {
    try {
      const { teamCode, langId } = data;
      const response = await axiosProcessing.get<NlsType>(`/api/actor/getTexts/${teamCode}/${langId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const logout = createAsyncThunk(
  'actor/logout',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      await dispatch(sendAnswers());
      const response = await axiosProcessing.delete('/api/actor/auth');
      dispatch(pagesSlice.actions.clearPagesState());
      dispatch(protocolsSlice.actions.clearProtocols());
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const finish = createAsyncThunk(
  'actor/finish',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      await dispatch(sendAnswers());
      const response = await axiosProcessing.delete('/api/actor/finish');
      dispatch(pagesSlice.actions.finishSurvey());
      dispatch(protocolsSlice.actions.clearProtocols());
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const selectProjectWithNls = createAsyncThunk(
  'actor/selectProjectWithNls',
  async (data: SelectProjectActionArgs, { dispatch }) => {
    await dispatch(selectProject(data));
    const { langId } = data.project;
    dispatch(loadNLS({ teamCode: data.teamCode, langId }));
  },
);

export const sendSnaCurrentItem = createAsyncThunk(
  'actor/sendSnaCurrentItem',
  async (data: SendSnaCurrentItemActionArgs, { rejectWithValue }) => {
    try {
      await axiosProcessing.put<ActorProfile>('/api/actor/auth', {
        snaCurrentItem: data.snaCurrentItem,
      });
      return data.snaCurrentItem;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const rollbackActor = createAction('actor/rollbackActor');