import React from 'react';
import AdminHeader from '@/components/Common/BaseTemplate/Header/AdminHeader';
import UserHeader from '@/components/Common/BaseTemplate/Header/UserHeader';

interface Props {
  isAdmin: boolean
}

function Header({ isAdmin = false }: Props) {
  return (isAdmin ? <AdminHeader /> : <UserHeader />);
}

export default Header;