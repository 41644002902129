import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styles from './CreateWebinarForm.module.sass';
import { CreateWebinarFieldsType } from '../types/webinarPageTypes';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import BUnitsMenuBtn from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitsBlueBtn/BUnitsMenuBtn';
import CreateWebinarFormSections from './CreateWebinarFormFields/CreateWebinarFormFields';
import { editWebinar } from '@/store/actions/admin/webinar';
import { selectWebinarsList } from '@/store/selectors/admin/webinars';

export default function CreateWebinarForm() {
  const formProps = useForm<CreateWebinarFieldsType>();
  const { webinarsList } = useAdminSelector(selectWebinarsList);
  const { webinarId } = useParams();
  const { handleSubmit } = formProps;

  useEffect(() => {
    if (webinarId && webinarsList[webinarId]) {
      formProps.reset(webinarsList[webinarId]);
    }
    // eslint-disable-next-line
  }, []);

  const dispatch = useAdminDispatch();

  const onSubmit = (data: CreateWebinarFieldsType) => {
    dispatch(editWebinar(data));
  };

  return (
    <form
      autoComplete="off"
      className={styles.form}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <CreateWebinarFormSections {...formProps} />
      <div className={styles['buttons-container']}>
        <BUnitsMenuBtn
          type="confirm"
          btnActionType="submit"
        >
          Сохранить
        </BUnitsMenuBtn>
        <BUnitsMenuBtn
          type="reject"
          additionalClassNames={styles.reject}
        >
          Сбросить
        </BUnitsMenuBtn>
      </div>
    </form>
  );
}