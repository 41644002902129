import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import FilesSection from '@/components/Common/BaseTemplate/MainContentContainer/AdminSupersetContainer/ButtonsPanel/SupersetMenuModal/FilesForDownload/FilesSection/FilesSection';
import {
  selectSuperset,
  selectTemplatesForDownload,
} from '@/store/selectors/admin/superset/supersets';
import useFileDownload from '@/components/App/Admin/hooks/downloadFile';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';

function TemplatesSection({ withTitle, isLastFile }) {
  const { availableTemplates, data360File } = useSelector(
    selectTemplatesForDownload,
  );
  const downloadFile = useFileDownload();
  const { supersetId } = useSelector(selectSuperset);
  const checkPermission = useCheckPermission();

  const downloadTemplate = useCallback(
    (langId) => {
      const args = {
        supersetId,
        fileType: 'data360',
      };
      if (langId) {
        args.langId = langId;
        args.fileType = 'project';
      }

      downloadFile({
        args,
      });
    },
    [downloadFile, supersetId],
  );

  const content = useMemo(
    () => availableTemplates.map((template) => ({
      ...template,
      ...{
        fileType: 'template',
        downloadFile: () => downloadTemplate(template.fileLang),
        isAvailable: checkPermission('load_templates'),
        fileDate: template.modifyDate,
        version: template.version,
      },
    })),
    [availableTemplates, checkPermission, downloadTemplate],
  );

  const data360FileForDownload = useMemo(() => {
    if (!data360File) return undefined;
    return {
      ...data360File,
      fileName: data360File.filename,
      fileType: 'template',
      isAvailable: checkPermission('load_templates'),
      fileDate: data360File.modifyDate,
      downloadFile: () => downloadTemplate(),
    };
  }, [checkPermission, data360File, downloadTemplate]);

  return (
    <FilesSection title={withTitle ? 'Шаблоны' : ''}>
      {isLastFile && content.length > 0
        ? [content[0], data360FileForDownload]
        : [...content, data360FileForDownload]}
    </FilesSection>
  );
}

TemplatesSection.propTypes = {
  withTitle: PropTypes.bool,
  isLastFile: PropTypes.bool,
};

TemplatesSection.defaultProps = {
  withTitle: true,
  isLastFile: true,
};

export default TemplatesSection;