// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".btn-switch-sna-direction--mcB-E{border-radius:4px;padding:7px 0;transition:background-color .3s ease 0s;width:2.5rem}.btn-switch-sna-direction--mcB-E.outgoing---8oxi{background-color:#2279fc}.btn-switch-sna-direction--mcB-E.outgoing---8oxi:hover{background-color:#005de9}.btn-switch-sna-direction--mcB-E.outgoing---8oxi:active{background-color:#4891ff}.btn-switch-sna-direction--mcB-E.incoming--gchZp{background-color:#27ae60}.btn-switch-sna-direction--mcB-E.incoming--gchZp:hover{background-color:#178546}.btn-switch-sna-direction--mcB-E.incoming--gchZp:active{background-color:#3dcd7a}.btn-switch-sna-direction--mcB-E .icon-direction--3UhbX:before{font-size:1rem;color:#fff}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/ChartSection/BtnSNADirection/BtnSNADirection.module.sass"],"names":[],"mappings":"AAEA,iCACE,iBAAA,CACA,aAAA,CACA,uCAAA,CACA,YAAA,CACA,iDACE,wBAAA,CACA,uDACE,wBAAA,CACF,wDACE,wBAAA,CACJ,iDACE,wBAAA,CACA,uDACE,wBAAA,CACF,wDACE,wBAAA,CAEF,+DACE,cAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn-switch-sna-direction": "btn-switch-sna-direction--mcB-E",
	"outgoing": "outgoing---8oxi",
	"incoming": "incoming--gchZp",
	"icon-direction": "icon-direction--3UhbX"
};
export default ___CSS_LOADER_EXPORT___;
