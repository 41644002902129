import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styles from './CreatePassword.module.sass';
import Input from '@/components/Common/form/Input';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import { animateScrollTo } from '@/helpers/helpers';
import { adminCreateNewPassword } from '@/store/actions/admin';

function CreatePassword() {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    clearErrors,
    setFocus,
    watch,
  } = useForm({ mode: 'onChange' });
  const firstPasswordValue = watch('oldPassword');
  const repeatPasswordValue = watch('password');
  const isPasswordsDontMatch = errors?.password?.type === 'validate'
    || errors?.oldPassword?.type === 'validate';

  const refSection = useRef();

  useEffect(() => {
    animateScrollTo(refSection, 1000);
    setFocus('oldPassword');
  }, [refSection, setFocus]);

  const onCreateNewPassword = (dataForm) => {
    dataForm.oldPassword = '';
    dispatch(adminCreateNewPassword(dataForm));
  };

  return (
    <section
      className={`admin-profile-section ${styles['create-password-section']}`}
      ref={refSection}
    >
      <h3
        className={`title-section ${styles['title-create-password-section']}`}
      >
        <span className={`icon-warning ${styles['icon-warning-title']}`}>
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
        </span>
        You need to create a password
      </h3>

      <form
        onSubmit={handleSubmit(onCreateNewPassword)}
        className={styles['form-create-password']}
      >
        <Input
          register={register}
          registerOptions={{
            required: true,
            validate: (value) => {
              if (!repeatPasswordValue) return true;

              if (value === repeatPasswordValue) {
                clearErrors('password');
                return true;
              }
              return 'The passwords do not match';
            },
          }}
          id="admin-first-password"
          name="oldPassword"
          label="Enter a new password:"
          type="password"
          externalClasses={{
            label: styles['label-field'],
            input: isPasswordsDontMatch
              ? styles['password-dont-math']
              : undefined,
          }}
        />

        <Input
          register={register}
          id="admin-repeat-password"
          registerOptions={{
            required: true,
            validate: (value) => {
              if (!firstPasswordValue) return true;

              if (value === firstPasswordValue) {
                clearErrors('oldPassword');
                return true;
              }
              return 'The passwords do not match';
            },
          }}
          name="password"
          label="Repeat the password:"
          type="password"
          externalClasses={{
            label: styles['label-field'],
            input: isPasswordsDontMatch
              ? styles['password-dont-math']
              : undefined,
          }}
        />

        {isPasswordsDontMatch ? (
          <span className={styles['error-valid-msg']}>
            {errors?.password?.message || errors?.oldPassword?.message}
          </span>
        ) : null}

        <CustomButton
          styleTypeBtn="btn-blue btn-large"
          typeBtnSubmit
          disabled={!isValid}
        >
          Create Password
        </CustomButton>
      </form>
    </section>
  );
}

export default CreatePassword;