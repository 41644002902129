import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import CreateWebinarPage from '@/components/App/Webinar/CreateWebinarPage';
import { getWebinarsList } from '@/store/actions/admin/webinar';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import { selectWebinarsList } from '@/store/selectors/admin/webinars';
import Preloader from '../../SupersetSettings/Preloader';
import WebinarsList from './WebinarsList';

export default function Webinars() {
  const dispatch = useAdminDispatch();
  const { loadStatus } = useAdminSelector(selectWebinarsList);

  useEffect(() => {
    dispatch(getWebinarsList());
  }, [dispatch]);

  if (loadStatus !== 'received') return <Preloader />;

  return (
    <Routes>
      <Route path="" element={<WebinarsList />} />
      <Route path="/:webinarId" element={<CreateWebinarPage />} />
      <Route path="/new" element={<CreateWebinarPage />} />
    </Routes>
  );
}