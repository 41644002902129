import React from 'react';
import styles from './WebinarListMetainfo.module.sass';

interface WebinarListMetaInfoProps {
  webinar: WebinarType;
}

export default function WebinarListMetaInfo({ webinar }: WebinarListMetaInfoProps) {
  return (
    <div className={styles['webinar-meta']}>
      <span>{`Webinar Key: ${webinar.webinarKey}`}</span>
    </div>
  );
}