import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from '@/components/Common/NotificationsBar/NotificationContainer/NotificationContainer.module.sass';

interface NotificationTitleProps {
  supersetId?: string;
  supersetName?: string;
}
function NotificationTitle({ supersetId, supersetName }: NotificationTitleProps) {
  const navigate = useNavigate();

  if (!supersetId || !supersetName) return null;

  return (
    <button
      className={`${styles['superset-link']} btn-without-style`}
      type="button"
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/admin/project/${supersetId}`);
      }}
    >
      {supersetName}
    </button>
  );
}

NotificationTitle.propTypes = {
  supersetId: PropTypes.string,
  supersetName: PropTypes.string,
};

export default NotificationTitle;