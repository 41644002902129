import React from "react";
import styles from "./SuperItemColumn.module.sass"

export interface SuperItemColumnProps {
    children: React.ReactNode;
    className?: string;
}

const SuperItemColumn: React.FC<SuperItemColumnProps> = ({ children, className }) => {
    return (
        <div className={`${styles['super-item-column']} ${className}`}>
            {children}
        </div>
    );
}


export default SuperItemColumn;