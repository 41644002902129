import { createContext } from 'react';
import { UnitId } from '@/store/types/user/units';
import { TreeUnit } from '@/store/types/admin/superset/unitsTree';

export interface BUnitContextProps {
  id?: UnitId;
  unit?: TreeUnit;
  index?: number
}

const BUnitContext = createContext<BUnitContextProps>({});

export default BUnitContext;