import React from 'react';
import PropTypes from 'prop-types';
import useSetCurrentLang from '@/components/App/Admin/InitSuperset/useSetCurrentLang';
import Preloader from '@/components/App/Admin/SupersetSettings/Preloader';

function InitSuperset({ children: pageContent }) {
  const currentLangID = useSetCurrentLang();

  if (!currentLangID) {
    return <Preloader />;
  }

  return pageContent;
}

InitSuperset.propTypes = {
  children: PropTypes.element.isRequired,
};

export default InitSuperset;