import React from 'react';
import { useSelector } from 'react-redux';
import styles from './FourD.module.sass';
import { selectIsThereDataForDiagram } from '@/store/selectors/admin/superset/process/cuts/fourD';

import Diagram from '@/components/App/Admin/Results/Cuts/FourD/Diagram';
import FourDParamsForm from '@/components/App/Admin/Results/Cuts/FourD/FourDParamsForm';

function FourD() {
  const isThereDataForDiagram = useSelector(selectIsThereDataForDiagram);

  return (
    <div className={styles['wrap-fourD']}>
      <FourDParamsForm />

      {isThereDataForDiagram && <Diagram />}

    </div>
  );
}

export default FourD;