import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './SectionUploadedFiles.module.sass';
import TemplatesSection from '@/components/Common/BaseTemplate/MainContentContainer/AdminSupersetContainer/ButtonsPanel/SupersetMenuModal/FilesForDownload/TemplatesSection/TemplatesSection';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { selectTemplatesForDownload } from '@/store/selectors/admin/superset/supersets';

function SectionUploadedFiles() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isAllFiles, toggleAllFiles] = useState(false);
  const tNLS = useNLS();
  const { availableTemplates } = useSelector(selectTemplatesForDownload);

  if (!availableTemplates?.length) return null;

  return (
    <section
      className={`${styles['uploaded-templates-section']} settings-section`}
    >
      <h3 className={`${styles['upload-section-title']} title-section`}>
        {tNLS('templates.uploaded')}
      </h3>
      <TemplatesSection isLastFile={!isAllFiles} withTitle={false} />
    </section>
  );
}

export default SectionUploadedFiles;