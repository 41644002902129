import React, {
  useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import styles from './Select.module.sass';
import './themeVars.sass';
import ArrowIcon from '@/components/Common/Elements/cssIcons/ArrowIcon';
import SelectionList from '@/components/Common/SelectionList';
import { getClearClassNames } from '@/helpers/helpers';

// todo вынести функционал закрытия окна по клику на страницу в отдельный hook
function Select({
  id,
  name,
  options,
  value,
  multiple,
  externalClasses,
  customOption,
  onChange,
  disabled,
}) {
  const openSelectBtnRef = useRef(null);
  const selectRef = useRef(null);
  const optionListRef = useRef(null);
  const [optionsState, setOptions] = useState([]);
  const [isActiveSelect, toggleActive] = useState(false);

  const {
    wrapSelect = '',
    customSelectTrigger = '',
    item = '',
    btnItem = '',
  } = externalClasses;

  useEffect(() => {
    setOptions([...options.map((option, i) => ({
      id: `select-option-${i}`,
      ...option,
    }))]);
  }, [options]);

  useEffect(() => {
    if (selectRef.current) {
      if (!value) {
        selectRef.current.value = null;
        return;
      }

      if (!multiple && !Array.isArray(value)) {
        selectRef.current.value = value.value;
      }

      if (multiple && Array.isArray(value)) {
        [...selectRef.current.options].forEach((option) => {
          const { value: optionVal, label: optionLabel } = option;

          option.selected = value.some(({ value: valueFromValues, label }) => (
            String(valueFromValues) === String(optionVal) && label === optionLabel
          ));
        });
      }

      selectRef.current.dispatchEvent(new Event('change', { bubbles: true }));
    }
  }, [value, multiple]);

  useEffect(() => {
    const watchClickOutside = (e) => {
      if (optionListRef.current
        && !optionListRef.current.contains(e.target)
        && !openSelectBtnRef.current.contains(e.target)) {
        toggleActive(false);
      }
    };

    if (isActiveSelect) {
      window.addEventListener('click', watchClickOutside);
    }

    return () => {
      window.removeEventListener('click', watchClickOutside);
    };
  }, [isActiveSelect]);

  const getSelectPlaceholder = (setValue) => {
    if (!multiple) {
      if (!setValue) {
        return optionsState[0].label;
      }

      if (setValue) {
        return setValue.label;
      }
    }

    if (multiple) {
      if (!setValue || setValue.length === 0) {
        return optionsState[0].label;
      }

      if (Array.isArray(setValue)) {
        return setValue.map(({ label }) => label).join(', ');
      }
    }

    throw new Error(`failed to form placeholder for Select with value: ${setValue}`);
  };

  return (
    <div className={getClearClassNames([
      styles['wrap-custom-select'],
      wrapSelect,
    ])}
    >
      <span className={styles['select-label']} id="selectLabel" />
      <div className={styles['wrap-selects']}>
        <select
          aria-labelledby="selectLabel"
          id={id}
          disabled={disabled}
          multiple={multiple}
          className={`${styles['native-select']}`}
          name={name}
          onChange={(e) => {
            if (e.isTrusted) {
              const selectedValues = [...e.target.options]
                .filter((option) => option.selected)
                .map((option) => ({ value: option.value, label: option.label }));

              onChange(selectedValues);
            }
          }}
          ref={selectRef}
        >
          {optionsState.map(({
            id: optionID, label, value: optionValue, placeholder,
          }) => (
            <option
              key={optionID}
              value={optionValue}
              hidden={!!placeholder}
            >
              {label}
            </option>
          ))}
        </select>

        <div
          className={getClearClassNames([
            styles['custom-select'],
            isActiveSelect ? styles.active : '',
          ])}
          ref={openSelectBtnRef}
          aria-hidden={!isActiveSelect}
        >
          <div
            tabIndex={0}
            role="button"
            className={getClearClassNames([
              styles['custom-select-trigger'],
              customSelectTrigger,
              disabled ? styles.disabled : '',
            ])}
            onKeyDown={() => {}}
            onClick={() => {
              if (!disabled) {
                toggleActive(!isActiveSelect);
              }
            }}
          >
            {optionsState.length && getSelectPlaceholder(value)}
          </div>

          {isActiveSelect && (
          <div
            className={styles['custom-select-options']}
            ref={optionListRef}
          >
            <SelectionList
              types="select-list"
              value={value}
              items={optionsState.filter(({ placeholder }) => !placeholder)}
              multiple={multiple}
              customItem={customOption}
              onClick={(selectedOptions) => {
                onChange(selectedOptions);

                if (!multiple) {
                  toggleActive(false);
                }
              }}
              externalClassNames={{
                wrapSelectionList: styles['list-options'],
                item,
                btnItem,
              }}
            />
          </div>
          )}
        </div>
        <span className={styles['select-arrow-icon']}>
          <ArrowIcon direction={isActiveSelect ? 'top' : 'bottom'} />
        </span>
      </div>
    </div>
  );
}

Select.defaultProps = {
  value: null,
  multiple: false,
  onChange: null,
  customOption: null,
  externalClasses: {},
  disabled: false,
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selected: PropTypes.bool,
    placeholder: PropTypes.bool,
  })).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
    PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })),
  ]),
  multiple: PropTypes.bool,
  customOption: PropTypes.func,
  externalClasses: PropTypes.shape({
    wrapSelect: PropTypes.string,
    customSelectTrigger: PropTypes.string,
    item: PropTypes.string,
    btnItem: PropTypes.string,
  }),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Select;