// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".transfer-modal--RR0nu{max-width:80vh}.transfer-modal--RR0nu .transfer-units-modal--MIFNd{display:flex;flex-direction:column;gap:0.5rem;height:fit-content;max-height:70vh}.transfer-modal--RR0nu .transfer-units-modal--MIFNd .buttons--oiANY{align-self:end}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitAddSpecsModal/BUnitAddSpecsModal.module.sass"],"names":[],"mappings":"AAEA,uBACE,cAAA,CACA,oDACE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,kBAAA,CACA,eAAA,CACA,oEACE,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transfer-modal": "transfer-modal--RR0nu",
	"transfer-units-modal": "transfer-units-modal--MIFNd",
	"buttons": "buttons--oiANY"
};
export default ___CSS_LOADER_EXPORT___;
