// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".buttons-panel-container--ZQ7rU{display:flex;flex-direction:column;gap:0.5rem;padding:0 0 0 0.75rem;position:absolute;left:100%;top:0}.buttons-panel-container--ZQ7rU .panel-button--2dGCl{font-size:2rem;color:#000}.buttons-panel-container--ZQ7rU .panel-button--2dGCl .button-icon---ycKY{color:#838999}.buttons-panel-container--ZQ7rU .panel-button--2dGCl .button-icon---ycKY:hover{color:var(--admin-color-font-hover-interactive-elements)}", "",{"version":3,"sources":["webpack://./src/components/Common/BaseTemplate/MainContentContainer/AdminSupersetContainer/ButtonsPanel/ButtonsPanel.module.sass"],"names":[],"mappings":"AAEA,gCACE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,qBAAA,CACA,iBAAA,CACA,SAAA,CACA,KAAA,CACA,qDACE,cAAA,CACA,UAAA,CACA,yEACE,aAAA,CACA,+EACE,wDAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons-panel-container": "buttons-panel-container--ZQ7rU",
	"panel-button": "panel-button--2dGCl",
	"button-icon": "button-icon---ycKY"
};
export default ___CSS_LOADER_EXPORT___;
