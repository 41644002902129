import React, { useContext, useState } from 'react';
// import styles from './BUnitAddHowTo.module.sass';
import BUnitAddHowToInput
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitAddHowTo/BUnitAddHowToInput/BUnitAddHowToInput';
import RoleSelect
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitHowToElement/RoleSelect/RoleSelect';
import BUnitContext, { BUnitContextProps } from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import AddNewHowToButton
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitAddHowTo/AddNewHowToButton/AddNewHowToButton';
import {
  AddRolesFunc,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/rolesListHooks';
import {
  HowToType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitCardHowToSection';

interface BUnitAddHowToProps {
  addNewRole: AddRolesFunc;
  type: HowToType;
}

function BUnitAddHowTo({ addNewRole, type }: BUnitAddHowToProps) {
  const [newUnitRole, setNewUnitRole] = useState<string>('');
  const [newUnitInRolesId, setNewUnitInRolesId] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const { unit } = useContext(BUnitContext) as Required<BUnitContextProps>;

  const handleAddRole = () => {
    addNewRole(newUnitInRolesId, newUnitRole);
    setNewUnitRole('');
    setInputValue('');
    setNewUnitInRolesId('');
  };

  return (
    <>
      <BUnitAddHowToInput
        inputValue={inputValue}
        setInputValue={setInputValue}
        selectUnit={setNewUnitInRolesId}
        type={type}
      />
      <RoleSelect
        currentRole={newUnitRole}
        rolesList={unit.rolesList}
        changeRole={setNewUnitRole}
        roleUnitId={newUnitInRolesId}
      />
      <AddNewHowToButton
        isDisabled={!(newUnitInRolesId && newUnitRole)}
        addNewRole={handleAddRole}
      />
    </>
  );
}

export default BUnitAddHowTo;