import { selectCurrentLangID } from '@/store/selectors/admin/superset/projects';
import {
  FACTORS_CUTS,
  QUESTIONS_CUTS,
  UNITS_CUTS,
} from '@/components/App/Admin/Results/Cuts/constants';
import {
  selectFirstItemQuestionsCuts,
  selectQuestionsCuts,
  selectQuestionsItems,
} from '@/store/selectors/admin/superset/process/cuts/questionsCuts';
import {
  selectFirstItemUnitsCuts,
  selectUnitsCuts,
  selectUnitsItems,
} from '@/store/selectors/admin/superset/process/cuts/unitsCuts';
import { calcOptionPercents } from '@/components/App/Admin/Results/Cuts/helpers';

const getStoreContext = (state) => state.admin.superset.process.cuts.cuts;

// loading info selectors

export const selectStatusLoadCutsData = (state) => getStoreContext(state).hasCutsReceived;

export const selectStatusLoadCriteria = (state) => getStoreContext(state).hasCriteriaReceived;

// criteria selectors

export const selectAreThereCriteria = (state) => !!getStoreContext(state).criteria.length;

export const selectCriteria = (state) => {
  const { criteria } = getStoreContext(state);
  const currentLangID = selectCurrentLangID(state);

  if (criteria.length === 0) {
    return [];
  }

  return criteria.map((crit) => {
    const { critByLang, ...restFieds } = crit;

    const content = critByLang[currentLangID];

    return {
      ...content,
      ...restFieds,
    };
  });
};

export const selectSelectedCriteria = (state) => getStoreContext(state).selectedCriteria;

export const selectSelectedCriteriaInFormatToBeSent = (state) => {
  const selectedCriteria = selectSelectedCriteria(state);

  const convertedCriteriaFormat = [];

  if (Object.keys(selectedCriteria).length === 0) {
    return convertedCriteriaFormat;
  }

  Object.keys(selectedCriteria).forEach((criteriaID) => {
    convertedCriteriaFormat.push({
      criterioId: criteriaID,
      casesCheckBox: selectedCriteria[criteriaID],
    });
  });

  return convertedCriteriaFormat;
};

export const selectAmountsActorsOptions = (state) => getStoreContext(state).amountsActorsOptions;

// cuts: common selectors for questionCuts and unitsCuts;

export const selectCutsByItemIDForActiveTypeCuts = (
  state,
  itemID,
  typeCuts,
) => {
  const questionsCuts = selectQuestionsCuts(state);
  const unitsCuts = selectUnitsCuts(state);

  if (typeCuts === QUESTIONS_CUTS) {
    return questionsCuts[itemID];
  }

  if (typeCuts === UNITS_CUTS) {
    return unitsCuts[itemID];
  }

  return null;
};

// items: common selectors for questionCuts and unitsCuts;

export const selectItemsByTypeCuts = (state, typeCuts) => {
  const questionsItems = selectQuestionsItems(state);
  const unitsItems = selectUnitsItems(state);

  if (typeCuts === QUESTIONS_CUTS) {
    return questionsItems;
  }

  if (typeCuts === UNITS_CUTS) {
    return unitsItems;
  }

  // TODO возвращать items
  if (typeCuts === FACTORS_CUTS) {
    return null;
  }

  return null;
};

export const selectItemByIDByTypeCuts = (state, itemID, typeCuts) => {
  const items = selectItemsByTypeCuts(state, typeCuts);
  const indxItem = items.findIndex((item) => item.id === itemID);

  return { ...items[indxItem], number: indxItem + 1 };
};

export const selectFirstItemByTypeCuts = (state, typeCuts) => {
  const firstItemQuestionsCuts = selectFirstItemQuestionsCuts(state);
  const firstItemUnitsCuts = selectFirstItemUnitsCuts(state);

  switch (typeCuts) {
    case QUESTIONS_CUTS:
      return firstItemQuestionsCuts;
    case UNITS_CUTS:
      return firstItemUnitsCuts;
    case FACTORS_CUTS:
      // first FS on the list
      return 0;
    default:
      return null;
  }
};

export const selectNeedToCalcStatisticsValues = (state, itemID, typeCuts) => {
  const { likertScale } = selectItemByIDByTypeCuts(state, itemID, typeCuts);

  return likertScale;
};

export const selectIsNPSItem = (state, itemID, typeCuts) => {
  const { answerStat, answersCodes } = selectCutsByItemIDForActiveTypeCuts(
    state,
    itemID,
    typeCuts,
  );

  const containsNumbersOneToTen = (ansCodes) => {
    const numbersArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    for (let i = 0; i < numbersArray.length; i += 1) {
      if (ansCodes[i] !== numbersArray[i]) {
        return false;
      }
    }

    return true;
  };

  // NPS question has 10 options
  if (answerStat.length === 10 && containsNumbersOneToTen(answersCodes)) {
    return true;
  }

  return false;
};

// TODO написать комменты
export const selectCalculateNPSScore = (state, itemID, typeCuts) => {
  const { answerStat, answerStatOther } = selectCutsByItemIDForActiveTypeCuts(
    state,
    itemID,
    typeCuts,
  );

  const sumInRange = (numbers, range) => {
    const [start, end] = range;
    let sum = 0;
    for (let i = start; i <= end; i += 1) {
      sum += numbers[i];
    }
    return sum;
  };

  const low = [0, 5];
  const high = [8, 9];

  const areThereDataForUnselected = !answerStatOther.every(
    (stat) => stat === 0,
  );

  const optionPercents = calcOptionPercents(answerStat);
  const sumOfPercentageForLow = sumInRange(optionPercents, low);
  const sumOfPercentageForHigh = sumInRange(optionPercents, high);

  let npsScoreForUnselected = null;

  if (areThereDataForUnselected) {
    const optionPercentsForUnselected = calcOptionPercents(answerStatOther);
    const sumOfPercentageForLowForUnselected = sumInRange(
      optionPercentsForUnselected,
      low,
    );
    const sumOfPercentageForHighForUnselected = sumInRange(
      optionPercentsForUnselected,
      high,
    );

    npsScoreForUnselected = (
      sumOfPercentageForHighForUnselected - sumOfPercentageForLowForUnselected
    ).toFixed(2);
  }

  return {
    forSelected: (sumOfPercentageForHigh - sumOfPercentageForLow).toFixed(2),
    forUnselected: npsScoreForUnselected,
  };
};