import React, { Dispatch, SetStateAction } from 'react';
import BUnitSpec, {
  SpecType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitSpec/BUnitSpec';
import CreateNewSpecBtn
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/CreateNewSpecBtn/CreateNewSpecBtn';

interface ModalSpecsListProps {
  specList: string[];
  isNewSpecs: boolean;
  deletedSpecs: string[];
  setDeletedSpecs: Dispatch<SetStateAction<string[]>>;
  setNewSpecs: Dispatch<SetStateAction<string[]>>;
}

function ModalSpecsList({
  specList,
  isNewSpecs = false,
  deletedSpecs,
  setDeletedSpecs,
  setNewSpecs,
}: ModalSpecsListProps) {
  const handleClickRemoveSpec = (value: string) => {
    if (isNewSpecs) {
      setNewSpecs((prevState) => prevState.filter((spec) => spec !== value));
      return;
    }
    if (deletedSpecs.includes(value)) {
      setDeletedSpecs((prevState) => prevState.filter((spec) => spec !== value));
      return;
    }
    setDeletedSpecs((prevState) => [...prevState, value]);
  };
  const handleCreateSpec = () => {
    setNewSpecs((prevState) => [...prevState, '']);
  };

  const getSpecType = (value: string) => {
    if (isNewSpecs) return SpecType.New;
    if (deletedSpecs.includes(value)) return SpecType.Deleted;
    return SpecType.Current;
  };

  const updateSpec = (value: string, index: number) => {
    const newSpList = [...specList];
    newSpList[index] = value;
    setNewSpecs(newSpList);
  };

  return (
    <>
      {specList.map((sp, index) => (
        <BUnitSpec
          type={getSpecType(sp)}
          key={`modal-${sp}-${isNewSpecs && 'new'}`}
          spec={specList}
          index={index}
          removeSpec={() => {
            handleClickRemoveSpec(sp);
          }}
          updateSpec={updateSpec}
          inModal
          isDisable={!isNewSpecs}
        />
      ))}
      {isNewSpecs && <CreateNewSpecBtn handleCreateSpec={handleCreateSpec} />}
    </>
  );
}

export default ModalSpecsList;