import React from 'react';
import styles from './Scales.module.sass';

function Scales() {
  return (
    <div className={`${styles['wrap-scales']}`}>
      <div className={`${styles.scale} ${styles['scale-1']}`}>
        <span className={styles['scale-label']}>0%</span>
      </div>
      <div className={`${styles.scale} ${styles['scale-2']}`}>
        <span className={styles['scale-label']}>15%</span>
      </div>
      <div className={`${styles.scale} ${styles['scale-3']}`}>
        <span className={styles['scale-label']}>30%</span>
      </div>
      <div className={`${styles.scale} ${styles['scale-4']}`}>
        <span className={styles['scale-label']}>50%</span>
      </div>
      <div className={`${styles.scale} ${styles['scale-5']}`}>
        <span className={styles['scale-label']}>70%</span>
      </div>
      <div className={`${styles.scale} ${styles['scale-6']}`}>
        <span className={styles['scale-label']}>85%</span>
      </div>
      <div className={`${styles.scale} ${styles['scale-7']}`}>
        <span className={styles['scale-label']}>100%</span>
      </div>
    </div>
  );
}

export default Scales;