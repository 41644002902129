import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import styles from './ModalSelectingFields.module.sass';
import AdminCheckbox from '@/components/App/Admin/UIElements/form/AdminCheckbox';
import { setSupersetFields } from '@/store/actions/admin';
import { selectAllFields } from '@/store/selectors/admin/superset/supersets';

// todo делать submit формы только тогда когда закрываем окно
function ModalSelectingFields({ supersetID, selectedFields }) {
  const dispatch = useDispatch();
  const fields = useSelector(selectAllFields);
  const { register, handleSubmit } = useForm();

  const onSelectFields = (formField) => {
    dispatch(
      setSupersetFields({
        supersetId: supersetID,
        fields: formField.fields.map(Number),
      }),
    );
  };

  return (
    <div className={styles['modal-content-list-fields']}>
      <form
        className={styles['form-fields']}
        onChange={() => {
          handleSubmit(onSelectFields)();
        }}
      >
        {Object.keys(fields).map((fieldID) => {
          const { name } = fields[fieldID];

          return (
            <div key={fieldID} className={styles['item-field']}>
              <AdminCheckbox
                label={name}
                id={`field-${fieldID}`}
                name="fields"
                value={fieldID}
                register={register}
                defaultChecked={selectedFields.includes(Number(fieldID))}
              />
            </div>
          );
        })}
      </form>
    </div>
  );
}

ModalSelectingFields.defaultProps = {
  selectedFields: [],
};

ModalSelectingFields.propTypes = {
  supersetID: PropTypes.string.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.number),
};

export default ModalSelectingFields;