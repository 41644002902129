// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".admin-input-file--SBGIj input[type=file]{-webkit-appearance:none;appearance:none}.admin-input-file--SBGIj input[type=file]:focus+label{outline:0.125rem solid var(--admin-outline-color)}.admin-input-file--SBGIj .label--Ig2nl{text-align:center;display:flex;flex-direction:column;align-items:center;justify-content:center;gap:0.625rem;font-size:0.875rem;color:var(--admin-btn-color);background:var(--admin-btn-bg);border-radius:1.4285714286em;cursor:pointer;max-width:35.7142857143em;min-width:35.7142857143em;height:7.1428571429em;transition:background-color .3s ease 0s}.admin-input-file--SBGIj .label--Ig2nl .input-icon--uHgff:before{font-size:1.875rem}.admin-input-file--SBGIj .label--Ig2nl:hover{background:var(--admin-btn-hover);color:var(--admin-btn-hover-color)}.admin-input-file--SBGIj .label--Ig2nl:active{background:var(--admin-btn-bg);color:var(--admin-btn-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/UIElements/form/AdminInputFile/AdminInputFile.module.sass"],"names":[],"mappings":"AAGE,0CACE,uBAAA,CAAA,eAAA,CACA,sDACE,iDAAA,CACJ,uCACE,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CACA,kBAAA,CACA,4BAAA,CACA,8BAAA,CACA,4BAAA,CACA,cAAA,CACA,yBAAA,CACA,yBAAA,CACA,qBAAA,CACA,uCAAA,CAEE,iEACE,kBAAA,CACJ,6CACE,iCAAA,CACA,kCAAA,CACF,8CACE,8BAAA,CACA,4BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin-input-file": "admin-input-file--SBGIj",
	"label": "label--Ig2nl",
	"input-icon": "input-icon--uHgff"
};
export default ___CSS_LOADER_EXPORT___;
