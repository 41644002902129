import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styles from './CreateBUnitForm.module.sass';
import BUnitCardInput, {
  CardFieldType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitCardSection/BUnitCardInput/BUnitCardInput';
import BUnitCardFooterButtons
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFooter/BUnitCardFooterButtons';
import BUnitType from '@/components/App/Admin/BUnitsSection/CreateBUnitSection/CreateBUnitModal/BUnitType/BUnitType';
import { useAdminDispatch } from '@/store/hooks/adminRedux';
import { createBUnit } from '@/store/actions/admin/superset/unitsTree';

interface CreateUnitFields {
  isDepartment?: boolean;
  name: string;
  email?: string;
  jobTitle?: string;
}

interface CreateBUnitFormProps {
  closeModal: () => void
}

function CreateBUnitForm({ closeModal }: CreateBUnitFormProps) {
  const [isActor, toggleActor] = useState(true);
  const dispatch = useAdminDispatch();
  // const {  hasUnitForms } = useAdminSelector(selectSuperset);
  // const tNLS = useNLS();
  const {
    register, reset, handleSubmit,
  } = useForm<CreateUnitFields>();

  // const isUnitLocal = () => isActor;
  // if (!newUnitFields.isDepartment === undefined) return true;
  // return newUnitFields.isDepartment;P
  // ;

  const createUnit = (newUnitFields: CreateUnitFields) => {
    const unitData = isActor
      ? { ...newUnitFields, isActor }
      : { name: newUnitFields.name, isActor };
    dispatch(
      createBUnit({
        unitData,
        isLocal: false,
      }),
    )
      .then(() => {
        closeModal();
        reset();
      }, () => {
        reset();
      });
  };
  const onHandleSubmit = (data: CreateUnitFields) => {
    createUnit(data);
  };

  return (
    <>
      <BUnitType
        isActor={isActor}
        toggleActor={toggleActor}
      />
      <form
        className={styles['create-unit-form']}
        onSubmit={handleSubmit(onHandleSubmit)}
        id="create-unit-form"
        noValidate
      >
        {/* {!isActor && hasUnitForms && ( */}
        {/* <label className={styles.checkbox} htmlFor="modal-path"> */}
        {/*  <input */}
        {/*    id="modal-path" */}
        {/*    // defaultChecked={isDepartment} */}
        {/*    type="checkbox" */}
        {/*    style={{ marginRight: '0.5rem' }} */}
        {/*  /> */}
        {/*  <span>{tNLS('tree.new.unit.department.focus')}</span> */}
        {/* </label> */}
        {/* )} */}
        <BUnitCardInput register={register} cardFieldType={CardFieldType.Name} />
        {isActor && (
        <>
          <BUnitCardInput
            register={register}
            cardFieldType={CardFieldType.Email}
          />
          <BUnitCardInput
            register={register}
            cardFieldType={CardFieldType.JobTitle}
          />
        </>
        )}
        <BUnitCardFooterButtons isUnitUpdated reset={reset} formId="create-unit-form" />
      </form>
    </>
  );
}

export default CreateBUnitForm;