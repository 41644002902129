import { useCallback, useState } from 'react';
import { BUnitHowTo, RolesType } from '@/store/types/admin/superset/unitsTree';

export type UpdateRoleFunc = (unitId: string, newRole: string, type: RolesType, isNew: boolean | undefined) => void;

export type DeleteRoleFunc = (unitId: string, type: RolesType, isNew: boolean | undefined) => void;

export type AddRolesFunc = (unitId: string, role: string) => void;

export type RestoreRoleFunc = (unitId: string, isUpdated: boolean | undefined) => void;
const useRolesList = () => {
  const [newRoles, setNewRoles] = useState<BUnitHowTo>({});
  const [updatedRoles, setUpdatedRoles] = useState<BUnitHowTo>({});
  const [deletedRoles, setDeletedRoles] = useState<BUnitHowTo>({});

  const updateRole = useCallback<UpdateRoleFunc>(
    (unitId, newRole, type, isNew = false) => {
      if (isNew) {
        setNewRoles((prevState) => ({ ...prevState, [unitId]: { role: newRole, type } }));
        return;
      }
      setUpdatedRoles((prevState) => ({ ...prevState, [unitId]: { role: newRole, type } }));
    },
    [],
  );

  const deleteRole = useCallback<DeleteRoleFunc>(
    (unitId, type, isNew = false) => {
      if (isNew) {
        setNewRoles((prevState) => {
          const newState = { ...prevState };
          delete newState[unitId];
          return newState;
        });
        return;
      }
      setDeletedRoles((prevState) => ({ ...prevState, [unitId]: { role: 'none', type } }));
    },
    [],
  );

  const addRoles = useCallback<AddRolesFunc>(
    (unitId, role) => {
      // @ts-ignore
      setNewRoles((prevState) => ({ ...prevState, [unitId]: { role, type: 'hard' } }));
    },
    [],
  );

  const restoreRole = useCallback<RestoreRoleFunc>(

    (unitId, isUpdated) => {
      if (isUpdated) {
        setUpdatedRoles((prevState) => {
          const newValue = { ...prevState };
          delete newValue[unitId];
          return newValue;
        });
        return;
      }
      setDeletedRoles((prevState) => {
        const newValue: BUnitHowTo = { ...prevState };
        delete newValue[unitId];
        return newValue;
      });
    },
    [],
  );

  const resetUpdates = useCallback(() => {
    setDeletedRoles({});
    setUpdatedRoles({});
    setNewRoles({});
  }, []);

  return {
    newRoles, updatedRoles, deletedRoles, updateRole, deleteRole, addRoles, restoreRole, resetUpdates,
  };
};

export default useRolesList;