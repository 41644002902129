// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".field-title--u7yxW{text-align:right;color:var(--secondary-color)}.field-input-container--2F7Sf{border-radius:0.5rem;background:var(--admin-background-interactive-elements);border:.5px solid var(--secondary-color);margin:0 0.75rem 0 0;padding:0.125rem 0.5rem 0.125rem 1rem;width:100%}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitCardSection/BUnitCardSection.module.sass"],"names":[],"mappings":"AAEA,oBAEE,gBAAA,CAGA,4BAAA,CAGF,8BACE,oBAAA,CACA,uDAAA,CACA,wCAAA,CACA,oBAAA,CACA,qCAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field-title": "field-title--u7yxW",
	"field-input-container": "field-input-container--2F7Sf"
};
export default ___CSS_LOADER_EXPORT___;
