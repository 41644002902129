import {
  BUnitSelectOption,
  UnitsSelectOptionsKeysByType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/useSelectOption';
import {
  BUnitSelectAction,
  UnitsSelectActionKeysByType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/useSelectAction';
import {
  BUnitGroupAction,
  UnitActionsKeysByOperationType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/useBUnitGroupAction';
import { MailTemplatesByLang } from '@/store/types/admin/superset/superset';

// todo Переписать потом, скорей всего сделать класс конструктор(если обобщить, то есть конфликт с типами)
interface GetSelectActionsAndOptionsWithEnumsReturn {
  selectOptions: BUnitSelectOption[];
  selectActions: BUnitSelectAction[];
}

export const getSelectActionByType = (type: keyof typeof UnitsSelectActionKeysByType, getNls: (arg: string) => string): BUnitSelectAction => ({
  key: UnitsSelectActionKeysByType[type],
  name: getNls(UnitsSelectActionKeysByType[type]),
});

export const getSelectOptionByType = (type: keyof typeof UnitsSelectOptionsKeysByType, getNls: (arg: string) => string): BUnitSelectOption => ({
  key: UnitsSelectOptionsKeysByType[type],
  name: getNls(UnitsSelectOptionsKeysByType[type]),
});

export const getOperationByType = (type: keyof typeof UnitActionsKeysByOperationType, getNLS: (arg: string) => string): BUnitGroupAction => ({
  name: getNLS(UnitActionsKeysByOperationType[type]),
  operationType: type,
});

export const getBUnitsOperations = (
  mailTemplates: MailTemplatesByLang,
  getNls: (arg: string) => string,
): BUnitGroupAction[] => Object.keys(UnitActionsKeysByOperationType).flatMap(
  (type) => {
    if (type === 'sendEmail' && !(mailTemplates && Object.keys(mailTemplates).length)) return [];
    return getOperationByType(type as keyof typeof UnitActionsKeysByOperationType, getNls);
  },
);
const getSelectActionsAndOptionsWithEnums = (getNls: (arg: string) => string): GetSelectActionsAndOptionsWithEnumsReturn => {
  const selectActions = Object.keys(UnitsSelectActionKeysByType).map(
    (value) => getSelectActionByType(value as keyof typeof UnitsSelectActionKeysByType, getNls),
  );
  const selectOptions = Object.keys(UnitsSelectOptionsKeysByType).map(
    (value) => getSelectOptionByType(value as keyof typeof UnitsSelectOptionsKeysByType, getNls),
  );

  return { selectOptions, selectActions };
};

export default getSelectActionsAndOptionsWithEnums;