// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".controller-input-number--ZxrQr{display:inline-flex;flex-direction:column}.controller-input-number--ZxrQr button{background:var(--controller-input-number-background-btn);font-size:0.5625rem;padding:0.1875rem;transition:background-color .3s ease 0s}.controller-input-number--ZxrQr button.btn-increase-ticket--tXWhI{border-radius:10px 10px 0 0}.controller-input-number--ZxrQr button.btn-reduce-ticket--gLLFy{border-radius:0 0 10px 10px}.controller-input-number--ZxrQr button .icon-control--8sMy9:before{color:var(--controller-input-number-color-icon)}.controller-input-number--ZxrQr button:hover{background:var(--controller-input-number-hover-background-btn)}.controller-input-number--ZxrQr button:active{background:#8b8b8b}.controller-input-number--ZxrQr:before{content:\"\";position:absolute;top:50%;transform:translateY(-50%);width:100%;height:0.0625rem;background:var(--light-grey-line-color)}", "",{"version":3,"sources":["webpack://./src/components/Common/form/ControlInputNumber/ControlInputNumber.module.sass"],"names":[],"mappings":"AAEA,gCACE,mBAAA,CACA,qBAAA,CACA,uCACE,wDAAA,CACA,mBAAA,CACA,iBAAA,CACA,uCAAA,CACA,kEACE,2BAAA,CACF,gEACE,2BAAA,CAEA,mEACE,+CAAA,CACJ,6CACE,8DAAA,CACF,8CACE,kBAAA,CACJ,uCACE,UAAA,CACA,iBAAA,CACA,OAAA,CACA,0BAAA,CACA,UAAA,CACA,gBAAA,CACA,uCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controller-input-number": "controller-input-number--ZxrQr",
	"btn-increase-ticket": "btn-increase-ticket--tXWhI",
	"btn-reduce-ticket": "btn-reduce-ticket--gLLFy",
	"icon-control": "icon-control--8sMy9"
};
export default ___CSS_LOADER_EXPORT___;
