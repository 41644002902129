// A block can have multiple languages.
// Need to get data on block only for one language.
// By default is english.
// If there is no English --> get data from a single language from active languages.
import { selectActiveLanguagesMasterSuperset } from '@/store/selectors/admin/superset/projects';

const selectClearBlocks = (state) => {
  const supersetActiveLangs = selectActiveLanguagesMasterSuperset(state);
  const { blocks } = state.admin.superset;
  const clearBlocks = {};

  if (!Object.keys(supersetActiveLangs).length) {
    return {};
  }

  const defaultActiveLangID = 'en' in supersetActiveLangs
    ? 'en' : Object.keys(supersetActiveLangs)[0];

  Object.keys(blocks).forEach((blockID) => {
    const { isActive, langs: blockLangs } = blocks[blockID];
    let langID;

    if (!(defaultActiveLangID in blockLangs)) {
      const currentBlockLangIDs = Object.keys(blockLangs);
      for (let i = 0; i < currentBlockLangIDs.length; i += 1) {
        const nextLangID = currentBlockLangIDs[i];

        if (nextLangID in supersetActiveLangs) {
          langID = nextLangID;
          break;
        }
      }
    } else {
      langID = defaultActiveLangID;
    }

    if (!langID) {
      return;
    }

    clearBlocks[blockID] = { isActive, ...blockLangs[langID] };
  });

  return clearBlocks;
};

export default selectClearBlocks;