import React from 'react';
// import styles from './CreateWebinarPagePreview.module.sass';
import WebinarTexts from '../../../WebinarRegisterPage/WebinarTexts/WebinarTexts';

interface CreateWebinarPagePreviewProps {
  webinarTitle: string;
  webinarDescription: string;
}

export default function CreateWebinarPagePreview({
  webinarTitle, webinarDescription,
}: CreateWebinarPagePreviewProps) {
  return (
    <WebinarTexts
      webinarTitle={webinarTitle}
      webinarDescription={webinarDescription}
    />
  );
}