import React from 'react';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectUploadResourcesInfo } from '@/store/selectors/admin/superset/projects';
import ErrorUploadingTemplate
  from '@/components/App/Admin/ListSupersets/ModalCreateSuperset/FormUploadingResources/ResultUploading/ResultUploadingTemplates/UploadingTemplateInfo/ErrorUploadingTemplate/ErrorUploadingTemplate';
import SuccessUploadingTemplateInfo
  from '@/components/App/Admin/ListSupersets/ModalCreateSuperset/FormUploadingResources/ResultUploading/ResultUploadingTemplates/UploadingTemplateInfo/SuccessUploadingTemplateInfo/SuccessUploadingTemplateInfo';

interface SuccessUploadingTemplateProps {
  inputValue: string;
  setInputValue: (arg: string) => void;
}

function UploadingTemplateInfo({ inputValue, setInputValue }: SuccessUploadingTemplateProps) {
  const { errs } = useAdminSelector(selectUploadResourcesInfo);

  if (errs && errs.length) return <ErrorUploadingTemplate />;

  return (<SuccessUploadingTemplateInfo inputValue={inputValue} setInputValue={setInputValue} />);
}

export default UploadingTemplateInfo;