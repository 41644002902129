// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text-area--2Ngph .text-editor--N\\+Nv7 .ql-editor--sTv5d{font-size:1.875rem !important}", "",{"version":3,"sources":["webpack://./src/components/App/Webinar/CreateWebinarPage/CreateWebinarForm/CreateWebinarFormField/CreateWebinarField/WebinarTextArea/CreateWebinarTextArea.module.sass"],"names":[],"mappings":"AAII,yDACE,6BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text-area": "text-area--2Ngph",
	"text-editor": "text-editor--N+Nv7",
	"ql-editor": "ql-editor--sTv5d"
};
export default ___CSS_LOADER_EXPORT___;
