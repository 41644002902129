import React, {
  ReactNode, useContext,
} from 'react';
// import styles from './SaveChangesModal.module.sass';
import UnitCardContext
, {
  BUnitCardContextProps,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/bUnitCardContext';
import Modal from '@/components/Common/Modal';
import Content from '@/components/Common/Modal/Content';
import useNLS from '@/components/App/Admin/hooks/hooks';

interface SaveChangesModalProps {
  children: ReactNode;
}

function SaveChangesModal({ children }: SaveChangesModalProps) {
  const { isOpen, toggleOpen } = useContext(UnitCardContext) as Required<BUnitCardContextProps>;
  const tNLS = useNLS();

  return (
    <Modal
      title=""
      isShowing={isOpen}
      hide={() => toggleOpen(false)}
    >
      <Content>
        <div>{tNLS('tree.card.unsaved.data')}</div>
        {children}
      </Content>
    </Modal>
  );
}

export default SaveChangesModal;