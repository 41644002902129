// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notification-panel--PWquw{position:fixed;z-index:10000;top:10px;height:100%;right:0}.notification-panel--PWquw .notifications-container--9ZerS{position:relative;top:30px}.notification-panel--PWquw .notifications-container--9ZerS .notify-container--zjRQE{position:relative;right:0;transition:top ease 1s;overflow-y:hidden}.notification-panel--PWquw .notifications-container--9ZerS .notify-container--zjRQE:first-child{animation:.5s ease showNotify--YW1En}.notification-panel--PWquw .notifications-container--9ZerS .notify-container--zjRQE .noty--7GZ0w{position:relative}.notification-panel--PWquw .notifications-container--9ZerS .notify-container--zjRQE .noty--7GZ0w .close-button--iXsMX{position:absolute;background:var(--content-block-bg);border-radius:50%;padding:0;margin:0;top:-8px;left:-8px;font-size:16px}.notification-panel--PWquw .notifications-container--9ZerS .notify-container--zjRQE .noty--7GZ0w .close-button--iXsMX .icon--7zOgp:before{color:var(--secondary-color)}.notification-panel--PWquw .notifications-container--9ZerS .notify-container--zjRQE .hidden--13au5{box-shadow:0 0 0 0;transition:box-shadow ease 1s}@keyframes showNotify--YW1En{0%{right:300px}100%{right:0}}", "",{"version":3,"sources":["webpack://./src/components/Common/NotificationsBar/NotificationsBar.module.sass"],"names":[],"mappings":"AAAA,2BACE,cAAA,CACA,aAAA,CACA,QAAA,CACA,WAAA,CAGA,OAAA,CACA,2DACE,iBAAA,CACA,QAAA,CAEA,oFACE,iBAAA,CACA,OAAA,CAEA,sBAAA,CACA,iBAAA,CACA,gGACE,oCAAA,CACF,iGACE,iBAAA,CACA,sHACE,iBAAA,CACA,kCAAA,CACA,iBAAA,CACA,SAAA,CACA,QAAA,CACA,QAAA,CACA,SAAA,CACA,cAAA,CAEE,0IACE,4BAAA,CACR,mGACE,kBAAA,CACA,6BAAA,CAGR,6BACE,GACE,WAAA,CACF,KACE,OAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification-panel": "notification-panel--PWquw",
	"notifications-container": "notifications-container--9ZerS",
	"notify-container": "notify-container--zjRQE",
	"showNotify": "showNotify--YW1En",
	"noty": "noty--7GZ0w",
	"close-button": "close-button--iXsMX",
	"icon": "icon--7zOgp",
	"hidden": "hidden--13au5"
};
export default ___CSS_LOADER_EXPORT___;
