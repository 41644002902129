import React, { useEffect } from "react";
import CloudSuperset from "./CloudSuperset/CloudSuperset";
import { useAdminDispatch, useAdminSelector } from "@/store/hooks/adminRedux";
import { selectCloud } from "@/store/selectors/admin/super";
import { ReadCloud } from "@/store/actions/admin/super";
export default function Cloud() {
    const cloudSupersets = useAdminSelector(selectCloud);
    const dispatch = useAdminDispatch();

    useEffect(() => {
        dispatch(ReadCloud())
    }, [dispatch])

    if (!cloudSupersets) return;

    return [...cloudSupersets].sort((a, b) => {
        return Date.parse(b.modTime) - Date.parse(a.modTime)
    }
    ).map(superset => {
        return <CloudSuperset key={superset.name} superset={superset} />;
    });
}