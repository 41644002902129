import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import styles from './CustomQuestionControls.module.sass';
import BtnCheckboxSlider from '@/components/App/Admin/UIElements/BtnCheckboxSlider';
import { editingQuestions } from '@/store/actions/admin/superset/addingQuestions';

function CustomQuestionControls({
  confirmRemovingQuestionToggleModal,
  isEnabled,
  itemId,
  index,
}) {
  const dispatch = useDispatch();
  const { id: supersetID } = useParams();

  const editingQuestion = useCallback(() => {
    dispatch(
      editingQuestions({
        supersetID,
        changedQuestions: [
          {
            itemId,
            isEnabled: !isEnabled,
          },
        ],
      }),
    );
  }, [dispatch, isEnabled, itemId, supersetID]);

  return (
    <div className={styles.controls}>
      <button
        type="button"
        className={`${styles['btn-remove-question']} btn-without-style`}
        onClick={() => confirmRemovingQuestionToggleModal({
          itemId,
          index,
        })}
      >
        {' '}
        <span className={`${styles['icon-remove']} icon-remove-light`} />
      </button>
      <BtnCheckboxSlider
        onChange={() => editingQuestion()}
        checked={isEnabled}
        externalClassNames={styles['btn-toggle-superset']}
      />
    </div>
  );
}

CustomQuestionControls.propTypes = {
  confirmRemovingQuestionToggleModal: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  itemId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default CustomQuestionControls;