import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CustomSelect from '@/components/App/Admin/BUnitsSection/CustomSelect';
import styles from './SelectTargetDepartment.module.sass';
import { selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';
import { isOneStringEnterAnother } from '@/helpers/helpers';
import CustomSelectOption from '@/components/App/Admin/BUnitsSection/CustomSelect/CustomSelectOption/CustomSelectOption';
import useNLS from '@/components/App/Admin/hooks/hooks';

function SelectTargetDepartment({ selectTargetPath, inCard }) {
  const { units, order } = useSelector(selectUnitsTreeData);
  const tNLS = useNLS();

  const [activeValue, setActiveValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isOpen, toggleOpen] = useState(false);

  const filteredDepartments = useMemo(
    () => order.flatMap((unitId) => {
      if (units[unitId].isunit === 0) return [];
      const { name } = units[unitId];
      if (!isOneStringEnterAnother(name, inputValue)) return [];
      return unitId;
    }),
    [inputValue, order, units],
  );

  const findPath = useCallback(
    (id) => {
      const unit = units[id];
      const path = [unit.name];
      let parentId = unit.parent;
      while (parentId) {
        path.unshift(units[parentId].name);
        parentId = units[parentId].parent;
      }
      return path;
    },
    [units],
  );

  return (
    <CustomSelect
      activeValue={
        activeValue
          ? units[activeValue].name
          : tNLS('tree.new.unit.choose.department')
      }
      type="input"
      mainBtnClassNames={`${styles['select-button']} ${inCard && styles.card}`}
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      onKeyUp={(event) => setInputValue(event.target.value)}
    >
      {filteredDepartments.map((id) => (
        <CustomSelectOption
          key={`${id}transferUnit`}
          onClick={() => {
            selectTargetPath({
              targetDepartmentId: id,
              path: findPath(id),
            });
            setActiveValue(id);
            toggleOpen(false);
          }}
          isCurrentValue={activeValue === id}
          optionClassNames={`${styles['select-option']}`}
        >
          <span style={{ marginLeft: `${units[id].level * 16}px` }}>
            {units[id].name}
          </span>
        </CustomSelectOption>
      ))}
    </CustomSelect>
  );
}

SelectTargetDepartment.propTypes = {
  selectTargetPath: PropTypes.func.isRequired,
  inCard: PropTypes.bool,
};

SelectTargetDepartment.defaultProps = {
  inCard: false,
};

export default SelectTargetDepartment;