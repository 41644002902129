import React, { useContext } from 'react';
import CardSelectDropdown from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitSpec/CardSelectDropdown/CardSelectDropdown';
import { TreeUnit } from '@/store/types/admin/superset/unitsTree';
import BUnitContext from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import AddHowToSearchDropdownBtn from './AddHowToSearchDropdownBtn/AddHowToSearchDropdownBtn';

interface BUnitAddHowToSearchDropdownProps {
  isOpen: boolean;
  selectUnit: (id: string, unit: TreeUnit) => void;
  filteredList: [string, TreeUnit][]
}

function BUnitAddHowToSearchDropdown({ isOpen, selectUnit, filteredList }: BUnitAddHowToSearchDropdownProps) {
  const { id: unitId } = useContext(BUnitContext);

  if (!isOpen) return null;

  return (
    <CardSelectDropdown>
      {filteredList
        .filter(([id]) => +id > 0)
        .map((unit) => (
          <AddHowToSearchDropdownBtn
            unitInfo={unit}
            key={`${unit[0]}-${unitId}-search-role`}
            selectUnit={selectUnit}
          />
        ))}
    </CardSelectDropdown>
  );
}

export default BUnitAddHowToSearchDropdown;