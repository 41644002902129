export const isFirstChild = (id, index, units, order) => {
  if (units[id].level === 0) return false;
  return units[id].level - 1 === units[order[index - 1]].level;
};

export const isEmployeeNext = (id, index, units, order) => {
  if (id === order[order.length - 1]) return false;
  return units[order[index + 1]] && units[order[index + 1]].isunit === 0;
};

export const isHaveChildrenUnits = (parent, units) => {
  if (!units[parent]?.children) return true;
  let departmentsCount = 0;
  for (let i = 0; i < units[parent].children.length; i += 1) {
    departmentsCount = units[units[parent].children[i]].isunit !== 0
      ? departmentsCount + 1 : departmentsCount;
  }
  return departmentsCount === units[parent].children.length || departmentsCount === 0;
};

export const isDrawVertical = (units, id) => {
  const unit = units[id];
  if (unit.level === 0) return false;

  const { parent } = unit;
  const currentLevel = units[parent] ? units[parent].children : [];

  let lastUnitNotEmployeeId = '';

  for (let i = currentLevel.length - 1; i > 0; i -= 1) {
    if (units[currentLevel[i]].isunit !== 0) {
      lastUnitNotEmployeeId = currentLevel[i];
      break;
    }
  }

  if (id === lastUnitNotEmployeeId) return false;
  return currentLevel.indexOf(id) <= currentLevel.indexOf(lastUnitNotEmployeeId);
};

export const isHaveEmployees = (units, id) => {
  const unit = units[id];
  if (!unit.children) return false;
  let isEmployeeInFirstLevel = 0;
  for (let i = 0; i < unit.children.length; i += 1) {
    if (units[unit.children[i]].isunit === 0) {
      isEmployeeInFirstLevel += 1;
      break;
    }
  }
  return !!isEmployeeInFirstLevel;
};