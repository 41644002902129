import { Dispatch, SetStateAction, useState } from 'react';
import useLocalStorageByAdmin from '@/components/App/Admin/BUnitsSection/UnitsTree/hooks/adminLocalStorageSaver';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { getSelectOptionByType } from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/helpers';

export type SetCurrentSelectOptionType = Dispatch<SetStateAction<BUnitSelectOption>>;

export enum UnitsSelectOptionsKeysByType {
  All = 'tree.select.all',
  Started = 'tree.select.started',
  Finished = 'tree.select.finished',
  DidntStart = 'tree.select.didntstart',
  BySpec = 'tree.select.byspec',
  Estimator = 'tree.select.respondents',
  Estimated = 'tree.select.foci',
}

export interface BUnitSelectOption {
  key: UnitsSelectOptionsKeysByType;
  name: string;
}

const useSelectOption = (): [BUnitSelectOption, SetCurrentSelectOptionType] => {
  const [, readFromLocalStorage] = useLocalStorageByAdmin<BUnitSelectOption>('selectActions');
  const tNLS = useNLS();
  const [currentSelectOption, setCurrentSelectOption] = useState<BUnitSelectOption>(
    () => readFromLocalStorage() || getSelectOptionByType('All', tNLS),
  );

  return [currentSelectOption, setCurrentSelectOption];
};

export default useSelectOption;