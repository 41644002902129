import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProcessing from '@/axiosConfig';

// eslint-disable-next-line import/prefer-default-export
export const getAlert = createAsyncThunk(
  'alert/getAlert',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get('/api/admin/getalert');
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);