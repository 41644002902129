import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { loadCutsOptions } from '@/store/actions/admin/superset/process/cuts/cuts';
import { selectStatusLoadCriteria } from '@/store/selectors/admin/superset/process/cuts/cuts';

const useFetchCriteria = () => {
  const dispatch = useDispatch();
  const { id: supersetID } = useParams();
  const hasCriteriaReceived = useSelector(selectStatusLoadCriteria);

  useEffect(() => {
    if (!hasCriteriaReceived) {
      dispatch(loadCutsOptions(supersetID));
    }
  }, [dispatch, hasCriteriaReceived, supersetID]);

  return hasCriteriaReceived;
};

export default useFetchCriteria;