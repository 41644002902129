import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ErrorsInitialState {
  errorInfo: string | '';
}

const initialState: ErrorsInitialState = {
  errorInfo: '',
};

export const errorsInfoSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    addError: {
      reducer: (state, { payload }: PayloadAction<{ errorInfo: string }>) => {
        state.errorInfo = payload.errorInfo;
      },
      prepare: (errorData: { errorInfo: string }) => ({ payload: { ...errorData } }),
    },
    removeError: (state) => {
      state.errorInfo = '';
    },
  },
});

const { reducer } = errorsInfoSlice;

export const { addError, removeError } = errorsInfoSlice.actions;
export default reducer;