import React from 'react';
import PropTypes from 'prop-types';
import styles from './SVGChart.module.sass';
import {
  calculatePath,
  calculateViewBoxFromPath,
} from '@/components/App/Admin/Results/Cuts/helpers';
import { getClearClassNames } from '@/helpers/helpers';

function SVGChart({ chartForUnselected, values, externalClasses }) {
  const path = calculatePath(1100, 1100, values);
  const viewBox = calculateViewBoxFromPath(path);

  if (chartForUnselected) {
    return (
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        className={getClearClassNames([
          externalClasses.svgUnselectedChart,
          styles['for-unselected-stats'],
        ])}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d={path}
          fill="none"
          strokeDasharray="5, 5"
        />
      </svg>
    );
  }

  // else render general chart

  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={getClearClassNames([externalClasses.svgChart])}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={path}
        fill="url(#paint0_linear_949_1096)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_949_1096"
          x1="2.7937"
          y1="89.8415"
          x2="941.535"
          y2="89.8415"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DE4887" />
          <stop offset="0.5" stopColor="#2978EF" />
          <stop offset="1" stopColor="#43B563" />
        </linearGradient>
      </defs>
    </svg>
  );
}

SVGChart.defaultProps = {
  chartForUnselected: false,
  externalClasses: {},
};

SVGChart.propTypes = {
  chartForUnselected: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  externalClasses: PropTypes.shape({
    svgChart: PropTypes.string,
    svgUnselectedChart: PropTypes.string,
  }),
};

export default SVGChart;