import React, {
  useCallback,
  useContext, useEffect, useMemo, useState,
} from 'react';
import styles from './BUnitCard.module.sass';
import BUnitCardContentContainer from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardContentContayner/BUnitCardContentContainer';
import BUnitCardTabs from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardTabs/BUnitCardTabs';
import {
  selectSuperset,
} from '@/store/selectors/admin/superset/supersets';
import { getRelatedUnits } from '@/store/actions/admin/superset/unitsTree';
import newBUnitContext, { BUnitContextProps } from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';

import BUnitCardContent
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardContent/BUnitCardContent';
import UnitCardContext
, {
  BUnitCardContextProps,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/bUnitCardContext';
import UnitCardTabIcons
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/TabIcons/UnitCardTabIcons';

// interface BUnitCardProps {
//
// }

export enum BUnitCardTabsTypes {
  Main = 'main',
  Objects = 'objects',
  Subjects = 'subjects',
}
export default function BUnitCard() {
  const [activeTabId, toggleActiveTab] = useState<BUnitCardTabsTypes>(BUnitCardTabsTypes.Main);
  const [howToLoaded, toggleLoaded] = useState<boolean>(false);
  const [isModalOpen, toggleOpen] = useState<boolean>(false);
  const [isTabWithUpdates, toggleUpdated] = useState<boolean>(false);
  const [nextTabAfterModal, setNextTabAfterModal] = useState<BUnitCardTabsTypes | undefined>(undefined);
  const { supersetId } = useAdminSelector(selectSuperset);
  const dispatch = useAdminDispatch();
  const { id } = useContext(newBUnitContext) as Required<BUnitContextProps>;

  useEffect(() => {
    dispatch(getRelatedUnits({ supersetId, unitId: id }))
      .finally(() => toggleLoaded(true));
  }, [dispatch, id, supersetId]);

  const tabsArray = useMemo(
    () => Object.values(BUnitCardTabsTypes).map(
      (value: BUnitCardTabsTypes) => ({
        name: <UnitCardTabIcons type={value} />,
        id: value,
      }),
    ),
    [],
  );

  const tabHandleClick = useCallback((tabId: BUnitCardTabsTypes) => {
    if (isTabWithUpdates) {
      toggleOpen(true);
      setNextTabAfterModal(tabId);
      return;
    }
    toggleActiveTab(tabId);
  }, [isTabWithUpdates]);

  const handleButtonClickInModal = useCallback(() => {
    if (nextTabAfterModal) toggleActiveTab(nextTabAfterModal);
    setNextTabAfterModal(undefined);
    toggleOpen(false);
  }, [nextTabAfterModal]);

  const modalHandleClose = () => {
    setNextTabAfterModal(undefined);
    toggleOpen(false);
  };

  const context = useMemo<BUnitCardContextProps>(() => ({
    isOpen: isModalOpen,
    toggleOpen,
    modalHandleClose,
    handleButtonClickInModal,
  }), [handleButtonClickInModal, isModalOpen]);

  return (
    <UnitCardContext.Provider value={context}>
      <div className={styles['unit-card']}>
        <BUnitCardTabs
          <BUnitCardTabsTypes>
          tabs={tabsArray}
          activeTabId={activeTabId}
          tabHandleClick={tabHandleClick}
        />
        <BUnitCardContentContainer>
          <BUnitCardContent
            activeTabId={activeTabId}
            isHowToLoaded={howToLoaded}
            isUpdated={isTabWithUpdates}
            toggleUpdated={toggleUpdated}
          />
        </BUnitCardContentContainer>
      </div>
    </UnitCardContext.Provider>
  );
}