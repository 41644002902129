import React from 'react';
import MainContentContainer from '@/components/Common/BaseTemplate/MainContentContainer';
import WebinarsFilteredList from './WebinarsFilteredList/WebinarsFilteredList';
import WebinarsListHeader from './WebinarsFilteredList/WebinarsListHeader';

export default function WebinarsList() {
  return (
    <MainContentContainer>
      <WebinarsListHeader />
      <WebinarsFilteredList />
    </MainContentContainer>
  );
}