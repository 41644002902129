import React from 'react';
import InformationPage from '@/components/App/User/UserContent/InformationPage/InformationPage';

interface WebinarRegisterPagePreviewProps {
  description: string
}
export default function WebinarRegisterPagePreview({ description }: WebinarRegisterPagePreviewProps) {
  return (
    <InformationPage description={description} />
  );
}