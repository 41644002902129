// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".btn-checkbox-slider--XUazC{position:relative;width:2.875rem;height:1.4375rem;border-radius:1rem;background:var(--bg-not-enabled-btn-toggle-superset);z-index:0;border:none;cursor:pointer;transition-duration:300ms}.btn-checkbox-slider--XUazC::after{content:\"\";position:absolute;top:50%;left:0;transform:translateY(-50%);margin:0 0.125rem 0 0.125rem;height:1.198125rem;width:1.198125rem;border-radius:1.0625rem;background:#fff;transition-duration:300ms}.btn-checkbox-slider--XUazC.enabled--Zp7gb{background:var(--bg-enabled-btn-toggle-superset)}.btn-checkbox-slider--XUazC.enabled--Zp7gb::after{left:calc(2.875rem - 1.198125rem - 0.25rem)}.btn-checkbox-slider--XUazC.not-interactive--SVK\\+J{pointer-events:none}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/UIElements/BtnCheckboxSlider/BtnCheckboxSlider.module.sass"],"names":[],"mappings":"AAMA,4BACE,iBAAA,CACA,cANS,CAOT,gBAAA,CACA,kBAAA,CACA,oDAAA,CACA,SAAA,CACA,WAAA,CACA,cAAA,CACA,yBAAA,CACA,mCACE,UAAA,CACA,iBAAA,CACA,OAAA,CACA,MAAA,CACA,0BAAA,CACA,4BAAA,CACA,kBAAA,CACA,iBAAA,CACA,uBAAA,CACA,eAAA,CACA,yBAAA,CACF,2CACE,gDAAA,CACA,kDACE,2CAAA,CACJ,oDACE,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn-checkbox-slider": "btn-checkbox-slider--XUazC",
	"enabled": "enabled--Zp7gb",
	"not-interactive": "not-interactive--SVK+J"
};
export default ___CSS_LOADER_EXPORT___;
