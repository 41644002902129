import { useEffect } from 'react';
import {
  selectSupersets,
} from '@/store/selectors/admin/superset/supersets';
import { getSupersets } from '@/store/actions/admin';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';

const useSupersets = () => {
  const dispatch = useAdminDispatch();
  const supersets = useAdminSelector(selectSupersets);

  useEffect(() => {
    dispatch(getSupersets());
  }, [dispatch]);

  return supersets;
};

export default useSupersets;