import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styles from './SupersetEvent.module.sass';
import { getDateInFormat } from '@/helpers/helpers';
import { hideSupersetEvent } from '@/store/actions/admin';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';

function SupersetEvent({
  message, createdAt, langId, eventId,
}) {
  const dispatch = useDispatch();
  const checkPermission = useCheckPermission();

  const hideEvent = () => {
    dispatch(
      hideSupersetEvent({
        langId,
        eventId,
      }),
    );
  };
  const eventTimeAndDate = getDateInFormat(createdAt).split(' ');
  return (
    <ul className={styles['events-list-element']}>
      {checkPermission('hide_project_events') && (
        <button
          type="button"
          className="btn-without-style"
          onClick={() => {
            hideEvent();
          }}
        >
          {' '}
          <span className="icon-remove-light" />
        </button>
      )}
      <div className={styles['event-date']}>
        <div className={styles.time}>{eventTimeAndDate[0]}</div>
        <div className={styles.date}>{eventTimeAndDate[1]}</div>
      </div>
      <div>{message}</div>
    </ul>
  );
}

SupersetEvent.propTypes = {
  message: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  langId: PropTypes.string.isRequired,
  eventId: PropTypes.number.isRequired,
};

export default SupersetEvent;