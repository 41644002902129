// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".unit-checkbox--f-bkc{position:relative;margin-right:0.5rem;z-index:1}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnit/EmployeeUnit/EmployeeUnitFields/BUnitCheckBox/BUnitCheckBox.module.sass"],"names":[],"mappings":"AAGA,sBACE,iBAAA,CACA,mBAAA,CACA,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unit-checkbox": "unit-checkbox--f-bkc"
};
export default ___CSS_LOADER_EXPORT___;
