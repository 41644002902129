import React, { useState } from 'react';
import styles from './SelectMenu.module.sass';
import SelectMenuTitle from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/BUnitSelectMenuOptionsSelectors/SelectMenuTitle/SelectMenuTitle';
import BUnitsSelectMenuSpecs from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/BUnitsSelecMenuSpecs/BUnitsSelectMenuSpecs';
import BUnitsSelectMenuSelectors from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/BUnitSelectMenuOptionsSelectors/BUnitsSelectMenuSelectors';
import BUnitsSelectMenuConfirmButton from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/BUnitsSelectMenuConfirmButton/BUnitsSelectMenuConfirmButton';
import BUnitActionMenu from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';
import useSelectAction from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/useSelectAction';
import useSelectOption, {
  UnitsSelectOptionsKeysByType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/useSelectOption';

interface SelectMenuProps {
  isTreeLoaded: boolean;
}
export default function SelectMenu({ isTreeLoaded }: SelectMenuProps) {
  const [currentSelectAction, setCurrentSelectAction] = useSelectAction();
  const [currentSelectOption, setCurrentSelectOption] = useSelectOption();
  const [currentSelectSpec, setCurrentSelectSpec] = useState('');
  const checkPermission = useCheckPermission();

  if (!isTreeLoaded || (!checkPermission('modify_units') && !checkPermission('group_operations'))) return null;

  return (
    <section className={styles['tree-actions']}>
      <div className={styles['select-menu-section']}>
        <SelectMenuTitle />
        <div className={styles['buttons-block']}>
          <div className={styles['select-block']}>
            <BUnitsSelectMenuSelectors
              setCurrentSelectOption={setCurrentSelectOption}
              setCurrentSelectAction={setCurrentSelectAction}
              currentSelectAction={currentSelectAction}
              currentSelectOption={currentSelectOption}
            />
            {currentSelectOption
              && currentSelectOption.key === UnitsSelectOptionsKeysByType.BySpec && (
                <BUnitsSelectMenuSpecs
                  currentSelectSpec={currentSelectSpec}
                  setCurrentSelectSpec={setCurrentSelectSpec}
                />
            )}
          </div>
          <BUnitsSelectMenuConfirmButton
            currentSelectAction={currentSelectAction}
            currentSelectOption={currentSelectOption}
            currentSelectSpec={currentSelectSpec}
          />
        </div>
      </div>
      {checkPermission('group_operations') && <BUnitActionMenu />}
    </section>
  );
}