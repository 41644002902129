import React from 'react';
import styles from './SectionBusinessUnitsSettings.module.sass';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import useModal from '@/hooks/modal';
import ModalGenerateTicketUnits from './ModalGenerateTicketUnits/ModalGenerateTicketUnits';
import { OPTION_BU_CODE_TICKET, USE_UNITS_SETTING_ID } from '@/constants/admin';
import RequireRoleChangeAccess from '@/components/App/Admin/SupersetSettings/RequireRoleChangeAccess';
import { selectAdminSettingsValue } from '@/store/selectors/admin/superset/settings';
import useNLS from '@/components/App/Admin/hooks/hooks';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';
import { useAdminSelector } from '@/store/hooks/adminRedux';

function SectionBusinessUnitsSettings() {
  const tNLS = useNLS();
  const checkPermission = useCheckPermission();
  const { unitsArray: units } = useAdminSelector(
    (state) => state.admin.superset.unitsTree,
  );

  // @ts-ignore
  const typeBusinessUnitsValue = useAdminSelector((state) => selectAdminSettingsValue(state, [USE_UNITS_SETTING_ID]))[USE_UNITS_SETTING_ID]?.value;

  const isGenerateUnitTickets = !!units.length && typeBusinessUnitsValue === OPTION_BU_CODE_TICKET;
  const {
    toggleStatusShowingModal: toggleModalGenerateTicketUnits,
    isShowingModal: isShowingModalGenerateTicketUnits,
  } = useModal();

  if (!checkPermission('generate_tickets') && !checkPermission('download_excel_tickets')) return null;

  if (!units || !units.length || !isGenerateUnitTickets) return null;

  return (
    <div className={styles['create-units-section']}>
      <RequireRoleChangeAccess>
        <div className={styles['wrap-btn-settings-units']}>
          {isGenerateUnitTickets && checkPermission('generate_tickets') && (
            <CustomButton
              classNames={styles['btn-generate-ticket-units']}
              onClick={toggleModalGenerateTicketUnits}
              styleTypeBtn="btn-grey btn-large"
            >
              {tNLS('units.download.generate.tickets')}
            </CustomButton>
          )}
        </div>
      </RequireRoleChangeAccess>
      {isGenerateUnitTickets && (
        <ModalGenerateTicketUnits
          toggleModal={toggleModalGenerateTicketUnits}
          isShowingModal={isShowingModalGenerateTicketUnits}
        />
      )}
    </div>
  );
}

export default SectionBusinessUnitsSettings;