// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uploaded-templates-section--I1gXA{display:flex;flex-direction:column}.show-files-btn--wi31m{color:var(--secondary-color);width:fit-content;text-decoration:underline}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SupersetSettings/SectionUploadedFiles/SectionUploadedFiles.module.sass"],"names":[],"mappings":"AAEA,mCACE,YAAA,CACA,qBAAA,CAEF,uBACE,4BAAA,CACA,iBAAA,CACA,yBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploaded-templates-section": "uploaded-templates-section--I1gXA",
	"show-files-btn": "show-files-btn--wi31m"
};
export default ___CSS_LOADER_EXPORT___;
