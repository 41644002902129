import { ControllerRenderProps } from 'react-hook-form';
import React, { useMemo } from 'react';
import { FormatOptionLabelMeta } from 'react-select';
import { ClassNamesConfig } from 'react-select/dist/declarations/src/styles';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { Rating } from '@/store/types/admin/superset/cuts/fourD';
import BaseSelect from '@/components/Common/form/BaseSelect';
import {
  FormValues,
  GroupedData,
  Option,
} from '@/components/App/Admin/Results/Cuts/FourD/FourDParamsForm/FourDParamsForm';
import useTransformRatingToGroupedRatingsOptions
  from '@/components/App/Admin/Results/Cuts/FourD/FourDParamsForm/hooks/hooks';

interface DataColorParam {
  ratings: Rating[],
  field: ControllerRenderProps<FormValues, 'color'>,
  formatGroupLabel: (data: GroupedData) => React.ReactNode,
  externalClasses: ClassNamesConfig<Option>,
  changeOptionValue: (
    option: Option,
    meta: FormatOptionLabelMeta <Option>
  ) => React.ReactNode;
}

function ColorParam({
  ratings, field, externalClasses, formatGroupLabel, changeOptionValue,
}: DataColorParam) {
  const tNLS = useNLS();
  const ratingsWithColors = useMemo(() => ratings.filter(({ colors }) => colors), [ratings]);
  const ratingsWithColorsOptions = useTransformRatingToGroupedRatingsOptions(ratingsWithColors);

  return (
    <BaseSelect
      id="colorField"
      {...field}
      label={tNLS('results.4d.color')}
      placeholder="Выберете параметр"
      options={ratingsWithColorsOptions}
      formatGroupLabel={formatGroupLabel}
      isSearchable
      formatOptionLabel={changeOptionValue}
      classNames={externalClasses}
    />
  );
}

export default ColorParam;