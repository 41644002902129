import React from 'react';
import CloudDirectory from './CloudDirectory/CloudDirectory';
import styles from "./CloudSuperset.module.sass"
import { humanFileSize } from '@/helpers/helpers';
interface CloudSupersetProps {
    superset: CloudDirectoryItem;
}

const CloudSuperset: React.FC<CloudSupersetProps> = ({ superset }) => {
    return (
        <div className={styles['cloud-superset-item']}>
            {superset.directories && (
                <details>
                    <summary>
                        <div className={styles['cloud-summary-header']}>
                            <span>{superset.name}</span>
                            <span>{superset.modTime}</span>
                        </div>
                    </summary>
                    {superset.directories.map((directory, i) => (
                        <CloudDirectory
                            key={`key-${i}`}
                            directory={directory}
                            directories={directory.directories}
                            files={directory.files}
                            headerStyle={styles['cloud-summary-header']}
                        />
                    ))}
                </details>
            )}
        </div>
    );
}

export default CloudSuperset;