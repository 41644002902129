import React from 'react';
import parse from 'html-react-parser';
import styles from './InformationPage.module.sass';

interface InformationPageProps {
  title?: string;
  description?: string;
}

function InformationPage({ title = '', description = '' }: InformationPageProps) {
  return (
    <div className={styles['info-page']}>
      <div className="employee-content">
        {title && <h1 className={styles.title}>{parse(title)}</h1>}
        {description && (
        <div className={styles.content}>
          {parse(description)}
        </div>
        )}
      </div>
    </div>
  );
}

export default InformationPage;