import React from 'react';
import PropTypes from 'prop-types';
import StatusElement from '@/components/App/Admin/Superset/HeaderSuperset/AssessmentStatus/StatusElement/StatusElement';
import styles from '../AssessmentStatus.module.sass';

function StatusColumn({ isInvalidated }) {
  const getKey = (key) => (isInvalidated ? `${key}Invalidated` : key);
  return (
    <div className={styles['status-column']}>
      <StatusElement
        statusKey={getKey('finished')}
        classNames={styles.finished}
        tooltipClassNames={`${styles.tooltip} ${
          isInvalidated ? styles.left : styles.right
        }`}
      />
      <StatusElement
        statusKey={getKey('lastActive')}
        classNames={styles['last-active']}
        tooltipClassNames={`${styles.tooltip} ${
          isInvalidated ? styles.left : styles.right
        }`}
      />
      <StatusElement
        statusKey={getKey('started')}
        classNames={styles['last-active']}
        tooltipClassNames={`${styles.tooltip} ${
          isInvalidated ? styles.left : styles.right
        }`}
      />
    </div>
  );
}

StatusColumn.propTypes = {
  isInvalidated: PropTypes.bool,
};

StatusColumn.defaultProps = {
  isInvalidated: false,
};

export default StatusColumn;