// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".upload-drop-container--tHoVC{display:flex;align-items:baseline;gap:0.75rem;height:6.25rem}.upload-drop-container--tHoVC .files-hidden-input--j\\+Jwa{display:none}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SuperAdmin/SuperUploading/types/consts/hooks/actions/UploadingInput/UploadingInput.module.sass"],"names":[],"mappings":"AAEA,8BACE,YAAA,CACA,oBAAA,CACA,WAAA,CACA,cAAA,CACA,0DACE,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload-drop-container": "upload-drop-container--tHoVC",
	"files-hidden-input": "files-hidden-input--j+Jwa"
};
export default ___CSS_LOADER_EXPORT___;
