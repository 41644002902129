import React, { useEffect, useState } from 'react';
import styles from './CopySuperset.module.sass';
import RadioButton from '@/components/Common/form/RadioButton';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import {
  clearSupersetsForCopy,
  createSuperset,
  getSupersetsForCopy,
  selectTemplate,
} from '@/store/actions/admin';
import Preloader from '@/components/App/Admin/SupersetSettings/Preloader';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { Superset } from '@/store/types/admin/supersets';
import {
  OnSuccessUploadingResources,
} from '@/components/App/Admin/ListSupersets/ModalCreateSuperset/ModalCreateSypersetContent/ModalCreateSupersetContent';

interface CopySupersetProps {
  onSuccess: OnSuccessUploadingResources
}

function CopySuperset({ onSuccess }: CopySupersetProps) {
  const { supersetsForCopy, copySupersetsStatus } = useAdminSelector(
    (state) => state.admin.supersets,
  );
  const { supersetId } = useAdminSelector(selectSuperset) as Superset;
  const [selectedMasterSuperset, setSelectedMasterSuperset] = useState('');
  const dispatch = useAdminDispatch();

  useEffect(() => {
    if (copySupersetsStatus === 'idle') {
      dispatch(getSupersetsForCopy());
    }
    return () => {
      dispatch(clearSupersetsForCopy());
    };
    // eslint-disable-next-line
  }, []);

  const getLabelSuperset = (name: string, descriptions: { [key: string]: string }, id: string) => (
    <div className={styles['wrap-option-label']}>
      <span>{name}</span>
      {Object.keys(descriptions).map((lang) => (
        <span key={`${id}-${lang}-description`} className={styles.description}>
          {descriptions[lang]}
        </span>
      ))}
    </div>
  );

  const onCopySuperset = () => {
    // or copy template-superset in created superset
    if (supersetId) {
      dispatch(
        selectTemplate({
          supersetID: supersetId,
          updatedSuperset: {
            superset: {
              // @ts-ignore
              masterSupersetId: selectedMasterSuperset,
            },
          },
        }),
      ).then(() => {
        onSuccess();
      })
        .catch(() => null);
      return;
    }

    // or create a new superset
    dispatch(
      createSuperset({
        name: 'New Project',
        masterSupersetId: selectedMasterSuperset,
      }),
    )
      .then((response) => {
        // @ts-ignore
        const { supersetId: createdSupersetID } = response.payload;
        onSuccess(createdSupersetID);
      })
      .catch(() => null);
  };

  if (copySupersetsStatus === 'loading') return <Preloader />;

  if (
    !Object.keys(supersetsForCopy).length
    && copySupersetsStatus === 'received'
  ) {
    return <div>No available supersets for copy</div>;
  }

  return (
    <div className={styles['wrap-copy-superset']}>
      <form className={styles['form-select-master-superset']}>
        {Object.keys(supersetsForCopy).map((id, i) => (
          // @ts-ignore
          <RadioButton
            key={id}
            name="supersetForCopy"
            id={`supersetForCopy-${i}`}
            value={id}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedMasterSuperset(e.target.value)}
            checked={id === selectedMasterSuperset}
            label={getLabelSuperset(
              supersetsForCopy[id].name,
              // @ts-ignore
              supersetsForCopy[id].description,
              id,
            )}
            externalClassNames={{
              wrapOption: styles['option-superset'],
            }}
          />
        ))}
      </form>

      <CustomButton
        styleTypeBtn="btn-blue btn-large"
        onClick={onCopySuperset}
        disabled={!selectedMasterSuperset}
      >
        Скопировать проект
      </CustomButton>
    </div>
  );
}

export default CopySuperset;