import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import styles from './SectionLanguages.module.sass';
import AdminCheckbox from '@/components/App/Admin/UIElements/form/AdminCheckbox';
import { selectSumProjectMetrics } from '@/store/selectors/admin/superset/supersets';
import ProhibitInteractivity from '@/components/App/Admin/SupersetSettings/ProhibitInteractivity';
import { selectLanguagesMasterSuperset } from '@/store/selectors/admin/superset/projects';
import { toggleLangSuperset } from '@/store/actions/admin/superset/projects';
import useNLS from '@/components/App/Admin/hooks/hooks';
import FlagIcon from '@/components/Common/Elements/cssIcons/FlagIcon/FlagIcon';

function SectionLanguages() {
  const tNLS = useNLS();
  const { id: supersetID } = useParams();
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const [isButtonsDisabled, toggleDisabled] = useState(false);
  const languages = useSelector(selectLanguagesMasterSuperset);
  const projectMetrics = useSelector(selectSumProjectMetrics);
  const selectedLangIDRef = useRef();

  const onChangeLangs = (form) => {
    toggleDisabled(true);
    dispatch(
      toggleLangSuperset({
        formUpdateSuperset: {
          supersetID,
          updatedSuperset: {
            chosenProjects: form.langs,
          },
        },
        toggledLangStatus: {
          langID: selectedLangIDRef.current,
          isActive: form.langs[selectedLangIDRef.current],
        },
      }),
    )
      .then(unwrapResult)
      .then(() => toggleDisabled(false))
      .catch(() => null);
  };

  const checkDisabledLang = (checkboxLangID) => {
    const activeLangID = {};

    Object.keys(languages).forEach((langID) => {
      if (languages[langID].isActive) {
        activeLangID[langID] = true;
      }
    });

    if (Object.keys(activeLangID).length > 1) {
      return false;
    }

    return Object.keys(activeLangID)[0] === checkboxLangID;
  };

  const secondsToMinutes = (seconds) => Math.round(seconds / 60);

  return (
    <section className={`settings-section ${styles['languages-section']}`}>
      <h3 className="title-section">{tNLS('project.languages')}</h3>

      <form
        onChange={() => {
          handleSubmit(onChangeLangs)();
        }}
      >
        <div className={styles['list-languages']}>
          {Object.keys(languages).map((langID) => {
            const { english, isActive, order } = languages[langID];
            let minTime;
            let maxTime;

            if (isActive) {
              const [min, max] = projectMetrics[langID];
              minTime = min;
              maxTime = max;
            }

            const labelContent = (
              <div className={styles['label-text-lang-checkbox']}>
                <FlagIcon langId={langID} classNames={styles['icon-lang']} />

                <div className={styles['wrap-lang-info']}>
                  <span className={styles['lang-label']}>{english}</span>
                  {isActive && minTime !== 0 && maxTime !== 0 && (
                    <div className={styles['lang-metrics']}>
                      {`${secondsToMinutes(minTime)} - ${secondsToMinutes(
                        maxTime,
                      )} minutes`}
                    </div>
                  )}
                </div>
              </div>
            );

            return (
              <div
                className={styles['item-lang']}
                key={langID}
                style={{ order }}
              >
                <ProhibitInteractivity>
                  <AdminCheckbox
                    register={register}
                    registerOptions={{
                      onChange: () => {
                        selectedLangIDRef.current = langID;
                      },
                    }}
                    label={labelContent}
                    id={`lang-${langID}`}
                    name={`langs.${langID}`}
                    externalClasses={{
                      wrapCheckbox: styles['wrap-lang-checkbox'],
                    }}
                    disabled={checkDisabledLang(langID) || isButtonsDisabled}
                    defaultChecked={!!isActive}
                  />
                </ProhibitInteractivity>
              </div>
            );
          })}
        </div>
      </form>
    </section>
  );
}

export default SectionLanguages;