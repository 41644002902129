import { selectActiveLangIDsSortedByPriority } from '@/store/selectors/admin/superset/projects';

const getStoreContextSuperset = (state) => state.admin.superset.addingQuestions;

export const selectAnswers = (state) => (
  getStoreContextSuperset(state).answers
);

export const selectAnswersForPriorityLang = (state) => {
  const answersForAllLangs = selectAnswers(state);
  const activeLangIDsByPriority = selectActiveLangIDsSortedByPriority(state);
  let answersForCurrentPriorityLang = null;

  if (!answersForAllLangs || !activeLangIDsByPriority) return null;

  activeLangIDsByPriority.every((langID) => {
    if (langID in answersForAllLangs) {
      answersForCurrentPriorityLang = answersForAllLangs[langID];
      return false;
    }

    return true;
  });

  return answersForCurrentPriorityLang;
};

export const selectAddingQuestions = (state) => (
  getStoreContextSuperset(state).questions
);