// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".select-superset-tabs--TmlAH{width:100%;margin-bottom:20px}.select-superset-tabs--TmlAH .select-superset-labels--kGmgo .select-superset-label--bjpkx{background:var(--admin-btn-bg)}.select-superset-tabs--TmlAH .select-superset-labels--kGmgo .select-superset-label--bjpkx:not(:last-child){border-right:2px solid var(--admin-filter-projects-border)}.select-superset-tabs--TmlAH .select-superset-labels--kGmgo .select-superset-label--bjpkx .label-btn--HbMhW{color:var(--admin-btn-color);padding:0.9375rem 1.5625rem}.select-superset-tabs--TmlAH .select-superset-labels--kGmgo .select-superset-label--bjpkx.active-label--tvwXV,.select-superset-tabs--TmlAH .select-superset-labels--kGmgo .select-superset-label--bjpkx:hover{background-color:#2279fc}.select-superset-tabs--TmlAH .select-superset-labels--kGmgo .select-superset-label--bjpkx.active-label--tvwXV .label-btn--HbMhW,.select-superset-tabs--TmlAH .select-superset-labels--kGmgo .select-superset-label--bjpkx:hover .label-btn--HbMhW{color:#fff}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/ListSupersets/FilterSupersets/FilterSupersets.module.sass"],"names":[],"mappings":"AAEA,6BACE,UAAA,CACA,kBAAA,CAEE,0FACE,8BAAA,CACA,2GACE,0DAAA,CACF,4GACE,4BAAA,CACA,2BAAA,CACF,8MACE,wBAAA,CACA,kPACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select-superset-tabs": "select-superset-tabs--TmlAH",
	"select-superset-labels": "select-superset-labels--kGmgo",
	"select-superset-label": "select-superset-label--bjpkx",
	"label-btn": "label-btn--HbMhW",
	"active-label": "active-label--tvwXV"
};
export default ___CSS_LOADER_EXPORT___;
