import React, { useState } from 'react';
import OpenSidebarButton from './OpenSidebarButton/OpenSidebarButton';
import superAdminRoutes from '../SuperAdmin/routes';
import SideBar from '../Sidebar';

export default function SuperAdminSidebar() {
  const [isOpen, toggleOpen] = useState<boolean>(false);

  const closeSidebar = () => {
    toggleOpen(false);
  };

  return (
    <SideBar
      links={[...superAdminRoutes, { url: 'admin/projects', name: 'Список проектов' }]}
      isOpen={isOpen}
      closeSidebar={closeSidebar}
    >
      <OpenSidebarButton toggleOpen={toggleOpen} />
    </SideBar>
  );
}