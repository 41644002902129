import { createSlice } from '@reduxjs/toolkit';

import {
  clearCutsData,
  loadActorsAmount,
  loadAllDataCuts,
  loadCutsOptions,
  setSelectedCriteria,
} from '@/store/actions/admin/superset/process/cuts/cuts';

const initialState = {
  hasCutsReceived: false,
  hasCriteriaReceived: false,
  criteria: [],
  selectedCriteria: {},
  amountsActorsOptions: {},
};

// general data for all type of cuts;
const cutsSlice = createSlice({
  name: 'admin/superset/process/cuts',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(loadAllDataCuts.fulfilled, (state) => {
        state.hasCutsReceived = true;
      })
      .addCase(loadCutsOptions.fulfilled, (state, { payload }) => {
        state.hasCriteriaReceived = true;
        const { cutCriterios } = payload;
        state.criteria = cutCriterios || [];
      })
      .addCase(loadActorsAmount.fulfilled, (state, { payload }) => {
        state.amountsActorsOptions = payload;
      })
      .addCase(setSelectedCriteria, (state, { payload: selectedCriteria }) => {
        state.selectedCriteria = selectedCriteria;
      })
      .addCase(clearCutsData, () => initialState);
  },
});

export default cutsSlice.reducer;