import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ResultLink.module.sass';
import { copyStrToClipboard, projectLang } from '@/helpers/helpers';
import { addNotification } from '@/store/reducers/notify';
import useTheme from '@/theme/useTheme';

export default function ResultLink() {
  const dispatch = useDispatch();
  const actor = useSelector((state) => state.user.actor);
  const lang = projectLang(actor);
  const { theme } = useTheme();
  const isLight = theme === 'light' ? 1 : 0;
  const { code } = actor.profile;
  const copyLinkToClipboard = (link) => {
    copyStrToClipboard(link);
    dispatch(
      addNotification({
        type: 'success',
        title: 'The link has been copied to the clipboard',
      }),
    );
  };
  // todo получать с бэка ссылку
  let link = `${window.location.protocol}//${window.location.host}/result/${code}/${isLight}/${lang}`;
  link = link.replaceAll(/\/finish\/lang\/([a-z]){2}/gm, '');
  return (
    <span
      onClick={() => copyLinkToClipboard(link)}
      role="button"
      onKeyDown={() => {}}
      tabIndex={0}
      className={styles['invite-link']}
    >
      {link}
    </span>
  );
}