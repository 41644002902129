// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mask--qlU2O{position:relative;min-height:30px;top:24px;background-color:hsla(0,0%,100%,0);border-radius:30px 30px 0 0;border-left:10px solid var(--main-bg);border-right:10px solid var(--main-bg);border-top:10px solid var(--main-bg);margin:0 auto;max-width:88.75rem;width:calc(95% + 20px);z-index:3}@media screen and (max-width: 768px){.mask--qlU2O{display:none}}.progress--MFLvW{position:sticky;opacity:1;z-index:1;top:calc(var(--header-height) - 4px)}@media screen and (max-width: 768px){.progress--MFLvW{top:0}}", "",{"version":3,"sources":["webpack://./src/components/Common/BaseTemplate/ProgressContainer/ProgressContainer.module.sass"],"names":[],"mappings":"AAEA,aACE,iBAAA,CACA,eAAA,CACA,QAAA,CACA,kCAAA,CAEA,2BAAA,CACA,qCAAA,CACA,sCAAA,CACA,oCAAA,CACA,aAAA,CACA,kBAAA,CAGA,sBAAA,CACA,SAAA,CACA,qCAhBF,aAiBI,YAAA,CAAA,CACJ,iBACE,eAAA,CACA,SAAA,CACA,SAAA,CACA,oCAAA,CACA,qCALF,iBAMI,KAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mask": "mask--qlU2O",
	"progress": "progress--MFLvW"
};
export default ___CSS_LOADER_EXPORT___;
