import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './GeneralLink.module.sass';
import CopyContentBtn from '@/components/Common/CopyContentBtn';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { getProjectLink } from '@/helpers/helpers';

function GeneralLink({ isDemo }) {
  const tNLS = useNLS();
  const generalLinkStartSurveyRef = useRef();
  const { webId } = useSelector(selectSuperset);

  return (
    <div className={styles['wrap-general-link']}>
      <div className={styles['block-with-link']}>
        <span className="title-link">
          {isDemo ? tNLS('respondent.demomode.link') : tNLS('respondent.link')}
        </span>
        <br />
        <a
          ref={generalLinkStartSurveyRef}
          className={styles['link-to-start-survey']}
          target="_blank"
          href={getProjectLink(webId, isDemo)}
          rel="noreferrer"
        >
          {getProjectLink(webId, isDemo)}
        </a>
      </div>
      <CopyContentBtn
        externalClasses={{ wrapBtn: styles['btn-copy-link'] }}
        ref={generalLinkStartSurveyRef}
        strToCopy={getProjectLink(webId, isDemo)}
      />
    </div>
  );
}

GeneralLink.propTypes = {
  isDemo: PropTypes.bool,
};

GeneralLink.defaultProps = {
  isDemo: false,
};

export default GeneralLink;