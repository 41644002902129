// @ts-nocheck
// todo доделать типы

// Элементы объекта "units" - объекты со следующей структурой:

// level* - level, уровень unit'а относительно корневого элемента дерева
// (0 - для корневых элементов,
// 1 - для дочерних узлов корневых элементов и т.д.)

// name* - имя человека или название департамента
// parent - ID родительского unit'а
// children - массив дочерних unit'ов
// isunit* - признак департамента: 0 - человек, 1 - департамент
// isobject* - признак объекта оценки: 0 - не оценивается, 1 - оценивается
// visible* - признак видимости:
// 0 - unit не отображается (находится в нераскрытой части дерева), 1 - отображается
// exp - признак раскрытия дочерних unit'ов:
// 0 - collapsed (дочерние unit'ы скрыты), 1 - expanded (дочерние unit'ы раскрыты)
// distance* - дистанция узла от целевого узла на дереве

// * - отмечены поля, которые есть у любого unit'а,
// остальные поля могут отсутствовать
// (например "exp" задано тольео для unit'в, у которых есть дочерние unit'ы)

import { UnitId } from '@/store/types/user/units';
import { BackendUnit, TreeUnits } from '@/store/types/admin/superset/unitsTree';

export default function parseUnits(
  uList: BackendUnit[],
  nVisibleUnits: number | null = 0,
  targetId: string | null = '0',
): { roots: UnitId[], order: UnitId[], units: TreeUnits } {
  let roots;
  const order: UnitId[] = [];
  const units = {};
  const infdistance = 1000000000;

  let i;
  let j;

  const nulist = uList.length;
  const ids = {};
  for (i = 0; i < nulist; i += 1) {
    ids[
      (uList[i].d != null && uList[i].d.length > 0
        ? `${uList[i].d.join('|')}|`
        : '') + uList[i].n
    ] = uList[i].id;
  }

  let branch;
  const tree = {};
  let rootdistance = 0;

  let s;
  let id;
  let nd;
  let next = -1;
  for (i = 0; i < nulist; i += 1) {
    id = uList[i].id;
    s = '';
    nd = 0;
    branch = tree;
    if (uList[i].d != null && uList[i].d && uList[i].d.length > 0) {
      nd = uList[i].d.length;

      for (j = 0; j < nd; j += 1) {
        if (j > 0) {
          s += '|';
        }
        s += uList[i].d[j];

        if (!ids[s]) {
          ids[s] = `${next}`;
          next -= 1;
        }

        if (!branch[ids[s]]) {
          branch[ids[s]] = {};
        }

        branch = branch[ids[s]];

        if (!units[ids[s]]) {
          units[ids[s]] = {
            name: uList[i].d[j],
            level: j,
            isunit: 1,
            distance: infdistance,
            isobject: 0,
          };
        }

        if (id === targetId && units[ids[s]].distance > nd - j) {
          units[ids[s]].distance = nd - j;
        }
      }
    }

    if (!branch[id]) {
      branch[id] = {};
    }
    if (!units[id]) {
      units[id] = {
        name: uList[i].n,
        level: nd,
        isunit: uList[i].u,
        distance: infdistance,
        order: uList[i].o,
        isEstimated: uList[i].isEstimated,
        isEstimator: uList[i].isEstimator,
        secret: uList[i].secret,
      };
      if (units[id].isunit === 0) {
        units[id].selected = uList[i].selected;
        units[id].hasFinished = uList[i].hasFinished;
        units[id].hasStarted = uList[i].hasStarted;
        units[id].email = uList[i].email;
        units[id].jobTitle = uList[i].jobTitle;
        units[id].spec = uList[i]?.spec
          ? uList[i].spec.filter((spec) => !!spec)
          : [];
        units[id].isRating = {};
        units[id].isRatedBy = {};
      }
    }

    units[id].isobject = 1;

    if (id === targetId && units[id].distance > 0) {
      units[id].distance = 0;
      rootdistance = nd + 1;
    }
  }

  let maxdistance = 0;

  // eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
  const build = (node, id, level, distance) => {
    const children = Object.keys(node).sort((a, b) => {
      if (units[a].isunit < units[b].isunit) {
        return -1;
      }
      if (units[a].isunit > units[b].isunit) {
        return 1;
      }
      if (units[a].name < units[b].name) {
        return -1;
      }
      if (units[a].name > units[b].name) {
        return 1;
      }
      return 0;
    });

    const nch = children.length;
    // eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
    let i;
    let cid;

    if (nch > 0) {
      if (level > 0) {
        units[id].children = children;
      } else {
        roots = children;
      }
    }

    if (level > 0) {
      if (units[id].distance > distance) {
        units[id].distance = distance;
      } else {
        distance = units[id].distance;
      }
    }

    if (distance > maxdistance) {
      maxdistance = distance;
    }

    for (i = 0; i < nch; i += 1) {
      cid = children[i];
      units[cid].level = level;
      if (level > 0) {
        units[cid].parent = id;
      }
      order.push(cid);
      build(node[cid], cid, level + 1, distance + 1);
    }
  };
  build(tree, 0, 0, rootdistance);

  // eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
  const expand = (id, distance, level) => {
    // eslint-disable-next-line no-shadow
    let nvch;
    // eslint-disable-next-line
    let i;
    let nch = 0;

    if (units[id].children) {
      nch = units[id].children.length;
    }

    nvch = 0;
    for (i = 0; i < nch; i += 1) {
      nvch += expand(units[id].children[i], distance, level + 1);
    }

    if (nvch) {
      units[id].visible = 1;
      units[id].exp = 1;
      nvch += 1;
    } else {
      if (nch) {
        units[id].exp = 0;
      }

      if (units[id].distance <= distance || level === 0) {
        units[id].visible = 1;
        nvch += 1;
      } else {
        units[id].visible = 0;
      }
    }

    return nvch;
  };

  let nv;
  const nroots = roots.length;
  let lastv = 0;
  i = 1;
  do {
    i += 1;
    nv = 0;
    for (j = 0; j < nroots; j += 1) {
      nv += expand(roots[j], i, 0);
    }

    if (nv === nVisibleUnits) {
      break;
    }

    if (nv < nVisibleUnits) {
      lastv = nv;
    } else {
      if (lastv === 0) {
        break;
      }
      if (nVisibleUnits - lastv > nv - nVisibleUnits) {
        break;
      }
      for (j = 0; j < nroots; j += 1) {
        expand(roots[j], i - 1, 0);
      }
      break;
    }
  } while (i <= maxdistance);

  for (let f = 0; f < uList.length; f += 1) {
    if (uList[f].d && uList[f].d.length > 0) {
      units[uList[f].id].departments = uList[f].d;
    }
  }

  return { roots, order, units };
}