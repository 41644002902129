import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styles from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitsSearch/BUnitsSearch.module.sass';
import { isOneStringEnterAnother } from '@/helpers/helpers';
import { selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';

function BUnitSearchList({ getSearchingUnitLocation, value }) {
  const { units, order } = useSelector(selectUnitsTreeData);
  const [listItems, setListItems] = useState([]);

  const validateResults = useCallback(
    () => order
      .filter(
        (id) => isOneStringEnterAnother(units[id].name, value)
            || isOneStringEnterAnother(units[id].email, value)
            || isOneStringEnterAnother(units[id].jobTitle, value),
      )
      .map((id) => ({
        id,
        name: units[id].name,
        isunit: units[id].isunit,
      })),
    [order, units, value],
  );

  useEffect(() => {
    setListItems(validateResults());
  }, [validateResults, value]);

  return (
    <div className={styles['dropdown-items']}>
      {listItems.length > 0 && (
        <ul className={styles['dropdown-list']}>
          {listItems.map((listItem) => (
            <li key={listItem.id} className={styles['dropdown-item']}>
              <button
                type="button"
                className={`${styles['dropdown-item-button']} ${
                  listItem.isunit === 0 && styles.unit
                }`}
                onClick={() => getSearchingUnitLocation(listItem.id)}
              >
                {listItem.name}
              </button>
              {/* <div className={styles['unit-path']}> */}
              {/*  {listItem.departments */}
              {/*      && <span>{parse(listItem.departments)}</span>} */}
              {/* </div> */}
            </li>
          ))}
        </ul>
      )}
      {listItems.length === 0 && (
        <div className={styles['dropdown-item-notfound']}>&mdash;</div>
      )}
    </div>
  );
}

BUnitSearchList.propTypes = {
  getSearchingUnitLocation: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default BUnitSearchList;