import React from 'react';
import PropTypes from 'prop-types';
import styles from './BUnitCardContentContainer.module.sass';

export default function BUnitCardContentContainer({ children }) {
  return <div className={styles['unit-menu-container']}>{children}</div>;
}

BUnitCardContentContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};