import { Dispatch, SetStateAction, useState } from 'react';
import useLocalStorageByAdmin from '@/components/App/Admin/BUnitsSection/UnitsTree/hooks/adminLocalStorageSaver';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { getSelectActionByType } from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/helpers';

export enum UnitsSelectActionKeysByType {
  Select = 'tree.select',
  Unselect = 'tree.unselect',
}

export interface BUnitSelectAction {
  key: UnitsSelectActionKeysByType;
  name: string
}

export type SetCurrentSelectActionType = Dispatch<SetStateAction<BUnitSelectAction>>;
const useSelectAction = (): [BUnitSelectAction, SetCurrentSelectActionType] => {
  const [, readFromLocalStorage] = useLocalStorageByAdmin<BUnitSelectAction>('selectActions');
  const tNLS = useNLS();
  const [currentSelectAction, setCurrentSelectAction] = useState<BUnitSelectAction>(
    () => readFromLocalStorage() || getSelectActionByType('Select', tNLS),
  );

  return [currentSelectAction, setCurrentSelectAction];
};

export default useSelectAction;