// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".unit-tabs-menu--jAyAx{display:flex;position:relative;left:1px}.unit-menu-tab--jv-7P{position:relative;height:30px}.unit-menu-tab--jv-7P .menu-tab-btn--5wj64{background:var(--tab-bg);color:var(--tab-color);border-radius:12px 12px 0 0;border-top:1px solid var(--admin-hover-background-interactive-elements);border-right:1px solid var(--admin-hover-background-interactive-elements);padding:4px 24px;height:100%}.unit-menu-tab--jv-7P .menu-tab-btn--5wj64 .tab-text--Drbqu{height:100%;padding:2px 0 0 0;font-size:14px}.unit-menu-tab--jv-7P .menu-tab-btn--5wj64.active--uqni-,.unit-menu-tab--jv-7P .menu-tab-btn--5wj64:hover{background:var(--tab-active-bg)}.unit-menu-tab--jv-7P:first-child .menu-tab-btn--5wj64{height:40px}.unit-menu-tab--jv-7P:last-child .menu-tab-btn--5wj64{height:40px}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardTabs/BUnitCardTabs.module.sass"],"names":[],"mappings":"AAAA,uBACE,YAAA,CACA,iBAAA,CACA,QAAA,CAEF,sBACE,iBAAA,CACA,WAAA,CACA,2CACE,wBAAA,CACA,sBAAA,CACA,2BAAA,CACA,uEAAA,CACA,yEAAA,CACA,gBAAA,CACA,WAAA,CACA,4DACE,WAAA,CACA,iBAAA,CACA,cAAA,CACF,0GAEE,+BAAA,CAEF,uDACE,WAAA,CAEF,sDACE,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unit-tabs-menu": "unit-tabs-menu--jAyAx",
	"unit-menu-tab": "unit-menu-tab--jv-7P",
	"menu-tab-btn": "menu-tab-btn--5wj64",
	"tab-text": "tab-text--Drbqu",
	"active": "active--uqni-"
};
export default ___CSS_LOADER_EXPORT___;
