import {
  CAN_COPY_SUPERSET,
  CREATOR,
  OPTIONS_WHEN_UNITS_ARE_USED,
  USE_UNITS_SETTING_ID,
} from '@/constants/admin';
import {
  selectActiveLanguagesMasterSuperset,
  selectCurrentLangID,
} from '@/store/selectors/admin/superset/projects';
import { selectAdminSettingsValue } from '@/store/selectors/admin/superset/settings';

const getStoreContextSuperset = (state) => state.admin.superset;

const sortAvailableSupersetsByCreatedAt = (availableSupersets) => availableSupersets.sort(
  ({ createdAt: currentSupersetDate }, { createdAt: nextSupersetDate }) => {
    const convertFormatDate = (dateStr) => dateStr.split('.').reverse().join('-');

    const dCurrent = new Date(convertFormatDate(currentSupersetDate));
    const dNext = new Date(convertFormatDate(nextSupersetDate));

    return dCurrent > dNext ? -1 : 1;
  },
);

export const selectSupersets = (state) => state.admin.supersets.supersets;

export const selectSupersetsLoadingStatus = (state) => state.admin.supersets.status;

export const selectAvailableSupersets = (state) => {
  const { supersets } = state.admin.supersets;
  const availableSupersets = [];
  Object.keys(supersets).forEach((supersetID) => {
    const { role } = supersets[supersetID];
    if (role && !(role[0] === CAN_COPY_SUPERSET)) {
      availableSupersets.push({
        ...supersets[supersetID],
        id: supersetID,
      });
    }
  });

  return sortAvailableSupersetsByCreatedAt(availableSupersets);
};

export const selectAllAdminsSupersets = (state) => {
  const { allAdminsSupersets } = state.admin.supersets;

  return sortAvailableSupersetsByCreatedAt(
    Object.keys(allAdminsSupersets).map((supersetId) => ({
      ...allAdminsSupersets[supersetId],
      id: supersetId,
    })),
  );
};

export const selectAvailableTemplatesForCopy = (state) => {
  const { supersets } = state.admin.supersets;
  const { isSuper } = state.admin.authentication.admin;

  const availableSupersets = [];

  Object.keys(supersets).forEach((supersetID) => {
    const { role, description: descriptionLangs } = supersets[supersetID];

    let langID = 'en';

    if (!(langID in descriptionLangs)) {
      [langID] = Object.keys(descriptionLangs);
    }

    if (
      (role && (role.includes(CAN_COPY_SUPERSET) || role.includes(CREATOR)))
      || isSuper
    ) {
      availableSupersets.push({
        ...supersets[supersetID],
        description: descriptionLangs[langID],
        id: supersetID,
      });
    }
  });

  return sortAvailableSupersetsByCreatedAt(availableSupersets);
};

// superset
export const selectLoadedStatusAllSupersetData = (state) => getStoreContextSuperset(state).supersetFields.received;

export const selectSuperset = (state) => state.admin.superset.supersetFields.superset;

export const selectAllFields = (state) => state.admin.superset.supersetFields.fields;

export const selectSelectedFieldIDs = (state) => state.admin.superset.supersetFields.superset.fields;

const selectNumberUnits = (state) => state.admin.superset.units.length;

// TODO перенести в superset/projects
export const selectSumProjectMetrics = (state) => {
  const activeLangs = selectActiveLanguagesMasterSuperset(state);
  const optionUnits = selectAdminSettingsValue(state, [USE_UNITS_SETTING_ID]);
  const areUnitsUsed = !!OPTIONS_WHEN_UNITS_ARE_USED[optionUnits?.value];
  const { blocks } = state.admin.superset;
  const projectMetrics = {};

  Object.keys(activeLangs).forEach((activeLangID) => {
    projectMetrics[activeLangID] = [0, 0];
  });

  Object.keys(blocks).forEach((blockID) => {
    const { isActive, langs: blockLangs } = blocks[blockID];

    if (!isActive) {
      return;
    }

    Object.keys(blockLangs).forEach((langID) => {
      const { metrics } = blockLangs[langID];

      if (!(langID in projectMetrics)) {
        return;
      }
      const { minSeconds, maxSeconds } = metrics;
      const [prevMin, prevMax] = projectMetrics[langID];

      const currentMin = prevMin + minSeconds;
      const currentMax = prevMax + maxSeconds;

      projectMetrics[langID] = [currentMin, currentMax];

      if (areUnitsUsed) {
        const numberUnits = selectNumberUnits(state);
        const { minUnitSeconds, maxUnitSeconds } = metrics;

        projectMetrics[langID] = [
          currentMin + minUnitSeconds * numberUnits,
          currentMax + maxUnitSeconds * numberUnits,
        ];
      }
    });
  });

  // in seconds
  // return {
  //   en: [min, max],
  //   ru: [min, max],
  // }

  return projectMetrics;
};

export const selectAdmins = (state) => {
  const { admins } = state.admin.superset.supersetFields;

  const { adminId: currentAdminID } = state.admin.authentication.admin;
  const adminsWithoutCurrent = {};

  Object.keys(admins).forEach((adminID) => {
    if (currentAdminID !== adminID) {
      adminsWithoutCurrent[adminID] = admins[adminID];
    }
  });

  return adminsWithoutCurrent;
};

export const selectUnits = (state) => [...state.admin.superset.supersetFields.units].sort((unitA, unitB) => {
  if (unitA.norder > unitB.norder) return 1;
  return -1;
});

export const selectSupersetTickets = (state) => state.admin.superset.supersetFields.superset.ticketCounter;

export const selectMailTasks = (state) => state.admin.superset.supersetFields.mailTasks;

export const selectNLS = (state) => {
  const currentLangID = selectCurrentLangID(state);

  const defaultNls = {
    'template.file.select':
      'Select a file with a questionnaire to upload to the project',
    'template.has.loaded': 'Project template loaded',
    'template.upload.cancel': 'Reset upload',
    'template.upload.confirm': 'Upload template',
    'template.upload': 'Uploading the Questionnaire',
    'template.upload.error': 'An error occurred while loading:',
    'template.upload.error.cancel': 'Reset upload',
    'template.upload.success': 'The template has been successfully validated!',
    'template.upload.warnings': 'The template has been successfully validated!',
    'template.upload.warnings.col': 'Column:',
    'template.upload.warnings.descr':
      'The template passed the test, but minor errors were identified:',
    'template.upload.warnings.page': 'Page:',
    'template.upload.warnings.row': 'Line:',
    'alert.processor.running': 'Results processing initiated',
    'alert.processor.done': 'Results processing completed',
    'no.access.rights':
      'Access denied, you do not have enough rights to perform the operation in the current project',
  };

  const nlsForLang = Object.keys(
    getStoreContextSuperset(state).supersetFields.superset,
  ).length
    ? getStoreContextSuperset(state).supersetFields.superset.nls[currentLangID]
    : defaultNls;

  try {
    if (!nlsForLang) {
      throw new Error(
        `no nls was found for the current language: ${currentLangID}`,
      );
    }
  } catch (err) {
    return {};
  }

  return nlsForLang;
};

export const selectTemplatesForDownload = (state) => {
  const { files } = state.admin.superset.supersetFields;
  const availableTemplates = [];
  if (!files) return [];
  const { templates, data360 } = files;
  if (templates && Object.keys(templates).length) {
    Object.keys(templates).forEach((langId) => {
      templates[langId].forEach((template) => {
        const {
          filename: fileName,
          modifyDate,
          version,
          uploadComment: comment,
        } = template;
        availableTemplates.push({
          fileName,
          fileLang: langId,
          modifyDate,
          version,
          comment,
        });
      });
    });
  }

  return { availableTemplates, data360File: data360 };
};