import React, { useEffect } from 'react';
import {
  Navigate, Outlet, useLocation, useParams,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Preloader from '@/components/App/Admin/SupersetSettings/Preloader';
import { clearCutsData } from '@/store/actions/admin/superset/process/cuts/cuts';
import WrapperCuts from '@/components/App/Admin/Results/Cuts/WrapperCuts';
import {
  useFetchCuts,
  useGetDefaultItemID,
  useGetDefaultTypeOfCuts,
} from '@/components/App/Admin/Results/Cuts/InitCuts/hooks';

function InitCuts() {
  const dispatch = useDispatch();
  const cutsPage = <Outlet />;
  const { pathname } = useLocation();
  const { typeCuts, itemID } = useParams();
  const hasCutsReceived = useFetchCuts();
  // if there's no typeCuts and itemID --> hooks return null;
  const defaultTypeCuts = useGetDefaultTypeOfCuts(hasCutsReceived, typeCuts);
  const defaultItemID = useGetDefaultItemID(
    hasCutsReceived,
    defaultTypeCuts,
    itemID,
  );

  useEffect(
    () => () => {
      dispatch(clearCutsData());
    },
    [dispatch],
  );

  if (!typeCuts && defaultTypeCuts && defaultItemID) {
    return (
      <Navigate
        to={`${pathname}/${defaultTypeCuts}/${defaultItemID}`}
        replace
      />
    );
  }

  if (hasCutsReceived && typeCuts && itemID) {
    return <WrapperCuts>{cutsPage}</WrapperCuts>;
  }

  return <Preloader />;
}

export default InitCuts;