import React, { useEffect, useRef, useState } from 'react';
import styles from './Diagram.module.sass';
import CirclesArea from '@/components/App/Admin/Results/Cuts/FourD/Diagram/CirclesArea/CirclesArea';
import Axes from '@/components/App/Admin/Results/Cuts/FourD/Diagram/Axes';

function Diagram() {
  const SVGContainerRef = useRef(null);
  const [svgContainerWidth, setSVGContainerWidth] = useState(0);

  useEffect(() => {
    if (SVGContainerRef.current) {
      setSVGContainerWidth(SVGContainerRef.current.clientWidth);
    }
  }, []);

  return (
    <div className={styles['diagram-container']}>
      <svg
        ref={SVGContainerRef}
        className={styles.graph}
      >
        <Axes svgContainerWidth={svgContainerWidth} />

        {svgContainerWidth > 0 && (
        <CirclesArea svgContainerWidth={svgContainerWidth} />
        )}
      </svg>

    </div>
  );
}

export default Diagram;