import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './ItemsPlaceholder.module.sass';
import BUnitsMenuBtn from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitsBlueBtn/BUnitsMenuBtn';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { fillEmptyItems } from '@/store/actions/admin';
import { selectAdmin } from '@/store/selectors/admin/authentication';

function FillEmptyItemsSection() {
  const [textValue, setTextValue] = useState('');
  const dispatch = useDispatch();
  const { isSuper } = useSelector(selectAdmin);
  const { supersetId } = useSelector(selectSuperset);
  const textAreaRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuper) return;
    navigate(`/admin/project/${supersetId}`);
  });

  useEffect(() => {
    if (!textAreaRef.current) return;

    const initialHeight = parseFloat(
      window.getComputedStyle(textAreaRef.current).height,
    );
    const newHeight = Math.max(textAreaRef.current.scrollHeight, initialHeight);
    textAreaRef.current.style.height = `${newHeight}px`;
  }, [textValue]);

  const handleButtonClick = () => {
    const value = textValue
      .replace(' ', '')
      .split(',')
      .filter((item) => item);
    dispatch(fillEmptyItems({ supersetId, items: value }));
  };

  return (
    <section className={styles['magic-section']}>
      <textarea
        className={styles['items-placeholder']}
        placeholder="Enter items id"
        ref={textAreaRef}
        onKeyUp={(event) => {
          setTextValue(event.target.value);
        }}
      />
      <BUnitsMenuBtn
        handleButtonClick={handleButtonClick}
        additionalClassNames={styles['blue-button']}
      >
        Fill protocols
      </BUnitsMenuBtn>
    </section>
  );
}

export default FillEmptyItemsSection;