import {
  ABSOLUTE,
  PERCENTILE,
} from '@/components/App/Admin/Results/Cuts/constants';

const getStoreContext = (state) => state.admin.superset.process.cuts.factorsCuts;

export const selectHasFactorsCuts = (state) => !!Object.keys(getStoreContext(state).cuts).length;

export const selectFactorsCuts = (state) => getStoreContext(state).cuts;

export const selectFactorsCutsByItemID = (state, fsIndex) => {
  const factorsCuts = selectFactorsCuts(state);

  if (factorsCuts.length === 0 || !factorsCuts[fsIndex]) {
    return null;
  }

  return factorsCuts[fsIndex];
};

export const selectActiveTypeFS = (state) => getStoreContext(state).typeFS;

export const selectDefaultTypeFS = (state, fsIndx) => {
  const { absoluteFactors } = selectFactorsCutsByItemID(state, fsIndx);

  if (absoluteFactors) {
    return ABSOLUTE;
  }

  return PERCENTILE;
};

export const selectAvailableTypeFSBasedOnActiveTypeFS = (state, fsIndx) => {
  if (fsIndx === null) {
    return null;
  }

  const typeFS = selectActiveTypeFS(state);
  const { percentileFactors, absoluteFactors } = selectFactorsCutsByItemID(
    state,
    fsIndx,
  );

  if (typeFS === PERCENTILE && percentileFactors) {
    return PERCENTILE;
  }

  if (typeFS === PERCENTILE && !percentileFactors) {
    return ABSOLUTE;
  }

  if (typeFS === ABSOLUTE && absoluteFactors) {
    return ABSOLUTE;
  }

  if (typeFS === ABSOLUTE && !absoluteFactors) {
    return PERCENTILE;
  }

  return null;
};

export const selectAreThereSeveralTypesOfFS = (state, fsIndx) => {
  const { percentileFactors, absoluteFactors } = selectFactorsCutsByItemID(
    state,
    fsIndx,
  );

  if (percentileFactors && absoluteFactors) {
    return true;
  }

  return false;
};

export const selectDataForPercentileFS = (state, fsIndx) => {
  const { factorsName, percentileFactors } = selectFactorsCutsByItemID(
    state,
    fsIndx,
  );

  return {
    factorsName,
    percentileFactors,
  };
};

export const selectDataForAbsoluteFS = (state, fsIndx) => {
  const {
    factorsName, absoluteFactors, nameAnswerOptions, answersCodes,
  } = selectFactorsCutsByItemID(state, fsIndx);

  return {
    factorsName,
    absoluteFactors,
    nameAnswerOptions,
    answersCodes,
  };
};

export const selectHasFSWithLowPoles = (state) => {
  const fStructures = selectFactorsCuts(state);

  return fStructures.some(({ factorsName }) => factorsName.some(({ leftPole }) => leftPole !== ''));
};