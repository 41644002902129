import { AdminState } from '@/store/admin';

export const selectSelectedUnits = (state: AdminState) => state.admin.superset.unitsTree.selectedUnits;

export const selectUnitsTreeData = (state: AdminState) => {
  const { order, units, roots } = state.admin.superset.unitsTree;
  return { order, units, roots };
};

export const selectUnitFromTree = (state: AdminState, id: string) => {
  const { units } = state.admin.superset.unitsTree;
  return units[id];
};

export const selectUnitsSpecs = (state: AdminState) => {
  const { units } = state.admin.superset.unitsTree;
  const specs = new Set();
  Object.keys(units).forEach((id) => {
    if (!units[id].spec) return;
    const { spec } = units[id];
    for (let i = 0; i < spec.length; i += 1) {
      specs.add(spec[i]);
    }
  });
  return Array.from(specs);
};

export const selectUnitsArray = (state: AdminState) => {
  const { unitsArray } = state.admin.superset.unitsTree;

  return unitsArray;
};