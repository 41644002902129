import React, { useEffect, useRef } from 'react';
import styles from './BUnitSpecInput.module.sass';
import { getClearClassNames } from '@/helpers/helpers';
import {
  SpecType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitSpec/BUnitSpec';

interface BUnitSpecProps {
  spec?: string;
  isNew: boolean;
  toggleDropdown: (arg: boolean) => void;
  setSpecValue: (arg: string) => void
  isDisable?: boolean;
  type?: SpecType;
}

function BUnitSpec({
  spec = '',
  isNew = false,
  toggleDropdown,
  setSpecValue,
  isDisable = false,
  type = SpecType.Current,
}: BUnitSpecProps) {
  const specRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!specRef.current) return;
    const input = specRef.current;
    input.style.width = '0';
    input.style.width = `${input.scrollWidth}px`;
    if (isNew) input.focus();
  }, [isNew, spec]);

  const controlClassNames = () => getClearClassNames([
    styles['spec-input'],
    type && styles[type],
  ]);

  if (isDisable) {
    return (
      <div className={controlClassNames()}>
        <span>{spec}</span>
      </div>
    );
  }

  return (
    <input
      className={controlClassNames()}
      ref={specRef}
      type="text"
      value={spec}
      onFocus={() => toggleDropdown(true)}
      onChange={(event) => {
        toggleDropdown(true);
        const input = event.target;
        setSpecValue(input.value);
      }}
    />
  );
}

export default BUnitSpec;