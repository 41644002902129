import { createSlice } from '@reduxjs/toolkit';
import {
  createBUnit,
  deleteUnit,
  getRelatedUnits,
  getSupersetUnits,
  makeUnitsTree,
  selectTreeUnits,
  updateTreeUnits, updateUnitRoles,
} from '@/store/actions/admin/superset/unitsTree';
import { clearSuperset } from '@/store/actions/admin';
import parseUnits from '@/components/Common/UnitsTree/parseUnits';
import { TreeUnit, UnitsTreeInitialState } from '@/store/types/admin/superset/unitsTree';

const initialState: UnitsTreeInitialState = {
  unitsArray: [],
  units: {},
  order: [],
  selectedUnits: [],
  roots: [],
};

export const unitTreeSlice = createSlice({
  name: 'admin/superset/units',
  initialState,
  reducers: {
    makeUnitsTree: (state, { payload }) => {
      const { order, units, roots } = payload;
      state.units = units;
      state.order = order;
      state.roots = roots;
    },
    updateLocalUnitName: (state, { payload }) => {
      const { newName, id } = payload;
      state.units[id].name = newName;
    },
    transferUnits: (state, { payload }) => {
      const { targetDepartmentId, unitsData } = payload;
      // @ts-ignore
      const unitsId = unitsData.map(({ ID }) => ID);
      if (!targetDepartmentId) {
        if (unitsId.length === 1) {
          if (state.units[unitsId[0]].isunit === 1) {
            return;
          }
        }
        return;
      }
      unitsId.forEach((unitId: string) => {
        const oldParent = state.units[unitId].parent;
        state.units[unitId].parent = targetDepartmentId;
        if (!oldParent) return;
        state.units[oldParent].children = state.units[
          oldParent
        ].children.filter((id) => id !== unitId);
      });
      const newParentChildren = state.units[targetDepartmentId].children || [];
      state.units[targetDepartmentId].children = [
        ...newParentChildren,
        ...unitsId.filter((id: string) => !newParentChildren.includes(id)),
      ];
    },
    rightUnitInLocal: (state, { payload }) => {
      const {
        name, departments, targetParentId, unitId, isActor,
      } = payload;
      const minElement = Math.min(...state.order.map((id) => Number(id)));
      // @ts-ignore
      const newUnit: TreeUnit = {
        name,
        departments,
        level: departments ? departments.length : 0,
        isunit: isActor ? 0 : 1,
      };
      const newUnitId = unitId || `${minElement - 1}`;
      if (targetParentId) newUnit.parent = targetParentId;
      const parentChildren = state.units[targetParentId].children || [];
      const lastParentChildId = parentChildren.length
        ? parentChildren[parentChildren.length - 1]
        : '';
      const newDepartmentTargetIndex = lastParentChildId
        ? state.order.indexOf(lastParentChildId)
        : state.order.indexOf(targetParentId);
      state.units[targetParentId].children = [...parentChildren, newUnitId];
      state.units[newUnitId] = { ...newUnit };
      state.order = [
        ...state.order.slice(0, newDepartmentTargetIndex),
        newUnitId,
        ...state.order.slice(newDepartmentTargetIndex),
      ];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(makeUnitsTree, (state) => {
        const { order, units, roots } = parseUnits([...state.unitsArray]);
        // @ts-ignore
        state.units = units;
        state.order = order;
        state.roots = roots;
      })
      .addCase(getSupersetUnits.fulfilled, (state, { payload }) => {
        if (!payload) return;
        state.unitsArray = payload;
        // @ts-ignore
        state.selectedUnits = payload.flatMap(({ id, selected }) => {
          if (!selected) return [];
          return id;
        });
      })
      .addCase(selectTreeUnits.fulfilled, (state, { payload }) => {
        const { unitsData, isSelect } = payload;
        state.selectedUnits = isSelect
          ? [...state.selectedUnits, ...unitsData]
          : [...state.selectedUnits].filter((id) => !unitsData.includes(id));
        unitsData.forEach((id) => {
          // @ts-ignore
          state.units[id].selected = isSelect;
        });
      })
      .addCase(updateTreeUnits.fulfilled, (state, { payload }) => {
        payload.forEach((unitUpdateInfo) => {
          // @ts-ignore
          const id = unitUpdateInfo.ID;
          Object.keys(unitUpdateInfo).forEach((key) => {
            if (key === 'ID') return;
            // @ts-ignore
            state.units[id][key] = unitUpdateInfo[key];
          });
        });
      })
      .addCase(getRelatedUnits.fulfilled, (state, { payload }) => {
        const {
          roles, rolesList, unitId,
        } = payload;
        state.units[unitId].rolesList = rolesList;
        state.units[unitId].isRating = roles.estimator;
        state.units[unitId].isRatedBy = roles.estimated;
      })
      .addCase(clearSuperset, () => initialState)
      .addCase(createBUnit.fulfilled, (state, { payload }) => {
        const {
          id,
          // @ts-ignore
          n,
          // @ts-ignore
          d,
          isEstimated,
          isEstimator,
          secret,
          selected,
          hasStarted,
          hasFinished,
          // @ts-ignore
          u,
        } = payload;
        // todo как будет правка с бэка получать spec оттуда
        const newUnit = {
          isunit: u,
          departments: d,
          name: n,
          isEstimator,
          isEstimated,
          level: d ? d.length : 0,
          secret,
          exp: 0,
          // @ts-ignore
          spec: [],
          selected,
          hasStarted,
          hasFinished,
        };
        // @ts-ignore
        state.unitsArray = [...state.unitsArray, payload];
        // @ts-ignore
        state.units[id] = newUnit;
        if (newUnit.level === 0) state.roots = [...state.roots, id];
      })
      .addCase(deleteUnit.fulfilled, (state, { payload }) => {
        const { unitId } = payload;
        state.unitsArray = [...state.unitsArray].filter(
          ({ id }) => id !== unitId,
        );
        state.order = [...state.order].filter((id) => id !== unitId);
        const unit = state.units[unitId];
        if (unit.parent) {
          state.units[unit.parent].children = [
            // @ts-ignore
            ...state.units[unit.parent].children,
          ].filter((id) => unitId !== id);
        }
        delete state.units[unitId];
      })
      .addCase(updateUnitRoles.fulfilled, (state, { payload }) => {
        const { unitId, type, unitData } = payload;
        if (type === 'estimator') {
          state.units[unitId].isRating = { ...state.units[unitId].isRating, ...unitData };
        } else {
          state.units[unitId].isRatedBy = { ...state.units[unitId].isRatedBy, ...unitData };
        }
      });
  },
});

const { reducer } = unitTreeSlice;
export default reducer;