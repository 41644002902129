import React from 'react';
import useDownloadWebinarRegistersList from './hooks/downloadWebinarRegisters';
import styles from './WebinarsListHeader.module.sass';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';

export default function WebinarsListHeader() {
  const downloadWebinarsRegistersList = useDownloadWebinarRegistersList();

  return (
    <header className={styles['webinars-header']}>
      <h1>Список вебинаров</h1>
      <CustomButton
        onClick={downloadWebinarsRegistersList}
      >
        Скачать список регистраций на вебинары
      </CustomButton>
    </header>
  );
}