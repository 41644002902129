import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import CreateWebinarFormField from '../CreateWebinarFormField/CreateWebinarFormField';
import styles from './WebinarContentSection.module.sass';
import {
  CreateWebinarFieldsType, CreateWebinarFielsNamesEnum, CreateWebinarFormSectionType,
} from '../../types/webinarPageTypes';
import WebinarContenPreview from './WebinarContentPreview/WebinarContenPreview';
import { CreateWebinarSectionTitle } from '../../consts/createWebinarPageConsts';

interface WebinarContentSectionProps extends UseFormReturn<CreateWebinarFieldsType> {
  names: CreateWebinarFielsNamesEnum[];
  sectionType: CreateWebinarFormSectionType;
}

export default function WebinarContentSection({
  names,
  sectionType,
  ...formProps
}: WebinarContentSectionProps) {
  return (
    <section className={styles['form-section']}>
      <h2>{CreateWebinarSectionTitle[sectionType]}</h2>
      <div className={styles['section-content']}>
        <div className={styles['fields-container']}>
          {names.map((name) => (
            <CreateWebinarFormField
              key={name}
              name={name}
              {...formProps}
            />
          ))}
        </div>
        <div className={styles['content-preview']}>
          <WebinarContenPreview
            type={sectionType}
            {...formProps}
          />
        </div>
      </div>
    </section>
  );
}