import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  selectFirstItemByTypeCuts,
  selectStatusLoadCutsData,
} from '@/store/selectors/admin/superset/process/cuts/cuts';
import { loadAllDataCuts } from '@/store/actions/admin/superset/process/cuts/cuts';
import { selectQuestionsItems } from '@/store/selectors/admin/superset/process/cuts/questionsCuts';
import { selectUnitsItems } from '@/store/selectors/admin/superset/process/cuts/unitsCuts';
import { selectHasFactorsCuts } from '@/store/selectors/admin/superset/process/cuts/factorsCuts';
import {
  FACTORS_CUTS,
  QUESTIONS_CUTS,
  UNITS_CUTS,
} from '@/components/App/Admin/Results/Cuts/constants';

export const useFetchCuts = () => {
  const dispatch = useDispatch();
  const hasCutsReceived = useSelector(selectStatusLoadCutsData);

  useEffect(() => {
    dispatch(loadAllDataCuts());
  }, [dispatch]);

  return hasCutsReceived;
};

export const useGetDefaultTypeOfCuts = (hasCutsReceived, typeCuts) => {
  const hasQuestionsItems = useSelector(selectQuestionsItems).length > 0;
  const hasUnitsItems = useSelector(selectUnitsItems).length > 0;
  const hasFactorsCuts = useSelector(selectHasFactorsCuts);
  const [defaultTypeCuts, setDefaultTypeCuts] = useState(null);

  useEffect(() => {
    if (hasCutsReceived && !typeCuts) {
      if (hasQuestionsItems) {
        setDefaultTypeCuts(QUESTIONS_CUTS);
        return;
      }

      if (hasUnitsItems) {
        setDefaultTypeCuts(UNITS_CUTS);
        return;
      }

      if (hasFactorsCuts) {
        setDefaultTypeCuts(FACTORS_CUTS);
      }
    }
  }, [
    hasCutsReceived,
    hasQuestionsItems,
    hasUnitsItems,
    hasFactorsCuts,
    typeCuts,
  ]);

  return defaultTypeCuts;
};

export const useGetDefaultItemID = (hasCutsReceived, typeCuts, itemID) => {
  const firstItemByTypeCuts = useSelector((state) => selectFirstItemByTypeCuts(state, typeCuts));
  const [defaultFirstItemID, setDefaultFirstItemID] = useState(null);

  useEffect(() => {
    if (hasCutsReceived && typeCuts && firstItemByTypeCuts && !itemID) {
      setDefaultFirstItemID(firstItemByTypeCuts.id);
    }
  }, [firstItemByTypeCuts, hasCutsReceived, typeCuts, itemID]);

  return defaultFirstItemID;
};