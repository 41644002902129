// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html[data-theme=dark] .btn-item-switcher--AaWJ6{--border-color: #ffffff;--color-arrow: #ffffff}html[data-theme=light] .btn-item-switcher--AaWJ6{--border-color: #4E515C;--color-arrow: #4E515C}.wrap-item-switcher--BhGkz{display:flex;align-items:center;gap:0.9375rem}.wrap-item-switcher--BhGkz .btn-item-switcher--AaWJ6{display:flex;align-items:center;justify-content:center;width:2.1875rem;height:2.1875rem;border-radius:100px;border:1.5px solid var(--border-color);transition:background-color .3s ease 0s}.wrap-item-switcher--BhGkz .btn-item-switcher--AaWJ6:hover{background:var(--admin-btn-hover)}.wrap-item-switcher--BhGkz .btn-item-switcher--AaWJ6:hover .icon-arrow--B6Krj:before{color:#3c3f47}.wrap-item-switcher--BhGkz .btn-item-switcher--AaWJ6:active{background:var(--admin-btn-bg)}.wrap-item-switcher--BhGkz .btn-item-switcher--AaWJ6:active .icon-arrow--B6Krj:before{color:#fff}.wrap-item-switcher--BhGkz .btn-item-switcher--AaWJ6 .icon-arrow--B6Krj:before{color:var(--color-arrow);font-size:0.75rem}.wrap-item-switcher--BhGkz .btn-item-switcher--AaWJ6 .icon-arrow--B6Krj.prev--CBaMY:before{transform:rotate(90deg);margin-right:0.1875rem}.wrap-item-switcher--BhGkz .btn-item-switcher--AaWJ6 .icon-arrow--B6Krj.next--a5ejt:before{transform:rotate(270deg);margin-left:0.1875rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/ChartSection/ItemSwitcher/ItemSwitcher.module.sass"],"names":[],"mappings":"AAGE,gDACE,uBAAA,CACA,sBAAA,CAGF,iDACE,uBAAA,CACA,sBAAA,CAEJ,2BACE,YAAA,CACA,kBAAA,CACA,aAAA,CACA,qDACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CACA,gBAAA,CACA,mBAAA,CACA,sCAAA,CACA,uCAAA,CACA,2DACE,iCAAA,CAEE,qFACE,aAAA,CACN,4DACE,8BAAA,CAEE,sFACE,UAAA,CAEJ,+EACE,wBAAA,CACA,iBAAA,CAEA,2FACE,uBAAA,CACA,sBAAA,CAEF,2FACE,wBAAA,CACA,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn-item-switcher": "btn-item-switcher--AaWJ6",
	"wrap-item-switcher": "wrap-item-switcher--BhGkz",
	"icon-arrow": "icon-arrow--B6Krj",
	"prev": "prev--CBaMY",
	"next": "next--a5ejt"
};
export default ___CSS_LOADER_EXPORT___;
