// name of cuts
export const QUESTIONS_CUTS = 'questionsCuts';
export const UNITS_CUTS = 'unitsCuts';
export const FACTORS_CUTS = 'factorsCuts';
export const FOUR_D = '4D';
export const ALL_TYPE_OF_CUTS = [
  QUESTIONS_CUTS,
  UNITS_CUTS,
  FACTORS_CUTS,
  FOUR_D,
];

// types of factor structures
export const PERCENTILE = 'percentile';
export const ABSOLUTE = 'absolute';

export const VALIDATION_REGEX = {
  NAME: /^(?!\s*$)[a-zA-Zа-яА-ЯёЁ\s]+$/,
  EMAIL: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  PHONE: /^[0-9]+$/,
};