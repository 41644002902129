import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from '@/components/Common/BaseTemplate/MainContentContainer/AdminSupersetContainer/ButtonsPanel/ButtonsPanel.module.sass';
import FilesForDownloadModal from '@/components/Common/BaseTemplate/MainContentContainer/AdminSupersetContainer/ButtonsPanel/SupersetMenuModal/FilesForDownload/FilesForDownloadModal/FilesForDownloadModal';
import { selectTemplatesForDownload } from '@/store/selectors/admin/superset/supersets';

function FilesForDownloadButton() {
  const [isShowingModal, toggleModal] = useState(false);
  const { availableTemplates } = useSelector(selectTemplatesForDownload);
  const { files } = useSelector((state) => state.admin.superset.supersetFields);

  if (!availableTemplates?.length && !files.processorFiles) return null;

  return (
    <div className={styles['buttons-panel-container']}>
      <button
        className={`${styles['panel-button']} btn-without-style`}
        type="button"
        onClick={() => {
          toggleModal(true);
        }}
      >
        {' '}
        <span className={`${styles['button-icon']} icon-file-downloads`} />
      </button>
      <FilesForDownloadModal isOpen={isShowingModal} toggleOpen={toggleModal} />
    </div>
  );
}

export default FilesForDownloadButton;