import React from 'react';
import PropTypes from 'prop-types';
import styles from './AdminCheckbox.module.sass';
import './themeVars.sass';

function AdminCheckbox({
  id,
  name,
  label,
  type,
  value,
  register,
  registerOptions,
  externalClasses,
  styleTypes,
  notInteractive,
  disabled,
  ...props
}) {
  return (
    <div
      className={`${styles['admin-checkbox']} 
      ${externalClasses ? externalClasses.wrapCheckbox : ''} 
      ${notInteractive ? styles['not-interactive'] : ''}`}
    >
      <input
        id={id}
        {...(register ? { ...register(name, registerOptions) } : { name })}
        type={type}
        name={name}
        defaultValue={value}
        disabled={notInteractive || disabled}
        {...props}
      />
      <label htmlFor={id}>{label && label}</label>
    </div>
  );
}

AdminCheckbox.defaultProps = {
  register: null,
  registerOptions: {},
  value: null,
  type: 'checkbox',
  styleTypes: '',
  externalClasses: null,
  notInteractive: false,
  disabled: false,
};

AdminCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  register: PropTypes.func,
  registerOptions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  externalClasses: PropTypes.shape({
    wrapCheckbox: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  styleTypes: PropTypes.string,
  notInteractive: PropTypes.bool,
};

export default AdminCheckbox;