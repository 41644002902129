import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Input from '../../../Common/form/Input';
import { adminSignIn, getAdmin } from '@/store/actions/admin';
import { selectAdmin } from '@/store/selectors/admin/authentication';
import styles from './SignIn.module.sass';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';

function SignIn() {
  const dispatch = useDispatch();
  const admin = useSelector(selectAdmin);
  const { register, handleSubmit } = useForm();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/admin/projects' } };

  useEffect(() => {
    if (!admin) {
      dispatch(getAdmin());
    }
  }, [dispatch, admin]);

  if (admin) {
    return <Navigate to={from} replace />;
  }

  const onSubmitSignIn = (form) => {
    dispatch(adminSignIn(form));
  };

  return (
    <div className={styles['admin-signIn']}>
      <h3>Вход</h3>
      <form
        className={styles['form-admin-auth']}
        onSubmit={handleSubmit(onSubmitSignIn)}
      >
        <Input
          register={register}
          registerOptions={{
            required: true,
          }}
          id="admin-email"
          name="email"
          placeholder="Email"
          type="email"
          externalClasses={{ label: styles['label-field'] }}
        />

        <Input
          register={register}
          registerOptions={{
            required: true,
          }}
          id="admin-password"
          name="password"
          placeholder="Password"
          type="password"
          externalClasses={{ label: styles['label-field'] }}
        />

        <CustomButton
          styleTypeBtn="btn-grey btn-large"
          typeBtnSubmit
          classNames={styles['btn-on-signIn']}
        >
          Войти
        </CustomButton>
      </form>
      <Link to="/admin/signUp" className={styles['link-switch-form']}>
        Зарегистрироваться
      </Link>
      <Link
        to="/admin/restoreAccount"
        className={styles['link-restore-password']}
      >
        Восстановить пароль
      </Link>
    </div>
  );
}

export default SignIn;