import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import adminStore from '@/store/admin';
import AdminApp from '@/components/App/AdminApp';
import ThemeProvider from '@/theme/ThemeProvider';

const rootElement = document.getElementById('app');
const root = createRoot(rootElement);

root.render(
  <Provider store={adminStore}>
    <BrowserRouter>
      <ThemeProvider>
        <AdminApp />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
);