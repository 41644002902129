import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { CreateWebinarFieldsType, CreateWebinarFormSectionType } from '../../../types/webinarPageTypes';
import WebinarDescriptionPreview from './WebinarDescriptionPreview/WebinarDescriptionPreview';

interface WebinarContentPrviewProps extends UseFormReturn<CreateWebinarFieldsType> {
  type: CreateWebinarFormSectionType
}

export default function WebinarContenPreview({ type, ...formProps }: WebinarContentPrviewProps) {
  if (type === CreateWebinarFormSectionType.Content) return <WebinarDescriptionPreview {...formProps} />;

  return <div />;
}