// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html[data-theme=dark]{--field-bg: #FFFFFF}html[data-theme=light]{--field-bg: var(--pale-grey)}.field-activity-section--mqwIJ .wrap-selected-fields--aAcGJ{margin-top:1.25rem}.field-activity-section--mqwIJ .wrap-selected-fields--aAcGJ .list-fields--GoL8y{display:flex;flex-wrap:wrap;gap:0.75rem;align-items:center}.field-activity-section--mqwIJ .wrap-selected-fields--aAcGJ .list-fields--GoL8y .item-field--L13J0{position:relative;font-size:0.875rem;height:2.4285714286em;line-height:2.4285714286em;padding:0 2.1428571429em 0 1.4285714286em;background:var(--field-bg);color:var(--primary-dark-grey);border-radius:100px}.field-activity-section--mqwIJ .wrap-selected-fields--aAcGJ .list-fields--GoL8y .item-field--L13J0 .btn-remove-field--5Idbu{position:absolute;top:50%;right:0.4375rem;transform:translateY(-50%);padding:0}.field-activity-section--mqwIJ .wrap-selected-fields--aAcGJ .list-fields--GoL8y .item-field--L13J0 .btn-remove-field--5Idbu .icon-remove--hCtV5:before{font-size:1rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SupersetSettings/SectionFieldActivity/SectionFieldActivity.module.sass"],"names":[],"mappings":"AAEA,sBACE,mBAAA,CAEF,uBACE,4BAAA,CAGA,4DACE,kBAAA,CACA,gFACE,YAAA,CACA,cAAA,CACA,WAAA,CACA,kBAAA,CACA,mGACE,iBAAA,CACA,kBAAA,CACA,qBAAA,CACA,0BAAA,CACA,yCAAA,CACA,0BAAA,CACA,8BAAA,CACA,mBAAA,CACA,4HACE,iBAAA,CACA,OAAA,CACA,eAAA,CACA,0BAAA,CACA,SAAA,CAEE,uJACE,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field-activity-section": "field-activity-section--mqwIJ",
	"wrap-selected-fields": "wrap-selected-fields--aAcGJ",
	"list-fields": "list-fields--GoL8y",
	"item-field": "item-field--L13J0",
	"btn-remove-field": "btn-remove-field--5Idbu",
	"icon-remove": "icon-remove--hCtV5"
};
export default ___CSS_LOADER_EXPORT___;
