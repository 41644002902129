import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BUnitsMenuBtn from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitsBlueBtn/BUnitsMenuBtn';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { makeUnitsGroupOperations } from '@/store/actions/admin/superset/unitsTree';
import {
  UnitActionsKeysByOperationType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/useBUnitGroupAction';

interface BUnitActionsConfirmButtonProps {
  toggleModal: Dispatch<SetStateAction<boolean>>
  operationType: keyof typeof UnitActionsKeysByOperationType
}
function BUnitActionsConfirmButton({ toggleModal, operationType }: BUnitActionsConfirmButtonProps) {
  const tNLS = useNLS();
  const dispatch = useDispatch();
  const { supersetId } = useSelector(selectSuperset);

  const executeOperation = () => {
    if (operationType) {
      dispatch(
        makeUnitsGroupOperations({
          supersetId,
          operationType,
        }),
      );
    }
  };

  const handleButtonClick = () => {
    if (operationType === 'sendEmail' || operationType === 'transferUnits' || operationType === 'addSpec') {
      toggleModal(true);
      return;
    }
    executeOperation();
  };

  return (
    <BUnitsMenuBtn handleButtonClick={() => handleButtonClick()}>
      {tNLS('tree.select.go')}
    </BUnitsMenuBtn>
  );
}

export default BUnitActionsConfirmButton;