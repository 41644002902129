import React from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import styles from './SuccessMessage.module.sass';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';

interface SuccessMessageProps {
  createdSupersetId: string | undefined;
  closeModal: () => void
}

function SuccessMessage({ createdSupersetId, closeModal }: SuccessMessageProps) {
  const navigate = useNavigate();

  const buttonHandleClick = () => {
    if (createdSupersetId) {
      navigate(`/admin/project/${createdSupersetId}/projectSettings`, { replace: true });
      return;
    }
    closeModal();
  };
  return (
    <div className={styles['wrap-create-project-success']}>
      <span className={`icon-success-noty ${styles['icon-success-noty']}`} />
      <CustomButton
        styleTypeBtn="btn-blue btn-large"
        onClick={buttonHandleClick}
      >
        Перейти в проект
      </CustomButton>
    </div>
  );
}

export default SuccessMessage;