import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import Modal from '@/components/Common/Modal';
import useModal from '@/hooks/modal';
import { changeAdminRole } from '@/store/actions/admin';
import useNLS from '@/components/App/Admin/hooks/hooks';

function ConfirmChangeAdminRole({
  isShowingModal,
  toggleModal,
  supersetID,
  adminChangedRoleData,
}) {
  const tNLS = useNLS();
  const { WrapBtns } = useModal();
  const dispatch = useDispatch();

  const onChangeAdminRole = () => {
    const {
      role: changedAdminRole,
      email: emailForChangedRole,
      adminID,
    } = adminChangedRoleData;

    dispatch(
      changeAdminRole({
        supersetID,
        form: {
          role: changedAdminRole,
          email: emailForChangedRole,
          adminID,
        },
      }),
    )
      .then(unwrapResult)
      .then(() => toggleModal())
      .catch(() => null);
  };

  return (
    <Modal
      isShowing={isShowingModal}
      hide={toggleModal}
      title={tNLS('admin.confirm.change.admin.role.message')}
      types="modal-small"
    >
      <WrapBtns>
        <CustomButton
          onClick={() => toggleModal()}
          styleTypeBtn="btn-grey-modal"
        >
          {tNLS('admin.confirm.change.admin.role.cancel')}
        </CustomButton>
        <CustomButton onClick={onChangeAdminRole} styleTypeBtn="btn-blue-modal">
          {tNLS('admin.confirm.change.admin.role.confirm')}
        </CustomButton>
      </WrapBtns>
    </Modal>
  );
}

ConfirmChangeAdminRole.defaultProps = {
  adminChangedRoleData: null,
};

ConfirmChangeAdminRole.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  isShowingModal: PropTypes.bool.isRequired,
  supersetID: PropTypes.string.isRequired,
  adminChangedRoleData: PropTypes.shape({
    role: PropTypes.number,
    email: PropTypes.string,
    adminID: PropTypes.string,
  }),
};

export default ConfirmChangeAdminRole;