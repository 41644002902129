import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import FilesSection from '@/components/Common/BaseTemplate/MainContentContainer/AdminSupersetContainer/ButtonsPanel/SupersetMenuModal/FilesForDownload/FilesSection/FilesSection';
import useFileDownload from '@/components/App/Admin/hooks/downloadFile';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { selectCurrentLangID } from '@/store/selectors/admin/superset/projects';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';
import useNLS from '@/components/App/Admin/hooks/hooks';

function ResultsSection() {
  const downloadFile = useFileDownload();
  const { supersetId, ticketCounter } = useSelector(selectSuperset);
  const currentLangID = useSelector(selectCurrentLangID);
  const { evaluatedAt } = useSelector(
    (state) => state.admin.superset.process.process,
  );
  const fName = (type, ext) => `${currentLangID}_${type}.${ext}`;
  const { files } = useSelector((state) => state.admin.superset.supersetFields);
  const hasFile = (type, ext) => {
    if (!files.processorFiles) return false;
    return files.processorFiles[fName(type, ext)] !== undefined;
  };

  const tNLS = useNLS();
  const checkPermission = useCheckPermission();

  const donwloadPersonalReports = useCallback(() => {
    downloadFile({
      args: {
        supersetId,
        fileType: 'archive',
      },
    });
  }, [downloadFile, supersetId]);

  const downloadAssessmentFile = useCallback(() => {
    downloadFile({
      args: {
        supersetId,
        fileType: 'assessment',
        params: {
          ticketNumberInfo: 'true',
          actorIdInfo: 'true',
          lastItemInfo: 'true',
          lastActiveInfo: 'true',
          departmentInfo: 'true',
        },
      },
    });
  }, [downloadFile, supersetId]);

  const download360Results = useCallback(() => {
    downloadFile({
      args: {
        supersetId,
        fileType: 'people.tsv',
        langId: currentLangID,
      },
    });
  }, [downloadFile, supersetId, currentLangID]);

  const downloadPresentation = useCallback(() => {
    downloadFile({
      args: {
        supersetId,
        fileType: 'presentation',
        langId: currentLangID,
      },
    });
  }, [downloadFile, supersetId, currentLangID]);

  const downloadAnswers = useCallback(() => {
    downloadFile({
      args: {
        supersetId,
        fileType: 'answers',
        langId: currentLangID,
      },
    });
  }, [downloadFile, supersetId, currentLangID]);

  const downloadCorporateUnits = useCallback(() => {
    downloadFile({
      args: {
        supersetId,
        fileType: 'units',
        langId: currentLangID,
      },
    });
  }, [downloadFile, supersetId, currentLangID]);

  const downloadCorporatePeople = useCallback(() => {
    downloadFile({
      args: {
        supersetId,
        fileType: 'people.pdf',
        langId: currentLangID,
      },
    });
  }, [downloadFile, supersetId, currentLangID]);

  const downloadCorporateSpec = useCallback(() => {
    downloadFile({
      args: {
        supersetId,
        fileType: 'people_spec',
        langId: currentLangID,
      },
    });
  }, [downloadFile, supersetId, currentLangID]);

  const downloadProjectTickets = useCallback(() => {
    downloadFile({
      args: {
        supersetId,
        fileType: 'tickets',
      },
    });
  }, [downloadFile, supersetId]);
  return (
    <FilesSection title="Results">
      {[
        {
          fileName: tNLS('download.raw.answers'),
          isAvailable: checkPermission('download_excel_answers'),
          fileType: 'answers',
          fileLang: `${currentLangID}`,
          fileDate: '',
          downloadFile: downloadAnswers,
        },
        {
          fileName: tNLS('download.respondents.status'),
          isAvailable: checkPermission('download_assessement_info'),
          fileType: 'assessment',
          fileDate: '',
          fileLang: '',
          downloadFile: downloadAssessmentFile,
        },
        {
          fileName: tNLS('download.personal.reports'),
          isAvailable: checkPermission('download_personal_report'),
          fileType: 'archive',
          fileDate: evaluatedAt,
          fileLang: '',
          downloadFile: donwloadPersonalReports,
        },
        {
          fileName: tNLS('download.360degree.results'),
          isAvailable:
            checkPermission('download_corporate_report')
            && hasFile('360_people', 'tsv'),
          fileType: 'people.tsv',
          fileDate: evaluatedAt,
          fileLang: `${currentLangID}`,
          downloadFile: download360Results,
        },
        {
          fileName: tNLS('download.presentation'),
          isAvailable:
            checkPermission('download_presentation')
            && hasFile('presentation', 'pdf'),
          fileType: 'presentation',
          fileLang: `${currentLangID}`,
          fileDate: evaluatedAt,
          downloadFile: downloadPresentation,
        },
        {
          fileName: tNLS('download.deps.corporate.report'),
          isAvailable:
            checkPermission('download_corporate_report')
            && hasFile('360_units', 'pdf'),
          fileType: 'units',
          fileLang: `${currentLangID}`,
          fileDate: evaluatedAt,
          downloadFile: downloadCorporateUnits,
        },
        {
          fileName: tNLS('download.people.corporate.report'),
          isAvailable:
            checkPermission('download_corporate_report')
            && hasFile('360_people', 'pdf'),
          fileType: 'people.pdf',
          fileLang: `${currentLangID}`,
          fileDate: evaluatedAt,
          downloadFile: downloadCorporatePeople,
        },
        {
          fileName: tNLS('download.people.spec.report'),
          isAvailable:
            checkPermission('download_corporate_report')
            && hasFile('360_people_spec', 'pdf'),
          fileType: 'people_spec',
          fileLang: `${currentLangID}`,
          fileDate: evaluatedAt,
          downloadFile: downloadCorporateSpec,
        },
        {
          fileName: tNLS('units.download.tickets'),
          isAvailable:
            checkPermission('download_excel_tickets') && ticketCounter > 0,
          fileType: 'tickets',
          fileLang: '',
          fileDate: '',
          downloadFile: downloadProjectTickets,
        },
      ]}
    </FilesSection>
  );
}
export default ResultsSection;