import React, {
  useCallback, useEffect, useRef,
} from 'react';
import styles from './CustomInput.module.sass';

interface CustomInputProps {
  onChange: (value?: string) => void;
  onClick: () => void;
  placeholder?: string;
  inContainer?: boolean;
  withResize?: boolean;
  inputValue: string;
  inputClassNames?: string
  containerClassNames?: string
  inputDisabled?: boolean;
  errorMessage?: string;
  pageClickAction?: () => void;
  keyAction?: (code: string) => void
  id?: string;
  name?: string;
  inputType?: HTMLInputElement['type']
}

function CustomInput({
  onChange,
  onClick = undefined,
  placeholder = '',
  inContainer = true,
  withResize = false,
  inputValue = '',
  inputClassNames = '',
  containerClassNames = '',
  inputDisabled = false,
  errorMessage = '',
  pageClickAction = () => null,
  keyAction = () => null,
  id = '',
  name = '',
  inputType = 'text',
}: CustomInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const inputRefContainer = useRef<HTMLDivElement>(null);

  const resizeInput = useCallback(() => {
    if (!withResize) return;
    if (!inputRef.current) return;
    inputRef.current.style.width = '0';
    inputRef.current.style.width = `${inputRef.current.scrollWidth}px`;
  }, [withResize]);

  const inputChange = useCallback(
    ({ value }: { value: string }) => {
      onChange(value);
    },
    [onChange],
  );

  useEffect(() => {
    if (!inputRef.current) return;
    resizeInput();
  }, [inputValue, resizeInput]);

  useEffect(() => {
    inputRef.current.value = inputValue;
  }, [inputValue]);

  useEffect(() => {
    if (!inputDisabled) inputRef.current.focus();
  }, [inputDisabled]);

  // useEffect(() => {
  //   if (!inputDisabled) return;
  //   if (!inputRef.current) return;
  //   inputRef.current.blur();
  //   resizeInput();
  // }, [inputDisabled, inputValue, resizeInput]);

  useEffect(() => {
    const pageClick = (event: MouseEvent) => {
      const target = event.target as Node;
      if (!inputRefContainer.current) return;
      if (!inputRefContainer.current.contains(target) && !inputDisabled) {
        if (!errorMessage) pageClickAction();
      }
    };
    if (!inputDisabled) {
      window.addEventListener('click', pageClick);
    }

    return () => {
      window.removeEventListener('click', pageClick);
    };
  }, [errorMessage, inputDisabled, inputRef, pageClickAction]);

  return (
    <div
      className={`${styles['default-input-container']} ${
        inContainer && styles.visible
      } 
      ${containerClassNames}`}
      ref={inputRefContainer}
    >
      {errorMessage && (
        <span className={styles['error-message']}>{errorMessage}</span>
      )}
      <input
        id={id}
        name={name}
        placeholder={placeholder}
        ref={inputRef}
        // readOnly={inputDisabled}
        defaultValue={inputValue}
        onClick={onClick}
        type={inputType}
        onChange={(event) => {
          inputChange(event.target);
        }}
        onKeyUp={(event) => keyAction(event.code)}
        className={`input-without-style ${styles['default-input']} ${inputClassNames}`}
      />
    </div>
  );
}

export default CustomInput;