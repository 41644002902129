// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".label-input--rmg2m{display:inline-flex;flex-direction:row;align-items:baseline;gap:0.3125rem}.label-input--rmg2m:hover .icon-label--QA-oQ span::before{color:#fff}.label-text--FhtYa{color:var(--admin-main-font-color);font-size:1.25rem;line-height:1em}.wrap-editable-resizeable-field--KwcfZ{position:relative}.wrap-editable-resizeable-field--KwcfZ .validation-error--IFfL-{font-size:0.875rem !important}.wrap-editable-resizeable-field--KwcfZ .validation-error--IFfL-{position:absolute;color:var(--error-color);top:100%}.wrap-editable-resizeable-field--KwcfZ input{-webkit-appearance:none;appearance:none;font-size:1.25rem;line-height:1.05em;color:var(--admin-main-font-color);background:none;outline:none;border:none}.wrap-editable-resizeable-field--KwcfZ input::placeholder{color:var(--admin-main-font-color)}.wrap-editable-resizeable-field--KwcfZ input::-webkit-contacts-auto-fill-button{visibility:hidden;position:absolute;right:0}.wrap-editable-resizeable-field--KwcfZ.not-interactive--sMgn8 input{pointer-events:none}.icon-label--QA-oQ{cursor:pointer}.icon-label--QA-oQ span::before{transition:color .3s ease 0s}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/UIElements/form/EditableResizeableInput/EditableResizeableInput.module.sass"],"names":[],"mappings":"AAEA,oBACE,mBAAA,CACA,kBAAA,CACA,oBAAA,CACA,aAAA,CAIM,0DACE,UAAA,CAEV,mBACE,kCAAA,CACA,iBAAA,CACA,eAAA,CAGF,uCACE,iBAAA,CACA,gEAGE,6BACA,CAJF,gEACE,iBAAA,CACA,wBAAA,CAEA,QAAA,CACF,6CACE,uBAAA,CAAA,eAAA,CACA,iBAAA,CACA,kBAAA,CACA,kCAAA,CACA,eAAA,CACA,YAAA,CACA,WAAA,CACA,0DACE,kCAAA,CACF,gFACE,iBAAA,CACA,iBAAA,CACA,OAAA,CAEF,oEACE,mBAAA,CAEN,mBACE,cAAA,CAEE,gCACE,4BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label-input": "label-input--rmg2m",
	"icon-label": "icon-label--QA-oQ",
	"label-text": "label-text--FhtYa",
	"wrap-editable-resizeable-field": "wrap-editable-resizeable-field--KwcfZ",
	"validation-error": "validation-error--IFfL-",
	"not-interactive": "not-interactive--sMgn8"
};
export default ___CSS_LOADER_EXPORT___;
