import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import styles from './SectionBlocks.module.sass';
import ProhibitInteractivity from '@/components/App/Admin/SupersetSettings/ProhibitInteractivity';
import selectClearBlocks from '@/store/selectors/admin/superset/blocks';
import { setSupersetBlocks } from '@/store/actions/admin/superset/blocks';
import useNLS from '@/components/App/Admin/hooks/hooks';
import CheckBox from '@/components/Common/form/CheckBox';

function SectionBlocks() {
  const tNLS = useNLS();
  const { id: supersetID } = useParams();
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const blocks = useSelector(selectClearBlocks);

  const onChangeBlocks = (form) => {
    const submitBlocks = form.blocks.map((objBlock) => {
      const [id, isActive] = Object.entries(objBlock)[0];

      return { id, isActive };
    });

    dispatch(
      setSupersetBlocks({
        supersetId: supersetID,
        blocks: submitBlocks,
      }),
    );
  };

  return (
    <section className={`settings-section ${styles['blocks-section']}`}>
      <h3 className="title-section">{tNLS('selecting.blocks')}</h3>

      <form
        onChange={() => {
          handleSubmit(onChangeBlocks)();
        }}
      >
        <div className={styles['list-blocks']}>
          {Object.keys(blocks).map((blockID, i) => {
            const { isActive, name, description: descr } = blocks[blockID];

            return (
              <div key={blockID} className={styles['item-block']}>
                <ProhibitInteractivity>
                  <CheckBox
                    id={`block-${blockID}`}
                    name={`blocks.${i}.${blockID}`}
                    onChange={() => {}}
                    label={name}
                    register={register}
                    checked={!!isActive}
                  />
                </ProhibitInteractivity>
                <div className={styles['descr-block']}>{parse(descr)}</div>
              </div>
            );
          })}
        </div>
      </form>
    </section>
  );
}

export default SectionBlocks;