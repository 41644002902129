// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html[data-theme=dark] .axis--5i4dj{--strock-color: #FFFFFF}html[data-theme=light] .axis--5i4dj{--strock-color: #000000}.axis--5i4dj{stroke:var(--strock-color);stroke-width:1}.label-rating-name--\\+8n1j tspan{color:#7e7e7e;font-size:0.875rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/FourD/Diagram/Axes/themeVars.sass","webpack://./src/components/App/Admin/Results/Cuts/FourD/Diagram/Axes/Axes.module.sass"],"names":[],"mappings":"AACE,mCACE,uBAAA,CAIF,oCACE,uBAAA,CCJJ,aACE,0BAAA,CACA,cAAA,CAGA,iCACE,aAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"axis": "axis--5i4dj",
	"label-rating-name": "label-rating-name--+8n1j"
};
export default ___CSS_LOADER_EXPORT___;
