import React from 'react';
import styles from "./CloudFile.module.sass"
import { humanFileSize } from '@/helpers/helpers';

const CloudFile: React.FC<CloudFileItem> = ({ name, size, modTime }) => {
    return (
        <div className={styles['cloud-file-item']}>
            <span>{name}</span>
            <span>{humanFileSize(size)}</span>
            <span>{modTime}</span>
        </div>
    );
}

export default CloudFile;