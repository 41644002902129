import React from 'react';
import { selectActorProfile } from '@/store/selectors/user/actor';
import styles from './UserWidgets.module.sass';
import {
  selectProjectName,
} from '@/store/selectors/user/projects';
import ThemeSwitcher from '@/components/Common/BaseTemplate/Header/ThemeSwitcher';
import useModal from '@/hooks/modal';
import Code from '@/components/App/User/Common/Code/Code';
import { usePlayerSelector } from '@/store/hooks/playerRedux';
import { ActorProfile } from '@/store/types/user/actor';
import UserProfileWidgetModal
  from '@/components/Common/BaseTemplate/Header/HeaderWidgets/UserWidgets/UserProfileWidgetModal/UserProfileWidgetModal';

function UserWidgets() {
  const profile = usePlayerSelector(selectActorProfile) as ActorProfile;
  const { isLogin } = usePlayerSelector((state) => state.user.actor);

  const {
    isShowingModal, toggleStatusShowingModal,
  } = useModal();

  const handleLogout = () => {
    toggleStatusShowingModal();
  };

  return (
    <div className={styles['header-user-widgets']}>
      <div className={styles['header-user-functions']}>

        {profile && isLogin && (
          <div
            className={`${styles['header-profile-widget']} ${styles['profile-widget']}`}
          >
            <div className={styles['profile-widget-return-inviteCode']}>
              <strong>
                <Code isHeader />
              </strong>
            </div>
            <button
              type="button"
              className={`${styles['profile-widget-btn-exit-profile']} btn-without-style`}
              onClick={() => handleLogout()}
            >
              {' '}
              <span className={`${styles['exit-user-icon']} icon-exit`} />
            </button>
            {profile.name && (
              <span className={styles['actor-name']}>{profile.name}</span>
            )}
          </div>
        )}
      </div>
      {profile && (
        <UserProfileWidgetModal
          isShowingModal={isShowingModal}
          toggleStatusShowingModal={toggleStatusShowingModal}
        />
      )}
    </div>
  );
}

export default UserWidgets;