import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import styles from './NotificationsBar.module.sass';
import NotificationAnimatedContainer from '@/components/Common/NotificationsBar/NotificationContainer/NotificationAnimatedContainer';

function NotificationsBar() {
  const [isHidden, toggleHidden] = useState(true);
  const { notifications, lastNotifyId } = useSelector((state) => state.notify);
  const notificationsList = useMemo(
    () => [...notifications].reverse(),
    [notifications],
  );

  const panelRef = useRef(null);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (panelRef.current && !panelRef.current.contains(e.target)) {
        toggleHidden(true);
      }
    };
    if (!isHidden) {
      window.addEventListener('click', pageClickEvent);
    }
    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isHidden]);

  const getTop = () => {
    const header = document.querySelector('.main-header-container');
    if (!header) return '70px';
    return `${header.getBoundingClientRect().bottom + 10}px`;
  };

  if (!notifications.length) return null;

  return (
    <div
      className={styles['notification-panel']}
      style={{
        top: getTop(),
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        type="button"
        className={`${styles['notifications-container']} ${
          isHidden && styles.hidden
        }`}
        ref={panelRef}
        onClick={() => {
          if (notifications.length === 1) return;
          if (!isHidden) return;
          toggleHidden(false);
        }}
      >
        {notificationsList.map((notify, index) => (
          <NotificationAnimatedContainer
            key={notify.id}
            notify={notify}
            isHidden={isHidden}
            withAnimation={index === 0 && notify.id === lastNotifyId}
            index={index}
            panelContainer={panelRef.current}
          />
        ))}
      </div>
    </div>
  );
}

NotificationsBar.propTypes = {};

export default NotificationsBar;