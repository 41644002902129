import { createAsyncThunk, unwrapResult, createAction } from '@reduxjs/toolkit';
import {
  getStorageInfo,
  loadSuperset,
  updateSuperset,
} from '@/store/actions/admin';
import { setDownloadableBlocksSuperset } from '@/store/actions/admin/superset/blocks';
import { setDownloadableSettings } from '@/store/actions/admin/superset/settings';
import axiosProcessing from '@/axiosConfig';
import { AdminState } from '@/store/admin';
import {
  CommitTemplateActionArgs, LoadProjectsActionArgs, LoadSupersetLangsActionArgs, SupersetProject, SupersetProjects,
  ToggleLoadSupersetActionArgs,
  UploadResourcesInfo,
} from '@/store/types/admin/superset/projects';
import { Languages, SupersetLang } from '@/store/types/admin/superset/superset';

export const uploadResources = createAsyncThunk(
  'supersetProjects/uploadResources',
  async (formData, { getState, rejectWithValue }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;
      // @ts-ignore
      const supersetID = state.admin.superset.supersetFields.superset.supersetId ?? 0;

      const response = await axiosProcessing.post<UploadResourcesInfo>(
        `/api/admin/uploadTemplate/${supersetID}`,
        formData,
        {
          headers,
        },
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const commitTemplate = createAsyncThunk(
  'supersetProjects/commitTemplate',
  async (form: CommitTemplateActionArgs, { getState, rejectWithValue, dispatch }) => {
    const state = getState() as AdminState;
    const { headers } = state.admin.authentication;

    try {
      const response = await axiosProcessing.post<SupersetProject>(
        '/api/admin/uploadTemplate/commit',
        form,
        { headers },
      );

      if (form.supersetId) await dispatch(getStorageInfo({ supersetId: form.supersetId }));

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const clearUploadResourcesInfo = createAction(
  'supersetProjects/clearUploadResourcesInfo',
);

export const setDownloadableLanguageSuperset = createAction<{ [langId: string]: SupersetLang }>(
  'supersetProjects/setDownloadableLanguage',
);

export const createProject = createAsyncThunk(
  'supersetProjects/createProject',
  async (form: CommitTemplateActionArgs, { dispatch, getState, rejectWithValue }) => {
    let project = null;

    try {
      project = unwrapResult(await dispatch(commitTemplate(form)));
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
    const state = getState() as AdminState;
    const { langId: uploadedLangID, blocks, settings } = project;
    const { languages } = state.admin.superset.supersetFields;

    if (uploadedLangID) {
      dispatch(
        setDownloadableLanguageSuperset({
          [uploadedLangID]: { ...languages[uploadedLangID], isActive: true },
        }),
      );
    }
    // todo переделать, когда появится API
    dispatch(loadSuperset({ supersetId: form.supersetId }));
    dispatch(setDownloadableBlocksSuperset(blocks));
    dispatch(setDownloadableSettings(settings));

    dispatch(clearUploadResourcesInfo());

    return {
      projectID: project.projectId,
      name: project.name,
      langId: project.langId,
    };
  },
);

export const loadProjects = createAsyncThunk(
  'supersetProjects/loadProjects',
  async (form: LoadProjectsActionArgs, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<SupersetProjects>(
        `/api/admin/getProjects/${form.supersetId}`,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const clearSupersetProjects = createAction(
  'supersetProjects/clearSupersetProjects',
);

export const loadSupersetLangs = createAsyncThunk(
  'superset/loadSupersetLang',
  async (form: LoadSupersetLangsActionArgs, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<Languages>(
        `/api/admin/getSupersetLanguages/${form.supersetId}`,
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const toggleLangSuperset = createAsyncThunk(
  'superset/toggleLangSuperset',
  async (
    { formUpdateSuperset, toggledLangStatus }: ToggleLoadSupersetActionArgs,
    { dispatch, rejectWithValue },
  ) => {
    try {
      unwrapResult(await dispatch(updateSuperset(formUpdateSuperset)));

      return toggledLangStatus;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);