import React, { useMemo, useState } from 'react';
import styles from './ModalCreateSuperset.module.sass';
import Modal from '@/components/Common/Modal';
import { selectAdmin } from '@/store/selectors/admin/authentication';
import LinkTabs from '@/components/App/Admin/UIElements/LinkTabs';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import { ButtonTabInterface, TabsType } from '@/components/App/Admin/UIElements/LinkTabs/types';
import ModalCreateSupersetContent
  from '@/components/App/Admin/ListSupersets/ModalCreateSuperset/ModalCreateSypersetContent/ModalCreateSupersetContent';
import { clearUploadResourcesInfo } from '@/store/actions/admin/superset/projects';

interface ModalCreateSupersetProps {
  isOpen: boolean;
  toggleOpen: (arg: boolean) => void
}

export enum CreateSupersetModalContentType {
  Upload = 'upload',
  Copy = 'copy',
  Success = 'success',
}

function ModalCreateSuperset({ isOpen, toggleOpen }: ModalCreateSupersetProps) {
  const tNLS = useNLS();
  const dispatch = useAdminDispatch();
  const { isSuper } = useAdminSelector(selectAdmin);
  const [contentType, setContentType] = useState<CreateSupersetModalContentType>(
    () => (isSuper ? CreateSupersetModalContentType.Upload : CreateSupersetModalContentType.Copy),
  );

  const modalTitles = {
    upload: tNLS('template.upload'),
    copy: 'Копирование шаблона',
    success: 'Проект успешно создан !',
  };

  const links = useMemo<ButtonTabInterface[]>(() => [
    {
      id: CreateSupersetModalContentType.Upload,
      title: 'Upload',
      isHide: !isSuper,
      onClick: () => { setContentType(CreateSupersetModalContentType.Upload); },
      isActive: contentType === CreateSupersetModalContentType.Upload,
    },
    {
      id: CreateSupersetModalContentType.Copy,
      title: 'Copy',
      onClick: () => { setContentType(CreateSupersetModalContentType.Copy); },
      isActive: contentType === CreateSupersetModalContentType.Copy,
    },
  ], [contentType, isSuper]);

  const closeModal = () => {
    toggleOpen(false);
    dispatch(clearUploadResourcesInfo());
    setContentType(isSuper ? CreateSupersetModalContentType.Upload : CreateSupersetModalContentType.Copy);
  };

  return (
    <Modal
      isShowing={isOpen}
      hide={closeModal}
      title={modalTitles[contentType]}
      externalClassNames={{
        modal: styles.modal,
      }}
    >
      <div className={styles['wrap-content-modal-create-superset']}>
        {isSuper && contentType !== CreateSupersetModalContentType.Success && (
        <LinkTabs
          <TabsType.Buttons>
          type={TabsType.Buttons}
          externalClassNames={{
            wrapLinkTabs: styles['wrap-create-superset-tabs'],
            itemTabLink: styles['item-tab-link'],
            itemLink: styles.link,
          }}
        >
          {links}
        </LinkTabs>
        )}
        <ModalCreateSupersetContent
          contentType={contentType}
          setContentType={setContentType}
          closeModal={closeModal}
        />
      </div>
    </Modal>
  );
}

export default ModalCreateSuperset;