// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer---ExsH{position:relative;margin-top:0.625rem}.footer---ExsH .footer-container--sAcvz{display:flex;justify-content:space-between;max-width:95%;margin:0 auto;padding:0 15px;align-items:center;min-height:5.3125rem;font-size:14px}@media screen and (max-width: 376px){.footer---ExsH .footer-container--sAcvz{min-height:5.625rem;font-size:12px;padding:0 5px}}.footer---ExsH .footer-container--sAcvz .footer-copyright--ILhD3{color:var(--secondary-grey-text)}.footer---ExsH .footer-container--sAcvz .footer-contact--3LSzU{z-index:5;color:#fff;text-align:right}.footer---ExsH .footer-decor--wvse2{position:absolute;right:0;bottom:0;width:440px;z-index:-1}", "",{"version":3,"sources":["webpack://./src/components/Common/BaseTemplate/Footer/Footer.module.sass"],"names":[],"mappings":"AAGA,eACE,iBAAA,CACA,mBAAA,CACA,wCACE,YAAA,CACA,6BAAA,CACA,aAAA,CACA,aAAA,CACA,cAAA,CACA,kBAAA,CACA,oBAAA,CACA,cAAA,CACA,qCATF,wCAUI,mBAAA,CACA,cAAA,CACA,aAAA,CAAA,CACF,iEACE,gCAAA,CACF,+DACE,SAAA,CACA,UAAA,CACA,gBAAA,CACJ,oCACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,WAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "footer---ExsH",
	"footer-container": "footer-container--sAcvz",
	"footer-copyright": "footer-copyright--ILhD3",
	"footer-contact": "footer-contact--3LSzU",
	"footer-decor": "footer-decor--wvse2"
};
export default ___CSS_LOADER_EXPORT___;
