import React, { useRef, useState } from 'react';

import { Toolbar } from './ArticleToolbar/ArticleToolbar';
import { BlockList } from './ArticleBlockList/ArticleBlockList';


import styles from './ArticleEditor.module.sass'

interface ArticleEditorProps {
    ArticleID?: string;

    isOpen: boolean;
    onClose: () => void;
}

export const ArticleEditor: React.FC<ArticleEditorProps> = ({ isOpen, onClose, ArticleID }) => {
    const [blocks, setBlock] = useState<ArticleTextBlock[]>([]);

    const addBlockItem = (item: ArticleTextBlock) => {
        setBlock((prevItems) => [...prevItems, item]);
    }

    const dialogRef = useRef<HTMLDialogElement>(null);
    if (isOpen && dialogRef.current) {
        dialogRef.current.showModal();
    } else if (!isOpen && dialogRef.current) {
        dialogRef.current.close();
    }
    return (
        <dialog ref={dialogRef} className={styles["article-modal"]}>
            <section className={styles["article-modal-container"]}>
                <BlockList blocks={blocks} />
                <Toolbar addBlock={addBlockItem} />
            </section>
        </dialog >
    );
};
