// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal--JM5Gh{width:37.5rem;border:1px solid var(--tab-border-color)}.wrap-content-modal-create-superset--baNys .wrap-create-superset-tabs--bJtH0{position:absolute;z-index:-1;left:-0.0625rem;top:-2.25rem}.wrap-content-modal-create-superset--baNys .wrap-create-superset-tabs--bJtH0 .item-tab-link--osw9w:first-child:after{content:\"\";position:absolute;color:var(--tab-bg);z-index:100;width:20px;height:26px;transform:none;top:36px;left:0;border-top:none;border-radius:0}.wrap-content-modal-create-superset--baNys .wrap-create-superset-tabs--bJtH0 .item-tab-link--osw9w:first-child.isActive--7OxB4:after{background:var(--tab-active-bg);border-left:1px solid var(--tab-border-color);border-bottom:1px solid var(--tab-border-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/ListSupersets/ModalCreateSuperset/ModalCreateSuperset.module.sass"],"names":[],"mappings":"AAEA,cACE,aAAA,CACA,wCAAA,CAGA,6EACE,iBAAA,CACA,UAAA,CACA,eAAA,CACA,YAAA,CAGI,qHACE,UAAA,CACA,iBAAA,CACA,mBAAA,CACA,WAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA,CACA,QAAA,CACA,MAAA,CACA,eAAA,CACA,eAAA,CAEA,qIACE,+BAAA,CACA,6CAAA,CACA,+CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal--JM5Gh",
	"wrap-content-modal-create-superset": "wrap-content-modal-create-superset--baNys",
	"wrap-create-superset-tabs": "wrap-create-superset-tabs--bJtH0",
	"item-tab-link": "item-tab-link--osw9w",
	"isActive": "isActive--7OxB4"
};
export default ___CSS_LOADER_EXPORT___;
