import { RefObject, useEffect, useRef } from 'react';

const useOutsideClick = <T extends Function, ElementType extends HTMLElement>(callback: T, isOpen: boolean | undefined = true): RefObject<ElementType> => {
  const ref = useRef<ElementType>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };
    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [callback, isOpen]);

  return ref;
};

export default useOutsideClick;