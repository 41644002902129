export enum AdminRoles {
  CREATOR = 0,
  FULL_ADMIN = 1,
  OBSERVER_WITH_ACCESS_RESULTS = 2,
  OBSERVER = 3,
  CAN_COPY_SUPERSET = -1,
}

export enum SupersetStages {
  CREATION = 0,
  SETUP = 1,
  TESTING = 2,
  DURING_SURVEY_PROCESS = 3,
  FEEDBACK = 4,
  ARCHIVE = 5,
}

export enum Stage {
  CREATION = 0,
  SETUP = 1,
  TESTING = 2,
  DURING_SURVEY_PROCESS = 3,
  FEEDBACK = 4,
  ARCHIVE = 5,
}

export enum OrderLang {
  ORDER_RU = 1,
  ORDER_ENG = 2,
  ORDER_FR = 3,
  ORDER_DE = 4,
  ORDER_ARM = 5,
}

export enum ResourcesTypes {
  RESOURCES = 'resources',
  TEMPLATE = 'template',
}