// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-fs--ca97S{margin-top:2.5rem}.wrap-fs--ca97S .header-fs--RoG\\+V{display:flex;justify-content:space-between;align-items:center}.wrap-fs--ca97S .fs-name--11xQ9{font-size:1.5rem}.wrap-fs--ca97S .wrap-list-of-factors--Ri9wI{width:80%;margin-top:1.875rem}.wrap-fs--ca97S .wrap-list-of-factors--Ri9wI.has-fs-with-low-poles--0JdkY{width:65%;margin:1.875rem auto 0 auto}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/FactorsCuts/FactorsCuts.module.sass"],"names":[],"mappings":"AAEA,gBACE,iBAAA,CACA,mCACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACF,gCACE,gBAAA,CACF,6CACE,SAAA,CACA,mBAAA,CACA,0EACE,SAAA,CACA,2BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-fs": "wrap-fs--ca97S",
	"header-fs": "header-fs--RoG+V",
	"fs-name": "fs-name--11xQ9",
	"wrap-list-of-factors": "wrap-list-of-factors--Ri9wI",
	"has-fs-with-low-poles": "has-fs-with-low-poles--0JdkY"
};
export default ___CSS_LOADER_EXPORT___;
