// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".password-section--pOdKx .form-change-password--ioFqv{display:flex;flex-direction:column;gap:0.9375rem}.password-section--pOdKx .form-change-password--ioFqv .label-field--SyVIW{width:100%}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/AdminProfile/ChangePassword/ChangePassword.module.sass"],"names":[],"mappings":"AAGE,sDACE,YAAA,CACA,qBAAA,CACA,aAAA,CACA,0EACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"password-section": "password-section--pOdKx",
	"form-change-password": "form-change-password--ioFqv",
	"label-field": "label-field--SyVIW"
};
export default ___CSS_LOADER_EXPORT___;
