import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import styles from './SupersetSettings.module.sass';
import SectionHeaderFields from '@/components/App/Admin/SupersetSettings/SectionHeaderFields';
import SectionFieldActivity from '@/components/App/Admin/SupersetSettings/SectionFieldActivity';
import SectionTemplate from '@/components/App/Admin/SupersetSettings/SectionTemplate';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { finishCreatingSuperset } from '@/store/actions/admin';
import { SETUP } from '@/constants/admin';
import SectionAdmins from '@/components/App/Admin/SupersetSettings/SectionAdmins';
import { selectSupersetProjects } from '@/store/selectors/admin/superset/projects';
import SectionDeleteProject from '@/components/App/Admin/SupersetSettings/SectionDeleteProject';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';

function SupersetSettings() {
  const modalCreateSuperset = <Outlet />;
  const superset = useSelector(selectSuperset);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const canAccessBtnCreateSuperset = !!Object.keys(
    useSelector(selectSupersetProjects),
  ).length;
  const checkPermission = useCheckPermission();

  if (!Object.keys(superset).length) {
    return <div>loading</div>;
  }

  // todo вынести в отдельный компонент с кнопкой
  const onFinishCreatingSuperset = () => {
    dispatch(
      finishCreatingSuperset({
        formUpdateSuperset: {
          supersetID: superset.supersetId,
          updatedSuperset: {
            superset: { stage: SETUP },
          },
        },
        redirectSettings: {
          url: `/admin/project/${superset.supersetId}`,
          navigate,
        },
      }),
    );
  };

  return (
    <div className={styles['content-creating-superset']}>
      <div className={styles['form-create-superset']}>
        <SectionHeaderFields />

        <SectionFieldActivity />

        <SectionTemplate />

        {checkPermission('delete_superset') && <SectionDeleteProject />}

        {checkPermission('view_admin') && <SectionAdmins />}

        {superset.stage === 0 && (
          <CustomButton
            classNames={styles['btn-create-project']}
            styleTypeBtn="btn-blue btn-large"
            disabled={!canAccessBtnCreateSuperset}
            onClick={onFinishCreatingSuperset}
          >
            Завершить настройку проекта
          </CustomButton>
        )}
      </div>
      {modalCreateSuperset && modalCreateSuperset}
    </div>
  );
}

export default SupersetSettings;