// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tab-icon--6gW6r{display:flex;flex-direction:row;align-items:center;font-size:1rem}.tab-icon--6gW6r.revert--mcj2R{flex-direction:row-reverse}.tab-icon--6gW6r .arrow--O6OLP{transform:rotate(90deg)}.tab-icon--6gW6r span:before{color:var(--main-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/TabIcons/UnitCardTabIcons.module.sass"],"names":[],"mappings":"AAEA,iBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,cAAA,CACA,+BACE,0BAAA,CACF,+BACE,uBAAA,CAEA,6BACE,uBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab-icon": "tab-icon--6gW6r",
	"revert": "revert--mcj2R",
	"arrow": "arrow--O6OLP"
};
export default ___CSS_LOADER_EXPORT___;
