import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from './PercentileFS.module.sass';
import { selectDataForPercentileFS } from '@/store/selectors/admin/superset/process/cuts/factorsCuts';
import Scales from '@/components/App/Admin/Results/Cuts/FactorsCuts/PercentileFS/Scales';
import Factor from '@/components/App/Admin/Results/Cuts/FactorsCuts/PercentileFS/Factor';

function PercentileFS() {
  const { itemID: fsIndx } = useParams();
  const { factorsName, percentileFactors } = useSelector((state) => selectDataForPercentileFS(state, fsIndx));

  return (
    <div className={styles['wrap-percentile-fs']}>
      <Scales />

      <ul className={styles['list-of-factors']}>
        {factorsName.map((factorName, factorIndx) => {
          const factorKey = `factor-${factorIndx}`;
          const factorData = percentileFactors[factorIndx];

          return (
            <li key={factorKey} className={styles['item-factor']}>
              <Factor factorData={{ factorName, ...factorData }} />
            </li>
          );
        })}
      </ul>

      <Scales />
    </div>
  );
}

export default PercentileFS;