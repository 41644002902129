// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".action-menu-block--FiJFA{display:flex;justify-content:right;align-items:center;padding:4px 0 0 0}.select-button--SQG1u{height:32px;width:33rem}.select-option--vvUNw{height:32px}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitActionMenu.module.sass"],"names":[],"mappings":"AAEA,0BACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,iBAAA,CAEF,sBACE,WAAA,CACA,WAAA,CACF,sBACE,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"action-menu-block": "action-menu-block--FiJFA",
	"select-button": "select-button--SQG1u",
	"select-option": "select-option--vvUNw"
};
export default ___CSS_LOADER_EXPORT___;
