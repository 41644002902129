import React, { useMemo, useState } from 'react';
import { LOCAL_STORAGE_THEME_KEY, Theme, ThemeContext } from '@/theme/themeContext';
import { setThemeToHtml } from '@/theme/useTheme';

const getDefaultTheme = (singleTheme: Theme | undefined): Theme => {
  if (singleTheme) {
    setThemeToHtml(singleTheme);
    return singleTheme;
  }
  const themeByLocal = localStorage.getItem(LOCAL_STORAGE_THEME_KEY) as Theme;
  setThemeToHtml(themeByLocal);
  if (themeByLocal) return themeByLocal;
  const themeBySystem = window.matchMedia
  && window.matchMedia('(prefers-color-scheme: dark)').matches
    ? Theme.DARK
    : undefined;
  const newTheme = themeBySystem || Theme.LIGHT;
  setThemeToHtml(newTheme);
  return themeBySystem || Theme.LIGHT;
};

function ThemeProvider(
  {
    children,
    singleTheme = undefined,
  }: { children: React.ReactNode, singleTheme ?: Theme },
) {
  const [theme, setTheme] = useState<Theme>(getDefaultTheme(singleTheme));

  const defaultProps = useMemo(() => ({
    theme,
    setTheme,
    singleTheme,
  }), [singleTheme, theme]);

  return (
    <ThemeContext.Provider value={defaultProps}>
      {children}
    </ThemeContext.Provider>
  );
}

export default ThemeProvider;