// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".status-table--HcvDR{display:flex;gap:1.5rem;padding:0 0.5rem 0 1.25rem}.status-table--HcvDR:not(:last-child){border-bottom:1px solid var(--secondary-color)}.assessment-status--NtjR4{position:relative}.status-column--P86nP{display:flex;flex-direction:column;align-items:flex-end}.finished--prPtH{text-align:right;font-size:1.75rem;font-weight:400}.last-active--G4yVq{text-align:right;font-size:1.25rem;font-weight:400}.total--edbsi{text-align:right;font-size:1.75rem}.tooltip--zQaTo{position:absolute;top:0;padding:0.5rem 1rem;border-radius:0.25rem;border:1px solid var(--secondary-color);font-weight:400;color:var(--main-color);background:var(--content-block-bg);font-size:1rem}.tooltip--zQaTo.right--BzCxs{right:calc(100% + 16px)}.tooltip--zQaTo.left--0IBLL{left:calc(100% + 16px)}.info-text--hioT2{text-align:right;line-height:0.875em}.info-text--hioT2 .lastActive---ctra{font-size:0.6875em;font-weight:350;color:var(--admin-main-font-color)}.info-text--hioT2 .started--oB1-4{font-size:0.5625em;font-weight:200;color:var(--admin-main-font-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Superset/HeaderSuperset/AssessmentStatus/AssessmentStatus.module.sass"],"names":[],"mappings":"AAEA,qBACI,YAAA,CACA,UAAA,CACA,0BAAA,CACA,sCACI,8CAAA,CAER,0BACI,iBAAA,CAEJ,sBACI,YAAA,CACA,qBAAA,CACA,oBAAA,CAEJ,iBACI,gBAAA,CACA,iBAAA,CACA,eAAA,CAEJ,oBACI,gBAAA,CACA,iBAAA,CACA,eAAA,CAEJ,cACI,gBAAA,CACA,iBAAA,CAEJ,gBACI,iBAAA,CACA,KAAA,CACA,mBAAA,CACA,qBAAA,CACA,uCAAA,CACA,eAAA,CACA,uBAAA,CACA,kCAAA,CACA,cAAA,CACA,6BACI,uBAAA,CACJ,4BACI,sBAAA,CAER,kBACI,gBAAA,CACA,mBAAA,CACA,qCACI,kBAAA,CACA,eAAA,CACA,kCAAA,CACJ,kCACI,kBAAA,CACA,eAAA,CACA,kCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status-table": "status-table--HcvDR",
	"assessment-status": "assessment-status--NtjR4",
	"status-column": "status-column--P86nP",
	"finished": "finished--prPtH",
	"last-active": "last-active--G4yVq",
	"total": "total--edbsi",
	"tooltip": "tooltip--zQaTo",
	"right": "right--BzCxs",
	"left": "left--0IBLL",
	"info-text": "info-text--hioT2",
	"lastActive": "lastActive---ctra",
	"started": "started--oB1-4"
};
export default ___CSS_LOADER_EXPORT___;
