import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { CreateWebinarFieldsType, CreateWebinarFielsNamesEnum } from '../../../../types/webinarPageTypes';
import CreateWebinarPagePreview from '../../../CreateWebinarPagePreview/CreateWebinarPagePreview';
import WebinarRegisterPagePreview from './WebinarRegisterPagePreview/WebinarRegisterPagePreview';
import styles from './WebinarDescriptionPreview.module.sass';

interface WebinarDescriptionPreviewProps extends UseFormReturn<CreateWebinarFieldsType> {

}
export default function WebinarDescriptionPreview({ ...formProps }: WebinarDescriptionPreviewProps) {
  const { watch } = formProps;
  const webinarName = watch(CreateWebinarFielsNamesEnum.WebinarName);
  const webinarTitle = watch(CreateWebinarFielsNamesEnum.Title);
  const webinarDescription = watch(CreateWebinarFielsNamesEnum.Description);
  const registerPreview = watch(CreateWebinarFielsNamesEnum.RegisterText);
  return (
    <div className={styles['description-preview']}>
      <h1 className={styles.title}>{webinarName}</h1>
      <CreateWebinarPagePreview
        webinarTitle={webinarTitle}
        webinarDescription={webinarDescription}
      />
      <WebinarRegisterPagePreview description={registerPreview} />
    </div>
  );
}