import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import styles from './Factor.module.sass';
import SVGChart from '@/components/App/Admin/Results/Cuts/SVGChart';
import { calcOptionPercents } from '@/components/App/Admin/Results/Cuts/helpers';
import StatisticsValues from '@/components/App/Admin/Results/Cuts/StatisticsValues';

function Factor({ factorData }) {
  const {
    factorName: { leftPole, rightPole },
    values,
    otherValues,
    answersCodes,
  } = factorData;

  const areThereDataForUnselected = !otherValues.every((stat) => stat === 0);
  const optionPercents = calcOptionPercents(values);

  const renderOptionValues = (percentagesOptionValues) => percentagesOptionValues.map((value, i) => {
    const key = `value-${i}`;

    return (
      <li key={key} className={styles['wrap-option-value']}>
        {`${Math.round(value)}%`}
      </li>
    );
  });

  return (
    <div className={styles['wrap-factor-chart']}>
      {leftPole && (
        <div className={`${styles['pole-name']} ${styles['left-pole-name']}`}>
          {leftPole}
        </div>
      )}

      <ul className={styles['list-of-option-values']}>
        {renderOptionValues(optionPercents)}
      </ul>

      <SVGChart
        values={values}
        externalClasses={{ svgChart: styles['svg-chart'] }}
      />

      {areThereDataForUnselected && (
        <SVGChart
          chartForUnselected={areThereDataForUnselected}
          values={otherValues}
          externalClasses={{
            svgUnselectedChart: styles['svg-unselected-chart'],
          }}
        />
      )}

      <div className={`${styles['pole-name']} ${styles['right-pole-name']}`}>
        {rightPole}
      </div>

      <StatisticsValues
        itemID={uuidv4()}
        answerValues={values}
        answerValuesOthers={otherValues}
        answerCodes={answersCodes}
        externalClassNames={{
          wrapStatisticsValues: styles['wrap-statistics-values'],
          wrapValues: styles['wrap-values'],
          iconValue: styles['icon-value'],
        }}
      />
    </div>
  );
}

Factor.propTypes = {
  factorData: PropTypes.shape({
    factorName: PropTypes.shape({
      leftPole: PropTypes.string,
      rightPole: PropTypes.string,
    }),
    values: PropTypes.arrayOf(PropTypes.number).isRequired,
    otherValues: PropTypes.arrayOf(PropTypes.number).isRequired,
    answersCodes: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
};

export default Factor;