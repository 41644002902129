import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axiosProcessing from '@/axiosConfig';
import {
  BackendUnit, CreateBUnitActionArgs, DeleteUnitActionArgs, GetRelatedUnitsActionArgs,
  GetSupersetUnitsActionArgs, MakeUnitsGroupOperationsActionArgs,
  MakeUnitsTreeActionArgs, SelectTreeUnitsActionArgs, UpdateTreeUnitsActionArgs, UpdateUnitRolesActionArgsArgs,
} from '@/store/types/admin/superset/unitsTree';
import { AdminState } from '@/store/admin';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';

export const getSupersetUnits = createAsyncThunk(
  'superset/unitsTree/getSupersetUnits',
  async ({ supersetId, flag }: GetSupersetUnitsActionArgs, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<BackendUnit[]>(
        `/api/admin/getNuklonUnits/${supersetId}/${flag}`,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const makeUnitsTree = createAction<MakeUnitsTreeActionArgs>('superset/unitsTree/makeUnitsTree');

export const getRelatedUnits = createAsyncThunk(
  'superset/unitsTree/getRelatedUnits',
  async ({ supersetId, unitId }: GetRelatedUnitsActionArgs, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get(
        `/api/admin/getHowtoInfo/${supersetId}/${unitId}`,
      );
      return { ...response.data, unitId };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateTreeUnits = createAsyncThunk(
  'superset/unitsTree/updateTreeUnits',
  async ({ supersetId, unitsData }: UpdateTreeUnitsActionArgs, { getState, rejectWithValue }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;
      await axiosProcessing.put(
        `/api/admin/updateNuklonUnits/${supersetId}`,
        unitsData,
        {
          headers,
        },
      );
      return unitsData;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const selectTreeUnits = createAsyncThunk(
  'superset/unitsTree/selectTreeUnits',
  async (
    { supersetId, unitsData, isSelect }: SelectTreeUnitsActionArgs,
    { getState, rejectWithValue },
  ) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;
      const responseData = unitsData.map((unitId) => ({
        unitId,
        selected: isSelect,
      }));
      await axiosProcessing.put(
        `/api/admin/selectNuklonUnits/${supersetId}`,
        responseData,
        {
          headers,
        },
      );
      return { unitsData, isSelect };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const makeUnitsGroupOperations = createAsyncThunk(
  'superset/makeUnitsGroupOperation',
  async (data: MakeUnitsGroupOperationsActionArgs, { getState, rejectWithValue }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;
      const response = await axiosProcessing.put(
        '/api/admin/groupOperation',
        data,
        {
          headers,
        },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createBUnit = createAsyncThunk(
  'superset/unitsTree/createBUnit',
  async (data: CreateBUnitActionArgs, { getState, rejectWithValue }) => {
    try {
      const { unitData, isLocal } = data;
      const state = getState() as AdminState;
      const { supersetId } = selectSuperset(state);
      if (!isLocal) {
        const { headers } = state.admin.authentication;
        const response = await axiosProcessing.post<BackendUnit>(
          '/api/admin/createUnit',
          { ...unitData, supersetId },
          {
            headers,
          },
        );
        return { ...response.data, ...unitData };
      }
      const order = [...state.admin.superset.unitsTree.order];
      const newId = `${Math.min(...order.map((id) => Number(id))) - 1}`;
      return { id: newId, ...unitData };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteUnit = createAsyncThunk(
  'superset/unitsTree/deleteUnit',
  async ({ unitId }: DeleteUnitActionArgs, { rejectWithValue, getState }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;
      await axiosProcessing.delete('/api/admin/deleteUnit', {
        headers,
        data: { unitId },
      });

      return { unitId };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateUnitRoles = createAsyncThunk(
  'superset/unitsTree/updateRoles',
  async (data: UpdateUnitRolesActionArgsArgs, { rejectWithValue, getState }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;
      const {
        unitData, supersetId, unitId, type,
      } = data;

      const form = {
        [type]: Object.keys(unitData).reduce((acc, id) => ({ ...acc, [id]: unitData[id].role }), {}),
      };

      await axiosProcessing.put(
        `/api/admin/updateNuklonUnits/${supersetId}`,
        [{
          ID: unitId,
          roles: form,
        }],
        { headers },
      );

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);