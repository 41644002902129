import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './AdminInputFile.module.sass';

function AdminInputFile({
  id,
  name,
  label,
  onChange: externalOnChange,
  register,
  registerOptions,
}) {
  const inputFile = useRef(null);
  const [isFileSelected, setStatusSelectedFile] = useState(false);
  const [fileName, setFileName] = useState(null);
  const {
    onChange: onChangeRegister,
    ref: refRegister,
    ...registerRest
  } = { ...register(name, registerOptions) };

  const onFileSelected = (e) => {
    const { files } = e.target;

    if (files.length > 0) {
      setStatusSelectedFile(true);
      setFileName(e.target.files[0].name);

      externalOnChange(e);
      onChangeRegister(e);
    } else {
      setStatusSelectedFile(false);
      setFileName(null);
    }
  };

  return (
    <div
      className={`${styles['admin-input-file']} ${
        isFileSelected ? styles['file-selected'] : ''
      }`}
    >
      <input
        id={id}
        {...(register ? { ...registerRest } : { name })}
        onChange={onFileSelected}
        ref={(e) => {
          refRegister(e);
          inputFile.current = e;
        }}
        type="file"
        className="visually-hidden"
      />
      <label className={styles.label} htmlFor={id}>
        {!isFileSelected && label}
        {isFileSelected && fileName}
        <span className={`${styles['input-icon']} icon-download-file`} />
      </label>
    </div>
  );
}

AdminInputFile.defaultProps = {
  errorMessage: '',
  onChange: () => {},
  register: null,
  registerOptions: {},
};

AdminInputFile.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  register: PropTypes.func,
  registerOptions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
};

export default AdminInputFile;