import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { selectAdmin } from '@/store/selectors/admin/authentication';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';

const useLocalStorageByAdmin = <T>(
  key: string,
  withSupersetId: boolean = true,
  withAdminId: boolean = true,
): [(data: T) => void, () => T | undefined] => {
  const { adminId } = useSelector(selectAdmin);
  const { supersetId } = useSelector(selectSuperset);

  const generateKey = useCallback(() => {
    let localKey = key;
    if (withAdminId && adminId) localKey = `${adminId}-${key}`;
    if (withSupersetId && supersetId) localKey = `${supersetId}-${localKey}`;
    return localKey;
  }, [adminId, key, supersetId, withAdminId, withSupersetId]);

  const writeInLocalStorage = useCallback(
    (data: T) => {
      const jsonData = JSON.stringify({ data });
      localStorage.setItem(generateKey(), jsonData);
    },
    [generateKey],
  );

  const readFromLocalStorage = useCallback(() => {
    const dataInLocal = localStorage.getItem(generateKey());
    if (!dataInLocal) return undefined;
    const jsonData: { data: T } = JSON.parse(dataInLocal);
    return jsonData?.data;
  }, [generateKey]);

  return [writeInLocalStorage, readFromLocalStorage];
};

export default useLocalStorageByAdmin;