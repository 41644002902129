import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './ItemSwitcher.module.sass';
import { selectItemsByTypeCuts } from '@/store/selectors/admin/superset/process/cuts/cuts';

function ItemSwitcher() {
  const { itemID, typeCuts } = useParams();
  const activeItems = useSelector((state) => selectItemsByTypeCuts(state, typeCuts));
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const switchToNextQuestion = (currentItemID, direction) => {
    const indxCurrentItemID = activeItems.findIndex(
      (item) => item.id === currentItemID,
    );
    let switchedItemID;

    if (indxCurrentItemID === 0 && direction === 'prev') {
      switchedItemID = activeItems[activeItems.length - 1].id;
    } else if (
      indxCurrentItemID === activeItems.length - 1
      && direction === 'next'
    ) {
      switchedItemID = activeItems[0].id;
    } else {
      switchedItemID = activeItems[
        direction === 'next' ? indxCurrentItemID + 1 : indxCurrentItemID - 1
      ].id;
    }

    const indxLastSlash = pathname.lastIndexOf('/');
    navigate(`${pathname.slice(0, indxLastSlash)}/${switchedItemID}`);
  };

  return (
    <div className={styles['wrap-item-switcher']}>
      <button
        type="button"
        className={`btn-without-style ${styles['btn-item-switcher']}`}
        onClick={() => switchToNextQuestion(itemID, 'prev')}
      >
        {' '}
        <span className={`icon-arrow ${styles['icon-arrow']} ${styles.prev}`} />
      </button>
      <button
        type="button"
        className={`btn-without-style ${styles['btn-item-switcher']}`}
        onClick={() => switchToNextQuestion(itemID, 'next')}
      >
        {' '}
        <span className={`icon-arrow ${styles['icon-arrow']} ${styles.next}`} />
      </button>
    </div>
  );
}

export default ItemSwitcher;