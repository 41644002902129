import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  selectAvailableTypeFSBasedOnActiveTypeFS,
  selectFactorsCuts,
} from '@/store/selectors/admin/superset/process/cuts/factorsCuts';
import styles from './FSswitcher.module.sass';
import BaseSelect from '@/components/Common/form/BaseSelect';
import { setActiveTypeFS } from '@/store/actions/admin/superset/process/cuts/factorsCuts';

function FSswitcher() {
  const [selectedFSIndx, setSelectedFSIndx] = useState(null);
  const typeFSforSelectedFS = useSelector((state) => selectAvailableTypeFSBasedOnActiveTypeFS(state, selectedFSIndx));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const factorsCuts = useSelector(selectFactorsCuts);
  const optionsFSName = factorsCuts.map(({ fsName }, i) => ({
    value: i,
    label: fsName,
  }));
  const shouldNavigate = useRef(false);

  const onSwitchFS = (options) => {
    setSelectedFSIndx(options.value);
    shouldNavigate.current = true;
  };

  useEffect(() => {
    if (selectedFSIndx !== null && shouldNavigate.current) {
      shouldNavigate.current = false;

      dispatch(setActiveTypeFS(typeFSforSelectedFS));
      const indxLastSlash = pathname.lastIndexOf('/');
      navigate(`${pathname.slice(0, indxLastSlash)}/${selectedFSIndx}`);
    }
  }, [dispatch, navigate, pathname, typeFSforSelectedFS, selectedFSIndx]);

  return (
    <div className={styles['wrap-fs-switcher']}>
      <BaseSelect
        placeholder="Выберите факторную структуру"
        options={optionsFSName}
        onChange={onSwitchFS}
        classNames={{
          container: () => styles['fs-switcher-container'],
        }}
      />
    </div>
  );
}

export default FSswitcher;