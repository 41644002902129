import React from 'react';
import styles from './DataCircle.module.sass';
import { RatingResultByScoreType } from '@/store/types/admin/superset/cuts/fourD';

interface DataCircleProps {
  ratingItem: RatingResultByScoreType;
  circlesAreaWidth: number;
  circlesAreaHeight: number;
}

function DataCircle({ ratingItem, circlesAreaWidth, circlesAreaHeight }: DataCircleProps) {
  const {
    id, xPerc, yPerc, size, color,
  } = ratingItem;

  const mapSizeToRadius = (cSize: number) => {
    const minRadius = 10;
    const maxRadius = 25;

    // using a linear interpolation formula
    return minRadius + ((cSize / 100) * (maxRadius - minRadius));
  };

  const calculateCX = (xPosition: number, areaWidth: number) => (
    (xPosition / 100) * areaWidth
  );

  const calculateCY = (yPosition: number, areaHeight: number) => (
    areaHeight - ((yPosition / 100) * areaHeight)
  );

  return (
    <circle
      id={id}
      key={id}
      cx={calculateCX(xPerc, circlesAreaWidth)}
      cy={calculateCY(yPerc, circlesAreaHeight)}
      r={mapSizeToRadius(size)}
      className={styles['data-circle']}
      fill={color || '#138bc5'}
      onClick={() => {
        console.log(id, 'id');
        console.log(xPerc, 'xPerc');
        console.log(yPerc, 'yPerc');
        console.log(size, 'size');
      }}
    />
  );
}

export default DataCircle;