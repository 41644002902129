// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".criteria-list--ni7TJ{margin-top:1.875rem;display:flex;flex-wrap:wrap;justify-content:space-between;gap:0.9375rem;border-bottom:1px solid var(--dark-grey-line-color);padding-bottom:1.875rem}.criteria-list--ni7TJ .wrap-criteria--r5CGT .title--5B1nT{font-family:\"Inter Medium\",sans-serif;margin-bottom:0.625rem}.criteria-list--ni7TJ .wrap-criteria--r5CGT .wrap-criteria-select--5iFL5{width:28.125rem}.criteria-list--ni7TJ .custom-criteria-option--Il\\+Yl{position:relative;width:100%}.criteria-list--ni7TJ .custom-criteria-option--Il\\+Yl .checkmark--Q0J-h{position:absolute;left:0;top:44%;transform:translateY(-50%)}.criteria-list--ni7TJ .custom-criteria-option--Il\\+Yl .label--jvlEM{display:inline;margin-left:1.25rem}.criteria-list--ni7TJ .custom-criteria-option--Il\\+Yl .wrap-amount-actors--5KbCM{display:flex;align-items:center;flex-direction:row-reverse;gap:0.3125rem;position:absolute;right:0;top:50%;transform:translateY(-50%)}.criteria-list--ni7TJ .custom-criteria-option--Il\\+Yl .wrap-amount-actors--5KbCM .icon-employee--t\\+MP5::before{color:#fff}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/WrapperCuts/Criteria/ListOfCriteria/ListOfCriteria.module.sass"],"names":[],"mappings":"AAEA,sBACE,mBAAA,CACA,YAAA,CACA,cAAA,CACA,6BAAA,CACA,aAAA,CACA,mDAAA,CACA,uBAAA,CAEE,0DACE,qCAAA,CACA,sBAAA,CACF,yEACE,eAAA,CACJ,sDACE,iBAAA,CACA,UAAA,CACA,wEACE,iBAAA,CACA,MAAA,CACA,OAAA,CACA,0BAAA,CACF,oEACE,cAAA,CACA,mBAAA,CACF,iFACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,aAAA,CACA,iBAAA,CACA,OAAA,CACA,OAAA,CACA,0BAAA,CAEE,gHACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"criteria-list": "criteria-list--ni7TJ",
	"wrap-criteria": "wrap-criteria--r5CGT",
	"title": "title--5B1nT",
	"wrap-criteria-select": "wrap-criteria-select--5iFL5",
	"custom-criteria-option": "custom-criteria-option--Il+Yl",
	"checkmark": "checkmark--Q0J-h",
	"label": "label--jvlEM",
	"wrap-amount-actors": "wrap-amount-actors--5KbCM",
	"icon-employee": "icon-employee--t+MP5"
};
export default ___CSS_LOADER_EXPORT___;
