import { useCallback } from 'react';
import axios from 'axios';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { WebinarUploadFilePostForm } from '../types/webinarFiles';

export const useSingleFileUpload = () => {
  const { headers } = useAdminSelector((state) => state.admin.authentication);

  const uploadFile = useCallback(async (data: WebinarUploadFilePostForm) => {
    try {
      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('entity', data.entity);
      formData.append('place', data.place);

      const resp = await axios.post('/api/upload', formData, {
        headers: {
          ...headers,
        },
      });

      return resp;
    } catch (error) {
      return error;
    }
  }, [headers]);

  return uploadFile;
};

export const useMultipleFilesUpload = () => {
  const uploadFile = useSingleFileUpload();

  const uploadMultipleFiles = useCallback(async (files: WebinarUploadFilePostForm[]) => {
    try {
      return await Promise.all(files.map((fileData) => uploadFile(fileData)));
    } catch (err) {
      return err;
    }
  }, [uploadFile]);

  return uploadMultipleFiles;
};