import React, { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import PasswordWarning from '@/components/Common/BaseTemplate/Header/PasswordWarning/PasswordWarning';
import AdminWidgets from '@/components/Common/BaseTemplate/Header/HeaderWidgets/AdminWidgets';
import HeaderContainer from '@/components/Common/BaseTemplate/Header/HeaderContainer/HeaderContainer';
import styles from './Header.module.sass';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import { selectAdmin } from '@/store/selectors/admin/authentication';
import useLongPooling from '@/hooks/longPooling';
import { addNotification } from '@/store/reducers/notify';
import { getClearClassNames } from '@/helpers/helpers';
import axiosProcessing from '@/axiosConfig';

interface GetAlertReturn {
  type?: string;
  message?: string;
  lifeTime?: number;
  supersetId?: string;
  supersetName?: string;
}

function AdminHeader() {
  const admin = useAdminSelector(selectAdmin);
  const dispatch = useAdminDispatch();

  const getAlert = useCallback(() => axiosProcessing.get<GetAlertReturn>('/api/admin/getalert'), []);

  const { isSubscribed, handleSubscribe, isDelayed } = useLongPooling<AxiosResponse<GetAlertReturn>, undefined>({
    action: getAlert,
    isSubscribeAvailable: !!admin,
    successfulCallback: (response) => {
      const message: GetAlertReturn | undefined = response?.data;

      if (!message?.message) return;
      dispatch(
        addNotification(
          { type: message.type || 'info', ...message } as NotifyType,
        ),
      );
    },
  });

  return (
    <HeaderContainer isSuper={admin?.isSuper} widgets={<AdminWidgets />}>
      <div className={styles['header-admin-title-page']}>
        Project management
        {
         !!admin && admin.isSuper && (
           <button
             className={getClearClassNames([
               styles['subscribe-btn'],
               isSubscribed && styles['is-subscribed'],
               isDelayed && styles['is-delayed'],
             ])}
             onClick={handleSubscribe}
             type="button"
           >
             {' '}
           </button>
         )
        }
      </div>
      <PasswordWarning />
    </HeaderContainer>
  );
}

export default AdminHeader;