export enum TabsType {
  Buttons = 'buttons',
  Links = 'links',
}

export interface Tab {
  isHide?: boolean;
  id: string;
  title: string;
}

export interface ButtonTabInterface extends Tab {
  isActive: boolean;
  onClick: () => void;
}

export interface LinkTabInterface extends Tab {
  link: string;
  end: boolean;
  replace: boolean;
}