import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  CreateWebinarFormSectionType,
  CreateWebinarFieldsType,
} from '../../types/webinarPageTypes';
import {
  WebinarMetaFieldsNames,
  WebinarSpeakerFieldsNames,
  WebinarDescripionFieldsNames,
} from '../../consts/createWebinarPageConsts';
import WebinarContentSection from '../WebinarContentSection/WebinarContentSection';

interface CreateWebinarFormFieldProps extends UseFormReturn<CreateWebinarFieldsType> {

}

export default function CreateWebinarFormSections({ ...formProps }: CreateWebinarFormFieldProps) {
  return (
    <>
      <WebinarContentSection
        names={WebinarMetaFieldsNames}
        sectionType={CreateWebinarFormSectionType.Meta}
        {...formProps}
      />
      <WebinarContentSection
        names={WebinarSpeakerFieldsNames}
        sectionType={CreateWebinarFormSectionType.Speaker}
        {...formProps}
      />
      <WebinarContentSection
        names={WebinarDescripionFieldsNames}
        sectionType={CreateWebinarFormSectionType.Content}
        {...formProps}
      />
    </>
  );
}