import React from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import useProcessor from '@/components/App/Admin/Results/hooks/useProcessor';
import { DURING_SURVEY_PROCESS } from '@/constants/admin';
import Preloader from '@/components/App/Admin/SupersetSettings/Preloader';
import styles from './LoadResults.module.sass';
import useNLS from '@/components/App/Admin/hooks/hooks';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';
import CreateCheckpoint from '@/components/App/Admin/Results/LoadResults/CreateCheckpoint/CreateCheckpoint';
import CalculateResults from '@/components/App/Admin/Results/LoadResults/CalculateResults/CalculateResults';

function LoadResults() {
  const tNLS = useNLS();
  const resultPage = <Outlet />;
  const { supersetId, stage } = useSelector(selectSuperset);
  const { received, hasEvaluated, isProcessorRunning } = useProcessor(
    supersetId,
    stage,
  );
  const checkPermission = useCheckPermission();

  if (stage < DURING_SURVEY_PROCESS) {
    return <div>{tNLS('results.bad.stage')}</div>;
  }

  if (!received) {
    return <Preloader />;
  }

  if (
    received
    && !hasEvaluated
    && !isProcessorRunning
    && checkPermission('run_processor')
  ) {
    return (
      <div className={styles['wrap-info']}>
        {tNLS('results.has.not.calulated')}
        {checkPermission('run_processor') && (
          <>
            <CreateCheckpoint />
            <CalculateResults />
          </>
        )}
      </div>
    );
  }

  if (received && !hasEvaluated && isProcessorRunning) {
    return <Preloader />;
  }

  return (
    <div className={styles['wrap-result']}>
      {checkPermission('run_processor') && (
        <div className={styles.header}>
          <CreateCheckpoint />
          <CalculateResults />
        </div>
      )}
      {resultPage}
    </div>
  );
}

export default LoadResults;