import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './RadioBlockSettings.module.sass';
import generalStyles from '../settingsStyle.module.sass';
import RadioButton from '@/components/Common/form/RadioButton';
import checkSettingsFitsCondition from '@/components/App/Admin/SupersetSettings/SectionAdminSettings/helpers';
import ProhibitInteractivity from '@/components/App/Admin/SupersetSettings/ProhibitInteractivity';
import { selectAdminSettings } from '@/store/selectors/admin/superset/settings';
import { setAssessmentSettings } from '@/store/actions/admin/superset/settings';

function RadioBlockSettings({ setting }) {
  const dispatch = useDispatch();
  const { id: supersetID } = useParams();
  const settings = useSelector(selectAdminSettings);

  const {
    settingId: settingID,
    name,
    description,
    cases,
    value: settingValue,
    cond: conditional,
  } = setting;

  const doesSettingFitCondition = checkSettingsFitsCondition(
    conditional,
    settings,
  );

  const setValueOnRadioGroup = useCallback(
    (value) => {
      dispatch(
        setAssessmentSettings({
          supersetID,
          form: {
            settings: [
              {
                settingId: settingID,
                value: Number(value),
              },
            ],
          },
        }),
      );
    },
    [dispatch, supersetID, settingID],
  );

  const checkCaseIsChecked = useCallback(
    (caseCondition, caseValue) => {
      const doesCaseFitCondition = checkSettingsFitsCondition(
        caseCondition,
        settings,
      );

      return doesCaseFitCondition && caseValue === settingValue;
    },
    [settings, settingValue],
  );

  // if it turns out that not one of the cases is not checked -->
  // set value as empty
  useEffect(() => {
    let radioBlockIsChecked = false;

    for (let i = 0; i < cases.length; i += 1) {
      const { caseValue, cond } = cases[i];
      const isCaseChecked = checkCaseIsChecked(cond, caseValue);

      if (isCaseChecked) {
        radioBlockIsChecked = true;
        break;
      }
    }

    if (!radioBlockIsChecked) {
      setValueOnRadioGroup(0);
    }
  }, [cases, settings, checkCaseIsChecked, setValueOnRadioGroup]);

  return (
    <div
      className={`${generalStyles['wrap-admin-setting']}
     ${styles['wrap-radio-block-settings']}
     ${!doesSettingFitCondition ? styles.disabled : ''}`}
    >
      <div className={generalStyles['title-setting']}>{name}</div>
      <div className={generalStyles['description-setting']}>{description}</div>
      <div className={styles['wrap-options']}>
        {cases.map(({ caseText, caseValue, cond: caseCondition }, i) => {
          const isChecked = checkCaseIsChecked(caseCondition, caseValue);
          const doesCaseFitCondition = checkSettingsFitsCondition(
            caseCondition,
            settings,
          );

          return (
            <ProhibitInteractivity key={`${settingID + i}`}>
              <RadioButton
                name={`${settingID}Setting`}
                id={`${settingID}Setting-${i}`}
                value={caseValue}
                onChange={(e) => setValueOnRadioGroup(e.target.value)}
                label={caseText}
                checked={isChecked}
                externalClassNames={{
                  wrapOption: styles['option-type-usage'],
                  textLabel: styles['option-text-label'],
                }}
                disabled={!doesSettingFitCondition || !doesCaseFitCondition}
              />
            </ProhibitInteractivity>
          );
        })}
      </div>
    </div>
  );
}

RadioBlockSettings.propTypes = {
  setting: PropTypes.shape({
    settingId: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    cases: PropTypes.arrayOf(
      PropTypes.shape({
        caseText: PropTypes.string,
        caseValue: PropTypes.number,
        cond: PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        ),
      }),
    ),
    cond: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
};

export default RadioBlockSettings;