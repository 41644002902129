// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".general-section--tlrrB .form-admin-profile--1HOl-{display:flex;flex-direction:column;gap:0.9375rem}.general-section--tlrrB .form-admin-profile--1HOl- .label-field--rLCPr{width:100%}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/AdminProfile/GeneralSection/GeneralSection.module.sass"],"names":[],"mappings":"AAGE,mDACE,YAAA,CACA,qBAAA,CACA,aAAA,CACA,uEACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"general-section": "general-section--tlrrB",
	"form-admin-profile": "form-admin-profile--1HOl-",
	"label-field": "label-field--rLCPr"
};
export default ___CSS_LOADER_EXPORT___;
