import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import ProhibitInteractivity from '@/components/App/Admin/SupersetSettings/ProhibitInteractivity';
import CheckBox from '@/components/Common/form/CheckBox';
import { updateSuperset } from '@/store/actions/admin';
import useNLS from '@/components/App/Admin/hooks/hooks';

function IsTemplateForCopyCheckbox() {
  const { supersetId, isTemplate } = useSelector(selectSuperset);
  const dispatch = useDispatch();
  const tNLS = useNLS();

  const handleChange = () => {
    dispatch(
      updateSuperset({
        supersetID: supersetId,
        updatedSuperset: {
          superset: { isTemplate: !isTemplate },
        },
      }),
    );
  };

  return (
    <ProhibitInteractivity>
      <CheckBox
        name={`${supersetId}toggleCopyTemplate`}
        id={`${supersetId}toggleCopyTemplate`}
        label={tNLS('template.project')}
        onChange={handleChange}
        checked={isTemplate}
      />
    </ProhibitInteractivity>
  );
}

export default IsTemplateForCopyCheckbox;