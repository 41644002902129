import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './ListActiveItems.module.sass';
import { getClearClassNames, scrollToUpperBorder } from '@/helpers/helpers';
import './themeVars.sass';
import MacrosReplacer from '@/components/App/Admin/MacrosReplacer';
import { selectItemsByTypeCuts } from '@/store/selectors/admin/superset/process/cuts/cuts';

function ListActiveItems({ chartSectionRef }) {
  const { itemID: currentItemID, typeCuts } = useParams();
  const currentItems = useSelector((state) => selectItemsByTypeCuts(state, typeCuts));
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onSelectItem = (itemID) => {
    const indxLastSlash = pathname.lastIndexOf('/');
    navigate(`${pathname.slice(0, indxLastSlash)}/${itemID}`);
    scrollToUpperBorder(chartSectionRef.current, 500, 50);
  };

  const renderQuestions = () => currentItems.map(({ id, title: titleQ }, i) => (
    <li
      key={id}
      className={getClearClassNames([
        styles['item-question'],
        currentItemID === id ? styles.selected : null,
      ])}
    >
      <button
        type="button"
        className={`${styles['btn-select-item']} btn-without-style`}
        onClick={() => {
          onSelectItem(id);
        }}
      >
        {`${i + 1}. `}
        <MacrosReplacer>{titleQ}</MacrosReplacer>
      </button>
    </li>
  ));

  return (
    <div className={styles['wrap-list-items']}>
      <ul className={styles['list-of-questions']}>{renderQuestions()}</ul>
    </div>
  );
}

ListActiveItems.propTypes = {
  chartSectionRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    .isRequired,
};

export default ListActiveItems;