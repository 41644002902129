import { RefObject, useCallback, useRef } from 'react';

type UseNotificationTopHookReturn<F extends HTMLElement> = [ref: RefObject<F>, Function];

function useNotificationTop<T extends HTMLElement>(
  index: number,
  isHidden: boolean,
):UseNotificationTopHookReturn<T> {
  const ref = useRef<T>(null);

  const calculateTopForHiddenElement = useCallback((notifyNodes: NodeListOf<T>): string => {
    if (!ref.current) return '0px';
    const firstContainerHeight = notifyNodes[0].offsetHeight;
    const coefficient = index <= 2 ? index : 3;
    return `${firstContainerHeight - ref.current.offsetHeight + 20 + 10 * coefficient}px`;
  }, [index]);

  const calculateTopForVisibleElement = useCallback((notifyNodes: NodeListOf<T>): string => {
    let newTop = 0;
    for (let i = 0; i < index; i += 1) {
      newTop += notifyNodes[i].offsetHeight + 15;
    }
    return `${newTop}px`;
  }, [index]);

  const calculateTop = useCallback((): string => {
    if (index === 0) return '0px';
    const currentElement = ref.current;
    const parentElement = currentElement?.parentElement;
    if (!parentElement) return '0px';
    const notifyNodes: NodeListOf<T> = parentElement.querySelectorAll('.notify');
    if (!notifyNodes) return '0px';
    if (isHidden) return calculateTopForHiddenElement(notifyNodes);
    return calculateTopForVisibleElement(notifyNodes);
  }, [calculateTopForHiddenElement, calculateTopForVisibleElement, index, isHidden]);

  return [ref, calculateTop];
}

export default useNotificationTop;