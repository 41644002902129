import React from 'react';

import egoscheme from 'static/img/azimuth_egonet.svg';
import styles from './WebinarSvg.module.sass';

function WebinarSvg() {
  return (
    <img
      src={egoscheme}
      className={styles.diagramm}
      alt="Webinar"
      width="800"
      height="500"
    />
  );
}

export default WebinarSvg;