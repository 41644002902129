// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html[data-theme=dark] .wrap-statistics-values--WTnMt{--icon-type-of-value-color: #ffffff;--value-color: #ffffff}html[data-theme=light] .wrap-statistics-values--WTnMt{--icon-type-of-value-color: #202020;--value-color: #202020}.wrap-statistics-values--WTnMt{display:flex;gap:0.9375rem;width:10.625rem}.wrap-statistics-values--WTnMt .statistics-value--SHb4B{display:flex;gap:0.3125rem;align-items:center}.wrap-statistics-values--WTnMt .statistics-value--SHb4B .icon-type-of-value--FlKQz:before{font-size:1.75rem;color:var(--icon-type-of-value-color)}.wrap-statistics-values--WTnMt .statistics-value--SHb4B .values--86I52{display:flex;flex-direction:column}.wrap-statistics-values--WTnMt .statistics-value--SHb4B .values--86I52 .value--ikTHl,.wrap-statistics-values--WTnMt .statistics-value--SHb4B .values--86I52 .for-unselected-value--Eq3XL{font-size:0.875rem;color:var(--value-color)}.wrap-statistics-values--WTnMt .wrap-tooltip-statistics-values--MvhyE{display:flex;flex-direction:column;max-width:25rem;gap:0.3125rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/StatisticsValues/StatisticsValues.module.sass"],"names":[],"mappings":"AAGE,qDACE,mCAAA,CACA,sBAAA,CAGF,sDACE,mCAAA,CACA,sBAAA,CAEJ,+BACE,YAAA,CACA,aAAA,CACA,eAAA,CACA,wDACE,YAAA,CACA,aAAA,CACA,kBAAA,CAEE,0FACE,iBAAA,CACA,qCAAA,CACJ,uEACE,YAAA,CACA,qBAAA,CACA,yLACE,kBAAA,CACA,wBAAA,CAEN,sEACE,YAAA,CACA,qBAAA,CACA,eAAA,CACA,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-statistics-values": "wrap-statistics-values--WTnMt",
	"statistics-value": "statistics-value--SHb4B",
	"icon-type-of-value": "icon-type-of-value--FlKQz",
	"values": "values--86I52",
	"value": "value--ikTHl",
	"for-unselected-value": "for-unselected-value--Eq3XL",
	"wrap-tooltip-statistics-values": "wrap-tooltip-statistics-values--MvhyE"
};
export default ___CSS_LOADER_EXPORT___;
