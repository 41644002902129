import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import UploadingInput from '@/components/App/Admin/SuperAdmin/SuperUploading/types/consts/hooks/actions/UploadingInput/UploadingInput';
import BUnitsMenuBtn from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitsBlueBtn/BUnitsMenuBtn';
import styles from './WebinarFilesSection.module.sass';
import { useMultipleFilesUpload } from './hooks/useFilesUpload';
import { WebinarFilesFormFields, WebinarUploadFilePostForm } from './types/webinarFiles';
import { ExtByName, WebinarFilesInputByName, WebinarFilesTitlesByName } from './consts/WebinarFilesConsts';

export default function WebinarFilesSection() {
  const formProps = useForm<WebinarFilesFormFields>();
  const { webinarId } = useParams();
  const uploadMultipleFiles = useMultipleFilesUpload();

  const { handleSubmit } = formProps;

  const onSubmit = (data: WebinarFilesFormFields) => {
    const uploadFilesForms: WebinarUploadFilePostForm[] = [];
    Object.entries(data).forEach(([place, files]) => {
      uploadFilesForms
        .push(...files.map((file: File) => ({
          file, place, entity: webinarId,
        })));
    });

    uploadMultipleFiles(uploadFilesForms);
  };

  return (
    <form
      className={styles['upload-files-form']}
      onSubmit={handleSubmit(onSubmit)}
    >
      {WebinarFilesInputByName.map((name) => (
        <UploadingInput
          <WebinarFilesFormFields>
          key={`${name}-file`}
          name={name}
          acceptFilesExt={ExtByName[name]}
          title={WebinarFilesTitlesByName[name]}
          {...formProps}
        />
      ))}
      <BUnitsMenuBtn
        type="confirm"
        btnActionType="submit"
      >
        Сохранить
      </BUnitsMenuBtn>
    </form>
  );
}