import React from 'react';
import PropTypes from 'prop-types';
import styles from './ArrowIcon.module.sass';
import { getClearClassNames } from '@/helpers/helpers';

function ArrowIcon({ classNames, direction }) {
  return (
    <span
      className={getClearClassNames([
        styles['arrow-icon'],
        classNames,
        styles[direction],
      ])}
    />
  );
}

ArrowIcon.defaultProps = {
  classNames: '',
  direction: 'down',
};

ArrowIcon.propTypes = {
  classNames: PropTypes.string,
  direction: PropTypes.string,
};

export default ArrowIcon;