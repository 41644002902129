import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { unwrapResult } from '@reduxjs/toolkit';
import Input from '@/components/Common/form/Input';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import Modal from '@/components/Common/Modal';
import useModal from '@/hooks/modal';
import { selectLanguagesMasterSuperset } from '@/store/selectors/admin/superset/projects';
import { createQuestion } from '@/store/actions/admin/superset/addingQuestions';
import styles from './ModalAddQuestion.module.sass';
import { selectAddingQuestions } from '@/store/selectors/admin/superset/addingQuestions';
import useNLS from '@/components/App/Admin/hooks/hooks';

const keyForInputName = 'formulations';

function ModalAddQuestion({ toggleModal, isShowingModal }) {
  const dispatch = useDispatch();
  const tNLS = useNLS();
  const { id: supersetID } = useParams();
  const languages = useSelector(selectLanguagesMasterSuperset);
  const questionsLength = useSelector(selectAddingQuestions).length;
  const shouldShowLanguageLabel = Object.keys(languages).length > 1;
  const {
    register,
    watch,
    setFocus,
    formState: { errors: errorsFields },
    handleSubmit,
  } = useForm();
  const { Content, WrapBtns } = useModal();
  const valueFields = watch(keyForInputName);

  const isAnyFieldFilled = Object.values(valueFields ?? {}).some(
    (field) => field && field.length > 0,
  );

  useEffect(() => {
    if (isShowingModal) {
      setFocus(`${keyForInputName}.${Object.keys(languages)[0]}`);
    }
  }, [isShowingModal, setFocus, languages]);

  const onCreateQuestion = (data) => {
    const { formulations } = data;

    dispatch(
      createQuestion({
        supersetId: supersetID,
        data: {
          content: formulations,
          isEnabled: true,
          itemId: `custom_${questionsLength + 1}`,
          norder: questionsLength + 1,
        },
      }),
    )
      .then(unwrapResult)
      .then(() => toggleModal())
      .catch(() => null);
  };

  return (
    <Modal
      isShowing={isShowingModal}
      hide={toggleModal}
      title={tNLS('customq.modal.new.item.header')}
      types="modal-small"
    >
      <Content>
        <form className={styles['form-adding-formulations']}>
          {Object.keys(languages).map((langID) => {
            const { native } = languages[langID];

            return (
              <div
                className={styles['wrap-formulation-field']}
                key={`formulations-${langID}`}
              >
                <Input
                  register={register}
                  registerOptions={{
                    maxLength: 500,
                  }}
                  id={`formulations-${langID}`}
                  name={`${keyForInputName}.${langID}`}
                  label={
                    shouldShowLanguageLabel
                      ? `${native}:`
                      : tNLS('customq.modal.enter.text')
                  }
                  externalClasses={{
                    wrapInput: styles['wrap-custom-input'],
                    label: styles['label-input-formulation'],
                  }}
                />

                {/* only for maxlength */}
                {Object.keys(errorsFields).length > 0
                  && errorsFields[keyForInputName][langID] && (
                    <span className={styles['field-error-message']}>
                      {tNLS('customq.message.item.too.long')}
                    </span>
                )}
              </div>
            );
          })}
        </form>
      </Content>
      <WrapBtns>
        <CustomButton onClick={toggleModal} styleTypeBtn="btn-grey-modal">
          {tNLS('customq.modal.new.item.button.cancel')}
        </CustomButton>
        <CustomButton
          onClick={() => {
            handleSubmit(onCreateQuestion)();
          }}
          styleTypeBtn="btn-blue-modal"
          disabled={!isAnyFieldFilled}
        >
          {tNLS('customq.modal.new.item.button.add')}
        </CustomButton>
      </WrapBtns>
    </Modal>
  );
}

ModalAddQuestion.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  isShowingModal: PropTypes.bool.isRequired,
};

export default ModalAddQuestion;