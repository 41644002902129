import React from 'react';
import PropTypes from 'prop-types';
import styles from './Factor.module.sass';
import SVGChart from '@/components/App/Admin/Results/Cuts/SVGChart';

const calculatePercentileSides = (percentile) => {
  const sumSlides = (arr, index) => {
    // Calculate the sum to the left of the index, including the value at the index
    let leftSum = 0;
    for (let i = 0; i <= index; i += 1) {
      leftSum += arr[i];
    }

    // Calculate the sum to the right of the index.
    // We calculate the sum from the following index
    let rightSum = 0;
    for (let i = index + 1; i < arr.length; i += 1) {
      rightSum += arr[i];
    }
    return {
      leftSum,
      rightSum,
    };
  };

  const sumOfAnswers = percentile.reduce((sum, answers) => sum + answers, 0);

  // подсчет слева -> сумма ответов слева / общую сумму * 100
  // подсчет справа -> сумма всех ответов справа / общую сумму * 100;
  // todo написать комменты
  const calculatedSlidesForEachScale = [];

  for (let i = 0; i < percentile.length - 1; i += 1) {
    const { leftSum, rightSum } = sumSlides(percentile, i);

    calculatedSlidesForEachScale.push({
      percentOfAnswersOnLeft: Math.round((leftSum / sumOfAnswers) * 100),
      percentOfAnswersOnRight: Math.round((rightSum / sumOfAnswers) * 100),
    });
  }

  return calculatedSlidesForEachScale;
};

function Factor({ factorData }) {
  const {
    factorName: { leftPole, rightPole },
    values,
    otherValues,
  } = factorData;

  const areThereDataForUnselected = !otherValues.every((stat) => stat === 0);
  const percentileSlidesForEachScale = calculatePercentileSides(values);

  return (
    <div className={styles['wrap-factor-chart']}>
      {leftPole && (
        <div className={`${styles['pole-name']} ${styles['left-pole-name']}`}>
          {leftPole}
        </div>
      )}

      {percentileSlidesForEachScale.map(
        ({ percentOfAnswersOnLeft, percentOfAnswersOnRight }, i) => {
          const key = `scale-${i}`;
          const numberScale = i + 2;

          return (
            <div
              key={key}
              className={`${styles['wrap-scale']} ${
                styles[`scale-${numberScale}`]
              }`}
            >
              <div className={styles['scale-line']} />
              <div className={styles['wrap-labels']}>
                <span className={styles['label-percent-answers-left']}>
                  {percentOfAnswersOnLeft}
                </span>
                <span className={styles['label-percent-answers-right']}>
                  {percentOfAnswersOnRight}
                </span>
              </div>
            </div>
          );
        },
      )}

      <SVGChart
        values={values}
        externalClasses={{ svgChart: styles['svg-chart'] }}
      />

      {areThereDataForUnselected && (
        <SVGChart
          chartForUnselected={areThereDataForUnselected}
          values={otherValues}
          externalClasses={{
            svgUnselectedChart: styles['svg-unselected-chart'],
          }}
        />
      )}

      <div className={`${styles['pole-name']} ${styles['right-pole-name']}`}>
        {rightPole}
      </div>
    </div>
  );
}

Factor.propTypes = {
  factorData: PropTypes.shape({
    factorName: PropTypes.shape({
      leftPole: PropTypes.string,
      rightPole: PropTypes.string,
    }),
    values: PropTypes.arrayOf(PropTypes.number).isRequired,
    valuesForDraw: PropTypes.arrayOf(PropTypes.number).isRequired,
    otherValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
};

export default Factor;