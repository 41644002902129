import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './AbsoluteFS.module.sass';
import { selectDataForAbsoluteFS } from '@/store/selectors/admin/superset/process/cuts/factorsCuts';
import Factor from '@/components/App/Admin/Results/Cuts/FactorsCuts/AbsoluteFS/Factor';
import AnswerOptionLabels from '@/components/App/Admin/Results/Cuts/FactorsCuts/AbsoluteFS/AnswerOptionLabels';

function AbsoluteFS() {
  const { itemID: fsIndx } = useParams();
  const {
    factorsName, absoluteFactors, nameAnswerOptions, answersCodes,
  } = useSelector((state) => selectDataForAbsoluteFS(state, fsIndx));

  return (
    <div className={styles['wrap-absolute-fs']}>
      <AnswerOptionLabels nameAnswerOptions={nameAnswerOptions} />

      <ul className={styles['list-of-factors']}>
        {factorsName.map((factorName, factorIndx) => {
          const factorKey = `factor-${factorIndx}`;
          const factorData = absoluteFactors[factorIndx];

          return (
            <li key={factorKey} className={styles['item-factor']}>
              <Factor
                factorData={{
                  factorName,
                  answersCodes,
                  ...factorData,
                }}
              />
            </li>
          );
        })}
      </ul>

      <AnswerOptionLabels nameAnswerOptions={nameAnswerOptions} />
    </div>
  );
}

export default AbsoluteFS;