import React from 'react';
import { useSelector } from 'react-redux';
import styles from '@/components/App/Admin/Superset/Superset.module.sass';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';

function InviteInfo() {
  const { assessmentStatus } = useSelector(selectSuperset);

  return (
    <section
      className={`${styles['superset-section']} ${styles['section-invite-info']}`}
    >
      <h2
        className={`${styles['title-section']} ${styles['title-section-invite-code']}`}
      >
        Information about invitations
      </h2>

      <div className={styles['wrap-invite-info']}>
        {assessmentStatus.inviteInfo ? (
          <table className={styles['table-invite-info']}>
            <thead>
              <tr>
                <th>Invite Code</th>
                <th>Ticket</th>
                <th>Name</th>
                <th>Finished</th>
                <th>Started</th>
              </tr>
            </thead>
            <tbody>

              {assessmentStatus.inviteInfo.map(({
                inviteCode, ticketNumber, name, finished, started,
              }, i) => {
                const key = `inviteInfo-${i}`;

                return (
                  <tr key={key}>
                    <td>{inviteCode}</td>
                    <td>{ticketNumber}</td>
                    <td>{name}</td>
                    <td>{finished}</td>
                    <td>{started}</td>
                  </tr>
                );
              }) }
            </tbody>
          </table>
        ) : (
          <div>
            No information about invitations
          </div>
        )}
      </div>
    </section>
  );
}

export default InviteInfo;