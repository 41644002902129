import { useContext } from 'react';
import { LOCAL_STORAGE_THEME_KEY, Theme, ThemeContext } from '@/theme/themeContext';

interface UseThemeResult {
  toggleTheme: () => void;
  theme: Theme
  singleTheme?: Theme;
}

export const setThemeToHtml = (newTheme: Theme) => {
  document.documentElement.setAttribute('data-theme', newTheme);
  document.body.setAttribute('data-theme', newTheme);
};

const useTheme = (): UseThemeResult => {
  const { theme, setTheme, singleTheme } = useContext(ThemeContext);

  const toggleTheme = () => {
    if (singleTheme) return;
    const newTheme = theme === Theme.DARK ? Theme.LIGHT : Theme.DARK;
    if (setTheme) setTheme(newTheme);
    localStorage.setItem(LOCAL_STORAGE_THEME_KEY, newTheme);
    setThemeToHtml(newTheme);
  };

  return { theme, toggleTheme, singleTheme };
};

export default useTheme;