import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Tabs.module.sass';
import TabPane from '@/components/Common/Tabs/TabPane';
import { getClearClassNames } from '@/helpers/helpers';

function Tabs({ children: tabPanes, selectedTabByID, externalClasses }) {
  const [panes, setPanes] = useState([]);
  const [IDToTabPanesData, setIDToTabPanesData] = useState({});
  const [activeTabID, setActiveTabID] = useState();

  useEffect(() => {
    setActiveTabID(selectedTabByID);
  }, [selectedTabByID]);

  useEffect(() => {
    const panesArray = [];
    const tabPanesData = {};

    React.Children.forEach(tabPanes, (tabPane, i) => {
      if (!React.isValidElement(tabPane)) return false;

      const {
        id, label, onClick, children: tabContent,
      } = tabPane.props;

      panesArray.push({
        id: id || `tab-${i}`,
        label,
        onClick,
      });

      tabPanesData[id] = {
        content: tabContent,
      };
      return false;
    });

    setPanes(panesArray);
    setIDToTabPanesData(tabPanesData);
  }, [tabPanes]);

  return (
    <div
      className={getClearClassNames([
        styles['wrap-tabs'],
        externalClasses.wrapTabs,
      ])}
    >
      <ul
        className={getClearClassNames([
          styles['panes-labels'],
          externalClasses.panesLabels,
        ])}
      >
        {panes.map(({ id, label, onClick }) => (
          <li
            key={id}
            className={getClearClassNames([
              styles['pane-label'],
              id === activeTabID
                ? `${styles.active} ${externalClasses.activePaneLabel}`
                : null,
              externalClasses.paneLabel,
            ])}
          >
            <button
              type="button"
              className={`${styles['btn-pane']} ${externalClasses.labelButton} btn-without-style`}
              onClick={() => {
                setActiveTabID(id);

                onClick();
              }}
            >
              {label}
            </button>
          </li>
        ))}
      </ul>
      {Object.keys(IDToTabPanesData).length > 0
        && IDToTabPanesData[activeTabID]
        && IDToTabPanesData[activeTabID].content && (
          <div className={styles['tabs-content']}>
            {IDToTabPanesData[activeTabID].content}
          </div>
      )}
    </div>
  );
}

Tabs.defaultProps = {
  externalClasses: {},
  selectedTabByID: 'tab-0',
};

Tabs.propTypes = {
  children: PropTypes.oneOfType([TabPane]).isRequired,
  selectedTabByID: PropTypes.string,
  externalClasses: PropTypes.shape({
    wrapTabs: PropTypes.string,
    panesLabels: PropTypes.string,
    paneLabel: PropTypes.string,
    activePaneLabel: PropTypes.string,
    labelButton: PropTypes.string,
  }),
};

export default Tabs;