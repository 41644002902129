import { isValidElement, cloneElement } from 'react';

import { useSelector } from 'react-redux';
import { CREATOR, FULL_ADMIN } from '@/constants/admin';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';

// eslint-disable-next-line react/prop-types
function ProhibitInteractivity({ children }) {
  const { role } = useSelector(selectSuperset);

  if (!role) return null;

  if (!role.includes(CREATOR) && !role.includes(FULL_ADMIN)) {
    if (isValidElement(children)) {
      return cloneElement(children, { notInteractive: true });
    }
  }

  return children;
}

export default ProhibitInteractivity;