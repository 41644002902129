// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".super-item-row--5t0Gs{display:flex;padding:15px 5px 15px 15px;background:var(--super-item-bg);justify-content:flex-start;align-items:center;margin-bottom:10px;border-radius:10px}", "",{"version":3,"sources":["webpack://./src/components/App/Super/Common/SuperItemRow/SuperItemRow.module.sass"],"names":[],"mappings":"AAAA,uBACI,YAAA,CACA,0BAAA,CACA,+BAAA,CACA,0BAAA,CACA,kBAAA,CACA,kBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"super-item-row": "super-item-row--5t0Gs"
};
export default ___CSS_LOADER_EXPORT___;
