import React from 'react';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import {
  HEIGHT_OF_SVG_CONTAINER,
  MAP_SOURS_NUMBER_TO_SOURCE_NLS,
} from '@/components/App/Admin/Results/Cuts/FourD/constants';
import styles from './Axes.module.sass';
import { selectNameSelectedOfAxes } from '@/store/selectors/admin/superset/process/cuts/fourD';
import useNLS from '@/components/App/Admin/hooks/hooks';

interface DataAxes {
  svgContainerWidth: number;
}

function Axes({ svgContainerWidth }: DataAxes) {
  const { xRatingDataName, yRatingDataName } = useSelector(selectNameSelectedOfAxes);

  const tNLS = useNLS();

  return (
    <>
      <defs>
        <marker
          id="arrowhead"
          markerWidth="10"
          markerHeight="7"
          refX="0"
          refY="3.5"
          orient="auto"
        >
          <polygon points="0 0, 10 3.5, 0 7" />
        </marker>
      </defs>

      {/* X-axis */}

      <line
        x1="0"
        y1={HEIGHT_OF_SVG_CONTAINER - 50}
        x2={svgContainerWidth - 30}
        y2={HEIGHT_OF_SVG_CONTAINER - 50}
        className={styles.axis}
        markerEnd="url(#arrowhead)"
      />

      {/* Y-axis */}
      <line
        x1="50"
        y1={HEIGHT_OF_SVG_CONTAINER}
        x2="50"
        y2="20"
        className={styles.axis}
        markerEnd="url(#arrowhead)"
      />

      <text className={styles['label-rating-name']} x="30%" y={HEIGHT_OF_SVG_CONTAINER - 25}>
        {xRatingDataName.name}
        <tspan
          x="30%"
          dy="1.2em"
        >
          {parse(tNLS(MAP_SOURS_NUMBER_TO_SOURCE_NLS[xRatingDataName.source].header))}
        </tspan>
      </text>
      <text
        className={styles['label-rating-name']}
        x="20"
        y="350"
        transform="rotate(-90 20,350)"
      >
        {yRatingDataName.name}
        <tspan
          x="20"
          dy="1.2em"
        >
          {parse(tNLS(MAP_SOURS_NUMBER_TO_SOURCE_NLS[yRatingDataName.source].header))}
        </tspan>
      </text>
    </>
  );
}

export default Axes;