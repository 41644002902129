import { createSlice } from '@reduxjs/toolkit';
import {
  loadSupersetBlocks,
  setDownloadableBlocksSuperset,
  setSupersetBlocks,
} from '@/store/actions/admin/superset/blocks';
import { SupersetBlocks } from '@/store/types/admin/superset/blocks';

const initialState: SupersetBlocks = {};

const blocksSlice = createSlice({
  name: 'admin/superset/blocks',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(loadSupersetBlocks.fulfilled, (state, { payload: blocks }) => (blocks))
      .addCase(setSupersetBlocks.fulfilled, (blocks, { payload: formBlocks }) => {
        formBlocks.forEach(({ id: blockID, isActive }) => {
          blocks[blockID].isActive = isActive;
        });
      })
      .addCase(setDownloadableBlocksSuperset, (state, { payload: uploadedBlocks }) => (
        uploadedBlocks
      ));
  },
});

export default blocksSlice.reducer;