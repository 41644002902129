import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import styles from './Categories.module.sass';

const getNumberAnswerOptionsInCat = (answerCats) => {
  if (!answerCats) {
    return null;
  }

  const numberAnsOptionsInCat = {};

  answerCats.forEach((numberCat) => {
    if (!(numberCat in numberAnsOptionsInCat)) {
      numberAnsOptionsInCat[numberCat] = 1;
      return;
    }

    numberAnsOptionsInCat[numberCat] += 1;
  });

  return numberAnsOptionsInCat;
};

const getGroupAnswers = (answerCats, answerStat) => {
  if (!answerCats) {
    return null;
  }

  const groupAns = {};
  answerStat.forEach((countActorsAnswers, i) => {
    const categoryNumber = answerCats[i];

    if (!(categoryNumber in groupAns)) {
      groupAns[categoryNumber] = countActorsAnswers;
      return;
    }

    groupAns[categoryNumber] += countActorsAnswers;
  });

  return groupAns;
};

function Categories({ answerCats, catsTexts, answerStat }) {
  const indxCategoryToNumberAnswers = getNumberAnswerOptionsInCat(answerCats);
  const groupAnswers = getGroupAnswers(answerCats, answerStat);
  const sumAnswers = answerStat.reduce((sum, current) => sum + current, 0);

  const renderCats = () => catsTexts.map((categoryName, i) => {
    const key = `category-${i}`;
    const countAnswersInCat = indxCategoryToNumberAnswers[i];
    const categoryWidth = `${(countAnswersInCat * 100) / answerCats.length}%`;

    if (countAnswersInCat === 1) {
      return (
        <div
          key={key}
          className={`${styles.category} ${styles['hidden-category']}`}
          style={{ width: categoryWidth }}
        />
      );
    }

    return (
      <div
        key={key}
        className={styles.category}
        style={{ width: categoryWidth }}
      >
        <div className={styles['wrap-label-category']}>
          <div className={styles['wrap-value']}>
            <div className={styles['value-placeholder']}>
              <span className={`icon-employee ${styles['icon-employee']}`} />
            </div>
            <span className={styles['count-answers']}>{groupAnswers[i]}</span>
            <span className={styles.percent}>
              {`${Math.round((groupAnswers[i] * 100) / sumAnswers)}%`}
            </span>
          </div>
          <div className={styles['case-title']}>{parse(categoryName)}</div>
        </div>
      </div>
    );
  });

  return <div className={styles['wrap-categories']}>{renderCats()}</div>;
}

export default Categories;

Categories.defaultProps = {
  answerCats: [],
  catsTexts: [],
  answerStat: [],
};

Categories.propTypes = {
  answerCats: PropTypes.arrayOf(PropTypes.number),
  catsTexts: PropTypes.arrayOf(PropTypes.string),
  answerStat: PropTypes.arrayOf(PropTypes.number),
};