// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cloud-file-item--9KM2R{display:flex;margin-left:10px;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/components/App/Super/Cloud/CloudSuperset/CloudDirectory/CloudFile/CloudFile.module.sass"],"names":[],"mappings":"AAAA,wBACI,YAAA,CACA,gBAAA,CACA,6BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cloud-file-item": "cloud-file-item--9KM2R"
};
export default ___CSS_LOADER_EXPORT___;
