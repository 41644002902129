import React from 'react';
import PropTypes from 'prop-types';
import Input from '@/components/Common/form/Input';
import styles from '@/components/App/Admin/AddingQuestions/AddingQuestions.module.sass';
import useNLS from '@/components/App/Admin/hooks/hooks';

function CustomQuestionInput({
  itemId,
  langID,
  register,
  formulation,
  shouldShowLanguageLabel,
  native,
}) {
  const tNLS = useNLS();

  const registerOptions = {
    maxLength: {
      value: 500,
      message: tNLS('customq.message.item.too.long'),
    },
    validate: {
      notEmptyAllFormulations: (value, allValues) => {
        const formulationsOfQuestion = allValues.formulations[itemId];

        return (
          Object.keys(formulationsOfQuestion).some(
            (FlangID) => formulationsOfQuestion[FlangID] !== '',
          ) || tNLS('customq.fill.one.lang.at.least')
        );
      },
    },
  };

  const externalClasses = {
    wrapInput: styles['wrap-formulation-field'],
    label: styles['label-formulation-field'],
    labelText: styles['label-text-formulation-field'],
  };

  return (
    <Input
      id={`formutalions-${itemId}-${langID}`}
      name={`formulations.${itemId}.${langID}`}
      register={register}
      registerOptions={registerOptions}
      defaultValue={formulation}
      label={
        shouldShowLanguageLabel
          ? `${native}:`
          : tNLS('customq.modal.enter.text')
      }
      externalClasses={externalClasses}
      placeholder="Добавьте формулировку"
    />
  );
}

CustomQuestionInput.propTypes = {
  itemId: PropTypes.string.isRequired,
  langID: PropTypes.string.isRequired,
  shouldShowLanguageLabel: PropTypes.bool.isRequired,
  register: PropTypes.string.isRequired,
  formulation: PropTypes.string.isRequired,
  native: PropTypes.bool.isRequired,
};
export default CustomQuestionInput;