import React from 'react';
import PropTypes from 'prop-types';
import styles from './CustomSelectOption.module.sass';
import customSelectStyles from '../CustomSelect.module.sass';

function CustomSelectOption({
  children,
  optionClassNames,
  onClick,
  isCurrentValue,
}) {
  return (
    <li
      className={`${styles['dropdown-list-item']} 
    ${isCurrentValue && styles['current-value']}`}
    >
      <button
        type="button"
        onClick={onClick}
        className={`btn-without-style 
        ${customSelectStyles['dropdown-button']} 
        ${isCurrentValue && customSelectStyles.active} 
        ${optionClassNames}`}
      >
        {children}
      </button>
    </li>
  );
}

CustomSelectOption.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  optionClassNames: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isCurrentValue: PropTypes.bool,
};

CustomSelectOption.defaultProps = {
  optionClassNames: '',
  isCurrentValue: false,
};

export default CustomSelectOption;