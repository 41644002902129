// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header-superset--f3ohU{margin-bottom:3.75rem}.header-superset--f3ohU .company--yorAI{font-size:1.25rem;color:var(--admin-main-font-color)}.header-superset--f3ohU .name--y4pd9{color:var(--title-font);font-size:1.75rem;font-weight:590;display:flex;justify-content:space-between;align-items:baseline}.header-superset--f3ohU .wrap-links-survey--S3q47{margin-top:1.25rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Superset/HeaderSuperset/HeaderSuperset.module.sass"],"names":[],"mappings":"AAEA,wBACE,qBAAA,CACA,wCACE,iBAAA,CACA,kCAAA,CACF,qCACE,uBAAA,CACA,iBAAA,CACA,eAAA,CACA,YAAA,CACA,6BAAA,CACA,oBAAA,CACF,kDACE,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-superset": "header-superset--f3ohU",
	"company": "company--yorAI",
	"name": "name--y4pd9",
	"wrap-links-survey": "wrap-links-survey--S3q47"
};
export default ___CSS_LOADER_EXPORT___;
