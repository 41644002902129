import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { unwrapResult } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-extraneous-dependencies
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'overlayscrollbars/styles/overlayscrollbars.css';
import styles from './ModalInviteAdmin.module.sass';
import Input from '@/components/Common/form/Input';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import Modal from '@/components/Common/Modal';
import { inviteAdmin } from '@/store/actions/admin';
import useModal from '@/hooks/modal';
import {
  FULL_ADMIN,
  OBSERVER,
  OBSERVER_WITH_ACCESS_RESULTS,
} from '@/constants/admin';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { addNotification } from '@/store/reducers/notify';
import RadioButton from '@/components/Common/form/RadioButton';
import BaseSelect from '@/components/Common/form/BaseSelect';

// values for type of invitation
// todo перенести в enum
const INVITATION_WITH_EMAIL = 'withEmail';
const INVITATION_WITH_PASSWORD = 'withPassword';

function ModalInviteAdmin({ isShowingModal, toggleModal, supersetID }) {
  const { Content, WrapBtns } = useModal();
  const tNLS = useNLS();
  const {
    register, watch, handleSubmit, reset, control, trigger, formState,
  } = useForm();

  const typeInvitationWatch = watch('typeInvitation');
  const dispatch = useDispatch();
  const delayTimer = useRef();

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      // trigger without delay
      if (name === 'role') {
        trigger(name);
        return;
      }

      clearTimeout(delayTimer.current);

      delayTimer.current = setTimeout(() => {
        trigger(name);
      }, 500);
    });

    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  const onAdminInvite = (form) => {
    const {
      email, name, role, typeInvitation, password,
    } = form;

    const sendForm = {
      email,
      name,
      role: Number(role.value),
      invite: typeInvitation === INVITATION_WITH_EMAIL,
      password,
    };

    dispatch(
      inviteAdmin({
        supersetID,
        formInviteAdmin: sendForm,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(
          addNotification({
            type: 'success',
            message: tNLS('admin.added'),
          }),
        );

        reset();
        toggleModal();
      })
      .catch(() => null);
  };

  return (
    <Modal
      isShowing={isShowingModal}
      hide={toggleModal}
      title={tNLS('admin.invitation')}
      externalClassNames={{
        contentClass: styles['content-admin-invite'],
      }}
      types="modal-small"
    >
      <Content>
        <OverlayScrollbarsComponent
          options={{ scrollbars: { autoHide: 'never' } }}
          style={{ maxHeight: '50vh', overflow: 'hidden' }}
          defer
        >
          <form
            className={styles['form-admin-invite']}
            onSubmit={handleSubmit(onAdminInvite)}
          >
            <Input
              register={register}
              registerOptions={{
                required: {
                  value: true,
                  message: tNLS('check.mandatory.field'),
                },
                pattern: {
                  /* eslint-disable no-useless-escape */
                  value:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: tNLS('check.wrong.email'),
                },
              }}
              id="inviteAdminEmail"
              name="email"
              type="email"
              label={tNLS('admin.email')}
              externalClasses={{
                label: styles['label-field'],
              }}
            >
              {formState?.errors?.email && (
                <span className={styles['input-error-message']}>
                  {formState.errors.email.message}
                </span>
              )}
            </Input>

            <Input
              register={register}
              id="inviteAdminName"
              name="name"
              type="text"
              label={tNLS('admin.name')}
              externalClasses={{
                label: styles['label-field'],
              }}
            />

            <RadioButton
              register={register}
              name="typeInvitation"
              id="inviteInvitationWithEmail"
              label={tNLS('admin.do.invite')}
              defaultChecked
              value={INVITATION_WITH_EMAIL}
            />

            <RadioButton
              register={register}
              name="typeInvitation"
              id="inviteInvitationWithoutEmail"
              label={tNLS('admin.set.password')}
              value={INVITATION_WITH_PASSWORD}
            />

            {typeInvitationWatch === INVITATION_WITH_PASSWORD && (
              <Input
                register={register}
                registerOptions={{
                  required: {
                    value: true,
                    message: tNLS('check.mandatory.field'),
                  },
                  minLength: {
                    value: 8,
                    message: tNLS('check.password.placeholder'),
                  },
                  pattern: {
                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/,
                    message: tNLS('check.wrong.password'),
                  },
                }}
                id="inviteAdminPassword"
                name="password"
                type="password"
                label={tNLS('admin.set.password')}
                externalClasses={{
                  label: styles['label-field'],
                }}
              >
                {formState?.errors?.password && (
                  <span className={styles['input-error-message']}>
                    {formState.errors.password.message}
                  </span>
                )}
              </Input>
            )}

            <Controller
              id="inviteAdminRole"
              name="role"
              control={control}
              rules={{
                required: tNLS('check.mandatory.field'),
              }}
              render={({ field }) => (
                <div>
                  <BaseSelect
                    {...field}
                    placeholder={tNLS('admin.access')}
                    options={[
                      {
                        label: tNLS('admin.full.access'),
                        value: FULL_ADMIN,
                      },
                      {
                        label: tNLS('admin.observer.with.results'),
                        value: OBSERVER_WITH_ACCESS_RESULTS,
                      },
                      {
                        label: tNLS('admin.observer.no.results'),
                        value: OBSERVER,
                      },
                    ]}
                  />
                  {formState?.errors?.role && (
                    <span className={styles['input-error-message']}>
                      {formState.errors.role.message}
                    </span>
                  )}
                </div>
              )}
            />
          </form>
        </OverlayScrollbarsComponent>
      </Content>
      <WrapBtns>
        <CustomButton onClick={toggleModal} styleTypeBtn="btn-grey-modal">
          {tNLS('admin.invitation.cancel')}
        </CustomButton>
        <CustomButton
          onClick={async () => {
            const isValid = await trigger();

            if (isValid) {
              handleSubmit(onAdminInvite)();
            }
          }}
          styleTypeBtn="btn-blue-modal"
        >
          {tNLS('admin.invitation.confirm')}
        </CustomButton>
      </WrapBtns>
    </Modal>
  );
}

ModalInviteAdmin.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  isShowingModal: PropTypes.bool.isRequired,
  supersetID: PropTypes.string.isRequired,
};

export default ModalInviteAdmin;