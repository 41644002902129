// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-score-type-toggler--HznTv .btn-score-type-toggler--9d2mI{border-radius:4px;padding:7px 0;transition:background-color .3s ease 0s;width:2.5rem}.wrap-score-type-toggler--HznTv .btn-score-type-toggler--9d2mI.percentile-type--yCy3P{background-color:#2279fc}.wrap-score-type-toggler--HznTv .btn-score-type-toggler--9d2mI.percentile-type--yCy3P:hover{background-color:#005de9}.wrap-score-type-toggler--HznTv .btn-score-type-toggler--9d2mI.percentile-type--yCy3P:active{background-color:#4891ff}.wrap-score-type-toggler--HznTv .btn-score-type-toggler--9d2mI.absolute-type--h4Tot{background-color:#27ae60}.wrap-score-type-toggler--HznTv .btn-score-type-toggler--9d2mI.absolute-type--h4Tot:hover{background-color:#178546}.wrap-score-type-toggler--HznTv .btn-score-type-toggler--9d2mI.absolute-type--h4Tot:active{background-color:#3dcd7a}.wrap-score-type-toggler--HznTv .btn-score-type-toggler--9d2mI .icon-type-factor-sctruct--vlEQy:before{font-size:1rem;color:#fff}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/FourD/FourDParamsForm/ScoreTypeToggler/ScoreTypeToggler.module.sass"],"names":[],"mappings":"AAGE,+DACE,iBAAA,CACA,aAAA,CACA,uCAAA,CACA,YAAA,CACA,sFACE,wBAAA,CACA,4FACE,wBAAA,CACF,6FACE,wBAAA,CACJ,oFACE,wBAAA,CACA,0FACE,wBAAA,CACF,2FACE,wBAAA,CAEF,uGACE,cAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-score-type-toggler": "wrap-score-type-toggler--HznTv",
	"btn-score-type-toggler": "btn-score-type-toggler--9d2mI",
	"percentile-type": "percentile-type--yCy3P",
	"absolute-type": "absolute-type--h4Tot",
	"icon-type-factor-sctruct": "icon-type-factor-sctruct--vlEQy"
};
export default ___CSS_LOADER_EXPORT___;
