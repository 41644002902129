import React from 'react';
import BUnitsMenuBtn from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitsBlueBtn/BUnitsMenuBtn';
import useNLS from '@/components/App/Admin/hooks/hooks';

interface BUnitCardRejectButtonProps {
  resetForm: () => void
}

function BUnitCardRejectButton({ resetForm }: BUnitCardRejectButtonProps) {
  const tNLS = useNLS();
  const canselUpdates = () => {
    resetForm();
  };

  return (
    <BUnitsMenuBtn
      type="reject"
      handleButtonClick={canselUpdates}
      btnActionType="reset"
    >
      {tNLS('tree.card.button.cancel')}
    </BUnitsMenuBtn>
  );
}

export default BUnitCardRejectButton;