import React from 'react';
import styles from './UnitCardTabIcons.module.sass';
import { BUnitCardTabsTypes } from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCard';
import { getClearClassNames } from '@/helpers/helpers';

interface UnitCardTabIconsProps {
  type: BUnitCardTabsTypes;
}

function UnitCardTabIcons({ type }: UnitCardTabIconsProps) {
  const secondIconByType = {
    [BUnitCardTabsTypes.Main]: <span className="icon-notebook" />,
    [BUnitCardTabsTypes.Objects]: <span className={`icon-mini-arrow ${styles.arrow}`} />,
    [BUnitCardTabsTypes.Subjects]: <span className={`icon-mini-arrow ${styles.arrow}`} />,
  };

  return (
    <div className={getClearClassNames([
      styles['tab-icon'],
      type === BUnitCardTabsTypes.Subjects && styles.revert,
    ])}
    >
      <span className="icon-employee" />
      {secondIconByType[type]}
    </div>
  );
}

export default UnitCardTabIcons;