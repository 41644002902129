import React from 'react';
import styles from './Footer.module.sass';
import footerDecor from '@/../static/img/footer_decor.svg';

interface FooterProps {
  langId?: 'en' | 'ru';
}

function Footer({ langId = 'en' }: FooterProps) {
  const companyName = window.location.host.includes('bssl')
    || window.location.host.includes('azimuth')
    ? 'azimuth'
    : 'lr';

  const footerTexts = {
    rights: {
      en: 'All rights reserved',
      ru: 'Все права защищены',
    },
    contact: {
      en: 'Contact us',
      ru: 'Напишите нам',
    },
    company: {
      azimuth: 'BSSL, 2005-',
      lr: 'Lost Report Inc., 2022-',
    },
    contactInfo: {
      azimuth: 'hi@bssl.ru',
      lr: 'hi@lostreport.com',
    },
  };

  const date = new Date();
  return (
    <footer className={styles.footer}>
      <div className={styles['footer-container']}>
        <div className={styles['footer-copyright']}>
          &copy;
          {` ${footerTexts.company[companyName]}${date.getFullYear()}`}
          {' '}
          <br />
          {footerTexts.rights[langId]}
        </div>
        <div className={styles['footer-contact']}>
          {footerTexts.contact[langId]}
          <br />
          {footerTexts.contactInfo[companyName]}
        </div>
      </div>
      <img className={styles['footer-decor']} src={footerDecor} alt="decore" />
    </footer>
  );
}

export default Footer;