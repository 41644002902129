import React from 'react';
import AdminMainListLink from '@/components/App/Admin/AdminMainListLinkLink';

export default function CreateNewWebinarLink() {
  return (
    <AdminMainListLink
      linkTitle="+ Создать вебинар"
      to="new"
    />
  );
}