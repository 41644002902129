import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DURING_SURVEY_PROCESS } from '@/constants/admin';
import { loadStatusProcessorResult } from '@/store/actions/admin/superset/process/process';
import selectStatusProcessorResult from '@/store/selectors/admin/superset/process/process';

const useProcessor = (supersetId, stage) => {
  const dispatch = useDispatch();
  const statusProcessorResult = useSelector(selectStatusProcessorResult);
  const { isProcessorRunning, received } = statusProcessorResult;

  useEffect(() => {
    if (stage >= DURING_SURVEY_PROCESS) {
      dispatch(loadStatusProcessorResult(supersetId));
    }
  }, [dispatch, stage, supersetId]);

  useEffect(() => {
    let interval;
    if (received && isProcessorRunning) {
      interval = setInterval(() => {
        dispatch(loadStatusProcessorResult(supersetId));
      }, 5000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, received, isProcessorRunning, supersetId]);

  return {
    ...statusProcessorResult,
    received:
      stage < DURING_SURVEY_PROCESS ? true : statusProcessorResult.received, // received status
  };
};

export default useProcessor;