import React, {
  DragEvent, useState, ReactNode, HTMLAttributes,
} from 'react';
import { getClearClassNames } from '@/helpers/helpers';
import styles from './FilesDragNDrop.module.sass';

interface UploadingInputWithDragDropProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  onFilesAdded: (files: FileList) => void;
}

export default function FilesDragNDropContainer({
  children,
  onFilesAdded,
  className,
  ...divProps
}: UploadingInputWithDragDropProps) {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);

    const { files } = e.dataTransfer;

    if (files.length > 0) {
      onFilesAdded(files);
    }
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      className={getClearClassNames([className, isDragging && styles.dragging])}
      {...divProps}
    >
      {children}
      {' '}
    </div>
  );
}