import React, { useState } from 'react';
import CustomSelect from '@/components/App/Admin/BUnitsSection/CustomSelect';
import styles from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/SelectMenu.module.sass';
import useLocalStorageByAdmin from '@/components/App/Admin/BUnitsSection/UnitsTree/hooks/adminLocalStorageSaver';
import CustomSelectOption from '@/components/App/Admin/BUnitsSection/CustomSelect/CustomSelectOption/CustomSelectOption';
import {
  BUnitSelectOption,
  SetCurrentSelectOptionType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/useSelectOption';

interface BUnitsSelectMenuOptionsProps {
  currentSelectOption: BUnitSelectOption;
  setCurrentSelectOption: SetCurrentSelectOptionType;
  selectOptions: BUnitSelectOption[];
}
function BUnitsSelectMenuOptions({
  currentSelectOption,
  setCurrentSelectOption,
  selectOptions,
}: BUnitsSelectMenuOptionsProps) {
  const [writeInLocalStorage] = useLocalStorageByAdmin('selectOptions');
  const [isOpen, toggleOpen] = useState(false);

  const handleSelectOptions = (selectedOption: BUnitSelectOption) => {
    toggleOpen(false);
    setCurrentSelectOption(selectedOption);
    writeInLocalStorage(selectedOption);
  };

  if (!currentSelectOption) return null;

  return (
    <CustomSelect
      activeValue={currentSelectOption.name}
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      type="button"
      mainBtnClassNames={styles['select-option']}
    >
      {selectOptions.map((option) => (
        <CustomSelectOption
          key={option.key}
          optionClassNames={styles['unit-menu-select']}
          onClick={() => handleSelectOptions(option)}
          isCurrentValue={option.key === currentSelectOption.key}
        >
          {option.name}
        </CustomSelectOption>
      ))}
    </CustomSelect>
  );
}

export default BUnitsSelectMenuOptions;