import { ReadAdmlist } from "@/store/actions/admin/super";
import { useAdminDispatch, useAdminSelector } from "@/store/hooks/adminRedux";
import { selectAdmlist } from "@/store/selectors/admin/super";
import React, { useEffect, useMemo } from "react";
import styles from "./Admlist.module.sass"
import SuperItemRow from "../Common/SuperItemRow/SuperItemRow";
import SuperItemColumn from "../Common/SuperItemRow/SuperItemColumn/SuperItemColumn";
import FlagIcon from "@/components/Common/Elements/cssIcons/FlagIcon/FlagIcon";
import BtnCheckboxSlider from "../../Admin/UIElements/BtnCheckboxSlider";
import { NavLink } from "react-router-dom";

const stages = [
    'Создание',
    'Настройка',
    'Тестирование',
    'Опрос',
    'Обратная связь',
    'Архив',
];

const parseDate = (date: string) => {
    const parts = date.split('.');
    return new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]))
}

export default function Admlist() {
    const dispatch = useAdminDispatch();
    const admlist = useAdminSelector(selectAdmlist);

    useEffect(() => {
        dispatch(ReadAdmlist());
    }, [dispatch]);

    if (!admlist) return;

    return [...admlist].sort((a, b) => {
        const aDate = parseDate(a.createdAt);
        const bDate = parseDate(b.createdAt);
        return bDate.getTime() - aDate.getTime();
    }).map(({
        id,
        name,
        createdAt,
        webId,
        company,
        stage,
        languages,
        lastActive,
        evaluatedAt,
        isPaused,
        isDeleted,
        isTemplate,
    }) => {
        return (
            <SuperItemRow key={`key-${id}`}>
                <SuperItemColumn>
                    <div className={`${styles['admlist-item-field']} ${styles['superset-name']}`}>
                        <NavLink to={`/admin/project/${id}`} target="_blank">
                            {name}
                        </NavLink>
                    </div>
                    <div className={`${styles['admlist-item-field']}`}>
                        {createdAt}
                    </div>
                    <div className={`${styles['admlist-item-field']}`}>
                        {webId}
                    </div>
                </SuperItemColumn>
                <SuperItemColumn>{company}</SuperItemColumn>
                <SuperItemColumn>{stages[stage]}</SuperItemColumn>
                <SuperItemColumn className={'superset-languages'}>
                    {languages.map((langId) => (
                        <FlagIcon key={langId} langId={langId} />
                    ))}
                </SuperItemColumn>
                <SuperItemColumn>{lastActive}</SuperItemColumn>
                <SuperItemColumn>{evaluatedAt}</SuperItemColumn>
                <SuperItemColumn>
                    <BtnCheckboxSlider checked={!isPaused} />
                </SuperItemColumn>
                <SuperItemColumn>
                    <BtnCheckboxSlider checked={!isDeleted} />
                </SuperItemColumn>
                <SuperItemColumn>
                    <BtnCheckboxSlider checked={isTemplate} />
                </SuperItemColumn>
            </SuperItemRow >
        )
    });
}