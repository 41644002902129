import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { withErrorBoundary } from 'react-error-boundary';
import styles from './SectionFieldActivity.module.sass';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import {
  selectAllFields,
  selectSelectedFieldIDs,
} from '@/store/selectors/admin/superset/supersets';
import ModalSelectingFields from '@/components/App/Admin/SupersetSettings/SectionFieldActivity/ModalSelectingFields';
import Modal from '@/components/Common/Modal';
import useModal from '@/hooks/modal';
import { setSupersetFields } from '@/store/actions/admin';
import RequireRoleChangeAccess from '@/components/App/Admin/SupersetSettings/RequireRoleChangeAccess';
import RestartFieldActivity from '@/components/App/Admin/SupersetSettings/SectionFieldActivity/RestartFieldActivity';
import useNLS from '@/components/App/Admin/hooks/hooks';

function SectionFieldActivity() {
  const { id: supersetID } = useParams();
  const tNLS = useNLS();
  const dispatch = useDispatch();
  const fields = useSelector(selectAllFields);
  const selectedFieldIDs = useSelector(selectSelectedFieldIDs);
  const { isShowingModal, toggleStatusShowingModal } = useModal();

  const removeField = (removedFieldID) => {
    dispatch(
      setSupersetFields({
        supersetId: supersetID,
        fields: selectedFieldIDs.filter(
          (fieldID) => fieldID !== removedFieldID,
        ),
      }),
    );
  };

  return (
    <>
      <section
        className={`settings-section ${styles['field-activity-section']}`}
      >
        <h3 className="title-section">{tNLS('business.area')}</h3>

        <RequireRoleChangeAccess>
          <CustomButton
            styleTypeBtn="btn-grey btn-large"
            onClick={toggleStatusShowingModal}
          >
            {tNLS('business.area.add')}
          </CustomButton>
        </RequireRoleChangeAccess>

        {!!selectedFieldIDs.length && (
          <div className={styles['wrap-selected-fields']}>
            <ul className={styles['list-fields']}>
              {selectedFieldIDs.map((selectedFieldID) => (
                <li key={selectedFieldID} className={styles['item-field']}>
                  {fields[selectedFieldID]?.name}
                  <RequireRoleChangeAccess>
                    <button
                      type="button"
                      className={`${styles['btn-remove-field']} btn-without-style`}
                      onClick={() => removeField(selectedFieldID)}
                    >
                      {' '}
                      <span
                        className={`${styles['icon-remove']} icon-remove-light`}
                      />
                    </button>
                  </RequireRoleChangeAccess>
                </li>
              ))}
            </ul>
          </div>
        )}
      </section>
      <Modal
        isShowing={isShowingModal}
        hide={toggleStatusShowingModal}
        title={tNLS('business.area.add.message')}
        types="modal-lg"
      >
        <ModalSelectingFields
          supersetID={supersetID}
          selectedFields={selectedFieldIDs}
        />
      </Modal>
    </>
  );
}

export default withErrorBoundary(SectionFieldActivity, {
  FallbackComponent: RestartFieldActivity,
});