import React, { ReactNode } from 'react';
import styles from './BUnitCardTabs.module.sass';

interface BUnitCardTabsProps<U> {
  tabs: BUnitCardTab<U>[];
  activeTabId: U;
  tabHandleClick: (id: U) => void;
}

export interface BUnitCardTab<U> {
  name: string | ReactNode;
  id: U;
  isHidden?: boolean;
}

export default function BUnitCardTabs<TabID extends string>({ tabs, activeTabId, tabHandleClick }: BUnitCardTabsProps<TabID>) {
  return (
    <ul className={styles['unit-tabs-menu']}>
      {tabs
        .filter((tab) => !tab.isHidden)
        .map((tab) => (
          <li key={tab.id} className={`${styles['unit-menu-tab']}`}>
            <button
              className={`${styles['menu-tab-btn']}  ${
                activeTabId === tab.id && styles.active
              } btn-without-style`}
              type="button"
              onClick={() => {
                tabHandleClick(tab.id);
              }}
            >
              <div className={styles['tab-text']}>{tab.name}</div>
            </button>
          </li>
        ))}
    </ul>
  );
}