// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".transfer-modal--PUkUD{margin:10vh auto auto auto !important}.transfer-modal--PUkUD .transfer-units-modal--j7Zly{height:fit-content;width:60vw;overflow-y:visible;overflow-y:initial}.transfer-modal--PUkUD .transfer-units-modal--j7Zly .transfer-button--crDhH{margin:0}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitTransferModal/BUnitTransferModal.module.sass"],"names":[],"mappings":"AAEA,uBACE,qCAAA,CACA,oDACE,kBAAA,CACA,UAAA,CACA,kBAAA,CAAA,kBAAA,CACA,4EACE,QAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transfer-modal": "transfer-modal--PUkUD",
	"transfer-units-modal": "transfer-units-modal--j7Zly",
	"transfer-button": "transfer-button--crDhH"
};
export default ___CSS_LOADER_EXPORT___;
