import React, { Dispatch, SetStateAction, useCallback } from 'react';
import BUnitTypeRadioCase from '@/components/App/Admin/BUnitsSection/CreateBUnitSection/CreateBUnitModal/BUnitType/BUnitTypeRadioCase/BUnitTypeRadioCase';
import useNLS from '@/components/App/Admin/hooks/hooks';
import styles from './BUnitType.module.sass';

interface BUnitTypeProps {
  isActor: boolean;
  toggleActor: Dispatch<SetStateAction<boolean>>
}
function BUnitType({ isActor, toggleActor }: BUnitTypeProps) {
  const onCheck = useCallback(() => {
    toggleActor(!isActor);
  }, [isActor, toggleActor]);
  const tNLS = useNLS();

  return (
    <div className={styles['units-type-container']}>
      <BUnitTypeRadioCase id="unit" isChecked={isActor} onCheck={onCheck}>
        {tNLS('tree.new.unit.tab.person')}
      </BUnitTypeRadioCase>
      <BUnitTypeRadioCase id="department" isChecked={!isActor} onCheck={onCheck}>
        {tNLS('tree.new.unit.tab.department')}
      </BUnitTypeRadioCase>
    </div>
  );
}

export default BUnitType;