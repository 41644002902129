import React from 'react';
import { useSelector } from 'react-redux';
import styles from './AdminProfile.module.sass';
import GeneralSection from '@/components/App/Admin/AdminProfile/GeneralSection';
import ChangePassword from '@/components/App/Admin/AdminProfile/ChangePassword';
import CreatePassword from '@/components/App/Admin/AdminProfile/CreatePassword';
import { selectAdmin } from '@/store/selectors/admin/authentication';

function AdminProfile() {
  // supersetID of the superset to which was invited
  // const { state: supersetID } = useLocation();
  const { newUser } = useSelector(selectAdmin);

  return (
    <div className={styles['wrapper-container']}>
      <div className={styles['wrap-admin-profile']}>
        <h2 className={styles.title}>Admin Profile</h2>

        <GeneralSection />

        {newUser ? <CreatePassword /> : <ChangePassword />}
      </div>
    </div>
  );
}

export default AdminProfile;