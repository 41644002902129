import React from 'react';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormatOptionLabelMeta } from 'react-select';
import BaseSelect from '@/components/Common/form/BaseSelect';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import { getResultsByRatings, setSelectedParams } from '@/store/actions/admin/superset/process/cuts/fourD';
import { selectRatings } from '@/store/selectors/admin/superset/process/cuts/fourD';
import ScoreTypeToggler from '@/components/App/Admin/Results/Cuts/FourD/FourDParamsForm/ScoreTypeToggler';
import styles from './FourDParamsForm.module.sass';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { MAP_SOURS_NUMBER_TO_SOURCE_NLS } from '@/components/App/Admin/Results/Cuts/FourD/constants';
import ColorParam from '@/components/App/Admin/Results/Cuts/FourD/FourDParamsForm/ColorParam';
import useTransformRatingToGroupedRatingsOptions
  from '@/components/App/Admin/Results/Cuts/FourD/FourDParamsForm/hooks/hooks';

export interface FormValues {
  axisX: { value: string; label: string };
  axisY: { value: string; label: string };
  size: { value: string; label: string };
  color: { value: string, label: string };
}

export interface Option {
  label: string;
  value: string | number;
  sourceType: number,
}

export interface GroupedData {
  label: string,
  options: Option[],
}

function FourDParamsForm() {
  const dispatch = useDispatch();
  const ratings = useSelector(selectRatings);
  const { handleSubmit, control } = useForm({
    defaultValues: {
      axisX: { value: ratings[0].id, label: ratings[0].name, sourceType: ratings[0].source },
      axisY: { value: ratings[1].id, label: ratings[1].name, sourceType: ratings[1].source },
      size: { value: ratings[2].id, label: ratings[2].name, sourceType: ratings[2].source },
      color: { value: ratings[3].id, label: ratings[3].name, sourceType: ratings[3].source },
    },
  });

  const ratingOptions = useTransformRatingToGroupedRatingsOptions(ratings);
  const tNLS = useNLS();

  const onChangeDiagram:SubmitHandler<FormValues> = (formData) => {
    dispatch(setSelectedParams({
      xRatingID: formData.axisX.value,
      yRatingID: formData.axisY.value,
      ratingSizeID: formData.size.value,
      ratingColorID: formData.color.value,
    }));

    dispatch(
      getResultsByRatings(),
    );
  };

  const changeOptionValue = (option: Option, { context }: FormatOptionLabelMeta<Option>) => {
    if (context === 'value') {
      return `${option.label}: ${tNLS(MAP_SOURS_NUMBER_TO_SOURCE_NLS[option.sourceType].short)}`;
    }

    return option.label;
  };

  const formatGroupLabel = (data: GroupedData) => (
    <div className={styles['wrap-base-select__group']}>
      <span className={styles['base-select__group_label']}>{data.label}</span>
      <span className={styles['base-select__group_options_length']}>{data.options.length}</span>
    </div>
  );

  return (
    <div className={styles['wrap-form']}>
      <ScoreTypeToggler externalClasses={{
        wrapScoreTypeToggler: styles['external-wrap-score-type-toggler'],
      }}
      />

      <form className={styles['fourD-params-form']} onSubmit={handleSubmit(onChangeDiagram)}>
        <Controller
          name="axisX"
          control={control}
          render={({ field }) => (
            <BaseSelect
              id="axisXField"
              {...field}
              label={tNLS('results.4d.x')}
              placeholder="Выберете параметр"
              options={ratingOptions}
              formatGroupLabel={formatGroupLabel}
              isSearchable
              formatOptionLabel={changeOptionValue}
              classNames={{
                container: () => styles['select-parameter'],
              }}
            />
          )}
        />

        <Controller
          name="axisY"
          control={control}
          render={({ field }) => (
            <BaseSelect
              id="axisYField"
              {...field}
              label={tNLS('results.4d.y')}
              placeholder="Выберете параметр"
              options={ratingOptions}
              formatGroupLabel={formatGroupLabel}
              isSearchable
              formatOptionLabel={changeOptionValue}
              classNames={{
                container: () => styles['select-parameter'],
              }}
            />
          )}
        />

        <Controller
          name="size"
          control={control}
          render={({ field }) => (
            <BaseSelect
              id="sizeField"
              {...field}
              label={tNLS('results.4d.size')}
              placeholder="Выберете параметр"
              options={ratingOptions}
              formatGroupLabel={formatGroupLabel}
              isSearchable
              formatOptionLabel={changeOptionValue}
              classNames={{
                container: () => styles['select-parameter'],
              }}
            />
          )}
        />

        <Controller
          name="color"
          control={control}
          render={({ field }) => (
            <ColorParam
              ratings={ratings}
              field={{ ...field }}
              externalClasses={{
                container: () => styles['select-parameter'],
              }}
              formatGroupLabel={formatGroupLabel}
              changeOptionValue={changeOptionValue}
            />
          )}
        />

        <CustomButton
          styleTypeBtn="btn-blue btn-large"
          typeBtnSubmit
          classNames={styles['btn-on-restore-account']}
        >
          Построить схему
        </CustomButton>
      </form>

    </div>
  );
}

export default FourDParamsForm;