// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input--Bl2L-{width:100%;height:100%;padding:0.25rem 0.5rem;font-size:1rem;outline:none}.input--Bl2L-.checkbox--ltjPB{padding:0}", "",{"version":3,"sources":["webpack://./src/components/App/Webinar/CreateWebinarPage/CreateWebinarForm/CreateWebinarFormField/CreateWebinarField/CreateWebinarInput/CreateWebinarInput.module.sass"],"names":[],"mappings":"AAEA,cACE,UAAA,CACA,WAAA,CACA,sBAAA,CACA,cAAA,CACA,YAAA,CACA,8BACE,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "input--Bl2L-",
	"checkbox": "checkbox--ltjPB"
};
export default ___CSS_LOADER_EXPORT___;
