import { createSlice } from '@reduxjs/toolkit';
import {
  createProject,
  loadProjects,
  clearSupersetProjects,
  setDownloadableLanguageSuperset,
  loadSupersetLangs,
  toggleLangSuperset,
  uploadResources,
  clearUploadResourcesInfo,
  commitTemplate,
} from '@/store/actions/admin/superset/projects';
import { SupersetProjectsInitialState } from '@/store/types/admin/superset/projects';

const initialState: SupersetProjectsInitialState = {
  projects: {},
  supersetLangs: {},
  uploadResourcesInfo: null,
  errorMessageOnUploading: null,
  uploadResourcesStatus: 'idle',
  creatingProjectStatus: 'idle',
  commitTemplateStatus: 'idle',
};

const projectsSlice = createSlice({
  name: 'admin/superset/projects',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(loadProjects.fulfilled, (state, { payload }) => {
        state.projects = payload;
      })
      .addCase(loadSupersetLangs.fulfilled, (state, { payload }) => {
        state.supersetLangs = payload;
      })
      .addCase(toggleLangSuperset.fulfilled, (state, { payload: toggledLangStatus }) => {
        const { langID, isActive } = toggledLangStatus;

        state.supersetLangs[langID].isActive = isActive;
      })
      .addCase(setDownloadableLanguageSuperset, (state, { payload }) => {
        state.supersetLangs = { ...state.supersetLangs, ...payload };
      })
      .addCase(uploadResources.pending, (state) => {
        state.uploadResourcesStatus = 'pending';
      })
      .addCase(uploadResources.fulfilled, (state, { payload: uploadInfo }) => {
        state.uploadResourcesInfo = uploadInfo;
        state.uploadResourcesStatus = 'idle';
      })
      .addCase(uploadResources.rejected, (state, { payload: errorMessage }) => {
        // @ts-ignore
        state.errorMessageOnUploading = errorMessage;
        state.uploadResourcesStatus = 'idle';
      })
      .addCase(clearUploadResourcesInfo, (state) => {
        state.uploadResourcesInfo = null;
        state.errorMessageOnUploading = null;
      })
      .addCase(createProject.pending, (state) => {
        state.creatingProjectStatus = 'loading';
      })
      .addCase(createProject.fulfilled, (state, { payload: project }) => {
        const { projectID, ...restFields } = project;
        state.creatingProjectStatus = 'idle';
        state.projects[projectID] = restFields;
      })
      .addCase(createProject.rejected, (state) => {
        state.creatingProjectStatus = 'idle';
      })
      .addCase(commitTemplate.pending, (state) => {
        state.commitTemplateStatus = 'loading';
      })
      .addCase(commitTemplate.fulfilled, (state) => {
        state.commitTemplateStatus = 'idle';
      })
      .addCase(clearSupersetProjects, (state) => {
        state.projects = {};
        state.uploadResourcesInfo = null;
      });
  },
});

export default projectsSlice.reducer;