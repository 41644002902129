import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { stat } from 'fs';
import {
  deleteAnswer,
  getProtocols,
  optOutOfEvaluation,
  sendAnswers,
  setAnswer,
  setProtocol,
  setRelations,
  setSeveralProtocols,
  setSeveralRelations,
  setTestBlockAnswer,
} from '@/store/actions/user/protocols';
import {
  getAnswersFromLocalStorage,
  getDateInPostgreFormat,
  setAnswerInLocalStorage,
} from '@/helpers/helpers';
import { getActor, selectProject } from '@/store/actions/user/actor';
import {
  GetProtocolsPayload,
  ItemRelations,
  OptOutOfEvaluationPayload,
  Protocols,
  ProtocolsState,
  Relations,
  RelationTime,
  SentProtocols,
  SetAnswerPayload, SetRelationArgs,
  SetSeveralRelationPayload,
  SetTestBlockAnswer,
} from '@/store/types/user/protocols';
import { Project } from '@/store/types/user/actor';

const initialState: ProtocolsState = {
  protocols: {},
  relations: {},
  updatedItems: {},
  updatedRelations: {},
  isHandleActionOnPage: false,
  deletedProtocols: {},
  deletedRelations: {},
  lastItemFormType: '',
  protocolsTime: {},
  lastUpdatedItem: '',
  isChanged: false,
  sentProtocols: [],
  projectId: undefined,
  isAnswersInProcess: false,
};
// TODO собрать типы в отдельный файл, разобраться с protocolsTime(разделить protocolsTime и RelationsTime)
export const protocolsSlice = createSlice({
  name: 'protocols',
  initialState,
  reducers: {
    clearProtocols: (state) => {
      state.protocols = {};
      state.relations = {};
      state.updatedItems = {};
      state.updatedRelations = {};
      state.protocolsTime = {};
    },
    toggleUserActionOnPage: (state, { payload }: PayloadAction<{ newValue: boolean }>) => {
      const { newValue } = payload;
      state.isHandleActionOnPage = newValue;
    },
    catchUserAction: (state) => {
      state.isChanged = true;
    },
    deleteItemRelations: (state, { payload }: PayloadAction<{ id: string }>) => {
      const { id } = payload;
      const deletedItemRelations = state.deletedRelations[id] ? { ...state.deletedRelations[id] } : {};
      const itemRelations = state.relations[id] ? { ...state.relations[id] } : {};
      state.deletedRelations[id] = { ...deletedItemRelations, ...itemRelations };
      delete state.relations[id];
    },
    getUserActorId: (state, { payload }: PayloadAction<string>) => {
      state.actorId = payload;
    },
    getUserProjectId: (state, { payload }: PayloadAction<string>) => {
      state.projectId = payload;
    },
    deleteLastUpdatedItem: (state) => {
      state.lastUpdatedItem = '';
    },
    deleteAnswer: (state, { payload }: PayloadAction<SetAnswerPayload>) => {
      const { id, unitId } = payload;
      if (unitId) {
        delete state.relations[id][unitId];
        delete state.updatedRelations[id][unitId];
      } else {
        state.deletedProtocols[id] = state.protocols[id];
        delete state.updatedItems[id];
        delete state.protocols[id];
        state.lastUpdatedItem = id;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setAnswer.fulfilled, (state, { payload }:PayloadAction<SetAnswerPayload>) => {
        const {
          id, unitId, formType, actorId, projectId,
        } = payload;

        state.isChanged = true;
        state.lastItemFormType = formType;
        if (unitId) {
          if (!state.protocolsTime[id]) state.protocolsTime[id] = {};
          const time: string = getDateInPostgreFormat();
          if (unitId.includes('|')) {
            const key: string = `${id}||comment`;
            // @ts-ignore
            state.protocolsTime[key][unitId] = time;
          } else {
            // @ts-ignore
            state.protocolsTime[id][unitId] = time;
          }
        } else {
          state.protocolsTime[id] = getDateInPostgreFormat();
          if (state.deletedProtocols[id]) delete state.deletedProtocols[id];
        }
        state.lastUpdatedItem = id;
        if (actorId && projectId) {
          setAnswerInLocalStorage({
            id,
            localKey: `${actorId}-${projectId}-unsaved-answers`,
            updatedItems: { ...state.updatedItems },
            updatedRelations: { ...state.updatedRelations },
            protocolsTime: { ...state.protocolsTime },
          });
        }
      })
      .addCase(setProtocol.fulfilled, (state, { payload }) => {
        const { id, value } = payload;
        state.isChanged = true;
        state.isHandleActionOnPage = true;
        state.updatedItems = { ...state.updatedItems, [id]: value };
        state.protocols = { ...state.protocols, [id]: value };
      })
      .addCase(selectProject.fulfilled, (state, { payload }: PayloadAction<Project>) => {
        const { projectId } = payload;
        state.projectId = projectId;
      })
      .addCase(getActor.fulfilled, (state, { payload }: PayloadAction<{ id: string }>) => {
        const { id } = payload;
        state.actorId = id;
      })
      .addCase(setRelations.fulfilled, (state, { payload }:PayloadAction<SetRelationArgs>) => {
        const { id, value, unitId } = payload;
        state.relations[id] = { ...state.relations[id], [unitId]: value };
        state.updatedRelations[id] = {
          ...state.updatedRelations[id],
          [unitId]: value,
        };
      })
      .addCase(getProtocols.fulfilled, (state, { payload }: PayloadAction<GetProtocolsPayload>) => {
        const { actorId, projectId } = payload;
        const localAnswers = actorId && projectId ? getAnswersFromLocalStorage({
          localKey: `${actorId}-${projectId}-unsaved-answers`,
          savedProtocols: payload.data,
        }) : { protocols: {}, relations: {}, protocolsTime: {} };
        state.protocols = {};
        const { degreeItemsByPagesFromState } = payload;
        if (!payload.data) {
          state.protocols = {};
        } else {
          const protocolsArray = payload.data;
          const protocolsObject: Protocols = {};
          const relationsObject: Relations = {};
          protocolsArray.forEach((el) => {
            if (!el.unitId) {
              protocolsObject[el.itemId] = el.answer;
            } else {
              let degreeItems: string[] = [];
              Object.keys(degreeItemsByPagesFromState).forEach((pageKey) => {
                degreeItems = [
                  ...degreeItems,
                  ...Object.keys(degreeItemsByPagesFromState[pageKey]),
                ];
              });
              const formatUnitId: string = degreeItems.includes(el.itemId) && typeof el.answer === 'string'
                ? `${el.unitId}|comment`
                : el.unitId;
              relationsObject[el.itemId] = {
                ...relationsObject[el.itemId],
                [formatUnitId]: el.answer,
              };
            }
          });
          Object.keys(localAnswers.relations).forEach((key) => {
            const localRelation = localAnswers.relations[key];
            if (!localRelation) return;
            if (Object.keys(localRelation).length === 0) delete localAnswers.relations[key];
          });

          state.protocols = { ...protocolsObject, ...localAnswers.protocols };
          const newRelations = { ...relationsObject };
          Object.entries(localAnswers.relations).forEach(([itemId, localRel]: [string, ItemRelations]) => {
            if (localRel) newRelations[itemId] = { ...newRelations[itemId], ...localRel };
          });
          state.relations = newRelations;
          state.protocolsTime = {
            ...state.protocolsTime,
            ...localAnswers.protocolsTime,
          };
          state.updatedItems = localAnswers.protocols;
          state.updatedRelations = localAnswers.relations;
        }
      })
      // @ts-ignore
      .addCase(deleteAnswer.fulfilled, (state, { payload }: PayloadAction<SetAnswerPayload>) => {
        const { id, unitId } = payload;
        if (unitId) {
          if (!state.deletedRelations[id]) state.deletedRelations[id] = {};
          state.deletedRelations[id][unitId] = state.relations[id][unitId];
          if (state.relations[id]) delete state.relations[id][unitId];
          if (state.updatedRelations[id]) delete state.updatedRelations[id][unitId];
        } else {
          state.deletedProtocols[id] = state.protocols[id];
          delete state.updatedItems[id];
          delete state.protocols[id];
          state.lastUpdatedItem = id;
        }
      })
      .addCase(optOutOfEvaluation.fulfilled, (state, { payload }: PayloadAction<OptOutOfEvaluationPayload>) => {
        const { questions, unitId } = payload;
        questions.forEach((question) => {
          state.relations[question.id] = {
            ...state.relations[question.id],
            [unitId]: question.value,
          };
          state.updatedRelations[question.id] = {
            ...state.updatedRelations[question.id],
            [unitId]: question.value,
          };
        });
        setAnswerInLocalStorage({
          id: '',
          localKey: `${state.actorId}-${state.projectId}-unsaved-answers`,
          updatedItems: { ...state.updatedItems },
          updatedRelations: { ...state.updatedRelations },
          protocolsTime: { ...state.protocolsTime },
        });
      })
      .addCase(setSeveralRelations.fulfilled, (state, { payload }: PayloadAction<SetSeveralRelationPayload>) => {
        const { units, id, value } = payload;
        const timeByUnits: RelationTime = {};
        units.forEach((unitId) => {
          timeByUnits[unitId] = getDateInPostgreFormat();
        });
        const newRelations: { [key: string]: string | number } = {};
        units.forEach((unitId) => {
          newRelations[unitId] = value;
        });
        state.relations[id] = { ...state.relations[id], ...newRelations };
        state.updatedRelations[id] = {
          ...state.updatedRelations[id],
          ...newRelations,
        };
        if (!state.protocolsTime[id]) state.protocolsTime[id] = {};
        state.protocolsTime[id] = {
          // @ts-ignore
          ...state.protocolsTime[id],
          ...timeByUnits,
        };
        setAnswerInLocalStorage({
          id: '',
          localKey: `${state.actorId}-${state.projectId}-unsaved-answers`,
          updatedItems: { ...state.updatedItems },
          updatedRelations: { ...state.updatedRelations },
          protocolsTime: { ...state.protocolsTime },
        });
      })
      .addCase(setSeveralProtocols.fulfilled, (state, { payload }) => {
        const { valuesWithId, protocolsTime, itemDeps } = payload;
        const updatedItemsWithDeps = Object.keys(valuesWithId).filter(
          (id) => itemDeps[id],
        );
        state.protocolsTime = { ...state.protocolsTime, ...protocolsTime };
        state.protocols = { ...state.protocols, ...valuesWithId };
        state.updatedItems = { ...state.updatedItems, ...valuesWithId };
        if (updatedItemsWithDeps.length) [state.lastUpdatedItem] = updatedItemsWithDeps;
        setAnswerInLocalStorage({
          id: '',
          localKey: `${state.actorId}-${state.projectId}-unsaved-answers`,
          updatedItems: { ...state.updatedItems },
          updatedRelations: { ...state.updatedRelations },
          protocolsTime: { ...state.protocolsTime },
        });
      })
      .addCase(setTestBlockAnswer.fulfilled, (state, { payload }: PayloadAction<SetTestBlockAnswer>) => {
        const { itemId, answer, time } = payload;
        state.protocols[itemId] = answer;
        state.protocolsTime[itemId] = time;
      })
      .addCase(sendAnswers.pending, (state) => {
        state.isAnswersInProcess = true;
      })
      .addCase(sendAnswers.fulfilled, (state, { payload }: PayloadAction<{ sentProtocols: SentProtocols, actorId: string, projectId: string }>) => {
        state.isAnswersInProcess = false;
        const { sentProtocols, actorId, projectId } = payload;
        localStorage.removeItem(
          `${actorId}-${projectId}-unsaved-answers`,
        );

        sentProtocols.forEach(({ itemId, answer, unitId }) => {
          if (!unitId) {
            if (state.updatedItems[itemId] === answer) delete state.updatedItems[itemId];
          } else if (
            state.updatedRelations[itemId]
            && state.updatedRelations[itemId][unitId] === answer
          ) {
            delete state.updatedRelations[itemId][unitId];
          }
        });
      });
  },
});

const { reducer } = protocolsSlice;

export default reducer;