import React from 'react';
import AdminMainListLink from '../../../AdminMainListLinkLink';
import SupersetStageInfo from '@/components/App/Admin/ListSupersets/FilteredSupersetsList/SupersetStageInfo/SupersetStageInfo';
import { Superset } from '@/store/types/admin/supersets';

interface SupersetListLinkProps {
  superset: Superset;
  index: number;
}

function SupersetListLink({ superset, index }: SupersetListLinkProps) {
  const {
    id, createdAt, name, stage, useFeedBack,
  } = superset;

  return (
    <AdminMainListLink
      key={id}
      to={`/admin/project/${id}`}
      linkTitle={`${index}. ${name}`}
      metaInfo={<SupersetStageInfo currentStage={stage} useFeedback={useFeedBack} />}
      date={createdAt}
    />
  );
}

export default SupersetListLink;