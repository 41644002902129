import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { clearSuperset, loadAllDataSuperset } from '@/store/actions/admin';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { clearSupersetProjects } from '@/store/actions/admin/superset/projects';

const useSuperset = () => {
  const { id: supersetId } = useParams();
  const dispatch = useDispatch();
  const superset = useSelector(selectSuperset);
  const doesSupersetExist = !!Object.keys(superset).length;
  const navigate = useNavigate();

  useEffect(() => {
    if (!doesSupersetExist) {
      dispatch(loadAllDataSuperset(supersetId))
        .then(unwrapResult)
        .catch(() => {
          navigate('/admin/projects');
        });
    }
  }, [supersetId, dispatch, doesSupersetExist, navigate]);

  useEffect(
    () => () => {
      dispatch(clearSuperset());
      dispatch(clearSupersetProjects());
    },
    [dispatch],
  );

  return superset;
};

export default useSuperset;