import { useState, useCallback } from 'react';

interface UseDelayProps {
  baseDelay: number;
  grouthRate?: number;
  factor?: number;
}

function useLogarithmicDelay({ baseDelay, grouthRate = 1, factor = 3000 }: UseDelayProps) {
  const [iteration, setIteration] = useState(1);
  const [delay, setDelay] = useState(baseDelay);

  const calculateDelay = useCallback(
    () => baseDelay + grouthRate * factor * Math.log(iteration + 1),
    [baseDelay, factor, grouthRate, iteration],
  );

  const resetDelay = useCallback(() => {
    setDelay(baseDelay);
    setIteration(0);
  }, [baseDelay]);

  const increaseIteration = useCallback(() => {
    setIteration((prevIteration) => prevIteration + 1);
    const newDelay = calculateDelay();
    setDelay(Math.trunc(newDelay));
  }, [calculateDelay]);

  return {
    delay, iteration, resetDelay, increaseIteration,
  };
}

export default useLogarithmicDelay;