import React, { useContext } from 'react';
import styles from './BUnitBranch.module.sass';
import { UnitId } from '@/store/types/user/units';
import BranchUnitsGroup
  from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/DepartmentUnit/BUnitBranch/BranchUnitsGroup/BranchUnitsGroup';
import useSplitedUnitsByType
  from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/DepartmentUnit/BUnitBranch/useSplitedUnitsByType';
import AnimatedContainerByHeight from '@/components/Common/AnimatedContainerByHeight/AnimatedContainerByHeight';
import newBUnitContext from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';
import { isDrawVertical } from '@/components/Common/UnitsTree/unitsTree';
import UnitsTreeContext from '@/components/App/Admin/BUnitsSection/UnitsTree/UnitsTreeContext';

interface BUnitBranchProps {
  childrenUnits?: UnitId[];
  isOpen: boolean;
}

function BUnitBranch({ childrenUnits, isOpen }: BUnitBranchProps) {
  const childrenUnitsByActorType = useSplitedUnitsByType(childrenUnits);
  const { toggleAnimation } = useContext(UnitsTreeContext);
  const { id, unit } = useContext(newBUnitContext);
  const { units } = useAdminSelector(selectUnitsTreeData);

  if (!childrenUnits?.length) return null;

  return (
    <div className={`${isDrawVertical(units, id) && styles.vertical}`}>
      <AnimatedContainerByHeight
        isOpen={isOpen}
        toggleAnimationState={(value: boolean) => {
          if (toggleAnimation && unit.level === 0) {
            toggleAnimation(value);
          }
        }}
      >
        <div>
          <BranchUnitsGroup isActor={false}>
            {childrenUnitsByActorType[0]}
          </BranchUnitsGroup>
          <BranchUnitsGroup isActor>
            {childrenUnitsByActorType[1]}
          </BranchUnitsGroup>
        </div>
      </AnimatedContainerByHeight>
    </div>
  );
}

export default BUnitBranch;