import React from 'react';
import { useSelector } from 'react-redux';
import styles from './HeaderCuts.module.sass';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';

function HeaderCuts() {
  const { name: supersetName } = useSelector(selectSuperset);
  // const checkPermission = useCheckPermission();

  return (
    <div className={styles['wrap-criteria-head']}>
      <h2>{supersetName}</h2>
      {/* {isDownloadsAvailable() && <Answers />} */}
    </div>
  );
}

export default HeaderCuts;