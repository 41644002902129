import { combineReducers } from '@reduxjs/toolkit';
import webinar from '../../../components/App/Webinar/CreateWebinarPage/reducers/webinar';

export interface SuperReducersState {
  webinar: ReturnType<typeof webinar>;
}

const superReducers = combineReducers<SuperReducersState>({
  webinar,
});

export default superReducers;