import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { unwrapResult } from '@reduxjs/toolkit';
import useModal from '@/hooks/modal';
import Modal from '@/components/Common/Modal';
import {
  selectSuperset,
  selectUnits,
} from '@/store/selectors/admin/superset/supersets';
import Input from '@/components/Common/form/Input';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import styles from './ModalGenerateTicketUnits.module.sass';
import { setUnitTickets } from '@/store/actions/admin';
import { UNIT_LIMIT_TICKETS } from '@/constants/admin';

function ModalGenerateTicketUnits({ toggleModal, isShowingModal }) {
  const { Content, WrapBtns } = useModal();
  const { supersetId } = useSelector(selectSuperset);
  const units = useSelector(selectUnits);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const onSaveTicketUnits = (unitTickets) => {
    // For each unit, we calculate how many tickets were added, not their total number
    Object.keys(unitTickets).forEach((unitID) => {
      const initCountTickets = units.find(
        ({ ID }) => ID === unitID,
      ).ticketsCount;
      unitTickets[unitID] -= initCountTickets;
    });

    dispatch(
      setUnitTickets({
        supersetId,
        ticketsCount: unitTickets,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        toggleModal();
      })
      .catch(() => null);
  };

  const validateTicketsUnitField = (
    changedCountTickets,
    initTicketsCount,
    fieldName,
  ) => {
    if (changedCountTickets < initTicketsCount) {
      setValue(fieldName, initTicketsCount);
      return 'Нельзя уменьшить количество созданных tickets';
    }

    if (changedCountTickets > UNIT_LIMIT_TICKETS) {
      setValue(fieldName, initTicketsCount);
      return 'Превышен лимит количества доступных tickets';
    }

    return true;
  };

  return (
    <Modal
      isShowing={isShowingModal}
      hide={toggleModal}
      title="Создание ticket'ов для бизнес юнитов"
      types="modal-small"
    >
      <Content>
        <form>
          {units.map(({ ID, name, ticketsCount }) => (
            <div key={ID} className={styles['unit-tickets-item']}>
              <Input
                register={register}
                registerOptions={{
                  valueAsNumber: true,
                  validate: (value) => validateTicketsUnitField(value, ticketsCount, ID),
                }}
                type="number"
                name={`${ID}`}
                label={name}
                id="supersetTickets"
                defaultValue={ticketsCount}
                externalClasses={{
                  wrapInput: styles['wrap-field-count-tickets'],
                  wrapInputNumberController:
                    styles['wrap-input-number-controller'],
                }}
              />
              {!!errors[ID] && (
                <span className={styles['valid-error-massage']}>
                  {errors[ID].message}
                </span>
              )}
            </div>
          ))}
        </form>
      </Content>
      <WrapBtns>
        <CustomButton onClick={toggleModal} styleTypeBtn="btn-grey-modal">
          Отменить
        </CustomButton>
        <CustomButton
          styleTypeBtn="btn-blue-modal"
          onClick={() => handleSubmit(onSaveTicketUnits)()}
        >
          Сгенерировать тикеты
        </CustomButton>
      </WrapBtns>
    </Modal>
  );
}

ModalGenerateTicketUnits.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  isShowingModal: PropTypes.bool.isRequired,
};
export default ModalGenerateTicketUnits;