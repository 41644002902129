// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".branch-container--lSFcZ{overflow-y:hidden}.vertical--42VJS{position:relative}.vertical--42VJS:before{content:\"\";position:absolute;border-left:solid 1px var(--admin-main-font-color);left:-30px;top:-30px;height:calc(100% + 30px);background:#838999}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnit/DepartmentUnit/BUnitBranch/BUnitBranch.module.sass"],"names":[],"mappings":"AAGA,yBACE,iBAAA,CAEF,iBACE,iBAAA,CACA,wBACE,UAAA,CACA,iBAAA,CACA,kDAAA,CACA,UAAA,CACA,SAAA,CACA,wBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"branch-container": "branch-container--lSFcZ",
	"vertical": "vertical--42VJS"
};
export default ___CSS_LOADER_EXPORT___;
