import React from 'react';
import { useSelector } from 'react-redux';
import styles from './BUnitsSectionHeader.module.sass';
import {
  selectSuperset,
} from '@/store/selectors/admin/superset/supersets';
import UnitsHeaderInformation
  from '@/components/App/Admin/BUnitsSection/BUnitsSectionHeader/UnitsHeaderInformation/UnitsHeaderInformation';

export default function BUnitsSectionHeader() {
  const { name, company } = useSelector(selectSuperset);

  return (
    <div className={styles['units-tree-header']}>
      <header className={styles.header}>
        <div className={styles.company}>{company}</div>
        <div className={styles.name}>{name}</div>
      </header>
      <UnitsHeaderInformation />
    </div>
  );
}