import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import styles from './SelectionList.module.sass';
import './themeVars.sass';
import { getClearClassNames } from '@/helpers/helpers';

function SelectionList({
  items,
  value,
  onClick,
  multiple,
  customItem,
  types,
  externalClassNames,
}) {
  const [itemsState, setItems] = useState(items);
  const [statusCloseList] = useState(false);
  const listRef = useRef(null);
  const [optionHoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    setItems(items);
  }, [items]);

  const {
    wrapSelectionList = '',
    itemClassName = '',
    btnItem = '',
  } = externalClassNames;

  const moduleClassNamesTypes = types
    .split(' ')
    .map((strClassName) => styles[strClassName])
    .join(' ');

  // TODO переделать
  const supportKeyboardNavigation = useCallback(
    () => {
      // if (e.key === 'ArrowDown') {
      //   e.preventDefault();
      //   setHoveredIndex((prevIndx) => {
      //     const nextIndx = prevIndx !== null ? prevIndx + 1 : 0;
      //
      //     return nextIndx > itemsState.length - 1 ? 0 : nextIndx;
      //   });
      // }
      //
      // if (e.key === 'ArrowUp') {
      //   e.preventDefault();
      //   setHoveredIndex((prevRenderIndx) => {
      //     const prevIndx = prevRenderIndx
      //       ? prevRenderIndx - 1
      //       : itemsState.length - 1;
      //
      //     return prevIndx < 0 ? itemsState.length - 1 : prevIndx;
      //   });
      // }
      //
      // if (e.key === 'Enter' || e.key === ' ') {
      //   e.preventDefault();
      //
      //   const { value: optionValue, onClickCurrentItem } = itemsState[optionHoveredIndex];
      //
      //   if (onClickCurrentItem) {
      //     onClickCurrentItem(optionValue);
      //   } else {
      //     onClickItem(optionValue);
      //   }
      // }
      //
      // if (e.key === 'Escape') {
      //   toggleStatusCloseList(true);
      // }
    },
    [], // [itemsState, onClickItem, optionHoveredIndex]
  );

  useEffect(() => {
    window.addEventListener('keydown', supportKeyboardNavigation);

    return () => {
      window.removeEventListener('keydown', supportKeyboardNavigation);
    };
  }, [supportKeyboardNavigation]);

  useEffect(() => {
    const adjustListSize = () => {
      if (!listRef.current) return;

      const pageHeight = document.documentElement.scrollHeight;
      const listPositionTop = listRef.current.getBoundingClientRect().top + window.scrollY;
      const listHeight = listRef.current.offsetHeight;

      const maxPossibleHeight = pageHeight - listPositionTop - 20;

      if (listHeight > maxPossibleHeight) {
        listRef.current.style.maxHeight = `${maxPossibleHeight}px`;
        listRef.current.style.overflowY = 'auto';
      } else {
        listRef.current.style.maxHeight = 'initial';
        listRef.current.style.overflowY = 'initial';
      }
    };

    adjustListSize(); // Check on mount
    window.addEventListener('resize', adjustListSize);
    return () => window.removeEventListener('resize', adjustListSize); // Cleanup on unmount
  }, []);

  if (statusCloseList) {
    return null;
  }

  const onClickItem = (
    clickedItem,
    isClickedItemSelected,
    selectedValues,
    isMultiple,
  ) => {
    const { value: clickedItemValue, label: clickItemLabel } = clickedItem;

    if (isMultiple) {
      if (!value) {
        onClick([clickedItem]);
        return;
      }

      const newSelectedItems = isClickedItemSelected
        ? selectedValues.filter(
          // eslint-disable-next-line max-len
          ({ value: valueSelectedOption, label: labelSelectedOption }) => String(valueSelectedOption) !== String(clickedItemValue)
              || labelSelectedOption !== clickItemLabel,
        )
        : [...value, clickedItem];

      onClick(newSelectedItems);
    }

    if (!isMultiple) {
      if (!clickedItem.onClickCurrentItem) {
        onClick(clickedItem);
      } else {
        clickedItem.onClickCurrentItem(clickedItem);
      }
    }
  };

  return (
    <div
      className={getClearClassNames([
        styles['wrap-selection-list'],
        wrapSelectionList,
        moduleClassNamesTypes,
        multiple ? 'multiple' : '',
      ])}
      onMouseLeave={() => setHoveredIndex(null)}
      ref={listRef}
    >
      <ul className={styles['selection-list']}>
        {itemsState.map((item, i) => {
          const { value: itemValue, label } = item;

          let isSelected = false;

          if (Array.isArray(value)) {
            isSelected = value.some(
              // eslint-disable-next-line max-len
              ({ value: selectedItemValue, label: selectedItemLabel }) => String(itemValue) === String(selectedItemValue)
                && label === selectedItemLabel,
            );
          } else if (value) {
            const { value: selectedItemValue, label: selectedItemLabel } = value;
            isSelected = String(selectedItemValue) === String(itemValue)
              && label === selectedItemLabel;
          }

          const itemID = `list-item-${i}`;

          return (
            <li
              key={itemID}
              className={getClearClassNames([
                styles.item,
                isSelected ? styles.selected : '',
                i === optionHoveredIndex ? styles.hovered : '',
                itemClassName,
              ])}
            >
              <div
                role="button"
                tabIndex="0"
                className={getClearClassNames([styles['btn-item'], btnItem])}
                data-value={itemValue}
                onKeyDown={() => {}}
                onClick={() => onClickItem(item, isSelected, value, multiple)}
                onMouseEnter={() => setHoveredIndex(i)}
              >
                {customItem
                  ? customItem({ ...item, checked: isSelected })
                  : label}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

SelectionList.defaultProps = {
  value: null,
  onClick: () => {},
  multiple: false,
  types: '',
  customItem: null,
  externalClassNames: {},
};

SelectionList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      onClickCurrentItem: PropTypes.func,
    }),
  ).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
      }),
    ),
  ]),
  multiple: PropTypes.bool,
  onClick: PropTypes.func,
  types: PropTypes.string,
  customItem: PropTypes.func,
  externalClassNames: PropTypes.shape({
    wrapSelectionList: PropTypes.string,
    itemClassName: PropTypes.string,
    btnItem: PropTypes.string,
  }),
};

export default SelectionList;