import React from 'react';
import styles from './BUnitCardFooterButtons.module.sass';
import BUnitCardConfirmButton
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFooter/BUnitCardConfirmButton/BUnitCardConfirmButton';
import BUnitCardRejectButton
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFooter/BUnitCardRejectButton/BUnitCardRejectButton';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';

interface BUnitCardFooterButtonsProps {
  isUnitUpdated: boolean;
  reset: () => void;
  formId?: string;
  confirmClick?: () => void
}

function BUnitCardFooterButtons({
  isUnitUpdated, reset, formId = '', confirmClick = () => {},
}: BUnitCardFooterButtonsProps) {
  const checkPermission = useCheckPermission();

  if (!checkPermission('modify_units')) return null;

  return (
    <div className={styles['unit-card-buttons']}>
      <BUnitCardConfirmButton isUnitUpdated={isUnitUpdated} formId={formId} handleClick={confirmClick} />
      <BUnitCardRejectButton resetForm={reset} />
    </div>
  );
}

export default BUnitCardFooterButtons;