import { useAdminDispatch } from "@/store/hooks/adminRedux";
import SuperItemRow from "../../Common/SuperItemRow/SuperItemRow";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { selectArticles } from "@/store/selectors/admin/super";
import { ReadArticles } from "@/store/actions/admin/super";

export default function ArticlesList({

}) {
    const dispatch = useAdminDispatch();
    const articles = useSelector(selectArticles);

    useEffect(() => {
        dispatch(ReadArticles());
    }, []);


    return articles && (
        articles.map(article => (
            <SuperItemRow key={article.id}>
                <h2>{article.path}</h2>
            </SuperItemRow>
        ))
    )
}