import React from 'react';
import PropTypes from 'prop-types';
import styles from './RadioButton.module.sass';
import './themeVars.sass';
import { getClearClassNames } from '@/helpers/helpers';

function RadioButton({
  id,
  name,
  label,
  onChange,
  register,
  registerOptions,
  externalClassNames,
  disabled,
  notInteractive,
  ...props
}) {
  const { onChange: setOnChange, ...restRegisterFields } = register
    ? register(name, registerOptions)
    : {
      onChange,
    };

  const { wrapOption = '', radioBtn = '', textLabel = '' } = externalClassNames;

  return (
    <div
      className={getClearClassNames([styles['wrap-custom-radio'], wrapOption])}
    >
      <input
        id={id}
        onChange={(e) => {
          setOnChange(e);
        }}
        type="radio"
        name={name}
        {...restRegisterFields}
        disabled={disabled || notInteractive}
        {...props}
      />
      <label htmlFor={id}>
        <span className={getClearClassNames([styles['radio-btn'], radioBtn])} />
        <span className={getClearClassNames([styles['text-label'], textLabel])}>
          {label}
        </span>
      </label>
    </div>
  );
}

RadioButton.defaultProps = {
  register: null,
  registerOptions: {},
  externalClassNames: {},
  onChange: () => {},
  disabled: false,
  notInteractive: false,
};

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onChange: PropTypes.func,
  register: PropTypes.func,
  registerOptions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  externalClassNames: PropTypes.shape({
    wrapOption: PropTypes.string,
    radioBtn: PropTypes.string,
    textLabel: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  notInteractive: PropTypes.bool,
};

export default RadioButton;