import React, { useRef } from 'react';
import { bool, node } from 'prop-types';
import { useTransition, animated } from '@react-spring/web';
import styles from './SlideToggleContent.module.sass';

const visibleStyle = {
  height: 'auto',
  opacity: 1,
  overflow: 'visible',
};

const hiddenStyle = {
  height: 0,
  opacity: 0,
  overflow: 'hidden',
};

function SlideToggleContent({ isVisible, children, forceSlideIn }) {
  const containerRef = useRef(null);
  const innerRef = useRef(null);
  const isVisibleOnMount = useRef(isVisible && !forceSlideIn);

  const transition = useTransition(isVisible, {
    enter: () => async (next) => {
      const height = innerRef.current ? innerRef.current.getBoundingClientRect().height : 0;
      if (height) {
        await next({ height, opacity: 1, overflow: 'hidden' });
        await next(visibleStyle);
      }
    },
    leave: () => async (next) => {
      const height = innerRef.current ? innerRef.current.getBoundingClientRect().height : 0;

      await next({ height, overflow: 'hidden' });
      await next(hiddenStyle);

      isVisibleOnMount.current = false;
    },
    from: isVisibleOnMount.current ? visibleStyle : hiddenStyle,
    unique: true,
  });

  return (
    <div
      className={styles['wrap-slide-toggle-content']}
      ref={containerRef}
    >

      {transition((stylesAnimation, item) => {
        if (item) {
          return (
            <animated.div
              ref={containerRef}
              style={stylesAnimation}
            >
              <div
                className={styles['inner-slide-toggle-content']}
                ref={innerRef}
              >
                {children}
              </div>
            </animated.div>
          );
        }

        return null;
      })}
    </div>
  );
}

SlideToggleContent.defaultProps = {
  forceSlideIn: false,
};

SlideToggleContent.propTypes = {
  isVisible: bool.isRequired,
  children: node.isRequired,
  forceSlideIn: bool,
};

export default SlideToggleContent;