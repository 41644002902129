// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-percentile-fs--gl0xh .list-of-factors--Qrarv{display:flex;flex-direction:column}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/FactorsCuts/PercentileFS/PercentileFS.module.sass"],"names":[],"mappings":"AAGE,mDACE,YAAA,CACA,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-percentile-fs": "wrap-percentile-fs--gl0xh",
	"list-of-factors": "list-of-factors--Qrarv"
};
export default ___CSS_LOADER_EXPORT___;
