import React from 'react';
import PropTypes from 'prop-types';
import styles from './FlagIcon.module.sass';

function FlagIcon({ langId, classNames }) {
  return (
    <img
      src={`/assets/flags/${langId}.svg`}
      className={`${classNames} ${styles['lang-icon']} lang-icon`}
      alt={`/assets/flags/${langId}.svg`}
    />
  );
}

FlagIcon.propTypes = {
  classNames: PropTypes.string,
  langId: PropTypes.string.isRequired,
};

FlagIcon.defaultProps = {
  classNames: '',
};

export default FlagIcon;