import React, { useLayoutEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CustomSelect from '@/components/App/Admin/BUnitsSection/CustomSelect';
import styles from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/SelectMenu.module.sass';
import { selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';
import CustomSelectOption from '@/components/App/Admin/BUnitsSection/CustomSelect/CustomSelectOption/CustomSelectOption';
import { isOneStringEnterAnother } from '@/helpers/helpers';

function BUnitsSelectMenuSpecs({ currentSelectSpec, setCurrentSelectSpec }) {
  const { units } = useSelector(selectUnitsTreeData);
  const [isOpen, toggleOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const specialisations = useMemo(() => {
    const specs = [];
    Object.keys(units).forEach((id) => {
      if (!units[id].spec) return;
      const { spec } = units[id];
      for (let i = 0; i < spec.length; i += 1) {
        if (!specs.includes(spec[i]) && spec[i].length) specs.push(spec[i]);
      }
    });
    return specs;
  }, [units]);

  const validatedSpecialisation = useMemo(
    () => specialisations.filter((spec) => isOneStringEnterAnother(spec, inputValue)),
    [inputValue, specialisations],
  );

  useLayoutEffect(() => {
    if (currentSelectSpec) return;

    setCurrentSelectSpec(() => specialisations[0]);
  }, [currentSelectSpec, setCurrentSelectSpec, specialisations]);

  if (!currentSelectSpec) return null;

  const handleSelectSpec = (spec) => {
    setCurrentSelectSpec(spec);
    toggleOpen(false);
    setInputValue('');
  };

  return (
    <CustomSelect
      activeValue={currentSelectSpec}
      type="input"
      toggleOpen={toggleOpen}
      isOpen={isOpen}
      mainBtnClassNames={styles['select-input']}
      onKeyUp={(event) => setInputValue(event.target.value)}
    >
      {validatedSpecialisation.map((spec) => (
        <CustomSelectOption
          key={spec}
          optionClassNames={styles['unit-menu-select']}
          onClick={() => handleSelectSpec(spec)}
          isCurrentValue={currentSelectSpec === spec}
        >
          {spec}
        </CustomSelectOption>
      ))}
    </CustomSelect>
  );
}

BUnitsSelectMenuSpecs.propTypes = {
  currentSelectSpec: PropTypes.string,
  setCurrentSelectSpec: PropTypes.func.isRequired,
};

BUnitsSelectMenuSpecs.defaultProps = {
  currentSelectSpec: '',
};

export default BUnitsSelectMenuSpecs;