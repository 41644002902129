import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import styles from './AdminSupersetContainer.module.sass';
import LinkToBackToPrevPage from '@/components/App/Admin/UIElements/LinkToBackToPrevPage';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import useNLS from '@/components/App/Admin/hooks/hooks';
import ButtonsPanel from '@/components/Common/BaseTemplate/MainContentContainer/AdminSupersetContainer/ButtonsPanel/ButtonsPanel';
import AdminSupersetTabs from '@/components/Common/BaseTemplate/MainContentContainer/AdminSupersetContainer/AdminSupersetTabs/AdminSupersetTabs';
import AdminSupersetContainerControls from '@/components/Common/BaseTemplate/MainContentContainer/AdminSupersetContainer/AdminSupersetContainerContolds/AdminSupersetContainerControls';
import { useAdminSelector } from '@/store/hooks/adminRedux';

interface AdminSupersetContainerProps {
  children: ReactNode;
}

function AdminSupersetContainer({ children: content }: AdminSupersetContainerProps) {
  const tNLS = useNLS();
  const { name } = useAdminSelector(selectSuperset);

  return (
    <>
      {name && (
        <Helmet>
          <title>{name}</title>
        </Helmet>
      )}
      <div className={styles['header-content-container']}>
        <AdminSupersetContainerControls />
        <LinkToBackToPrevPage
          classNames={styles['link-to-projects']}
          to="/admin/projects"
        >
          {tNLS('to.project.list')}
        </LinkToBackToPrevPage>
        <AdminSupersetTabs />
      </div>
      <div
        className={`main-content-container ${styles['admin-superset-container']}`}
      >
        <ButtonsPanel />
        {content}
      </div>
    </>
  );
}

export default AdminSupersetContainer;