// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".how-to-control-btn--8Vhzw{font-size:1.25rem;padding:0}.how-to-control-btn--8Vhzw .revert--Dw1J8{font-size:0.875rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitHowToElement/DeleteRestoreButton/DeleteRestoreButton.module.sass"],"names":[],"mappings":"AAEA,2BACE,iBAAA,CACA,SAAA,CACA,0CACE,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"how-to-control-btn": "how-to-control-btn--8Vhzw",
	"revert": "revert--Dw1J8"
};
export default ___CSS_LOADER_EXPORT___;
