// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".controls--AaKFY{align-self:start;display:flex;align-items:center;gap:0.625rem}.controls--AaKFY .btn-remove-question--Dk7oR .icon-remove--22Umx{font-size:1.25rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/AddingQuestions/CustomQuestionControls/CustomQuestionControls.module.sass"],"names":[],"mappings":"AAEA,iBACE,gBAAA,CACA,YAAA,CACA,kBAAA,CACA,YAAA,CAEE,iEACE,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controls": "controls--AaKFY",
	"btn-remove-question": "btn-remove-question--Dk7oR",
	"icon-remove": "icon-remove--22Umx"
};
export default ___CSS_LOADER_EXPORT___;
