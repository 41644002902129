import axios from 'axios';
import defaultUserErrors from 'src/../static/defaultData/defaultUserErrorsNls.json';
import { addNotification } from '@/store/reducers/notify';
import { adminLogoutAndClearData } from '@/store/actions/admin';

const axiosInstance = axios.create();

let dispatch: any;

const isAdmin = window.location.pathname.includes('/admin');

export const setGlobalDispatch = (reduxDispatch: any) => {
  dispatch = reduxDispatch;
};

interface CustomError {
  status: number;
  response: { data: { message: string; } }
}

axiosInstance.interceptors.response.use(
  (response) => {
    if (!response.data) return response;
    const redirectErrors = defaultUserErrors as NlsType;
    if (!('status' in response.data)) return response;

    const { status, content = {}, message = '' } = response.data;

    if (dispatch && message && !redirectErrors[message]) {
      const formatedMessage = message.toLowerCase().replaceAll(' ', '');
      if (formatedMessage && formatedMessage !== "somethinggoeswrong.we'realreadyworkingonit!") {
        dispatch(addNotification({
          type: status === 0 ? 'success' : 'error',
          message,
        }));
      }
    }

    if (status === 0) {
      return {
        ...response,
        data: content,
      };
    }

    const customError: CustomError = {
      status,
      response: { data: { message } },
    };

    if (status === 10002 && isAdmin) {
      dispatch(adminLogoutAndClearData());
    }

    return Promise.reject(customError);
  },
  (error) => Promise.reject(error),
);

export default axiosInstance;