import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axiosProcessing from '@/axiosConfig';
import { AdminState } from '@/store/admin';
import {
  LoadSupersetBlocksActionArgs,
  SetSupersetBlocksActionArgs,
  SupersetBlocks,
} from '@/store/types/admin/superset/blocks';

export const loadSupersetBlocks = createAsyncThunk(
  'superset/blocks/loadSupersetBlocks',
  async (form: LoadSupersetBlocksActionArgs, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<SupersetBlocks>(
        `/api/admin/getSupersetBlocks/${form.supersetId}`,
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const setSupersetBlocks = createAsyncThunk(
  'superset/blocks/setSupersetBlocks',
  async (form: SetSupersetBlocksActionArgs, { getState, rejectWithValue }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;
      await axiosProcessing.put('/api/admin/setBlocks', form, { headers });

      return form.blocks;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const setDownloadableBlocksSuperset = createAction<SupersetBlocks>(
  'supersetProjects/setDownloadableBlocksSuperset',
);