import React from 'react';
import { useSelector } from 'react-redux';
import styles from './SelectMenuTitle.module.sass';
import { selectSelectedUnits } from '@/store/selectors/admin/superset/unitsTree';

function SelectMenuTitle() {
  const selectedUnits = useSelector(selectSelectedUnits);

  return (
    <div className={styles['selected-count']}>
      <span className={styles.title}>Сотрудников отмечено: </span>
      <span className={styles.number}>{selectedUnits.length}</span>
    </div>
  );
}

export default SelectMenuTitle;