// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content-admin-invite--IidCM .form-admin-invite--VIALE{display:flex;flex-direction:column;gap:0.9375rem;max-width:21.875rem}.content-admin-invite--IidCM .form-admin-invite--VIALE .label-field--qGjZv{width:100%}.content-admin-invite--IidCM .form-admin-invite--VIALE .wrap-select--poM0e{width:100%}.input-error-message--R0zjm{color:var(--error-color);font-size:0.875rem;bottom:100%;margin-top:0.3125rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SupersetSettings/SectionAdmins/ModalInviteAdmin/ModalInviteAdmin.module.sass"],"names":[],"mappings":"AAGE,uDACE,YAAA,CACA,qBAAA,CACA,aAAA,CACA,mBAAA,CACA,2EACE,UAAA,CACF,2EACE,UAAA,CAEN,4BACE,wBAAA,CACA,kBAAA,CACA,WAAA,CACA,oBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-admin-invite": "content-admin-invite--IidCM",
	"form-admin-invite": "form-admin-invite--VIALE",
	"label-field": "label-field--qGjZv",
	"wrap-select": "wrap-select--poM0e",
	"input-error-message": "input-error-message--R0zjm"
};
export default ___CSS_LOADER_EXPORT___;
