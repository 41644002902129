import React from 'react';
import { Tooltip } from 'react-tooltip';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styles from './TypeFSToggler.module.sass';
import { getClearClassNames } from '@/helpers/helpers';
import {
  ABSOLUTE,
  PERCENTILE,
} from '@/components/App/Admin/Results/Cuts/constants';
import { setActiveTypeFS } from '@/store/actions/admin/superset/process/cuts/factorsCuts';
import useNLS from '@/components/App/Admin/hooks/hooks';

function TypeFSToggler({ typeFS }) {
  const dispatch = useDispatch();
  const tNLS = useNLS();

  const toggleTypeFS = () => {
    dispatch(setActiveTypeFS(typeFS === ABSOLUTE ? PERCENTILE : ABSOLUTE));
  };

  return (
    <div className={styles['wrap-type-fs-toggler']}>
      <Tooltip id="btnTypeFSToggler" />
      <button
        type="button"
        className={getClearClassNames([
          'btn-without-style',
          styles['btn-type-fs-toggler'],
          typeFS === PERCENTILE
            ? styles['percentile-fs']
            : styles['absolute-fs'],
        ])}
        data-tooltip-id="btnTypeFSToggler"
        data-tooltip-content={
          typeFS === PERCENTILE
            ? tNLS('cuts.scales.perc')
            : tNLS('cuts.scales.abs')
        }
        onClick={toggleTypeFS}
      >
        {' '}
        <span
          className={`icon-type-factor-sctruct ${styles['icon-type-factor-sctruct']}`}
        />
      </button>
    </div>
  );
}

TypeFSToggler.propTypes = {
  typeFS: PropTypes.string.isRequired,
};

export default TypeFSToggler;