import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { authAdminByToken } from '@/store/actions/admin';

function AuthByToken() {
  const dispatch = useDispatch();
  const { adminId, time, inviteToken } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(authAdminByToken({ adminId, time, token: inviteToken }))
      .then(unwrapResult)
      .then(() => {
        navigate('/admin/profile', { replace: true });
      })
      .catch(() => null);
  }, [dispatch, navigate, inviteToken, adminId, time]);

  return null;
}

export default AuthByToken;