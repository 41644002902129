// calculate path for histogram
export const calculatePath = (
  parentWidth,
  defaultContainerWidth,
  answerStat,
) => {
  const getPath = (vector, total, xleft, xstep, yc, maxamp, delta) => {
    const rectHeight = 3; // The height of the rectangle

    const isAllZero = vector.every((v) => v === 0);
    if (isAllZero) {
      // Draw a thin rectangle that stretches the full width of the SVG container
      return `M ${xleft} ${yc - rectHeight / 2} L ${xleft + 1000} ${
        yc - rectHeight / 2
      } L ${xleft + 1000} ${yc + rectHeight / 2} L ${xleft} ${
        yc + rectHeight / 2
      } Z`;
    }

    let output = '';
    xstep = 188 * (5 / vector.length);
    const dxc = xstep / 3;

    output += `M ${xleft} ${yc} L ${xleft} ${yc + delta}\n`;
    for (let i = 0; i < vector.length; i += 1) {
      let yr = yc + delta;
      if (i < vector.length - 1) {
        yr = yc + delta + ((vector[i] + vector[i + 1]) / (2 * total)) * maxamp;
      }
      const y = yc + delta + (vector[i] / total) * maxamp;
      const xl = i * xstep + xleft;
      const xr = xl + xstep;
      output += `C ${xl + dxc} ${y} ${xr - dxc} ${y} ${xr} ${yr}\n`;
    }

    output += `L ${xleft + xstep * vector.length} ${yc - delta}\n`;

    for (let i = vector.length - 1; i >= 0; i -= 1) {
      let yl = yc - delta;
      if (i > 0) {
        yl = yc - delta - ((vector[i] + vector[i - 1]) / (2 * total)) * maxamp;
      }
      const y = yc - delta - (vector[i] / total) * maxamp;
      const xl = (i + 1) * xstep + xleft;
      const xr = xl - xstep;
      output += `C ${xl - dxc} ${y} ${xr + dxc} ${y} ${xr} ${yl}\n`;
    }

    output += `L ${xleft} ${yc}\n`;
    return output;
  };

  const normalize = answerStat.map(
    (countActors) => ((countActors * 100) / 100) * 1.1,
  );

  const total = answerStat.reduce((sum, current) => sum + current, 0);

  return getPath(
    normalize,
    total,
    0,
    180,
    30,
    100 * (defaultContainerWidth / parentWidth),
    2,
  );
};

export const calculateViewBoxFromPath = (pathData) => {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  // Split the pathData into commands and points
  const pathParts = pathData
    .split(/(?=[MmLlHhVvCcSsQqTtAaZz])/)
    .filter(Boolean);

  pathParts.forEach((part) => {
    const command = part.charAt(0);
    const points = part
      .substring(1)
      .trim()
      .split(/[\s,]+/)
      .map(Number);

    switch (command) {
      case 'M':
      case 'L':
        for (let i = 0; i < points.length; i += 2) {
          minX = Math.min(minX, points[i]);
          maxX = Math.max(maxX, points[i]);
          minY = Math.min(minY, points[i + 1]);
          maxY = Math.max(maxY, points[i + 1]);
        }
        break;
      case 'C':
        // For cubic Bezier curves, include control points in the bounds calculation
        for (let i = 0; i < points.length; i += 6) {
          minX = Math.min(minX, points[i], points[i + 2], points[i + 4]);
          maxX = Math.max(maxX, points[i], points[i + 2], points[i + 4]);
          minY = Math.min(minY, points[i + 1], points[i + 3], points[i + 5]);
          maxY = Math.max(maxY, points[i + 1], points[i + 3], points[i + 5]);
        }
        break;
      default:
        break;
    }
  });

  const width = maxX - minX;
  const height = maxY - minY;
  return `${minX} ${minY} ${width} ${height}`;
};

export const calcOptionPercents = (optionValues) => {
  const total = optionValues.reduce((acc, num) => acc + num, 0);
  if (total === 0) {
    return optionValues.map(() => 0);
  }

  return optionValues.map((num) => (num / total) * 100);
};

export const calcAverageScore = (answersCodes, optionPercents) => {
  const numerator = answersCodes.reduce((sum, answerCode, i) => {
    const optionPercent = optionPercents[i];

    return sum + answerCode * optionPercent;
  }, 0);

  return Number(
    (
      numerator / optionPercents.reduce((sum, percent) => sum + percent, 0)
    ).toFixed(2),
  );
};

export const calcPercentageOfMaxScore = (averageScores, answersCodes) => {
  const min = Math.min(...answersCodes);
  const max = Math.max(...answersCodes);

  if (Array.isArray(averageScores)) {
    return averageScores.map((num) => Number((((num - min) / (max - min)) * 100).toFixed(1)));
  }

  return Number((((averageScores - min) / (max - min)) * 100).toFixed(1));
};