import React, {
  ChangeEvent, KeyboardEvent, useContext, useEffect, useRef, useState,
} from 'react';
import '../DepartmentUnitControlByType.sass';
import { unwrapResult } from '@reduxjs/toolkit';
import styles from './DepartmentUnitInput.module.sass';
import { resizeInput } from '@/helpers/helpers';
import newBUnitContext from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import { unitTreeSlice } from '@/store/reducers/admin/superset/unitsTree';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { updateTreeUnits } from '@/store/actions/admin/superset/unitsTree';
import useUpdateUnitName
  from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/DepartmentUnit/DepartmentUnitControlByType/DepartmentUnitInput/UnpdateUnitName';

interface DepartmentUnitInputProps {
  closeInput: () => void;
}

function DepartmentUnitInput({ closeInput }: DepartmentUnitInputProps) {
  const { unit, id } = useContext(newBUnitContext);
  const { supersetId } = useAdminSelector(selectSuperset);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(unit.name);
  const dispatch = useAdminDispatch();

  const genUpdateForms = useUpdateUnitName();

  const updateBUnitName = () => {
    const newName = inputValue.trim();
    if (!newName) {
      closeInput();
      return;
    }
    if (newName === unit.name) {
      closeInput();
      return;
    }
    const form = genUpdateForms(inputValue, id);
    if (!form.length) return;
    closeInput();
    dispatch(updateTreeUnits({ supersetId, unitsData: genUpdateForms(inputValue, id) }))
      .then(unwrapResult)
      .then(() => {
        if (Number(id) < 0) dispatch(unitTreeSlice.actions.updateLocalUnitName({ newName, id }));
      })
      .catch(() => null);
  };

  const handleBlur = () => {
    updateBUnitName();
  };

  const keyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') handleBlur();
    if (event.code === 'Escape') closeInput();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value);
    resizeInput(e.target);
  };

  useEffect(() => {
    inputRef?.current?.focus();
    resizeInput(inputRef.current, 10);
  }, []);

  return (
    <input
      className={`department-name ${styles['department-input']}`}
      ref={inputRef}
      value={inputValue}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyUp={keyUp}
    />
  );
}

export default DepartmentUnitInput;