import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { selectAdminSettings } from '@/store/selectors/admin/superset/settings';
import { selectCurrentLangID } from '@/store/selectors/admin/superset/projects';

function MacrosReplacer({ children: str }) {
  const settings = useSelector(selectAdminSettings);
  const currentLangID = useSelector(selectCurrentLangID);
  const regex = /\{([^}]+)\}/g;

  const replacedStr = str.replace(regex, (match) => {
    const [name, substitutionIndx] = match.slice(1, -1).split(':');
    const indx = parseInt(substitutionIndx, 10);

    const { value: currentCaseValue, langs } = settings[name];
    const { cases } = langs[currentLangID];
    const currentCase = cases.find(
      ({ caseValue }) => caseValue === currentCaseValue,
    );

    return currentCase.substitutions[indx];
  });

  return parse(replacedStr);
}

export default MacrosReplacer;