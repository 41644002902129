import React, { ReactNode, CSSProperties } from 'react';
import { Outlet } from 'react-router-dom';
import styles from './MainContentContainer.module.sass';
import AdminSupersetContainer from '@/components/Common/BaseTemplate/MainContentContainer/AdminSupersetContainer';

interface MainContentContainerProps {
  children?: ReactNode;
  typeContainer?: MainContentContainerTypes;
  parentContainerStyle?: CSSProperties;
}

export enum MainContentContainerTypes {
  Admin = 'adminSuperset',
  Default = 'default',
}
function MainContentContainer({ children, parentContainerStyle, typeContainer = MainContentContainerTypes.Default }: MainContentContainerProps) {
  const content = children || <Outlet />;
  let extraContainerWithContent = null;

  if (typeContainer === 'default') {
    extraContainerWithContent = (
      <div
        className={`main-content-container ${styles[typeContainer]}`}
      >
        {content}
      </div>
    );
  }

  if (typeContainer === 'adminSuperset') {
    extraContainerWithContent = (
      <AdminSupersetContainer>{content}</AdminSupersetContainer>
    );
  }

  return (
    <div className={styles['wrapper-container']} style={parentContainerStyle}>
      {extraContainerWithContent}
    </div>
  );
}
export default MainContentContainer;