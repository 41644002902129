import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import BUnitsMenuBtn from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitsBlueBtn/BUnitsMenuBtn';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { selectTreeUnits } from '@/store/actions/admin/superset/unitsTree';
import { selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';

function BUnitsSelectMenuConfirmButton({
  currentSelectAction,
  currentSelectOption,
  currentSelectSpec,
}) {
  const tNLS = useNLS();
  const dispatch = useDispatch();
  const { supersetId } = useSelector(selectSuperset);
  const { units } = useSelector(selectUnitsTreeData);
  const selectUnits = (unitsData) => {
    dispatch(
      selectTreeUnits({
        supersetId,
        unitsData,
        isSelect: currentSelectAction.key === 'tree.select',
      }),
    );
  };

  const checkCondition = (action, id, spec) => {
    if (units[id].isunit !== 0 || units[id].selected === action) return false;
    const {
      isEstimator, isEstimated, hasStarted, hasFinished,
    } = units[id];
    if (currentSelectOption.key === 'tree.select.all') return true;
    if (
      currentSelectOption.key === 'tree.select.started'
      && hasStarted
      && !hasFinished
    ) return true;
    if (
      currentSelectOption.key === 'tree.select.didntstart'
      && !hasStarted
      && !hasFinished
    ) return true;
    if (currentSelectOption.key === 'tree.select.finished' && hasFinished) return true;
    if (currentSelectOption.key === 'tree.select.respondents' && isEstimator) return true;
    if (currentSelectOption.key === 'tree.select.foci' && isEstimated) return true;

    return !!(
      currentSelectOption.key === 'tree.select.byspec'
      && spec.includes(currentSelectSpec)
    );
  };

  const setUnitsForSelect = () => {
    const action = currentSelectAction.key === 'tree.select';
    return Object.keys(units).flatMap((id) => {
      const { spec } = units[id];
      if (!spec) return [];
      if (!checkCondition(action, id, spec)) return [];
      return id;
    });
  };

  return (
    <BUnitsMenuBtn
      handleButtonClick={() => {
        selectUnits(setUnitsForSelect());
      }}
    >
      {tNLS('tree.select.go')}
    </BUnitsMenuBtn>
  );
}

BUnitsSelectMenuConfirmButton.propTypes = {
  currentSelectAction: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
  }),
  currentSelectOption: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
  }),
  currentSelectSpec: PropTypes.string,
};

export default BUnitsSelectMenuConfirmButton;