import React from 'react';
import PropTypes from 'prop-types';
import styles from './AnswerOptionLabels.module.sass';

function AnswerOptionLabels({ nameAnswerOptions }) {
  const renderAnswerOptionLabels = (options) => options.map((label, i) => {
    const key = `label-${i}`;

    return (
      <li key={key} className={styles['answer-option-label']}>
        {label}
      </li>
    );
  });

  return (
    <div className={styles['wrap-answer-option-labels']}>
      <ul className={styles['list-of-answer-option-labels']}>
        {renderAnswerOptionLabels(nameAnswerOptions)}
      </ul>
    </div>
  );
}

AnswerOptionLabels.propTypes = {
  nameAnswerOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AnswerOptionLabels;