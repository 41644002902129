import React from 'react';
import PropTypes from 'prop-types';
import styles from './BUnitTemplatesContent.module.sass';
import AdminMacro from '@/components/App/User/Common/AdminMacros/AdminMacros';

function BUnitTemplatesContent({
  templatesByLang,
  currentTemplateId,
  currentTemplateLang,
}) {
  if (!currentTemplateId) return null;
  return (
    <div className={styles['templates-container']}>
      <div className={styles['templates-carousel']}>
        {templatesByLang[currentTemplateId] && (
          <div className={styles['template-container']}>
            <AdminMacro langByParent={currentTemplateLang}>
              {templatesByLang[currentTemplateId].Preview}
            </AdminMacro>
          </div>
        )}
      </div>
    </div>
  );
}

BUnitTemplatesContent.propTypes = {
  templatesByLang: PropTypes.objectOf(
    PropTypes.shape({
      ID: PropTypes.string,
      Title: PropTypes.string,
      Subject: PropTypes.string,
      Body: PropTypes.string,
      Preview: PropTypes.string,
    }),
  ).isRequired,
  currentTemplateLang: PropTypes.string.isRequired,
  currentTemplateId: PropTypes.string.isRequired,
};

export default BUnitTemplatesContent;