// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".employee--W7JEL{display:flex;flex-direction:column}.employee--W7JEL .employee-fields--EbKjP{display:flex;align-items:baseline;width:100%;justify-content:space-between}.with-card--kOyLR{background:var(--admin-hover-background-interactive-elements);border-radius:0.75rem;margin-bottom:0.5rem}.card-container--f0Fa0{padding-bottom:0.5rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnit/EmployeeUnit/EmployeeUnit.module.sass"],"names":[],"mappings":"AAIA,iBACE,YAAA,CACA,qBAAA,CACA,yCACE,YAAA,CACA,oBAAA,CACA,UAAA,CACA,6BAAA,CAEJ,kBACE,6DAAA,CACA,qBAAA,CACA,oBAAA,CAEF,uBACE,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"employee": "employee--W7JEL",
	"employee-fields": "employee-fields--EbKjP",
	"with-card": "with-card--kOyLR",
	"card-container": "card-container--f0Fa0"
};
export default ___CSS_LOADER_EXPORT___;
