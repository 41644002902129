import React from 'react';
import { string } from 'prop-types';
import styles from './Preloader.module.sass';
import { getClearClassNames } from '@/helpers/helpers';

function Preloader({ externalClass }) {
  return (
    <div className={getClearClassNames([
      styles.loader,
      externalClass,
    ])}
    />
  );
}

Preloader.defaultProps = {
  externalClass: '',
};

Preloader.propTypes = {
  externalClass: string,
};

export default Preloader;