import React from 'react';
import styles from './CreateNewSpecBtn.module.sass';

interface CreateNewSpecBtnProps {
  handleCreateSpec: () => void;
}
function CreateNewSpecBtn({ handleCreateSpec }: CreateNewSpecBtnProps) {
  return (
    <li>
      <button
        type="button"
        className={styles['create-new-btn']}
        onClick={handleCreateSpec}
      >
        +
      </button>
    </li>
  );
}

export default CreateNewSpecBtn;