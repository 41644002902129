import {
  ORDER_ARM, ORDER_DE, ORDER_ENG, ORDER_RU,
} from '@/constants/admin';
import { AdminState } from '@/store/admin';
import { Languages } from '@/store/types/admin/superset/superset';

const getStoreContextSuperset = (state: AdminState) => state.admin.superset.projects;
const getStoreContextSupersetFields = (state: AdminState) => state.admin.superset.supersetFields;

export const selectSupersetProjects = (state: AdminState) => state.admin.superset.projects.projects;

export const selectUploadResourcesInfo = (state: AdminState) => getStoreContextSuperset(state).uploadResourcesInfo;

export const selectTypeOfUploadingResources = (state: AdminState) => getStoreContextSuperset(state).uploadResourcesInfo?.fileType ?? null;

export const selectStatusIsResourceUploaded = (state: AdminState) => !!getStoreContextSuperset(state).uploadResourcesInfo;

export const selectErrorMessageOnUploading = (state: AdminState) => getStoreContextSuperset(state).errorMessageOnUploading;

export const selectSortedLanguages = (langs: Languages): Languages => {
  const orderLang = {
    am: ORDER_ARM,
    de: ORDER_DE,
    en: ORDER_ENG,
    ru: ORDER_RU,
  };

  const copyLanguages = {};

  Object.keys(langs).forEach((langID) => {
    // @ts-ignore
    copyLanguages[langID] = { order: orderLang[langID], ...langs[langID] };
  });

  return copyLanguages;
};

export const selectLanguages = (state: AdminState) => {
  const { languages } = state.admin.superset.supersetFields;

  return selectSortedLanguages(languages);
};

export const selectLanguagesMasterSuperset = (state: AdminState): Languages => {
  const { supersetLangs } = state.admin.superset.projects;

  return selectSortedLanguages(supersetLangs);
};

export const selectActiveLanguagesMasterSuperset = (state: AdminState) => {
  const supersetLangs: Languages = selectLanguagesMasterSuperset(state);
  const activeLangs: Languages = {};

  Object.keys(supersetLangs).forEach((langID) => {
    if (supersetLangs[langID].isActive) {
      activeLangs[langID] = supersetLangs[langID];
    }
  });

  return activeLangs;
};

export const selectActiveLangIDsSortedByPriority = (state: AdminState) => {
  const activeLangs = selectActiveLanguagesMasterSuperset(state);
  const langs = selectLanguages(state);

  if (
    Object.keys(activeLangs).length === 0
    || Object.keys(langs).length === 0
  ) {
    return ['en'];
  }

  return Object.keys(activeLangs).sort((langIdA, langIdB) => {
    const { priority: priorityLangIdA } = langs[langIdA];
    const { priority: priorityLangIdB } = langs[langIdB];

    if (priorityLangIdA > priorityLangIdB) return 1;
    return -1;
  });
};

export const selectCurrentLangID = (state: AdminState) => getStoreContextSupersetFields(state).currentLangID;