import React from 'react';
import styles from './CreateWebinarPage.module.sass';
import CreateWebinarForm from './CreateWebinarForm/CreateWebinarForm';
import WebinarFilesSection from './CreateWebinarForm/WebinarFilesSection';

function CreateWebinarPage() {
  return (
    <div className={styles['create-form-container']}>
      <WebinarFilesSection />
      <CreateWebinarForm />
    </div>
  );
}

export default CreateWebinarPage;