import { useCallback } from 'react';
import { selectNLS } from '@/store/selectors/admin/superset/supersets';
import { useAdminSelector } from '@/store/hooks/adminRedux';

const useNLS = () => {
  const nls = useAdminSelector(selectNLS);

  return useCallback((key: string): string => (nls[key] ? nls[key] : ''), [nls]);
};

export default useNLS;