// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".units-list--RK41e{position:relative;padding:0 0 0 1.25rem;display:flex;flex-direction:column}.units-list--RK41e.with-line--kKWf-:before{content:\"\";position:absolute;width:0;left:10px;bottom:-1px;height:100%;border-left:1px solid #838999}.units-list--RK41e.actor--9\\+4cx{gap:0.9375rem;padding:1rem 0 0 2.5rem}.units-list--RK41e.root--77gSQ{padding:0;margin-bottom:1rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnit/DepartmentUnit/BUnitBranch/BranchUnitsGroup/BranchUnitsGroup.module.sass"],"names":[],"mappings":"AAEA,mBACE,iBAAA,CACA,qBAAA,CACA,YAAA,CACA,qBAAA,CAEE,2CACE,UAAA,CACA,iBAAA,CACA,OAAA,CACA,SAAA,CACA,WAAA,CACA,WAAA,CACA,6BAAA,CACJ,iCACE,aAAA,CACA,uBAAA,CACF,+BACE,SAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"units-list": "units-list--RK41e",
	"with-line": "with-line--kKWf-",
	"actor": "actor--9+4cx",
	"root": "root--77gSQ"
};
export default ___CSS_LOADER_EXPORT___;
