import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from './ChartSection.module.sass';
import ItemSwitcher from '@/components/App/Admin/Results/Cuts/ChartSection/ItemSwitcher';
import CutsChart from '@/components/App/Admin/Results/Cuts/CutsChart';
import BtnSNADirection from '@/components/App/Admin/Results/Cuts/ChartSection/BtnSNADirection';
import MacrosReplacer from '@/components/App/Admin/MacrosReplacer';
import SelfAssessmentToggler from '@/components/App/Admin/Results/Cuts/ChartSection/SelfAssessmentToggler';
import { selectItemByIDByTypeCuts } from '@/store/selectors/admin/superset/process/cuts/cuts';

const ChartSection = forwardRef((_, ref) => {
  const { itemID, typeCuts } = useParams();
  const item = useSelector((state) => selectItemByIDByTypeCuts(state, itemID, typeCuts));

  if (!item) {
    return null;
  }

  return (
    <div className={`${styles['wrap-chart-section']}`} ref={ref}>
      <div className={styles['wrap-chart-title']}>
        <h2>
          <span className={styles['item-number']}>
            {item.number}
            .
          </span>
          <MacrosReplacer>{item.title}</MacrosReplacer>
        </h2>
        <div className={styles['controls-chart']}>
          {item.sna && (
            <>
              <SelfAssessmentToggler />
              <BtnSNADirection />
            </>
          )}
          <ItemSwitcher />
        </div>
      </div>
      <CutsChart />
    </div>
  );
});

export default ChartSection;