import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axiosProcessing from '@/axiosConfig';

export const loadStatusProcessorResult = createAsyncThunk(
  'superset/admin/process/loadStatusProcessorResult',
  async (supersetID, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get(
        `/api/admin/getResultStatus/${supersetID}`,
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const startProcessor = createAsyncThunk(
  'superset/admin/process/startProcessor',
  async ({ supersetId, checkpointId }, { rejectWithValue, getState }) => {
    const { headers } = getState().admin.authentication;

    try {
      const response = await axiosProcessing.post(
        `/api/admin/evaluateResults/${supersetId}`,
        { checkpointId },
        { headers },
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createCheckpoint = createAsyncThunk(
  'superset/admin/process/createCheckpoint',
  async (data, { rejectWithValue, getState }) => {
    try {
      const { headers } = getState().admin.authentication;
      const response = await axiosProcessing.put(
        '/api/admin/checkpoint',
        data,
        {
          headers,
        },
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getCheckpoints = createAsyncThunk(
  'superset/admin/process/getCheckpoints',
  async ({ supersetId }, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get(
        `/api/admin/checkpoint/${supersetId}`,
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const clearCheckpoints = createAction(
  'superset/admin/process/clearCheckpoints',
);