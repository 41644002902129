import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { adminSignUp } from '@/store/actions/admin';

import Input from '../../../Common/form/Input';
import { selectAdmin } from '@/store/selectors/admin/authentication';

import styles from './SignUp.module.sass';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';

function SignUp() {
  const dispatch = useDispatch();
  const admin = useSelector(selectAdmin);
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  if (admin) {
    return <Navigate to="/admin/projects" replace />;
  }

  const onSubmitSignUp = (form) => {
    dispatch(
      adminSignUp({
        form,
        navigateSettings: {
          url: '/admin/signIn',
          navigate,
        },
      }),
    );
  };

  return (
    <div className={styles['admin-signUp']}>
      <h3>Регистрация</h3>
      <form
        className={styles['form-admin-signUp']}
        onSubmit={handleSubmit(onSubmitSignUp)}
      >
        <Input
          register={register}
          registerOptions={{
            required: true,
          }}
          id="admin-email"
          name="email"
          placeholder="Email"
          type="email"
          externalClasses={{ label: styles['label-field'] }}
        />

        <Input
          register={register}
          registerOptions={{
            required: true,
          }}
          id="admin-password"
          name="password"
          placeholder="Password"
          type="password"
          externalClasses={{ label: styles['label-field'] }}
        />
        <CustomButton
          styleTypeBtn="btn-grey btn-large"
          typeBtnSubmit
          classNames={styles['btn-on-signUp']}
        >
          Зарегистрироваться
        </CustomButton>
      </form>

      <Link to="/admin/signIn" className={styles['link-switch-form']}>
        Войти
      </Link>
    </div>
  );
}

export default SignUp;