export enum FilesPlaces {
  Banner = 'banner',
  Video = 'video',
  Logo = 'logo',
  Speaker = 'speaker',
}

export interface WebinarFilesFormFields {
  [FilesPlaces.Banner]: File[];
  [FilesPlaces.Video]: File[];
  [FilesPlaces.Logo]: File[];
  [FilesPlaces.Speaker]: File[];
}

export interface WebinarUploadFilePostForm {
  file: File;
  place: FilesPlaces;
  entity: string;
}