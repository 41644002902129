import React from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import Modal from '@/components/Common/Modal';
import texts from '@/components/Common/BaseTemplate/Header/HeaderWidgets/UserWidgets/UserWidgetsTexts.json';
import Code from '@/components/App/User/Common/Code/Code';
import MainButton from '@/components/Common/Elements/Buttons/MainButton/MainButton';
import { logout } from '@/store/actions/user/actor';
import { usePlayerDispatch, usePlayerSelector } from '@/store/hooks/playerRedux';
import Content from '@/components/Common/Modal/Content';
import WrapBtns from '@/components/Common/Modal/WrapBtns';
import { selectActorProject } from '@/store/selectors/user/actor';
import { Project } from '@/store/types/user/actor';

interface UserProfileWidgetModalProps {
  isShowingModal: boolean;
  toggleStatusShowingModal: () => void
}

function UserProfileWidgetModal({ isShowingModal, toggleStatusShowingModal }: UserProfileWidgetModalProps) {
  const project = usePlayerSelector(selectActorProject);
  const dispatch = usePlayerDispatch();
  const handleLogout = (currentProject: Project | undefined) => {
    dispatch(logout())
      .then(unwrapResult)
      .then(() => {
        if (currentProject.projectId) {
          window.location.href = `${window.location.origin}/go/logout/${currentProject?.teamCode}/${currentProject?.langId || 'en'}`;
        }
      })
      .catch(() => null);
  };

  return (
    <Modal
      isShowing={isShowingModal}
      hide={toggleStatusShowingModal}
      // @ts-ignore
      title={texts.modalTitle[project?.langId || 'en']}
      types="modal-sm"
      style={{}}
    >
      <Content>
        { /* @ts-ignore */}
        <div>{texts.returnToProject[project?.langId || 'en']}</div>
        <div>
          <Code />
        </div>
      </Content>
      <WrapBtns>
        <MainButton
          type="secondary"
          onClick={() => toggleStatusShowingModal()}
        >
          { /* @ts-ignore */}
          {texts.cancelButton[project?.langId || 'en']}
        </MainButton>
        <MainButton
          type="primary"
          onClick={() => {
            toggleStatusShowingModal();
            handleLogout(project);
          }}
        >
          { /* @ts-ignore */}
          {texts.finishButton[project?.langId || 'en']}
        </MainButton>
      </WrapBtns>
    </Modal>
  );
}

export default UserProfileWidgetModal;