import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetProjectsArgs, ProjectMap, SetProjectSettingByAnswerArgs } from '@/store/types/user/projects';
import { Settings } from '@/store/types/user/actor';
import axiosProcessing from '@/axiosConfig';

export const getProjects = createAsyncThunk(
  'actor/getProjects',
  async (data: GetProjectsArgs, { rejectWithValue }) => {
    const {
      teamCode,
      qType,
      secret,
      inviteCode,
      supersetId,
      serverId,
      blowFish,
    } = data;
    try {
      let path = `/api/actor/go/${teamCode}`;
      if (inviteCode) {
        path = `${path}/i/${supersetId}/${inviteCode}/${serverId}/${blowFish}`;
      }
      if (qType && secret) {
        path = `${path}/${qType}/${secret}`;
      }
      const response = await axiosProcessing.get<ProjectMap>(path);
      let projects = {};
      for (let i = 0; i < Object.keys(response.data).length; i += 1) {
        const key = Object.keys(response.data)[i];
        const project = response.data[key];
        if (
          (qType === 'leader' && project.extra.leaderEnd > 0)
          || (qType === 'leaderTeam' && project.extra.leaderTeamEnd > 0)
          || !qType
        ) {
          projects = { ...projects, [key]: project };
        }
      }
      return projects;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const setProjectSettingByAnswer = createAsyncThunk(
  'actor/setQuestionSettings',
  async (data: SetProjectSettingByAnswerArgs, { rejectWithValue }) => {
    const { settingId, value } = data;
    try {
      const response = await axiosProcessing.put<{ settings: Settings, projectId: string }>('/api/actor/auth', {
        settings: { [settingId]: value },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);