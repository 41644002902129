import React from 'react';
import Tabs from '@/components/Common/Tabs';
import styles from './FilterSupersets.module.sass';
import TabPane from '@/components/Common/Tabs/TabPane';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';
import { FilterSupersetsFlag } from '@/components/App/Admin/ListSupersets/ListSupersets';

interface FilterSupersetsProps {
  filterFlag: FilterSupersetsFlag,
  setFilterFlag: (arg: FilterSupersetsFlag) => void;
}

function FilterSupersets({ filterFlag, setFilterFlag }: FilterSupersetsProps) {
  const checkPermission = useCheckPermission();

  return (
    <Tabs
      selectedTabByID={`supersets.tab.${filterFlag}`}
      externalClasses={{
        wrapTabs: styles['select-superset-tabs'],
        paneLabel: styles['select-superset-label'],
        panesLabels: styles['select-superset-labels'],
        activePaneLabel: styles['active-label'],
        labelButton: styles['label-btn'],
      }}
    >
      {/* @ts-ignore */}
      <TabPane
        key={`supersets.tab.${FilterSupersetsFlag.ACTIVE}`}
        // @ts-ignore
        id={`supersets.tab.${FilterSupersetsFlag.ACTIVE}`}
        label="Active"
        onClick={() => setFilterFlag(FilterSupersetsFlag.ACTIVE)}
      />
      {/* @ts-ignore */}
      <TabPane
        key={`supersets.tab.${FilterSupersetsFlag.ARCHIVE}`}
        // @ts-ignore
        id={`supersets.tab.${FilterSupersetsFlag.ARCHIVE}`}
        label="Archive"
        onClick={() => setFilterFlag(FilterSupersetsFlag.ARCHIVE)}
      />
      {/* @ts-ignore */}
      <TabPane
        key={`supersets.tab.${FilterSupersetsFlag.ALL}`}
        // @ts-ignore
        id={`supersets.tab.${FilterSupersetsFlag.ALL}`}
        label="All"
        onClick={() => setFilterFlag(FilterSupersetsFlag.ALL)}
      />
      {checkPermission() && (
        // @ts-ignore
        <TabPane
          key={`supersets.tab.${FilterSupersetsFlag.TEMPLATES}`}
          // @ts-ignore
          id={`supersets.tab.${FilterSupersetsFlag.TEMPLATES}`}
          label="Templates"
          onClick={() => setFilterFlag(FilterSupersetsFlag.TEMPLATES)}
        />
      )}
      {checkPermission() && (
        // @ts-ignore
        <TabPane
          key={`supersets.tab.${FilterSupersetsFlag.AllAsSuper}`}
          // @ts-ignore
          id={`supersets.tab.${FilterSupersetsFlag.AllAsSuper}`}
          label="Super"
          onClick={() => setFilterFlag(FilterSupersetsFlag.AllAsSuper)}
        />
      )}
    </Tabs>
  );
}

export default FilterSupersets;