import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';

function AdminMacros({ children, langByParent }) {
  const macrosPattern = /({[^}]+})/g;
  const { settings } = useSelector((state) => state.admin.superset);
  const { currentLangID } = useSelector(
    (state) => state.admin.superset.supersetFields,
  );
  const { nls } = useSelector(selectSuperset);

  const macroByValues = useMemo(() => ({}), []);

  const macroByNls = useMemo(
    () => ({
      NAME: 'mail.tmpl.macro.name',
      CODE: 'mail.tmpl.macro.code',
      LINK: 'mail.tmpl.macro.link',
      REPORT: 'mail.tmpl.macro.report',
      getFieldByMacro(macro) {
        const nlsKey = this[macro];
        return (
          nls[langByParent]?.[nlsKey] || nls[currentLangID]?.[nlsKey] || macro
        );
      },
    }),
    [currentLangID, langByParent, nls],
  );

  const replaceSetting = useCallback(
    (part) => {
      const replaceCase = part.split(':');
      const { langs } = settings?.[replaceCase[0]] || { langs: undefined };
      const { cases } = langs?.[langByParent]
        || langs?.[currentLangID] || {
        cases: undefined,
      };
      if (!cases) return part;
      const currentCase = cases.find(
        ({ caseValue }) => caseValue === replaceCase[1],
      );
      if (!currentCase) return part;
      return currentCase?.substitutions?.[replaceCase[1]] || part;
    },
    [currentLangID, langByParent, settings],
  );

  const changeMacros = useCallback(
    (part) => {
      const macro = part.replace(/{|}/g, '');
      if (macro.includes(':')) return replaceSetting(macro);
      if (macroByValues[macro]) return macroByValues[macro] || macro;
      if (macroByNls[macro]) return macroByNls.getFieldByMacro(macro);
      return part;
    },
    [macroByNls, macroByValues, replaceSetting],
  );

  try {
    return parse(children.replace(macrosPattern, changeMacros));
  } catch (e) {
    return children;
  }
}

AdminMacros.propTypes = {
  langByParen: PropTypes.string,
  children: PropTypes.string,
};

AdminMacros.defaultProps = {
  langByParen: '',
  children: '',
};

export default AdminMacros;