// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".upload-files-form--wZ8dM{display:flex;flex-direction:column;width:fit-content;gap:1rem}", "",{"version":3,"sources":["webpack://./src/components/App/Webinar/CreateWebinarPage/CreateWebinarForm/WebinarFilesSection/WebinarFilesSection.module.sass"],"names":[],"mappings":"AAEA,0BACE,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,QAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload-files-form": "upload-files-form--wZ8dM"
};
export default ___CSS_LOADER_EXPORT___;
