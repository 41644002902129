import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from '../LinkTabs.module.sass';
import { LinkTabInterface } from '@/components/App/Admin/UIElements/LinkTabs/types';

interface LinkTabProps {
  children: LinkTabInterface;
  itemLink?: string;
}

function LinkTab({
  children, itemLink = '',
}: LinkTabProps) {
  const {
    link, replace, title, end,
  } = children;
  return (
    <NavLink
      to={link}
      className={`${styles.tab} ${itemLink}`}
      replace={replace}
      end={end}
    >
      <span>{title}</span>
    </NavLink>
  );
}

export default LinkTab;