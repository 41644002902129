import React, { useContext } from 'react';
import styles from './BUnitCheckBox.module.sass';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { selectTreeUnits } from '@/store/actions/admin/superset/unitsTree';
import BUnitContext from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import { UnitId } from '@/store/types/user/units';

export default function BUnitCheckBox() {
  const { unit, id } = useContext(BUnitContext);
  const dispatch = useAdminDispatch();
  const { supersetId } = useAdminSelector(selectSuperset);
  const selectUnits = (unitsData: [UnitId], isSelect: boolean) => {
    dispatch(
      selectTreeUnits({
        supersetId,
        unitsData,
        isSelect,
      }),
    );
  };

  return (
    <input
      className={styles['unit-checkbox']}
      type="checkbox"
      checked={unit.selected}
      onChange={() => selectUnits([id], !unit.selected)}
    />
  );
}