// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input-content--NfPs2{display:grid;grid-template-columns:min-content auto;width:100%;grid-gap:1rem 1rem;margin:0 0 1.5rem 0}.input-content--NfPs2 .input-title--Fbmc3{text-align:right;width:fit-content}.input-content--NfPs2 .input-container--jk96Z{width:90%}.input-content--NfPs2 .input-container--jk96Z .input--5cuxM{width:100%}.buttons-container--qQWnd{display:flex;gap:1rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/LoadResults/CreateCheckpoint/CreateCheckpointModal/CreateCheckpointModal.module.sass"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,sCAAA,CACA,UAAA,CACA,kBAAA,CACA,mBAAA,CACA,0CACE,gBAAA,CACA,iBAAA,CACF,8CACE,SAAA,CACA,4DACE,UAAA,CAEN,0BACE,YAAA,CACA,QAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-content": "input-content--NfPs2",
	"input-title": "input-title--Fbmc3",
	"input-container": "input-container--jk96Z",
	"input": "input--5cuxM",
	"buttons-container": "buttons-container--qQWnd"
};
export default ___CSS_LOADER_EXPORT___;
