// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".webinar-meta--5bVfc{display:flex;font-size:0.875rem;color:var(--secondary-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SuperAdmin/Webinars/WebinarsList/WebinarsFilteredList/WebinarLink/WebinarListMetaInfo/WebinarListMetainfo.module.sass"],"names":[],"mappings":"AAEA,qBACE,YAAA,CACA,kBAAA,CACA,4BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"webinar-meta": "webinar-meta--5bVfc"
};
export default ___CSS_LOADER_EXPORT___;
