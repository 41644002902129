import React, { useEffect } from "react";
import SuperItemRow from "../Common/SuperItemRow/SuperItemRow";
import SuperItemColumn from "../Common/SuperItemRow/SuperItemColumn/SuperItemColumn";
import { useAdminDispatch, useAdminSelector } from "@/store/hooks/adminRedux";
import { selectUsers } from "@/store/selectors/admin/super";
import { ReadUsers } from "@/store/actions/admin/super";
export default function Users() {
    const dispatch = useAdminDispatch();
    const users = useAdminSelector(selectUsers);

    useEffect(() => {
        dispatch(ReadUsers());
    }, [dispatch]);

    return users && users.map(({ id, name, email }) => {
        return (
            <SuperItemRow key={id}>
                <SuperItemColumn>{name}</SuperItemColumn>
                <SuperItemColumn>{email}</SuperItemColumn>
            </SuperItemRow>
        );
    });
} 