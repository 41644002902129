import React, { useContext } from 'react';
import styles from './BranchUnitsGroup.module.sass';
import { UnitId } from '@/store/types/user/units';
import BUnit from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnit';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';
import newBUnitContext from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import SelectAllCheckBox from '../SelectAllCheckBox/SelectAllCheckBox';
import { getClearClassNames } from '@/helpers/helpers';

interface BranchUnitsGroupProps {
  children?: UnitId[];
  isActor?: boolean;
  isRoot?: boolean;
}

function BranchUnitsGroup({ children, isActor = false, isRoot = false }: BranchUnitsGroupProps) {
  const { units } = useAdminSelector(selectUnitsTreeData);
  const { unit } = useContext(newBUnitContext);

  const withLine = () => {
    if (isActor) return false;
    if (isRoot) return false;
    return unit?.children?.length !== children?.length;
  };

  if (!children?.length) return null;

  return (
    <div
      className={getClearClassNames([
        styles['units-list'],
        isRoot && styles.root,
        isActor && styles.actor,
        withLine() && styles['with-line'],
      ])}
    >
      {!isActor && !isRoot && <SelectAllCheckBox unitsForSelect={children} />}
      {children.map((id) => (
        <BUnit key={id} id={id} unit={units[id]} />
      ))}
    </div>
  );
}

export default BranchUnitsGroup;