// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-admin-setting--Ovi3S{margin-bottom:1.25rem}.wrap-admin-setting--Ovi3S .title-setting--dJXTq{font-weight:bold}.wrap-admin-setting--Ovi3S .description-setting--q178K{color:#85878e;font-size:0.875rem;margin-top:0.3125rem}.wrap-admin-setting--Ovi3S.disabled--BFneD .title-setting--dJXTq{color:#acacac}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SupersetSettings/SectionAdminSettings/settingsStyle.module.sass"],"names":[],"mappings":"AAEA,2BACE,qBAAA,CACA,iDACE,gBAAA,CACF,uDACE,aAAA,CACA,kBAAA,CACA,oBAAA,CAEA,iEACE,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-admin-setting": "wrap-admin-setting--Ovi3S",
	"title-setting": "title-setting--dJXTq",
	"description-setting": "description-setting--q178K",
	"disabled": "disabled--BFneD"
};
export default ___CSS_LOADER_EXPORT___;
