export const HEIGHT_OF_SVG_CONTAINER = 500;

export const CONTAINER_PADDING = {
  top: 25,
  right: 25,
  bottom: 80,
  left: 80,
};

export const ABSOLUTE_SCORE_TYPE = 'ABSOLUTE';
export const PERCENTILE_SCORE_TYPE = 'PERCENTILE';

interface MapSourceToSourceNLS {
  [key: string]: { header: string, short: string };
}

export const MAP_SOURS_NUMBER_TO_SOURCE_NLS: MapSourceToSourceNLS = {
  1: { header: 'results.4d.source.test.header', short: 'results.4d.source.test' },
  2: { header: 'results.4d.source.360.header', short: 'results.4d.source.360' },
  3: { header: 'results.4d.source.sna.header', short: 'results.4d.source.sna' },
};