import React from 'react';
import styles from './BUnitsMenuBtn.module.sass';

interface BUnitsMenuBtnProps {
  handleButtonClick?: () => void;
  children: string | React.ReactNode;
  additionalClassNames?: string;
  type?: 'confirm' | 'reject';
  isActive?: boolean;
  btnActionType?: HTMLButtonElement['type'];
  formId?: string;
}

function BUnitsMenuBtn({
  handleButtonClick = () => null,
  children,
  additionalClassNames = '',
  type = 'confirm',
  isActive = true,
  btnActionType = 'button',
  formId = '',
}: BUnitsMenuBtnProps) {
  return (
    <button
      /* eslint-disable-next-line react/button-has-type */
      type={btnActionType}
      className={`${styles['units-menu-btn']} 
      ${styles[`${type}`]} ${additionalClassNames} 
      ${!isActive && styles['not-active']}`}
      disabled={!isActive}
      onClick={() => handleButtonClick()}
      {...(formId ? { form: formId } : {})} // Добавление атрибута form только если formId задан
    >
      {children}
    </button>
  );
}

export default BUnitsMenuBtn;