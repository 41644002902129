// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".floating-container--96Vgj{position:absolute;left:calc(100% - 60px);top:0;z-index:99}.floating-container--96Vgj.floating--1GI1F{position:fixed}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/FloatingContainer/FloatingContainer.module.sass"],"names":[],"mappings":"AAAA,2BACE,iBAAA,CACA,sBAAA,CACA,KAAA,CACA,UAAA,CACA,2CACE,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"floating-container": "floating-container--96Vgj",
	"floating": "floating--1GI1F"
};
export default ___CSS_LOADER_EXPORT___;
