import React, { useRef } from 'react';
import ChartSection from '@/components/App/Admin/Results/Cuts/ChartSection';
import ListActiveItems from '@/components/App/Admin/Results/Cuts/ListActiveItems';

function QuestionsCuts() {
  const chartSectionRef = useRef(null);

  return (
    <>
      <ChartSection ref={chartSectionRef} />
      <ListActiveItems chartSectionRef={chartSectionRef} />
    </>
  );
}

export default QuestionsCuts;