// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".admin-signUp--5DdTZ{width:18.75rem;margin:0 auto;flex-direction:column}.admin-signUp--5DdTZ h3{margin-bottom:0.625rem;text-align:center}.admin-signUp--5DdTZ .form-admin-signUp--x6Xko{display:flex;flex-direction:column;gap:0.625rem}.admin-signUp--5DdTZ .form-admin-signUp--x6Xko .label-field--Drwtj{width:100%}.admin-signUp--5DdTZ .form-admin-signUp--x6Xko .btn-on-signUp--v0BsM{margin-top:0.625rem}.admin-signUp--5DdTZ .link-switch-form--thASF{display:block;text-align:center;margin-top:1.25rem;color:var(--main-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SignUp/SignUp.module.sass"],"names":[],"mappings":"AAEA,qBACE,cAAA,CACA,aAAA,CACA,qBAAA,CACA,wBACE,sBAAA,CACA,iBAAA,CACF,+CACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,mEACE,UAAA,CACF,qEACE,mBAAA,CACJ,8CACE,aAAA,CACA,iBAAA,CACA,kBAAA,CACA,uBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin-signUp": "admin-signUp--5DdTZ",
	"form-admin-signUp": "form-admin-signUp--x6Xko",
	"label-field": "label-field--Drwtj",
	"btn-on-signUp": "btn-on-signUp--v0BsM",
	"link-switch-form": "link-switch-form--thASF"
};
export default ___CSS_LOADER_EXPORT___;
