import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './SidebarLink.module.sass';
import { getClearClassNames } from '@/helpers/helpers';

export interface SidebarLinkProps {
  name: string;
  url: string;
}

export default function SidebarLink({ name, url }: SidebarLinkProps) {
  const location = useLocation();
  return (
    <NavLink
      className={getClearClassNames([
        styles['sidebar-link'],
        location.pathname.includes(url) && styles.active,
      ])}
      to={url}
    >
      {name}
    </NavLink>
  );
}