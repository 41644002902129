import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import styles from './RestoreAccount.module.sass';
import Input from '@/components/Common/form/Input';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import { selectAdmin } from '@/store/selectors/admin/authentication';
import { adminRestorePassword } from '@/store/actions/admin';

function RestoreAccount() {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: 'onBlur' });
  const admin = useSelector(selectAdmin);

  if (admin) {
    return <Navigate to="/admin/projects" replace />;
  }

  const onRestoreEmail = (formEmailForRestore) => {
    dispatch(adminRestorePassword(formEmailForRestore));
  };

  return (
    <div className={styles['admin-restore-account']}>
      <h3>Восстановление пароля</h3>
      <p>Укажите ваш email для восстановления пароля:</p>
      <form
        className={styles['form-admin-restore-account']}
        onSubmit={handleSubmit(onRestoreEmail)}
      >
        <Input
          register={register}
          registerOptions={{
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            },
          }}
          id="admin-email"
          name="email"
          placeholder="Email"
          type="email"
          externalClasses={{ label: styles['label-field'] }}
        />

        <CustomButton
          styleTypeBtn="btn-blue btn-large"
          typeBtnSubmit
          classNames={styles['btn-on-restore-account']}
          disabled={!isValid}
        >
          Отправить запрос на восстановление
        </CustomButton>
      </form>
    </div>
  );
}

export default RestoreAccount;