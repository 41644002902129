import React from 'react';
import AdminMainListLink from '@/components/App/Admin/AdminMainListLinkLink';
import WebinarListMetaInfo from './WebinarListMetaInfo/WebinarListMetaInfo';

interface WebinarListLinkProps {
  webinar: WebinarType;
}

export default function WebinarListLink({ webinar }: WebinarListLinkProps) {
  return (
    <AdminMainListLink
      to={`${webinar.webinarKey}`}
      linkTitle={webinar.title}
      date={webinar.startTime}
      metaInfo={<WebinarListMetaInfo webinar={webinar} />}
    />
  );
}