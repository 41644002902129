import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './FilesSection.module.sass';
import FileButton from '@/components/Common/BaseTemplate/MainContentContainer/AdminSupersetContainer/ButtonsPanel/SupersetMenuModal/FilesForDownload/FilesSection/FileButton/FileButton';

function FilesSection({ title, children }) {
  const availableFiles = useMemo(
    () => children && children.filter((file) => file && file.isAvailable),
    [children],
  );

  if (!availableFiles || availableFiles.length === 0) return null;

  return (
    <section className={styles['files-section']}>
      {title && <div className={styles['']}>{title}</div>}
      <ul className={styles['files-buttons-list']}>
        {availableFiles.map((fileInfo) => (
          <FileButton
            key={`${fileInfo.fileName}${fileInfo.fileType}${
              fileInfo.version || ''
            }`}
            downloadFile={fileInfo.downloadFile}
            fileInfo={fileInfo}
          />
        ))}
      </ul>
    </section>
  );
}

FilesSection.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.shape({
      fileLang: PropTypes.string,
      fileName: PropTypes.string,
      fileType: PropTypes.string,
      fileDate: PropTypes.string,
      fileExt: PropTypes.string,
      isAvailable: PropTypes.bool,
      downloadFile: PropTypes.func,
      comment: PropTypes.string,
    }),
  ),
  title: PropTypes.string,
};

FilesSection.defaultProps = {
  children: undefined,
  title: '',
};

export default FilesSection;