import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from '@/components/App/Admin/BUnitsSection/CustomSelect/CustomSelect.module.sass';
import ArrowIcon from '@/components/Common/Elements/cssIcons/ArrowIcon';

function CustomSelectByType({
  type,
  activeValue,
  toggleOpen,
  isOpen,
  onKeyUp,
}) {
  const inputRef = useRef(null);
  const inputKeyUp = (event) => {
    if (!onKeyUp) return;
    onKeyUp(event);
  };

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.value = '';
  }, [activeValue]);

  if (type === 'button') {
    return (
      <button
        className={`${styles['dropdown-button']} ${isOpen && styles.opened}`}
        type="button"
        onClick={() => toggleOpen((prevValue) => !prevValue)}
      >
        <span>
          {typeof activeValue === 'object' ? activeValue.name : activeValue}
        </span>
        <ArrowIcon classNames={styles['select-arrow']} />
      </button>
    );
  }
  return (
    <input
      ref={inputRef}
      type="text"
      className={styles['dropdown-input']}
      placeholder={activeValue}
      onKeyUp={inputKeyUp}
      onClick={() => {
        toggleOpen((prevState) => !prevState);
      }}
    />
  );
}

CustomSelectByType.propTypes = {
  type: PropTypes.string.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onKeyUp: PropTypes.func,
  activeValue: PropTypes.string,
};

CustomSelectByType.defaultProps = {
  activeValue: '',
  onKeyUp: undefined,
};

export default CustomSelectByType;