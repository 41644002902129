import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import styles from './AdminItem.module.sass';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import SelectionList from '@/components/Common/SelectionList';

function AdminItem({
  admin: {
    adminId, name, email, role,
  },
  customItem,
  adminItemMenu,
  onClickAdminItemMenu,
  adminRoleToColorIndicator,
  notInteractive,
  currentRoleLable,
}) {
  const [isOpenMenu, toggleMenuStatus] = useState(false);
  const adminItem = useRef(null);

  useEffect(() => {
    const watchClickOutside = (event) => {
      if (!adminItem.current.contains(event.target) && isOpenMenu) toggleMenuStatus(false);
    };

    if (isOpenMenu) {
      window.addEventListener('click', watchClickOutside);
    }

    return () => {
      window.removeEventListener('click', watchClickOutside);
    };
  }, [isOpenMenu]);

  return (
    <>
      <Tooltip id={adminId} content={currentRoleLable} place="bottom" />
      <div
        className={`${styles['wrap-admin-item']}
       ${notInteractive ? styles['not-interactive'] : ''}`}
        key={adminId}
        data-tooltip-id={adminId}
        ref={adminItem}
      >
        <CustomButton
          onClick={() => toggleMenuStatus((prevState) => !prevState)}
          styleTypeBtn="btn-grey btn-large"
          classNames={styles['btn-item-admin']}
        >
          <span
            className={`${styles['icon-admin-role']} ${
              styles[adminRoleToColorIndicator[role]]
            }`}
          />
          <span className={styles['admin-label']} title={email}>
            {name || email}
          </span>
          <span className="icon-arrow" />
        </CustomButton>

        {isOpenMenu && (
          <SelectionList
            onClick={(value) => {
              onClickAdminItemMenu({
                role: value.role,
                email,
                adminID: adminId,
              });
            }}
            items={adminItemMenu}
            customItem={customItem}
            externalClassNames={{
              wrapSelectionList: styles['admin-menu'],
            }}
          />
        )}
      </div>
    </>
  );
}

AdminItem.defaultProps = {
  notInteractive: false,
  customItem: null,
};

AdminItem.propTypes = {
  customItem: PropTypes.func,
  admin: PropTypes.shape({
    adminId: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.number,
  }).isRequired,
  adminItemMenu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      onClickCurrentItem: PropTypes.func,
    }),
  ).isRequired,
  onClickAdminItemMenu: PropTypes.func.isRequired,
  adminRoleToColorIndicator: PropTypes.objectOf(PropTypes.string).isRequired,
  notInteractive: PropTypes.bool,
  currentRoleLable: PropTypes.string,
};

export default AdminItem;