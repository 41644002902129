import React from 'react';
import { Tooltip } from 'react-tooltip';
import PropTypes from 'prop-types';
import styles from './CheckExplanation.module.sass';
import { getClearClassNames } from '@/helpers/helpers';

function CheckExplanation({ children: text, tooltipID }) {
  if (text.includes('|')) {
    const [answer, explanation] = text.split('|');

    return (
      <>
        <span
          className={styles['wrap-explanation-text']}
          data-tooltip-id={tooltipID}
          data-tooltip-content={explanation}
        >
          {answer}
          <span
            className={getClearClassNames([
              'icon-tooltip-dark',
              styles['icon-tooltip'],
            ])}
          >
            <span className="path1" />
            <span className="path2" />
          </span>
        </span>
        <Tooltip id={tooltipID} />
      </>
    );
  }

  return text;
}

CheckExplanation.propTypes = {
  children: PropTypes.string.isRequired,
  tooltipID: PropTypes.string.isRequired,
};

export default CheckExplanation;