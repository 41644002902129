import { createAction, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import {
  loadProjects,
  loadSupersetLangs,
} from '@/store/actions/admin/superset/projects';
import { loadSupersetBlocks } from '@/store/actions/admin/superset/blocks';
import { loadAdminSettings } from '@/store/actions/admin/superset/settings';
import axiosProcessing from '@/axiosConfig';
import { AdminState } from '@/store/admin';
import {
  Admins,
  Fields, Languages, SupersetEvents, SupersetFiles,
} from '@/store/types/admin/superset/superset';
import { Admin } from '@/store/types/admin/authentication';
import {
  ChangeAdminRoleActionArgs,
  CreateSupersetArgs, FillEmptyItemsActionArgs, FinishCreatingSupersetActionArgs,
  GetStorageInfoActionArgs,
  GetSupersetEventsActionArgs,
  HideSupersetEventActionArgs,
  InviteAdminActionArgs,
  LoadSupersetActionArgs, LoadSupersetAdminsActionArgs,
  SetSupersetFieldsActionArgs,
  SetTicketsSupersetActionArgs,
  Superset,
  SupersetId,
  Supersets, UnsetAdminActionArgs,
  UpdateSupersetActionArgs,
  UpdateSupersetStageActionArgs,
} from '@/store/types/admin/supersets';
import { Stage } from '@/types/admin';

// supersets
export const createSuperset = createAsyncThunk(
  'supersets/createSuperset',
  async (form: CreateSupersetArgs, { getState, rejectWithValue }) => {
    const state = getState() as AdminState;
    const { headers } = state.admin.authentication;
    try {
      const response = await axiosProcessing.post<Superset>(
        '/api/admin/createSuperset',
        form,
        {
          headers,
        },
      );

      return {
        ...response.data,
        name: form.name,
        stage: Stage.SETUP,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getSupersets = createAsyncThunk(
  'supersets/getSupersets',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<Supersets>('/api/admin/supersetsList');
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getSupersetsForCopy = createAsyncThunk(
  'supersets/getSupersetsForCopy',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<Supersets>(
        '/api/admin/supersetsCopyList',
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const clearSupersetsForCopy = createAction(
  'supersets/clearSupersetsForCopy',
);

export const clearSupersets = createAction('supersets/clearSupersets');

// superset

export const loadSuperset = createAsyncThunk(
  'superset/loadSuperset',
  async (form: LoadSupersetActionArgs, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<Superset>(
        `/api/admin/supersetInfo/${form.supersetId}`,
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const clearSuperset = createAction('superset/clearSuperset');

export const loadLanguages = createAsyncThunk(
  'superset/getLanguages',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<Languages>('/api/admin/langs');

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const loadFields = createAsyncThunk(
  'superset/loadFields',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<Fields>('/api/admin/fields');
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const setSupersetFields = createAsyncThunk(
  'superset/setSupersetFields',
  async (form: SetSupersetFieldsActionArgs, { rejectWithValue, getState }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;
      await axiosProcessing.put('/api/admin/setSupersetFields', form, {
        headers,
      });

      return form.fields;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const generateTickets = createAsyncThunk(
  'superset/generateTickets',
  async (form: SetTicketsSupersetActionArgs, { getState, rejectWithValue }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;
      await axiosProcessing.put('/api/admin/generateTickets', form, {
        headers,
      });

      return null;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const setTicketsSuperset = createAsyncThunk(
  'superset/setTicketsSuperset',
  async (form: SetTicketsSupersetActionArgs, { dispatch, rejectWithValue }) => {
    try {
      unwrapResult(await dispatch(generateTickets(form)));

      return form.ticketsCount[0];
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const setUnitTickets = createAsyncThunk(
  'superset/setUnitTickets',
  async (form: SetTicketsSupersetActionArgs, { dispatch, rejectWithValue }) => {
    try {
      unwrapResult(await dispatch(generateTickets(form)));

      return form.ticketsCount;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const downloadTickets = createAsyncThunk(
  'superset/downloadTickets',
  async (supersetID: SupersetId, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get(
        `/api/admin/downloadTickets/${supersetID}`,
        {
          responseType: 'blob',
        },
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getStorageInfo = createAsyncThunk(
  'superset/getStorageInfo',
  async ({ supersetId }: GetStorageInfoActionArgs, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<SupersetFiles>(
        `/api/admin/getStorageInfo/${supersetId}`,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateSuperset = createAsyncThunk(
  'superset/updateSuperset',
  async ({ supersetID, updatedSuperset }: UpdateSupersetActionArgs, { getState, rejectWithValue }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;
      const { data: responseUpdatedSupersetData } = await axiosProcessing.put<Superset>(
        `/api/admin/updateSuperset/${supersetID}`,
        updatedSuperset,
        { headers },
      );

      return responseUpdatedSupersetData;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateSupersetStage = createAsyncThunk(
  'superset/updateSupersetStage',
  async (form: UpdateSupersetStageActionArgs, { dispatch, rejectWithValue }) => {
    try {
      // todo какая-то проблема со стадиями, поэтому типы не соответствуют
      // @ts-ignore
      unwrapResult(await dispatch(updateSuperset(form)));

      return {
        supersetId: form.supersetID,
        stage: form.updatedSuperset.superset.newStage,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateSupersetName = createAsyncThunk(
  'superset/updateSupersetName',
  async (form: UpdateSupersetActionArgs, { dispatch, rejectWithValue }) => {
    try {
      unwrapResult(await dispatch(updateSuperset(form)));

      return {
        supersetID: form.supersetID,
        name: form.updatedSuperset.superset.name,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const selectTemplate = createAsyncThunk(
  'superset/selectTemplate',
  async (form: UpdateSupersetActionArgs, { dispatch, rejectWithValue }) => {
    try {
      // const { masterSupersetId } = form.updatedSuperset.superset;

      unwrapResult(await dispatch(updateSuperset(form)));

      // unwrapResult(await dispatch(loadSupersetLangs({
      //   supersetId: masterSupersetId,
      // })));
      //
      // unwrapResult(await dispatch(loadSupersetBlocks({
      //   supersetId: masterSupersetId,
      // })));
      //
      // unwrapResult(await dispatch(loadAdminSettings({
      //   supersetId: masterSupersetId,
      // })));
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
    return null;
  },
);

export const getSupersetEvents = createAsyncThunk(
  'superset/getSupersetEvents',
  async ({ supersetId, langId }: GetSupersetEventsActionArgs, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<SupersetEvents>(
        `/api/admin/getEvents/${supersetId}/${langId}`,
      );

      return { events: response.data, langId };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const hideSupersetEvent = createAsyncThunk(
  'superset/hideSupersetEvent',
  async ({ eventId, langId }: HideSupersetEventActionArgs, { rejectWithValue, getState }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;
      await axiosProcessing.put(`/api/admin/hideEvent/${eventId}`, '', {
        headers,
      });
      return { langId, eventId };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const inviteAdmin = createAsyncThunk(
  'superset/inviteAdmin',
  async ({ supersetID, formInviteAdmin }: InviteAdminActionArgs, { getState, rejectWithValue }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;
      const response = await axiosProcessing.put<Admin>(
        `/api/admin/setAdmin/${supersetID}`,
        formInviteAdmin,
        {
          headers,
        },
      );

      return {
        ...formInviteAdmin,
        adminID: response.data.adminId,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const changeAdminRole = createAsyncThunk(
  'superset/changeAdminRole',
  async ({ supersetID, form }: ChangeAdminRoleActionArgs, { getState, rejectWithValue }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;
      await axiosProcessing.put(`/api/admin/setAdmin/${supersetID}`, form, {
        headers,
      });

      return {
        changedRole: form.role,
        adminID: form.adminID,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const loadSupersetAdmins = createAsyncThunk(
  'supersets/loadSupersetAdmins',
  async ({ supersetId }: LoadSupersetAdminsActionArgs, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<Admins>(
        `/api/admin/getSupersetAdmins/${supersetId}`,
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const unsetAdmin = createAsyncThunk(
  'superset/unsetAdmin',
  async ({ supersetID, unsetAdminForm }: UnsetAdminActionArgs, { getState, rejectWithValue }) => {
    const state = getState() as AdminState;
    const { headers } = state.admin.authentication;
    try {
      await axiosProcessing.put(
        `/api/admin/unsetAdmin/${supersetID}`,
        unsetAdminForm,
        {
          headers,
        },
      );

      return unsetAdminForm.adminId;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const finishCreatingSuperset = createAsyncThunk(
  'superset/finishCreatingSuperset',
  async (
    { formUpdateSuperset, redirectSettings }: FinishCreatingSupersetActionArgs,
    { dispatch, rejectWithValue },
  ) => {
    try {
      unwrapResult(await dispatch(updateSuperset(formUpdateSuperset)));

      redirectSettings.navigate(redirectSettings.url, { replace: true });

      return null;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getSupersetInfo = createAsyncThunk(
  'superset/getSupersetInfo',
  async (supersetId, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get(
        `/api/admin/deleteSuperset/${supersetId}`,
      );
      return {
        information: response.data,
        supersetID: supersetId,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getMailTemplates = createAsyncThunk(
  'superset/getMailTemplates',
  async (supersetId: SupersetId, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get(
        `/api/admin/mailTemplates/${supersetId}`,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteSuperset = createAsyncThunk(
  'superset/deleteSuperset',
  async (supersetId: SupersetId, { getState, rejectWithValue }) => {
    const state = getState() as AdminState;
    const { headers } = state.admin.authentication;
    try {
      const response = await axiosProcessing.delete(
        `/api/admin/deleteSuperset/${supersetId}`,
        { headers },
      );
      return {
        ...response.data,
        supersetID: supersetId,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getMailTasks = createAsyncThunk(
  'superset/getMailTasks',
  async (supersetId: SupersetId, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get(
        `/api/admin/ctasks/${supersetId}`,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteMailTask = createAsyncThunk(
  'superset/deleteMailTask',
  async (taskId: string, { getState, rejectWithValue }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;
      await axiosProcessing.delete(`/api/admin/ctasks/${taskId}`, { headers });
      return { taskId };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const clearMailTasks = createAction('superset/clearMailTasks');

export const fillEmptyItems = createAsyncThunk(
  'superset/fillEmptyItems',
  async ({ items, supersetId }: FillEmptyItemsActionArgs, { getState, rejectWithValue }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;
      const response = await axiosProcessing.put(
        `/api/admin/fillEmpty/${supersetId}`,
        items,
        { headers },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const loadAllDataSuperset = createAsyncThunk(
  'superset/loadAllDataSuperset',
  async (supersetId: SupersetId, { dispatch }) => {
    unwrapResult(await dispatch(loadSuperset({ supersetId })));
    // @ts-ignore
    unwrapResult(await dispatch(loadProjects({ supersetId })));
    unwrapResult(await dispatch(loadFields()));
    unwrapResult(await dispatch(loadLanguages()));
    // @ts-ignore
    unwrapResult(await dispatch(loadSupersetLangs({ supersetId })));
    // @ts-ignore
    unwrapResult(await dispatch(loadSupersetBlocks({ supersetId })));
    // @ts-ignore
    unwrapResult(await dispatch(loadAdminSettings({ supersetId })));
    unwrapResult(await dispatch(getSupersets()));
    unwrapResult(await dispatch(loadSupersetAdmins({ supersetId })));
    // unwrapResult(await dispatch(getSupersetInfo(supersetId)));
    unwrapResult(await dispatch(getStorageInfo({ supersetId })));
  },
);

export const setCurrentLanguageID = createAction('superset/setLanguageID');