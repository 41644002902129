// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".units-information-block--cK0fY{display:flex;flex-direction:row;margin-bottom:0.9375rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/BUnitsSectionHeader/UnitsHeaderInformation/UnitsHeaderInformation.module.sass"],"names":[],"mappings":"AAEA,gCACE,YAAA,CACA,kBAAA,CACA,uBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"units-information-block": "units-information-block--cK0fY"
};
export default ___CSS_LOADER_EXPORT___;
