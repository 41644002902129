import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from '@/components/App/Admin/ListSupersets/ListSupersets.module.sass';
import SupersetListLink from '@/components/App/Admin/ListSupersets/FilteredSupersetsList/SupersetListLink/SupersetListLink';
import {
  selectAllAdminsSupersets,
  selectAvailableSupersets,
  selectSupersetsLoadingStatus,
} from '@/store/selectors/admin/superset/supersets';
import Preloader from '@/components/App/Admin/SupersetSettings/Preloader';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { Superset } from '@/store/types/admin/supersets';
import { Stage } from '@/types/admin';
import { FilterSupersetsFlag } from '@/components/App/Admin/ListSupersets/ListSupersets';

interface FilteredSupersetsListProps {
  filterFlag: FilterSupersetsFlag;
}

function FilteredSupersetsList({ filterFlag }: FilteredSupersetsListProps) {
  const availableSupersets = useAdminSelector(selectAvailableSupersets);
  const supersetsLoadingStatus = useAdminSelector(selectSupersetsLoadingStatus);
  const superAdminSupersets = useAdminSelector(selectAllAdminsSupersets);

  const filterSuperset = useCallback(
    (superset: Superset) => {
      const { stage, isTemplate } = superset;
      if (filterFlag === FilterSupersetsFlag.TEMPLATES) {
        return isTemplate;
      }
      if (filterFlag === FilterSupersetsFlag.ARCHIVE) {
        return stage === Stage.ARCHIVE;
      }
      if (filterFlag === FilterSupersetsFlag.ACTIVE) {
        return stage < Stage.ARCHIVE;
      }
      return true;
    },
    [filterFlag],
  );

  const filteredSupersets = useMemo<Superset[]>(() => {
    if (filterFlag === FilterSupersetsFlag.AllAsSuper) return superAdminSupersets;
    return availableSupersets.filter(filterSuperset);
  }, [availableSupersets, filterFlag, filterSuperset, superAdminSupersets]);

  if (!filteredSupersets || supersetsLoadingStatus === 'loading') return <Preloader />;

  return (
    <ul className={styles['list-supersets']}>
      {filteredSupersets.map((superset, i) => (
        <SupersetListLink
          key={`list-${superset.id}`}
          index={filteredSupersets.length - i}
          superset={superset}
        />
      ))}
    </ul>
  );
}

FilteredSupersetsList.propTypes = {
  filterFlag: PropTypes.string.isRequired,
};

export default FilteredSupersetsList;