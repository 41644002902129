import React, { ReactNode, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getAdmin } from '@/store/actions/admin';

import {
  selectAdmin,
  selectLoadingStatus,
} from '@/store/selectors/admin/authentication';
import Preloader from '@/components/App/Admin/SupersetSettings/Preloader';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';

interface RequireAuthProps {
  children: ReactNode,
  isSuper?: boolean;
}

function RequireAuth({ children, isSuper = false }: RequireAuthProps) {
  const admin = useAdminSelector(selectAdmin);
  const adminLoadingStatus = useAdminSelector(selectLoadingStatus);
  const location = useLocation();

  const dispatch = useAdminDispatch();

  useEffect(() => {
    if (!admin) {
      dispatch(getAdmin());
    }
  }, [dispatch, admin]);

  if (adminLoadingStatus === 'loading' || adminLoadingStatus === 'idle') {
    return <Preloader />;
  }

  if (!admin) {
    return <Navigate to="/admin/signIn" state={{ from: location.pathname }} />;
  }

  if (isSuper && !admin.isSuper) {
    return <Navigate to="/admin/projects" />;
  }

  return children;
}

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RequireAuth;