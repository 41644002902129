// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content-creating-superset--U9Ll\\+{position:relative;margin-bottom:1.25rem}.content-creating-superset--U9Ll\\+ .form-create-superset--RbG-W .settings-section{border-top:.5px solid var(--dark-grey-line-color);padding:0.625rem 0 1.25rem 0}.content-creating-superset--U9Ll\\+ .form-create-superset--RbG-W .settings-section .title-section{color:var(--admin-main-font-color);font-size:1rem;font-weight:600;margin-bottom:1.25em}.content-creating-superset--U9Ll\\+ .form-create-superset--RbG-W .settings-section .superset-section-description{color:var(--admin-main-font-color)}.content-creating-superset--U9Ll\\+ .form-create-superset--RbG-W>.settings-section:last-child{padding-bottom:0}.content-creating-superset--U9Ll\\+ .btn-create-project--jkzOg{margin-top:1.875rem;font-weight:600}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SupersetSettings/SupersetSettings.module.sass"],"names":[],"mappings":"AAEA,mCACE,iBAAA,CACA,qBAAA,CAEE,kFACE,iDAAA,CACA,4BAAA,CACA,iGACE,kCAAA,CACA,cAAA,CACA,eAAA,CACA,oBAAA,CACF,gHACE,kCAAA,CAEF,6FACE,gBAAA,CACN,8DACE,mBAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-creating-superset": "content-creating-superset--U9Ll+",
	"form-create-superset": "form-create-superset--RbG-W",
	"btn-create-project": "btn-create-project--jkzOg"
};
export default ___CSS_LOADER_EXPORT___;
