import React, { ReactNode } from 'react';
import SidebarContainer from './SidebarContainer/SidebarContainer';
import useOutsideClick from '@/hooks/checkOutsideClick';

interface SideBarLink {
  name: string;
  url: string;
}

interface SideBarProps {
  links: SideBarLink[];
  children: ReactNode;
  isOpen: boolean
  closeSidebar: () => void
}

export default function SideBar({
  children, links, isOpen, closeSidebar,
}: SideBarProps) {
  const sideBarRef = useOutsideClick<() => void, HTMLDivElement>(closeSidebar);

  return (
    <div ref={sideBarRef}>
      {children}
      <SidebarContainer
        links={links}
        isOpen={isOpen}
      />
    </div>
  );
}