// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".employee-button--gf330{font-size:1.125rem;margin-right:0.625rem;text-decoration:none;color:var(--main-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnit/EmployeeUnit/EmployeeUnitFields/EmployeeUnitFields.module.sass"],"names":[],"mappings":"AAEA,wBACE,kBAAA,CACA,qBAAA,CACA,oBAAA,CACA,uBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"employee-button": "employee-button--gf330"
};
export default ___CSS_LOADER_EXPORT___;
