import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import styles from './ModalDeleteProject.module.sass';
import Modal from '@/components/Common/Modal';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { deleteSuperset, downloadTickets } from '@/store/actions/admin';
import { addNotification } from '@/store/reducers/notify';
import { formatDate } from '@/helpers/helpers';
import { iconsLang } from '@/constants/admin';
import useNLS from '@/components/App/Admin/hooks/hooks';

// todo перенести функции
function ModalDeleteProject({ toggleModal }) {
  const { name } = useSelector(selectSuperset);
  const tNLS = useNLS();
  const dispatch = useDispatch();
  const { supersetId } = useSelector(selectSuperset);
  const { name: supersetName } = useSelector(selectSuperset);
  const supersetLangs = useSelector(
    (state) => state.admin.superset.projects.supersetLangs,
  );
  const { information } = useSelector(
    (state) => state.admin.supersets.supersets[supersetId],
  );
  const navigate = useNavigate();

  const deleteProject = () => {
    dispatch(deleteSuperset(supersetId))
      .finally(() => {
        navigate('/admin/projects');
        dispatch(
          addNotification({
            type: 'success',
            title: 'Успех',
            message: 'Проект успешно удален',
          }),
        );
      })
      .catch(() => {
        toggleModal();
        dispatch(
          addNotification({
            type: 'error',
            title: 'Ошибка',
            message: 'Не удалось удалить проект',
          }),
        );
      });
  };

  const downloadAnswers = () => {};

  const downloadProjectTickets = () => {
    dispatch(downloadTickets(supersetId)).then((response) => {
      const outputFilename = `${supersetName}-tickets-${formatDate(
        new Date(),
        'dd-mm-yyyy',
      )}.xlsx`;
      const url = URL.createObjectURL(new Blob([response.payload]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <Modal
      isShowing
      hide={toggleModal}
      title={`${tNLS('delete.project.modal.title')} ${name}`}
      externalClassNames={{
        modal: styles.modal,
      }}
    >
      <div className={styles['delete-project-modal']}>
        <h3 className={styles['deleting-information-title']}>
          {tNLS('delete.project.modal.info.title')}
        </h3>
        {information && (
          <ul className={styles['project-information-list']}>
            {Object.keys(information).map((item) => {
              if (information[item]) {
                if (typeof information[item] !== 'object') {
                  return (
                    <li key={item} className={styles['information-list-item']}>
                      {`${item}: ${information[item]}`}
                    </li>
                  );
                }
              }
              return '';
            })}
          </ul>
        )}
        {information && (
          <div className={styles['files-information']}>
            <h3>Файлы:</h3>
            <div className={styles['download-files-block']}>
              {/* <FilesList /> */}
              {Object.keys(supersetLangs).map((lang) => (
                <button
                  className={styles['modal-download-button']}
                  type="button"
                  onClick={() => downloadAnswers(lang)}
                >
                  <img
                    className={styles['icon-lang']}
                    src={iconsLang[lang]}
                    alt={`icon lang ${lang}`}
                  />
                  <span>Ответы</span>
                </button>
              ))}

              <button
                type="button"
                className={styles['modal-download-button']}
                onClick={() => downloadProjectTickets()}
              >
                Тикеты
              </button>
            </div>
          </div>
        )}
        <CustomButton
          classNames={styles['btn-delete-project']}
          onClick={deleteProject}
        >
          Удалить проект
        </CustomButton>
      </div>
    </Modal>
  );
}

ModalDeleteProject.propTypes = {
  toggleModal: PropTypes.func.isRequired,
};

export default ModalDeleteProject;