// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-info--eEGCg{display:flex;flex-direction:column;align-items:center;gap:0.3125rem}.wrap-result--RIAaf .header--mptIO{display:flex;gap:1.25rem;margin-bottom:1.25rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/LoadResults/LoadResults.module.sass"],"names":[],"mappings":"AAEA,kBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,aAAA,CAGA,mCACE,YAAA,CACA,WAAA,CACA,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-info": "wrap-info--eEGCg",
	"wrap-result": "wrap-result--RIAaf",
	"header": "header--mptIO"
};
export default ___CSS_LOADER_EXPORT___;
