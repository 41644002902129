import { BackendUnit } from '@/store/types/admin/superset/unitsTree';

export const getUnitsCount = (units: BackendUnit[]) => {
  if (!units) return 0;
  return units.filter(({ u }) => u === 0).length;
};

export const getStartedCount = (units: BackendUnit[]) => {
  if (!units) return 0;
  return units.filter(
    ({ hasStarted, hasFinished, u }) => u === 0 && hasStarted && !hasFinished,
  ).length;
};

export const getFinishedCount = (units: BackendUnit[]) => {
  if (!units) return 0;
  return units.filter(({ u, hasFinished }) => u === 0 && hasFinished).length;
};

export const getNotStartedCount = (units: BackendUnit[]) => {
  if (!units) return 0;
  return units.filter(
    ({ u, hasFinished, hasStarted }) => u === 0 && !hasFinished && !hasStarted,
  ).length;
};

export const getRespondentsCount = (units: BackendUnit[]) => {
  if (!units) return 0;
  return units.filter(({ u, isEstimator }) => u === 0 && isEstimator).length;
};

export const getFinishedRespondentsPercent = (units: BackendUnit[]) => {
  if (!units) return 0;
  const respondents = units.filter(
    ({ u, isEstimator }) => u === 0 && isEstimator,
  );
  if (respondents.length === 0) return 0;
  const finished = respondents.filter(
    ({ hasFinished }) => hasFinished,
  ).length;
  return Math.round((finished / respondents.length) * 100);
};