import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProcessing from '@/axiosConfig';

const path = '/api/admin/super';

export const ReadAdmlist = createAsyncThunk(
  'admin/super/admList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<AdmlistItem[]>(`${path}/admlist`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const ReadUsers = createAsyncThunk(
  'admin/super/users',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<User[]>(`${path}/users`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const ReadCloud = createAsyncThunk(
  'admin/super/cloud',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<CloudSuperset[]>(`${path}/cloud`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const ReadWebinars = createAsyncThunk(
  'admin/super/webinars',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<WebinarItem[]>(`${path}/webinars`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const ReadArticles = createAsyncThunk(
  'admin/super/articles',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<ArticleItem[]>(`${path}/articles`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);