import React from 'react';
import parse from 'html-react-parser';
import styles from './WebinarTexts.module.sass';
import WebinarSvg from '@/components/App/Webinar/WebinarSvg/WebinarSvg';
import WebinarContentContainer from '@/components/App/Webinar/WebinarContentContainer/WebinarContentContainer';

interface WebinarTextsProps {
  webinarTitle?: string;
  webinarDescription?: string
}

function WebinarTexts({ webinarTitle = '', webinarDescription = '' }: WebinarTextsProps) {
  return (
    <WebinarContentContainer>
      <div className={styles['webinar-text']}>
        <h2 className={styles['webinar-title']}>{parse(webinarTitle)}</h2>
        <div className={styles['webinar-description']}>
          {parse(webinarDescription)}
          <WebinarSvg />
        </div>
      </div>
    </WebinarContentContainer>
  );
}

export default WebinarTexts;