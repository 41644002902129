import React from 'react';
import { Tooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import styles from './SelfAssessmentToggler.module.sass';
import { getClearClassNames } from '@/helpers/helpers';
import { loadUnitCuts } from '@/store/actions/admin/superset/process/cuts/unitsCuts';
import { selectSelfAssessmentStatus } from '@/store/selectors/admin/superset/process/cuts/unitsCuts';

function SelfAssessmentToggler() {
  const dispatch = useDispatch();
  const self = useSelector(selectSelfAssessmentStatus);

  const onToggleSelfAssessment = () => {
    dispatch(
      loadUnitCuts({
        self: !self,
      }),
    );
  };

  return (
    <div className={styles['wrap-self-assessment-toggler']}>
      <Tooltip id="btnSelfAssessmentToggler" />
      <button
        type="button"
        className={getClearClassNames([
          'btn-without-style',
          styles['btn-self-assessment-toggler'],
          self ? styles['self-enabled'] : styles['self-disabled'],
        ])}
        data-tooltip-id="btnSelfAssessmentToggler"
        data-tooltip-content={
          self ? 'Самооценка включена' : 'Самооценка выключена'
        }
        onClick={onToggleSelfAssessment}
      >
        {' '}
        <span className={`icon-employee ${styles['icon-employee']}`} />
      </button>
    </div>
  );
}

export default SelfAssessmentToggler;