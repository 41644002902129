// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-logs-info--2hPe5 .control-panel--hC35J{margin-bottom:2.5rem}.wrap-logs-info--2hPe5 .control-panel--hC35J form{display:flex;gap:1.25rem;align-items:center}.wrap-logs-info--2hPe5 .table-logs-info--jA8Is{width:100%;border-collapse:collapse}.wrap-logs-info--2hPe5 .table-logs-info--jA8Is thead{text-align:left}.wrap-logs-info--2hPe5 .table-logs-info--jA8Is tbody tr{height:3.125rem;border-bottom:1px solid var(--light-grey-line-color);transition:background-color .3s ease 0s}.wrap-logs-info--2hPe5 .table-logs-info--jA8Is tbody tr:hover{background:var(--hover-content-block-bg)}.wrap-logs-info--2hPe5 .table-logs-info--jA8Is tbody tr:last-child{border-bottom:none}.wrap-logs-info--2hPe5 .table-logs-info--jA8Is tbody tr td{padding:0.625rem 0}.wrap-logs-info--2hPe5 .wrap-btns-switcher-pages--YBhNJ{position:fixed;bottom:3.125rem;left:50%;transform:translateX(-50%);z-index:1000}.wrap-logs-info--2hPe5 .wrap-btns-switcher-pages--YBhNJ .btn-switcher-pages--drAAL{font-size:1.125rem;padding:0 1.1111111111em}.wrap-logs-info--2hPe5 .wrap-btns-switcher-pages--YBhNJ .btn-switcher-pages--drAAL:nth-child(1){border-radius:100px 0 0 100px;border-right:1px solid var(--primary-dark-grey)}.wrap-logs-info--2hPe5 .wrap-btns-switcher-pages--YBhNJ .btn-switcher-pages--drAAL:nth-child(2){border-radius:0 100px 100px 0}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/LogInfo/LogInfo.module.sass"],"names":[],"mappings":"AAGE,6CACE,oBAAA,CACA,kDACE,YAAA,CACA,WAAA,CACA,kBAAA,CACJ,+CACE,UAAA,CACA,wBAAA,CACA,qDACE,eAAA,CAEA,wDACE,eAAA,CACA,oDAAA,CACA,uCAAA,CACA,8DACE,wCAAA,CAEF,mEACE,kBAAA,CACF,2DACE,kBAAA,CACR,wDACE,cAAA,CACA,eAAA,CACA,QAAA,CACA,0BAAA,CACA,YAAA,CACA,mFACE,kBAAA,CACA,wBAAA,CACA,gGACE,6BAAA,CACA,+CAAA,CACF,gGACE,6BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-logs-info": "wrap-logs-info--2hPe5",
	"control-panel": "control-panel--hC35J",
	"table-logs-info": "table-logs-info--jA8Is",
	"wrap-btns-switcher-pages": "wrap-btns-switcher-pages--YBhNJ",
	"btn-switcher-pages": "btn-switcher-pages--drAAL"
};
export default ___CSS_LOADER_EXPORT___;
