// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".data-circle--EYwU6{stroke:#000;stroke-width:.5;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/FourD/Diagram/CirclesArea/DataCircle/DataCircle.module.sass"],"names":[],"mappings":"AAAA,oBACE,WAAA,CACA,eAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"data-circle": "data-circle--EYwU6"
};
export default ___CSS_LOADER_EXPORT___;
