import React, { ReactNode } from 'react';
import styles from './WebinarContentContainer.module.sass';
import { getClearClassNames } from '@/helpers/helpers';

interface WebinarContentContainerProps {
  children: ReactNode;
  classNames?: string;
}

function WebinarContentContainer({ children, classNames = '' }: WebinarContentContainerProps) {
  return (
    <div className={getClearClassNames([
      styles['content-container'],
      classNames,
    ])}
    >
      {children}
    </div>
  );
}

export default WebinarContentContainer;