import React from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import styles from './UploadResourcesInput.module.sass';
import AdminInputFile from '@/components/App/Admin/UIElements/form/AdminInputFile';
import { clearUploadResourcesInfo } from '@/store/actions/admin/superset/projects';
import { useAdminDispatch } from '@/store/hooks/adminRedux';
import useNLS from '@/components/App/Admin/hooks/hooks';

interface UploadResourcesInputProps {
  register: UseFormRegister<FieldValues>;
}

function UploadResourcesInput({ register }: UploadResourcesInputProps) {
  const dispatch = useAdminDispatch();
  const tNLS = useNLS();
  return (
    <div className={styles['wrap-field-uploading-resources']}>
      <AdminInputFile
        id="fieldUploadingResources"
        name="resources"
        label={tNLS('template.file.select') as string}
        onChange={() => dispatch(clearUploadResourcesInfo())}
        register={register}
        registerOptions={{
          required: true,
        }}
      />
    </div>
  );
}

export default UploadResourcesInput;