import React, { useState } from 'react';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import CreateCheckpointModal from '@/components/App/Admin/Results/LoadResults/CreateCheckpoint/CreateCheckpointModal/CreateCheckpointModal';

function CreateCheckpoint() {
  const [isOpen, toggleModal] = useState(false);
  return (
    <>
      <CustomButton onClick={() => toggleModal(true)}>
        Create checkpoint
      </CustomButton>
      <CreateCheckpointModal isOpen={isOpen} toggleModal={toggleModal} />
    </>
  );
}

export default CreateCheckpoint;