import React from 'react';
import { ResourcesTypes } from '@/types/admin';
import ResultUploadingTemplates
  from '@/components/App/Admin/ListSupersets/ModalCreateSuperset/FormUploadingResources/ResultUploading/ResultUploadingTemplates';
import {
  OnSuccessUploadingResources,
} from '@/components/App/Admin/ListSupersets/ModalCreateSuperset/ModalCreateSypersetContent/ModalCreateSupersetContent';

interface ResultUploadingProps {
  typeOfResource: ResourcesTypes
  onSucess: OnSuccessUploadingResources
}

function ResultUploading({ typeOfResource, onSucess }: ResultUploadingProps) {
  if (typeOfResource === ResourcesTypes.RESOURCES) {
    onSucess();
  }

  if (typeOfResource === ResourcesTypes.TEMPLATE) {
    return <ResultUploadingTemplates onSuccess={onSucess} />;
  }

  return null;
}

export default ResultUploading;