import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './TabsItems.module.sass';
import Tabs from '@/components/Common/Tabs';
import TabPane from '@/components/Common/Tabs/TabPane';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { getClearClassNames } from '@/helpers/helpers';
import {
  QUESTIONS_CUTS,
  UNITS_CUTS,
  FACTORS_CUTS,
  ALL_TYPE_OF_CUTS,
  FOUR_D,
} from '@/components/App/Admin/Results/Cuts/constants';
import {
  selectFirstItemQuestionsCuts,
  selectQuestionsItems,
} from '@/store/selectors/admin/superset/process/cuts/questionsCuts';
import {
  selectFirstItemUnitsCuts,
  selectUnitsItems,
} from '@/store/selectors/admin/superset/process/cuts/unitsCuts';
import { selectHasFactorsCuts } from '@/store/selectors/admin/superset/process/cuts/factorsCuts';
import { selectHasFourD } from '@/store/selectors/admin/superset/process/cuts/fourD';

function TabsItems() {
  const { typeCuts } = useParams();
  const tNLS = useNLS();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const hasQuestionsItems = useSelector(selectQuestionsItems).length > 0;
  const hasUnitsItems = useSelector(selectUnitsItems).length > 0;
  const hasFactorsCuts = useSelector(selectHasFactorsCuts);
  const hasFourD = useSelector(selectHasFourD);
  const firstItemIDQuestionsCutsID = useSelector(
    selectFirstItemQuestionsCuts,
  )?.id;
  const firstItemIDUnitsCutsID = useSelector(selectFirstItemUnitsCuts)?.id;

  const numberExistingTabs = [
    hasQuestionsItems,
    hasUnitsItems,
    hasFactorsCuts,
  ].filter((value) => value).length;

  const onChangeActiveTypeItems = (typeToRedirect, itemIDToRedirect) => {
    const pathSegments = pathname.split('/');
    const cutIndex = pathSegments.findIndex((segment) => ALL_TYPE_OF_CUTS.some((type) => segment.includes(type)));

    if (cutIndex !== -1) {
      pathSegments.splice(cutIndex);
      const baseURL = pathSegments.join('/');
      navigate(`${baseURL}/${typeToRedirect}/${itemIDToRedirect}`);
    }
  };

  const renderTabsPane = () => {
    const tabsPanes = [];

    if (hasQuestionsItems) {
      tabsPanes.push(
        <TabPane
          key="cuts.tab.questions"
          id={QUESTIONS_CUTS}
          label={tNLS('cuts.tab.questions')}
          onClick={() => onChangeActiveTypeItems(QUESTIONS_CUTS, firstItemIDQuestionsCutsID)}
        />,
      );
    }

    if (hasUnitsItems) {
      tabsPanes.push(
        <TabPane
          key="cuts.tab.sna"
          id={UNITS_CUTS}
          label={tNLS('cuts.tab.sna')}
          onClick={() => onChangeActiveTypeItems(UNITS_CUTS, firstItemIDUnitsCutsID)}
        />,
      );
    }

    if (hasFactorsCuts) {
      tabsPanes.push(
        <TabPane
          key="factorStructures"
          id={FACTORS_CUTS}
          label={tNLS('cuts.tab.scales')}
          // first FS on the list
          onClick={() => onChangeActiveTypeItems(FACTORS_CUTS, '0')}
        />,
      );
    }

    if (hasFourD) {
      tabsPanes.push(
        <TabPane
          key={FOUR_D}
          id={FOUR_D}
          label={FOUR_D}
          onClick={() => onChangeActiveTypeItems(FOUR_D, '0')}
        />,
      );
    }

    return tabsPanes;
  };

  return (
    <div className={styles['wrap-tabs-items']}>
      <Tabs
        externalClasses={{
          wrapTabs: getClearClassNames([
            styles['tabs-items'],
            numberExistingTabs === 1 ? styles['has-only-one-item'] : null,
          ]),
          panesLabels: styles['panes-labels'],
          paneLabel: styles['pane-label'],
        }}
        selectedTabByID={typeCuts}
      >
        {renderTabsPane()}
      </Tabs>
    </div>
  );
}

export default TabsItems;