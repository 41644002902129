import React, { useEffect, useRef, useState } from 'react';
import UnitsTree from './UnitsTree/index';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { getMailTemplates } from '@/store/actions/admin';
import SelectMenu from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu';
import useNLS from '@/components/App/Admin/hooks/hooks';
import CreateBUnitSection from '@/components/App/Admin/BUnitsSection/CreateBUnitSection/CreateBUnitSection';
import styles from './BUnitsSection.module.sass';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import BUnitsSectionHeader from '@/components/App/Admin/BUnitsSection/BUnitsSectionHeader/BUnitsSectionHeader';
import SectionBusinessUnitsSettings from '@/components/App/Admin/BUnitsSection/SectionBusinessUnitsSettings';
import useLoadUnits from '@/components/App/Admin/BUnitsSection/loadUnits';

export default function BUnitsSection() {
  const { supersetId } = useAdminSelector(selectSuperset);
  const containerRef = useRef(null);
  const tNLS = useNLS();
  const [isTemplatesLoaded, toggleLoaded] = useState(false);
  const stateUnits = useAdminSelector(
    (state) => state.admin.superset.unitsTree.unitsArray,
  );
  const dispatch = useAdminDispatch();

  const [treeLoaded, isLoading, toggleTreeLoaded] = useLoadUnits();

  useEffect(() => {
    if (isTemplatesLoaded) return;
    dispatch(getMailTemplates(supersetId)).then(() => toggleLoaded(true));
  }, [dispatch, isTemplatesLoaded, supersetId]);

  return (
    <div>
      <BUnitsSectionHeader />
      <SectionBusinessUnitsSettings />
      <SelectMenu isTreeLoaded={treeLoaded && !isLoading} />
      <div className={styles['tree-container']} ref={containerRef}>
        <CreateBUnitSection
          containerRef={containerRef}
          treeLoaded={treeLoaded}
          toggleTreeLoaded={toggleTreeLoaded}
        />
        {stateUnits.length === 0 && treeLoaded && !isLoading && (
          <div style={{ width: '80%' }}>{tNLS('tree.no.tree')}</div>
        )}
        {treeLoaded && !isLoading && <UnitsTree />}
      </div>
    </div>
  );
}