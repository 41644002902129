import React, { lazy, Suspense, useEffect } from 'react';
import Preloader from '../SupersetSettings/Preloader';
import { injectReducer } from '@/store/admin';
import superReducers from '@/store/reducers/super';

export default function SuperAdminEntry() {
  const SuperAdmin = lazy(() => import(
    /* webpackChunkName: "admin/super" */
    /* webpackMode: "lazy" */
    './SuperAdmin'
  ));

  useEffect(() => {
    injectReducer('super', superReducers);
  }, []);

  return (
    <Suspense fallback={<Preloader />}>
      <SuperAdmin />
    </Suspense>
  );
}