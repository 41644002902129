// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".langs--\\+\\+rsw{width:100px}.admlist-item-field--vevOO{width:auto}.admlist-item-field--vevOO.superset-name--C5dYm{font-weight:bold;width:350px}.admlist-item-field--vevOO.superset-name--C5dYm a{color:var(--secodary-grey-text)}.admlist-item-field--vevOO.superset-languages--rcuL2{display:flex;width:100px}.admlist-item-field--vevOO.superset-languages--rcuL2 img{margin-right:5px}", "",{"version":3,"sources":["webpack://./src/components/App/Super/Admlist/Admlist.module.sass"],"names":[],"mappings":"AAAA,gBACI,WAAA,CACJ,2BACI,UAAA,CACA,gDACI,gBAAA,CACA,WAAA,CACA,kDACI,+BAAA,CACR,qDACI,YAAA,CACA,WAAA,CACA,yDACI,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"langs": "langs--++rsw",
	"admlist-item-field": "admlist-item-field--vevOO",
	"superset-name": "superset-name--C5dYm",
	"superset-languages": "superset-languages--rcuL2"
};
export default ___CSS_LOADER_EXPORT___;
