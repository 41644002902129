const ASSESSMENT_STATUS_NLS_BY_KEY = {
  total: 'total.accepted',
  finished: 'completed',
  finishedInvalidated: 'completed.invalid',
  started: 'quit',
  startedInvalidated: 'quit.invalid',
  lastActive: 'in.progress',
  lastActiveInvalidated: 'in.progress.invalid',
  getText({ statKey, getNLS }) {
    return getNLS(`stat.parts.${this[statKey]}`);
  },
  getTotalValue({
    finished,
    finishedInvalidated,
    started,
    startedInvalidated,
    lastActive,
    lastActiveInvalidated,
  }) {
    const total = finished + started + lastActive;
    const totalInvalidated = finishedInvalidated + lastActiveInvalidated + startedInvalidated;
    return total - totalInvalidated;
  },
  getFormatValue({ statKey, values }) {
    if (statKey === 'finished' || statKey === 'total') {
      return values[statKey].toString();
    }
    if (statKey.includes('Invalidated')) {
      return `-${values[statKey].toString()}`;
    }
    return `+${values[statKey].toString()}`;
  },
};

export default ASSESSMENT_STATUS_NLS_BY_KEY;
// "stat.parts.completed", "Участников, завершивших заполнение опросника",
// "stat.parts.completed.invalid", "Участников, чьи ответы не прошли процедуру валидации (среди завершивших)",
// "stat.parts.in.progress", "Участников в процессе заполнения опросника (последние 18 часов)",
// "stat.parts.in.progress.invalid", "Участников, чьи ответы не прошли процедуру валидации (среди заполняющих опросник)",
// "stat.parts.quit", "Участников прервали заполнение опросника (более 18 часов назад)",
// "stat.parts.quit.invalid", "Участников, чьи ответы не прошли процедуру валидации (среди прервавших)",
// "stat.parts.total.accepted", "ИТОГО УЧАСТНИКОВ: валидизированная выборка",