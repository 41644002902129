import React from 'react';
import styles from './RoleSelectOption.module.sass';
import useNLS from '@/components/App/Admin/hooks/hooks';

interface RoleSelectOptionProps {
  role: string;
  changeRole: (newRole: string) => void
}

function RoleSelectOption({ role, changeRole }: RoleSelectOptionProps) {
  const tNLS = useNLS();
  const handleButtonClick = () => {
    changeRole(role);
  };

  return (
    <div
      role="option"
      aria-selected={false}
      tabIndex={-1}
      onKeyDown={() => {}}
      className={styles['role-select-option']}
      onClick={handleButtonClick}
    >
      {tNLS(`tree.card.role.${role}`) || role}
    </div>
  );
}

export default RoleSelectOption;