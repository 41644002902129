import React from 'react';
import CloudFile from './CloudFile/CloudFile';
import styles from "./CloudDirectory.module.sass"
import { humanFileSize } from '@/helpers/helpers';
interface CloudDirectoryProps {
    directory: CloudDirectoryItem;
    directories?: CloudDirectoryItem[];
    files?: CloudFileItem[];
    headerStyle?: string;
}

const CloudDirectory: React.FC<CloudDirectoryProps> = ({ directory, directories, files, headerStyle }) => {
    return (directories || files) && (
        <div className={styles['cloud-directory-item']}>
            {directories &&
                <details>
                    <summary>
                        <div className={headerStyle}>
                            <span>{directory.name}</span>
                            <span>{directory.modTime}</span>
                        </div>
                    </summary>
                    {directories.map((dir, i) =>
                        <CloudDirectory
                            key={`key-${i}`}
                            directory={dir}
                            directories={dir.directories}
                            files={dir.files}
                            headerStyle={headerStyle}
                        />)}
                </details>
            }

            {files &&
                <details>
                    <summary>{directory.name}</summary>
                    {files.map(({ name, size, modTime }, i) =>
                        <CloudFile key={`key${i}`} name={name} size={size} modTime={modTime} />)}
                </details>}
        </div>
    );
}

export default CloudDirectory;