import React, { useState } from 'react';
import {
  CreateSupersetModalContentType,
} from '@/components/App/Admin/ListSupersets/ModalCreateSuperset/ModalCreateSuperset';
import FormUploadingResources from '@/components/App/Admin/ListSupersets/ModalCreateSuperset/FormUploadingResources';
import CopySuperset from '@/components/App/Admin/ListSupersets/ModalCreateSuperset/CopySuperset';
import SuccessMessage from '@/components/App/Admin/ListSupersets/ModalCreateSuperset/SuccessMessage';
import { SupersetId } from '@/store/types/admin/supersets';

interface ModalCreateSupersetContentProps {
  contentType: CreateSupersetModalContentType;
  setContentType: (arg: CreateSupersetModalContentType) => void;
  closeModal: () => void
}

export type OnSuccessUploadingResources = (supersetId: SupersetId | void) => void;

function ModalCreateSupersetContent({ contentType, setContentType, closeModal }: ModalCreateSupersetContentProps) {
  const [createdSupersetId, setCreatedSupersetId] = useState<SupersetId>('');

  const onSuccess = (supersetId: SupersetId | void) => {
    if (supersetId) {
      setCreatedSupersetId(supersetId);
      setContentType(CreateSupersetModalContentType.Success);
      return;
    }
    closeModal();
  };

  if (contentType === CreateSupersetModalContentType.Success) {
    return (
      <SuccessMessage
        closeModal={closeModal}
        createdSupersetId={createdSupersetId}
      />
    );
  }

  if (contentType === CreateSupersetModalContentType.Copy) {
    return <CopySuperset onSuccess={onSuccess} />;
  }

  return (
    <FormUploadingResources onSuccess={onSuccess} />
  );
}

export default ModalCreateSupersetContent;