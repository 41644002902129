import React, { useState } from 'react';
import CustomSelect from '@/components/App/Admin/BUnitsSection/CustomSelect';
import styles from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/SelectMenu.module.sass';
import useLocalStorageByAdmin from '@/components/App/Admin/BUnitsSection/UnitsTree/hooks/adminLocalStorageSaver';
import CustomSelectOption from '@/components/App/Admin/BUnitsSection/CustomSelect/CustomSelectOption/CustomSelectOption';
import {
  BUnitSelectAction,
  SetCurrentSelectActionType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/useSelectAction';

interface BUnitsSelectMenuActionsProps {
  currentSelectAction: BUnitSelectAction,
  setCurrentSelectAction: SetCurrentSelectActionType;
  selectActions: BUnitSelectAction[];
}

function BUnitsSelectMenuActions({
  currentSelectAction,
  setCurrentSelectAction,
  selectActions,
}: BUnitsSelectMenuActionsProps) {
  const [writeInLocalStorage] = useLocalStorageByAdmin('selectActions');
  const [isOpen, toggleOpen] = useState(false);

  const handleSelectAction = (selectedAction: BUnitSelectAction) => {
    toggleOpen(false);
    setCurrentSelectAction(selectedAction);
    writeInLocalStorage(selectedAction);
  };

  if (!currentSelectAction) return null;

  return (
    <CustomSelect
      type="button"
      activeValue={currentSelectAction.name}
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      mainBtnClassNames={styles['select-action']}
    >
      {selectActions.map(({ key, name }) => (
        <CustomSelectOption
          optionClassNames={styles['unit-menu-select']}
          key={key}
          onClick={() => {
            handleSelectAction({ key, name });
          }}
          isCurrentValue={key === currentSelectAction.key}
        >
          {name}
        </CustomSelectOption>
      ))}
    </CustomSelect>
  );
}

export default BUnitsSelectMenuActions;