import { useEffect, useRef, useState } from 'react';
import Content from '@/components/Common/Modal/Content';
import WrapBtns from '@/components/Common/Modal/WrapBtns';

const useModal = ({ afterClosing, isShowingModal = false } = {}) => {
  const [isShowing, setStatusShowing] = useState(isShowingModal);
  const [additionalData, setAdditionalData] = useState(null);
  const isMountRef = useRef(false);
  const afterClosingRef = useRef(null);

  const toggleStatusShowing = (externalAdditionalData) => {
    setAdditionalData(
      typeof externalAdditionalData !== 'undefined'
        ? externalAdditionalData
        : null,
    );

    setStatusShowing(!isShowing);
  };

  useEffect(() => {
    afterClosingRef.current = afterClosing;
  }, [afterClosing]);

  useEffect(() => {
    if (isMountRef.current && !isShowing) {
      if (typeof afterClosingRef.current === 'function') {
        afterClosingRef.current();
      }
    }

    isMountRef.current = true;
  }, [isShowing]);

  return {
    isShowingModal: isShowing,
    toggleStatusShowingModal: toggleStatusShowing,
    onShowingModalData: additionalData,
    Content,
    WrapBtns,
  };
};

export default useModal;