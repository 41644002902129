import React from 'react';
// import styles from './BUnitCardConfirmButton.module.sass';
import BUnitsMenuBtn from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitsBlueBtn/BUnitsMenuBtn';
import useNLS from '@/components/App/Admin/hooks/hooks';

interface BUnitCardConfirmButtonProps {
  isUnitUpdated: boolean;
  formId?: string;
  handleClick?: () => void
}

function BUnitCardConfirmButton({
  isUnitUpdated,
  formId = '',
  handleClick = () => {},
}: BUnitCardConfirmButtonProps) {
  const tNLS = useNLS();

  return (
    <BUnitsMenuBtn
      isActive={isUnitUpdated}
      type="confirm"
      btnActionType="submit"
      formId={formId}
      handleButtonClick={handleClick}
    >
      {tNLS('tree.card.button.save')}
    </BUnitsMenuBtn>
  );
}

export default BUnitCardConfirmButton;