import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  selectActiveLangIDsSortedByPriority,
  selectCurrentLangID,
} from '@/store/selectors/admin/superset/projects';
import { setCurrentLanguageID } from '@/store/actions/admin';
import { selectAdmin } from '@/store/selectors/admin/authentication';

const useSetCurrentLang = () => {
  const dispatch = useDispatch();
  const [priorityLangID] = useSelector(selectActiveLangIDsSortedByPriority);
  const currentLangID = useSelector(selectCurrentLangID);
  const { adminId } = useSelector(selectAdmin);
  const { id: supersetID } = useParams();

  useEffect(() => {
    const checkIfLangIsSelectedInSuperset = (adminsToSelectedLangID) => {
      if (
        typeof adminsToSelectedLangID !== 'object'
        || adminsToSelectedLangID == null
      ) {
        return false;
      }

      if (typeof adminsToSelectedLangID[adminId] !== 'object') {
        return false;
      }

      return typeof adminsToSelectedLangID[adminId][supersetID] === 'string';
    };

    const adminsToSelectedLangID = JSON.parse(
      localStorage.getItem('adminsToSelectedLangID'),
    );

    if (checkIfLangIsSelectedInSuperset(adminsToSelectedLangID)) {
      dispatch(
        setCurrentLanguageID(adminsToSelectedLangID[adminId][supersetID]),
      );
    } else {
      dispatch(setCurrentLanguageID(priorityLangID));
    }
  }, [dispatch, priorityLangID, adminId, supersetID]);

  return currentLangID;
};

export default useSetCurrentLang;