import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  selectActiveTypeFS,
  selectDefaultTypeFS,
} from '@/store/selectors/admin/superset/process/cuts/factorsCuts';
import { setActiveTypeFS } from '@/store/actions/admin/superset/process/cuts/factorsCuts';

const useSetTypeFS = () => {
  const dispatch = useDispatch();
  const { itemID: fsIndx } = useParams();
  const defaultTypeFS = useSelector((state) => selectDefaultTypeFS(state, fsIndx));
  const typeFS = useSelector(selectActiveTypeFS);

  useEffect(() => {
    if (!typeFS) {
      dispatch(setActiveTypeFS(defaultTypeFS));
    }
  }, [dispatch, typeFS, defaultTypeFS]);

  return typeFS;
};

export default useSetTypeFS;