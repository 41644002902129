import React, {
  Dispatch, SetStateAction,
  useContext, useMemo,
} from 'react';
import Preloader from '@/components/App/Admin/SupersetSettings/Preloader';
import BUnitContext, { BUnitContextProps } from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import BUnitRolesList
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitRolesList';

// import styles from './BUnitCardHowToSection.module.sass'

export enum HowToType {
  Objects = 'isRating',
  Subjects = 'isRatedBy',
}
interface BUnitCardHowToSectionProps {
  isLoaded: boolean;
  type: HowToType;
  toggleUpdated: Dispatch<SetStateAction<boolean>>
}

function BUnitCardHowToSection({
  isLoaded, type, toggleUpdated,
}: BUnitCardHowToSectionProps) {
  const { unit } = useContext(BUnitContext) as Required<BUnitContextProps>;

  const howTo = useMemo(() => unit[type], [type, unit]);

  if (!isLoaded) return <Preloader />;

  if (!howTo) return null;

  return (
    <BUnitRolesList
      roles={howTo}
      type={type}
      toggleUpdated={toggleUpdated}
    />
  );
}

export default BUnitCardHowToSection;