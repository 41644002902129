import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { selectCurrentLangID } from '@/store/selectors/admin/superset/projects';
import { selectSelectedCriteriaInFormatToBeSent } from '@/store/selectors/admin/superset/process/cuts/cuts';
import {
  selectSelfAssessmentStatus,
  selectSNADirection,
} from '@/store/selectors/admin/superset/process/cuts/unitsCuts';
import axiosProcessing from '@/axiosConfig';

export const loadUnitCuts = createAsyncThunk(
  'superset/admin/process/cuts/unitsCuts/loadUnitCuts',
  async (form, { getState, rejectWithValue }) => {
    const state = getState();
    const { headers } = state.admin.authentication;
    const { supersetId } = selectSuperset(state);
    const langID = selectCurrentLangID(state);
    const direction = selectSNADirection(state);
    const self = selectSelfAssessmentStatus(state);
    const selectedCriteriaForSendingFormat = selectSelectedCriteriaInFormatToBeSent(state);

    const sendingData = {
      /*
        Data by default.
            direction: true - Outgoing (delivered) assessments by selected staff members on a given question;
            direction: false - Incoming (received) assessments to selected employees on a given issue;
       */
      langId: langID,
      criterios: selectedCriteriaForSendingFormat,
      modForSNA: true,
      direction,
      self,
      ...form,
    };
    try {
      const response = await axiosProcessing.post(
        `/api/admin/getUnitCuts/${supersetId}`,
        sendingData,
        { headers },
      );

      return {
        SNACuts: response.data,
        direction: sendingData.direction,
        self: sendingData.self,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const setUnitsCutsItems = createAction(
  'superset/admin/process/cuts/unitsCuts/setUnitsCutsItems',
);