import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { selectSelectedCriteriaInFormatToBeSent } from '@/store/selectors/admin/superset/process/cuts/cuts';
import { selectCurrentLangID } from '@/store/selectors/admin/superset/projects';
import { loadFactorCuts } from '@/store/actions/admin/superset/process/cuts/factorsCuts';
import {
  loadUnitCuts,
  setUnitsCutsItems,
} from '@/store/actions/admin/superset/process/cuts/unitsCuts';
import { selectUnitsCuts } from '@/store/selectors/admin/superset/process/cuts/unitsCuts';
import { selectQuestionsCuts } from '@/store/selectors/admin/superset/process/cuts/questionsCuts';
import {
  QUESTIONS_CUTS,
  UNITS_CUTS,
} from '@/components/App/Admin/Results/Cuts/constants';
import {
  loadCuts,
  setQuestionsCutsItems,
} from '@/store/actions/admin/superset/process/cuts/questionsCuts';
import axiosProcessing from '@/axiosConfig';
import { loadRatingsFourD } from '@/store/actions/admin/superset/process/cuts/fourD';

// questionCuts and unitsCuts;
const filteringItemsByCutsTypeByLang = (items, questionsCuts, unitsCuts) => {
  const filteredItemsByCutsTypeByLang = {
    [QUESTIONS_CUTS]: {},
    [UNITS_CUTS]: {},
  };

  Object.keys(items).forEach((langID) => {
    const itemsByLang = [...items[langID]];

    const filteredItemsByLang = itemsByLang
      // if item doesn't exist in cuts --> remove from items
      .filter(
        ({ id: itemID }) => itemID in questionsCuts || itemID in unitsCuts,
      )
      // filter items that don't have answer text in cuts
      .filter(({ id: itemID }) => {
        const { answerText } = questionsCuts[itemID] || unitsCuts[itemID];

        return !!answerText;
      });

    const questionsCutsItems = filteredItemsByLang.filter(({ sna }) => !sna);
    const unitsCutsItems = filteredItemsByLang.filter(({ sna }) => sna);

    filteredItemsByCutsTypeByLang[QUESTIONS_CUTS][langID] = questionsCutsItems;
    filteredItemsByCutsTypeByLang[UNITS_CUTS][langID] = unitsCutsItems;

    return filteredItemsByCutsTypeByLang;
  });

  return filteredItemsByCutsTypeByLang;
};

export const loadActorsAmount = createAsyncThunk(
  'superset/admin/process/cuts/loadActorsAmount',
  async (form, { getState, rejectWithValue }) => {
    const state = getState();
    const { headers } = state.admin.authentication;
    const { supersetId } = selectSuperset(state);
    const langID = selectCurrentLangID(state);
    const selectedCriteriaForSendingFormat = selectSelectedCriteriaInFormatToBeSent(state);

    try {
      const response = await axiosProcessing.post(
        `/api/admin/getActorsAmount/${supersetId}`,
        {
          langId: langID,
          criterios: selectedCriteriaForSendingFormat,
          ...form,
        },
        { headers },
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const loadItems = createAsyncThunk(
  'superset/admin/process/loadItems',
  async (supersetID, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await axiosProcessing.get(
        `/api/admin/getItems/${supersetID}`,
      );

      const state = getState();
      const questionsCuts = selectQuestionsCuts(state);
      const unitsCuts = selectUnitsCuts(state);

      const filteredItemsByCutsTypeByLang = filteringItemsByCutsTypeByLang(
        response.data,
        questionsCuts,
        unitsCuts,
      );

      dispatch(
        setQuestionsCutsItems(filteredItemsByCutsTypeByLang[QUESTIONS_CUTS]),
      );
      dispatch(setUnitsCutsItems(filteredItemsByCutsTypeByLang[UNITS_CUTS]));

      return null;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const loadAllDataCuts = createAsyncThunk(
  'superset/admin/process/cuts/loadAllDataCuts',
  async (_, { rejectWithValue, dispatch, getState }) => {
    const state = getState();
    const { hasTree } = selectSuperset(state);
    const { supersetId } = selectSuperset(state);

    try {
      await dispatch(loadCuts());
      if (hasTree) {
        await dispatch(loadUnitCuts());
      }
      await dispatch(loadFactorCuts());
      await dispatch(loadRatingsFourD());
      await dispatch(loadActorsAmount());

      await dispatch(loadItems(supersetId));

      return null;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const loadCutsOptions = createAsyncThunk(
  'superset/admin/process/cuts/loadCutsOptions',
  async (supersetID, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get(
        `/api/admin/getCutsOptions/${supersetID}`,
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const setSelectedCriteria = createAction(
  'superset/admin/process/setSelectedCriteria',
);

export const clearCutsData = createAction(
  'superset/admin/process/cuts/clearCutsData',
);