import { createContext, Dispatch, SetStateAction } from 'react';
import { SearchingValue } from '@/components/App/Admin/BUnitsSection/UnitsTree/UnitsTree';

export interface UnitsTreeContextProps {
  searchingValue?: SearchingValue;
  setSearchingValue?: Dispatch<SetStateAction<SearchingValue | undefined>>
  isAnimation?: boolean;
  toggleAnimation?: Dispatch<SetStateAction<boolean>>
}

const unitsTreeContext = createContext<UnitsTreeContextProps>({});

export default unitsTreeContext;