import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { selectCalculateNPSScore } from '@/store/selectors/admin/superset/process/cuts/cuts';
import styles from './NPSscore.module.sass';
import useNLS from '@/components/App/Admin/hooks/hooks';

function NPSscore() {
  const { itemID, typeCuts } = useParams();
  const tNLS = useNLS();
  const npsScore = useSelector((state) => selectCalculateNPSScore(state, itemID, typeCuts));

  return (
    <div className={styles['wrap-nps-score']}>
      <Tooltip id={`nps-score-${itemID}`}>
        <div className={styles['wrap-tooltip-nps-score']}>
          {!npsScore.forUnselected && (
            <div className={styles['wrap-for-selected']}>
              {`${tNLS('cuts.scales.abs.nps')}: `}
              <span
                className={styles.value}
              >
                {`${npsScore.forSelected} %`}
              </span>
            </div>
          )}

          {npsScore.forUnselected && (
            <>
              <div className={styles['wrap-for-selected']}>
                {`${tNLS('cuts.scales.abs.nps.selected')}: `}
                <span
                  className={styles.value}
                >
                  {`${npsScore.forSelected} %`}
                </span>
              </div>
              <div className={styles['wrap-for-unselected']}>
                {`${tNLS('cuts.scales.abs.nps.other')}: `}
                <span className={styles['for-unselected-value']}>
                  {`${npsScore.forUnselected} %`}
                </span>
              </div>
            </>
          )}
        </div>
      </Tooltip>

      <div
        className={styles['nps-scores-info']}
        data-tooltip-id={`nps-score-${itemID}`}
      >
        <span className={`icon-relative-percent ${styles['icon-nps-score']}`} />

        <div className={styles['wrap-type-of-values']}>
          {!npsScore.forUnselected && (
            <span>{`${npsScore.forSelected} %`}</span>
          )}

          {npsScore.forUnselected && (
            <>
              <span>{`${npsScore.forSelected} %`}</span>
              <span>{`${npsScore.forUnselected} %`}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default NPSscore;