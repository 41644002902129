// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".info-page--8gsb-{background:var(--content-block-bg);color:var(--main-color);max-width:43.75rem;margin:auto;padding:1.875rem;border-radius:1.25rem;width:100%}.info-page--8gsb- p{margin-bottom:0.75rem}.info-page--8gsb- .title--iHmyK{margin-bottom:0.625rem}.info-page--8gsb- .content--SKUWv{font-size:1rem}", "",{"version":3,"sources":["webpack://./src/components/App/User/UserContent/InformationPage/InformationPage.module.sass"],"names":[],"mappings":"AAEA,kBACE,kCAAA,CACA,uBAAA,CACA,kBAAA,CACA,WAAA,CACA,gBAAA,CACA,qBAAA,CACA,UAAA,CACA,oBACE,qBAAA,CACF,gCACE,sBAAA,CACF,kCACE,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info-page": "info-page--8gsb-",
	"title": "title--iHmyK",
	"content": "content--SKUWv"
};
export default ___CSS_LOADER_EXPORT___;
