// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html[data-theme=dark]{--color-webID: var(--secondary-grey)}html[data-theme=light]{--color-webID: var(--primary-dark-grey)}.header-fields--LSkhi{margin-bottom:2.8125rem}.header-fields--LSkhi .header-form--6D591{margin-bottom:1.875rem}.header-fields--LSkhi .header-form--6D591 .admin-resize-input--egVnA.wrap-company-field--fyRCa{margin-bottom:0.3125rem}.header-fields--LSkhi .header-form--6D591 .admin-resize-input--egVnA.wrap-name-field--74w4w input{font-size:1.75rem;line-height:1.0357142857em;color:var(--title-font);font-weight:600}.header-fields--LSkhi .header-form--6D591 .admin-resize-input--egVnA.wrap-web-id-field--G-2MU{margin-top:1.875rem}.header-fields--LSkhi .header-form--6D591 .admin-resize-input--egVnA.wrap-web-id-field--G-2MU span{font-size:1.125rem}.header-fields--LSkhi .header-form--6D591 .admin-resize-input--egVnA.wrap-web-id-field--G-2MU input{font-size:1.125rem;color:var(--color-webID)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SupersetSettings/SectionHeaderFields/SectionHeaderFields.module.sass"],"names":[],"mappings":"AACA,sBACE,oCAAA,CACF,uBACE,uCAAA,CAEF,sBACE,uBAAA,CACA,0CACE,sBAAA,CAEE,+FACE,uBAAA,CAEA,kGACE,iBAAA,CACA,0BAAA,CACA,uBAAA,CACA,eAAA,CACJ,8FACE,mBAAA,CACA,mGACE,kBAAA,CACF,oGACE,kBAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-fields": "header-fields--LSkhi",
	"header-form": "header-form--6D591",
	"admin-resize-input": "admin-resize-input--egVnA",
	"wrap-company-field": "wrap-company-field--fyRCa",
	"wrap-name-field": "wrap-name-field--74w4w",
	"wrap-web-id-field": "wrap-web-id-field--G-2MU"
};
export default ___CSS_LOADER_EXPORT___;
