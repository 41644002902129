import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  changeOrderQuestions,
  editingQuestions,
  loadAddingQuestions,
  loadAnswersForAddingQuestions,
} from '@/store/actions/admin/superset/addingQuestions';
import styles from './AddingQuestions.module.sass';
import ModalAddQuestion from '@/components/App/Admin/AddingQuestions/ModalAddQuestion';
import useModal from '@/hooks/modal';
import {
  selectAddingQuestions,
  selectAnswersForPriorityLang,
} from '@/store/selectors/admin/superset/addingQuestions';
import { selectLanguagesMasterSuperset } from '@/store/selectors/admin/superset/projects';
import { getClearClassNames } from '@/helpers/helpers';
import ModalDeleteQuestion from '@/components/App/Admin/AddingQuestions/ModalDeleteQuestion/ModalDeleteQuestion';
import CustomQuestionControls from '@/components/App/Admin/AddingQuestions/CustomQuestionControls/CustomQuestionControls';
import AddingQuestionsHeader from '@/components/App/Admin/AddingQuestions/AddingQuestionsHeader/AddingQuestionsHeader';
import CustomQuestionInput from '@/components/App/Admin/AddingQuestions/CustomQuestionInput/CustomQuestionInput';
import checkPermission from '@/components/App/Admin/hooks/checkPermission';

const getValidationErrorForQuestionByType = (
  errorsFields,
  questionID,
  langID,
  neededErrorType,
) => {
  // if errors exist
  if (Object.keys(errorsFields).length > 0) {
    const errorForQuestion = errorsFields.formulations?.[questionID];

    // if there's a question with error and it has a formulation with error
    if (errorForQuestion && errorForQuestion[langID]) {
      const { message, type } = errorForQuestion[langID];

      if (type === neededErrorType) {
        return <span className={styles['field-error-message']}>{message}</span>;
      }
    }
  }

  return null;
};

function AddingQuestions() {
  const dispatch = useDispatch();
  // const tNLS = useNLS();
  const { id: supersetID } = useParams();
  const questions = useSelector(selectAddingQuestions);
  const answers = useSelector(selectAnswersForPriorityLang);
  const languages = useSelector(selectLanguagesMasterSuperset);
  const shouldShowLanguageLabel = Object.keys(languages).length > 1;
  const {
    register,
    watch,
    formState: { errors: errorsFields },
    trigger,
  } = useForm();
  const delayTimer = useRef();
  const {
    toggleStatusShowingModal: createQuestionToggleModal,
    isShowingModal: createQuestionIsShowingModal,
  } = useModal();

  const {
    toggleStatusShowingModal: confirmRemovingQuestionToggleModal,
    isShowingModal: confirmRemovingQuestionIsShowingModal,
    onShowingModalData: deletedQuestionData,
  } = useModal();

  useEffect(() => {
    dispatch(loadAnswersForAddingQuestions(supersetID));
    dispatch(loadAddingQuestions(supersetID));
  }, [dispatch, supersetID]);

  useEffect(() => {
    const subscription = watch(
      ({ formulations }, { name: changedInputName }) => {
        clearTimeout(delayTimer.current);

        delayTimer.current = setTimeout(async () => {
          const [, questionID] = changedInputName.split('.');
          const { ...changedContentQuestion } = formulations[questionID];

          // call validation for all field of formulations of question
          await trigger(`formulations.${questionID}`).then((isValid) => {
            if (isValid) {
              dispatch(
                editingQuestions({
                  supersetID,
                  changedQuestions: [
                    {
                      itemId: questionID,
                      content: changedContentQuestion,
                      isEnabled: true,
                    },
                  ],
                }),
              );
            }

            return null;
          });
        }, 300);
      },
    );

    return () => subscription.unsubscribe();
  }, [watch, trigger, dispatch, supersetID]);

  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    if (!result.destination) return;

    const [...items] = questions;

    const [reorderItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderItem);

    dispatch(
      changeOrderQuestions({
        supersetID,
        changedQuestions: items.map((q, i) => ({
          itemId: q.itemId,
          norder: i + 1,
          isEnabled: q.isEnabled,
        })),
      }),
    );
  };

  return (
    <div className={styles['wrap-adding-questions']}>
      <AddingQuestionsHeader
        answers={answers}
        createQuestionToggleModal={createQuestionToggleModal}
      />
      <div className={styles['wrap-questions']}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="some">
            {(provided) => (
              <form {...provided.droppableProps} ref={provided.innerRef}>
                {questions.map(({ itemId, content, isEnabled }, indx) => {
                  if (!content) return null;
                  return (
                    <Draggable key={itemId} draggableId={itemId} index={indx}>
                      {(providedDraggable) => (
                        <div
                          className={styles['wrap-question']}
                          {...providedDraggable.draggableProps}
                          {...providedDraggable.dragHandleProps}
                          ref={providedDraggable.innerRef}
                        >
                          {getValidationErrorForQuestionByType(
                            errorsFields,
                            itemId,
                            Object.keys(content)[0],
                            'notEmptyAllFormulations',
                          )}
                          <div
                            className={getClearClassNames([
                              styles.question,
                              !shouldShowLanguageLabel
                                && styles['one-formulation'],
                            ])}
                          >
                            <span className={styles['number-of-question']}>
                              {`${indx + 1}.`}
                            </span>

                            <div className={styles.formulations}>
                              {Object.keys(content).map((langID) => {
                                const formulation = content[langID];
                                const { native } = languages[langID];

                                return (
                                  <div
                                    key={`${itemId}-${langID}`}
                                    className={styles.formulation}
                                  >
                                    <CustomQuestionInput
                                      itemId={itemId}
                                      shouldShowLanguageLabel={
                                        shouldShowLanguageLabel
                                      }
                                      langID={langID}
                                      native={native}
                                      formulation={formulation}
                                      register={register}
                                    />
                                    {getValidationErrorForQuestionByType(
                                      errorsFields,
                                      itemId,
                                      langID,
                                      'maxLength',
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                            {checkPermission('delete_custom_question') && (
                              <CustomQuestionControls
                                itemId={itemId}
                                isEnabled={isEnabled}
                                index={indx}
                                confirmRemovingQuestionToggleModal={
                                  confirmRemovingQuestionToggleModal
                                }
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </form>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <ModalAddQuestion
        toggleModal={createQuestionToggleModal}
        isShowingModal={createQuestionIsShowingModal}
      />
      <ModalDeleteQuestion
        deletedQuestionData={deletedQuestionData}
        confirmRemovingQuestionIsShowingModal={
          confirmRemovingQuestionIsShowingModal
        }
        confirmRemovingQuestionToggleModal={confirmRemovingQuestionToggleModal}
        questions={questions}
      />
    </div>
  );
}

export default AddingQuestions;