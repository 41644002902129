// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper-container--4Sets{padding:0 15px}.wrapper-container--4Sets .main-content-container{max-width:68.75rem;margin:0 auto;background:var(--content-block-bg);border-radius:20px;padding:30px 30px 10px 30px}.wrapper-container--4Sets .header-content-container--1P3Ry{max-width:68.75rem;margin:0 auto}.wrapper-container--4Sets .header-content-container--1P3Ry .link-to-projects--aEVaE{margin-bottom:0.9375rem;display:inline-block}.page-num--NtFg5{font-size:20px;color:var(--secondary-color);position:absolute;top:24px;right:24px;font-weight:500;z-index:10;border-radius:6px;background:var(--content-block-bg)}@media screen and (max-width: 768px){.wrapper-container--4Sets{width:100%;padding:0}.wrapper-container--4Sets .main-content-container{width:100%;max-width:none;margin:0;border-radius:0}}", "",{"version":3,"sources":["webpack://./src/components/Common/BaseTemplate/MainContentContainer/MainContentContainer.module.sass"],"names":[],"mappings":"AAGA,0BACE,cAAA,CACA,kDACE,kBAAA,CACA,aAAA,CACA,kCAAA,CACA,kBAAA,CACA,2BAAA,CACF,2DACE,kBAAA,CACA,aAAA,CACA,oFACE,uBAAA,CACA,oBAAA,CAEN,iBACE,cAAA,CACA,4BAAA,CACA,iBAAA,CACA,QAAA,CACA,UAAA,CACA,eAAA,CACA,UAAA,CACA,iBAAA,CACA,kCAAA,CAEF,qCACE,0BACE,UAAA,CACA,SAAA,CACA,kDACE,UAAA,CACA,cAAA,CACA,QAAA,CACA,eAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper-container": "wrapper-container--4Sets",
	"header-content-container": "header-content-container--1P3Ry",
	"link-to-projects": "link-to-projects--aEVaE",
	"page-num": "page-num--NtFg5"
};
export default ___CSS_LOADER_EXPORT___;
