import { useState, useCallback } from 'react';
import useDebounce from '@/hooks/debounce';

interface UseInputSearchProps<T> {
  elementsList: T[];
  filterCallback: (arg: T, value: string) => boolean;
  minLenForFilter?: number;
  debounce?: number;
}

const useDebounceSearch = <ListElement>({
  elementsList,
  filterCallback,
  minLenForFilter = 0,
  debounce = 300,
}: UseInputSearchProps<ListElement>) => {
  const [filteredList, setFilteredList] = useState<ListElement[]>(elementsList);
  const [lastValue, setLastValue] = useState<string>(''); // track the last input value

  const filterValues = useCallback(
    (value: string) => {
      if (value.length < minLenForFilter) return;

      let listToFilter = elementsList;

      // If the current value extends the last input, filter the already filtered list
      if (lastValue && value.startsWith(lastValue)) {
        listToFilter = filteredList; // filter on already filtered list
      }

      const result = listToFilter.filter((element) => filterCallback(element, value));
      setFilteredList(result);
      setLastValue(value); // Update the last input value
    },
    [elementsList, filteredList, filterCallback, lastValue, minLenForFilter],
  );

  const filterWithDebounce = useDebounce(filterValues, debounce);

  return {
    filteredList,
    filterValues: filterWithDebounce,
  };
};

export default useDebounceSearch;