import React from 'react';
import styles from './UploadingErrorsAndWarns.module.sass';
import { UploadingMessage } from '@/store/types/admin/superset/projects';

interface UploadingErrorsProps {
  content?: UploadingMessage[];
}

function UploadingErrorsAndWarns({ content }: UploadingErrorsProps) {
  const getCellInFormatExcel = (col: number, row: number) => {
    if (col + row === 0) return '';
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const quotient = Math.floor((col - 1) / 26);
    const remainder = (col - 1) % 26;
    const letter = (quotient > 0 ? alphabet.charAt(quotient - 1) : '')
      + alphabet.charAt(remainder);
    return ` / ${letter}${row} `;
  };
  if (!content || !content.length) return null;

  return (
    <div className={styles.messages}>
      {content.map(({
        Sheet, Col, Row, Message,
      }, indx) => {
        const key = `error-${indx}`;

        return (
          <div key={key} className={styles['error-message']}>
            {`${Sheet} / ${getCellInFormatExcel(
              Col + 1,
              Row + 1,
            )} : ${Message}`}
          </div>
        );
      })}
    </div>
  );
}

export default UploadingErrorsAndWarns;