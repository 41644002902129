import React, { ReactNode } from 'react';
import Redirect from '../../Redirect/Redirect';
import useCheckPermission from '../hooks/checkPermission';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';

interface RedirectWithoutPermissionProps {
  children: ReactNode;
  ruleKey?: string;
}

export default function RedirectAdminWithoutPermission({
  children,
  ruleKey, // если не задан, то идёт проверка на admin.isSuper
}: RedirectWithoutPermissionProps) {
  const { supersetId } = useAdminSelector(selectSuperset);
  const checkPermission = useCheckPermission();

  if (!checkPermission(ruleKey)) {
    return (
      <Redirect
        path={supersetId ? `/admin/project/${supersetId}` : '/admin/projects'}
      />
    );
  }

  return (
    children
  );
}