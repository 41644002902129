import React from 'react';
import styles from './SidebarContainer.module.sass';
import { getClearClassNames } from '@/helpers/helpers';
import SidebarLink, { SidebarLinkProps } from './SidebarLink/SidebarLink';

interface SidebarContainerProps {
  isOpen: boolean;
  links : SidebarLinkProps[]
}

export default function SidebarContainer({ isOpen, links }: SidebarContainerProps) {
  if (!isOpen) return null;

  return (
    <nav
      className={getClearClassNames([
        styles.sidebar,
      ])}
    >
      {links.map((link) => (
        <SidebarLink
          key={`${link.name}-nav`}
          {...link}
        />
      ))}
    </nav>
  );
}