// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-criteria-head--0mQqZ{display:flex;justify-content:space-between}@media screen and (max-width: 768px){.wrap-criteria-head--0mQqZ{flex-wrap:wrap}}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/HeaderCuts/HeaderCuts.module.sass"],"names":[],"mappings":"AAAA,2BACE,YAAA,CACA,6BAAA,CACA,qCAHF,2BAII,cAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-criteria-head": "wrap-criteria-head--0mQqZ"
};
export default ___CSS_LOADER_EXPORT___;
