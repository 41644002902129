import React from 'react';
import Select, { Props as SelectProps } from 'react-select';
import './BaseSelect.sass';

// Define an interface for the component props
interface BaseSelectProps extends SelectProps {
  id: string;
  label?: string;
}

const BaseSelect = React.forwardRef<HTMLDivElement, BaseSelectProps>(({ id, label, ...props }, ref) => (
  <>
    {label && (
      <label htmlFor={id}>{label}</label>
    )}
    <Select
      inputId={id}
      className="base-select"
      classNamePrefix="base-select"
      isSearchable={false}
      {...props}
      unstyled
    />
  </>
));

export default BaseSelect;