import React, { useRef, useState } from 'react';
import { ArticleEditor } from "./ArticleEditor/ArticleEditor";
import ArticlesList from "./ArticlesList/ArticlesList";


export default function Articles() {
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedArticleID, setSelectedArticleID] = useState<string | null>(null);

    const openModalWithArticleID = (articleID: string) => {
        setSelectedArticleID(articleID);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setSelectedArticleID(null);
    };

    return (
        <>
            <section>
                <button type="button" onClick={() => openModalWithArticleID(null)}>Add article</button>
            </section>
            <section>
                <ArticlesList />
            </section>
            <ArticleEditor
                isOpen={isModalOpen}
                onClose={closeModal}
                ArticleID={selectedArticleID || undefined}
            />
        </>
    )
}