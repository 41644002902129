import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProcessing from '@/axiosConfig';

export const loadAnswersForAddingQuestions = createAsyncThunk(
  'superset/superset/addingQuestions/loadAnswersForAddingQuestions',
  async (supersetID, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get(
        `/api/admin/getAnsTexts/${supersetID}`,
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const loadAddingQuestions = createAsyncThunk(
  'superset/superset/addingQuestions/getQuestions',
  async (supersetID, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get(
        `/api/admin/customItems/${supersetID}`,
      );

      const questions = response.data;

      // convert object of items to array of items sorted by order.
      return Object.keys(questions)
        .map((itemId) => ({ itemId, ...questions[itemId] }))
        .sort((qA, qB) => qA.norder - qB.norder);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateQuestions = createAsyncThunk(
  'superset/superset/addingQuestions/updateQuestions',
  async ({ supersetID, changedQuestions }, { getState, rejectWithValue }) => {
    try {
      const { headers } = getState().admin.authentication;

      const response = await axiosProcessing.put(
        `/api/admin/customItems/${supersetID}`,
        changedQuestions,
        { headers },
      );

      // return itemID
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const editingQuestions = createAsyncThunk(
  'superset/superset/addingQuestions/editingQuestions',
  async (form, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(updateQuestions(form));

      return form.changedQuestions;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const changeOrderQuestions = createAsyncThunk(
  'superset/superset/addingQuestions/changeOrderQuestions',
  async (form, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(updateQuestions(form));

      return form.changedQuestions;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createQuestion = createAsyncThunk(
  'superset/superset/addingQuestions/createQuestion',
  async ({ data, supersetId }, { getState, rejectWithValue }) => {
    try {
      const { headers } = getState().admin.authentication;
      const response = await axiosProcessing.post(
        `/api/admin/customItems/${supersetId}`,
        data,
        { headers },
      );
      return { itemId: response.data, ...data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteQuestion = createAsyncThunk(
  'superset/superset/addingQuestions/deleteQuestion',
  async ({ supersetID, itemId }, { getState, rejectWithValue }) => {
    try {
      const { headers } = getState().admin.authentication;
      await axiosProcessing.delete(`/api/admin/customItems/${supersetID}`, {
        headers,
        itemId,
      });

      return itemId;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);