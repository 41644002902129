import React from 'react';
import { useSelector } from 'react-redux';
import { withErrorBoundary } from 'react-error-boundary';
import styles from './SectionAdminSettings.module.sass';
import FallbackSupersetSection from '@/components/App/Admin/SupersetSettings/FallbackSupersetSection';
import RadioBlockSettings from '@/components/App/Admin/SupersetSettings/SectionAdminSettings/RadioBlockSettings';
import WrapperGroupCheckboxesSetting from '@/components/App/Admin/SupersetSettings/SectionAdminSettings/WrapperGroupCheckboxesSetting';
import { selectProcessedAdminSettings } from '@/store/selectors/admin/superset/settings';
import useNLS from '@/components/App/Admin/hooks/hooks';

function SectionAdminSettings() {
  const tNLS = useNLS();
  const settings = useSelector(selectProcessedAdminSettings);
  const settingTypeToComponent = {
    groupRadio: RadioBlockSettings,
    wrapperGroupCheckboxes: WrapperGroupCheckboxesSetting,
  };

  return (
    <section className={`settings-section ${styles['admin-settings-section']}`}>
      <h3 className="title-section">{tNLS('project.settings')}</h3>

      <div className={styles['wrap-admin-settings']}>
        {settings.map((setting) => {
          const { settingId, type } = setting;

          const ComponentSettingType = settingTypeToComponent[type];

          if (!ComponentSettingType) {
            return null;
          }

          return <ComponentSettingType key={settingId} setting={setting} />;
        })}
      </div>
    </section>
  );
}

export default withErrorBoundary(SectionAdminSettings, {
  FallbackComponent: FallbackSupersetSection,
});