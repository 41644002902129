// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header-btn-to-switch-theme--tJMlC{font-size:1.09375rem}", "",{"version":3,"sources":["webpack://./src/components/Common/BaseTemplate/Header/ThemeSwitcher/ThemeSwitcher.module.sass"],"names":[],"mappings":"AAEA,mCACE,oBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-btn-to-switch-theme": "header-btn-to-switch-theme--tJMlC"
};
export default ___CSS_LOADER_EXPORT___;
