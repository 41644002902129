// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".file-button--63yWD{display:flex;width:fit-content;align-items:center;height:fit-content;gap:0.5rem;padding:0.375rem 0.75rem 0.375rem 0.375rem;border-radius:0.25rem}.file-button--63yWD .file-icon--w4qRh{font-size:2.125rem;color:#838999}.file-button--63yWD .download-button--xl1C7 .file-info--9vLf8{display:flex;flex-direction:column;gap:0.25rem;align-items:baseline}.file-button--63yWD .download-button--xl1C7 .file-info--9vLf8 .file-name--ocYp8{font-size:1rem;color:var(--main-color);text-decoration:underline}.file-button--63yWD .download-button--xl1C7 .file-info--9vLf8 .file-additional-info--ixYpI{display:flex;align-items:center;gap:0.5rem;font-size:0.75rem}.file-button--63yWD .download-button--xl1C7 .file-info--9vLf8 .file-additional-info--ixYpI .lang-icon--pna8F{height:0.75rem}.file-button--63yWD .download-button--xl1C7 .file-info--9vLf8 .file-additional-info--ixYpI .file-date--wuLtT{color:var(--secondary-color)}", "",{"version":3,"sources":["webpack://./src/components/Common/BaseTemplate/MainContentContainer/AdminSupersetContainer/ButtonsPanel/SupersetMenuModal/FilesForDownload/FilesSection/FileButton/FileButton.module.sass"],"names":[],"mappings":"AAEA,oBACE,YAAA,CACA,iBAAA,CACA,kBAAA,CACA,kBAAA,CACA,UAAA,CAEA,0CAAA,CACA,qBAAA,CACA,sCACE,kBAAA,CACA,aAAA,CAEA,8DACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,oBAAA,CACA,gFACE,cAAA,CACA,uBAAA,CACA,yBAAA,CACF,2FACE,YAAA,CACA,kBAAA,CACA,UAAA,CACA,iBAAA,CACA,6GACE,cAAA,CACF,6GACE,4BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"file-button": "file-button--63yWD",
	"file-icon": "file-icon--w4qRh",
	"download-button": "download-button--xl1C7",
	"file-info": "file-info--9vLf8",
	"file-name": "file-name--ocYp8",
	"file-additional-info": "file-additional-info--ixYpI",
	"lang-icon": "lang-icon--pna8F",
	"file-date": "file-date--wuLtT"
};
export default ___CSS_LOADER_EXPORT___;
