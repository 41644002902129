import React, { useState } from 'react';
import styles from './BUnitAddSpecsModal.module.sass';
import Modal from '@/components/Common/Modal';
import Content from '@/components/Common/Modal/Content';
import SelectMenuTitle
  from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/BUnitSelectMenuOptionsSelectors/SelectMenuTitle/SelectMenuTitle';
import UnitsForChangeList
  from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitTransferModal/UnitsForTransferList/UnitsForChangeList';
import BUnitsMenuBtn from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitsBlueBtn/BUnitsMenuBtn';
import EditSpecsInActionModal
  from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitAddSpecsModal/EditSpecsInActionModal/EditSpecsInActionModal';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import { selectSelectedUnits, selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';
import { updateTreeUnits } from '@/store/actions/admin/superset/unitsTree';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import useNLS from '@/components/App/Admin/hooks/hooks';

interface BUnitAddSpecsModalProps {
  isOpen: boolean;
  toggleOpen: () => void
}

function BUnitAddSpecsModal({ isOpen, toggleOpen }: BUnitAddSpecsModalProps) {
  const [deletedSpecs, setDeletedSpecs] = useState<string[]>([]);
  const { supersetId } = useAdminSelector(selectSuperset);
  const selectedUnits = useAdminSelector(selectSelectedUnits);
  const { units } = useAdminSelector(selectUnitsTreeData);
  const tNLS = useNLS();
  const [newSpecs, setNewSpecs] = useState<string[]>([]);
  const dispatch = useAdminDispatch();

  const clearChanges = () => {
    setDeletedSpecs([]);
    setNewSpecs([]);
  };

  const closeModal = () => {
    clearChanges();
    toggleOpen();
  };
  const handleAddSpecs = () => {
    const form = selectedUnits.map((unitId: string) => {
      const { spec } = units[unitId];
      const newSpecsSet = new Set([...spec, ...newSpecs]);
      deletedSpecs.forEach((sp) => {
        newSpecsSet.delete(sp);
      });
      return {
        ID: unitId,
        spec: [...newSpecsSet],
      };
    });
    dispatch(updateTreeUnits({
      unitsData: form,
      supersetId,
    }));

    closeModal();
  };

  return (
    <Modal
      isShowing={isOpen}
      hide={closeModal}
      title={tNLS('tree.spec.modal.header')}
      externalClassNames={{
        contentClass: styles['transfer-units-modal'],
        modal: styles['transfer-modal'],
      }}
    >
      <Content>
        <SelectMenuTitle />
        <UnitsForChangeList actionType="addSpec" />
        <EditSpecsInActionModal
          newSpecs={newSpecs}
          editNewSpecs={setNewSpecs}
          deletedSpecs={deletedSpecs}
          deleteSpecs={setDeletedSpecs}
        />
        <div className={styles.buttons}>
          <BUnitsMenuBtn
            handleButtonClick={clearChanges}
            type="reject"
          >
            {tNLS('tree.spec.modal.cancel')}
          </BUnitsMenuBtn>
          <BUnitsMenuBtn
            handleButtonClick={handleAddSpecs}
          >
            {tNLS('tree.spec.modal.save')}
          </BUnitsMenuBtn>
        </div>
      </Content>
    </Modal>
  );
}

export default BUnitAddSpecsModal;