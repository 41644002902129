import React, { Dispatch, SetStateAction } from 'react';
import Modal from '@/components/Common/Modal';
import Content from '@/components/Common/Modal/Content';
import useNLS from '@/components/App/Admin/hooks/hooks';
import styles from './CreateBUnitModal.module.sass';
import CreateBUnitForm
  from '@/components/App/Admin/BUnitsSection/CreateBUnitSection/CreateBUnitModal/CreateBUnitForm/CreateBUnitForm';

interface CreateBUnitModalProps {
  isShowingModal: boolean;
  toggleModal: Dispatch<SetStateAction<boolean>>
}
function CreateBUnitModal({
  isShowingModal,
  toggleModal,
}: CreateBUnitModalProps) {
  const tNLS = useNLS();

  return (
    <Modal
      isShowing={isShowingModal}
      hide={() => toggleModal(false)}
      title={tNLS('tree.new.unit')}
      externalClassNames={{
        contentClass: styles['create-bunit-modal'],
      }}
    >
      <Content>
        <CreateBUnitForm
          closeModal={() => { toggleModal(false); }}
        />
      </Content>
    </Modal>
  );
}

export default CreateBUnitModal;