import React, { useState } from 'react';
import styles from './CreateSupersetHeader.module.sass';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import ModalCreateSuperset from '@/components/App/Admin/ListSupersets/ModalCreateSuperset';

function CreateSupersetHeader() {
  const [isOpen, toggleOpen] = useState<boolean>(false);

  const openModalCreatingProject = () => {
    toggleOpen(true);
  };

  return (
    <header className={styles['content-header']}>
      <h1>Your projects</h1>
      {/* @ts-ignore */}
      <CustomButton
        onClick={openModalCreatingProject}
      >
        + Create project
      </CustomButton>
      <ModalCreateSuperset isOpen={isOpen} toggleOpen={toggleOpen} />
    </header>
  );
}

export default CreateSupersetHeader;