import React from 'react';
import styles from './AddHowToSearchDropdownBtn.module.sass';
import { TreeUnit } from '@/store/types/admin/superset/unitsTree';

interface AddHowToSearchDropdownBtnProps {
  unitInfo: [string, TreeUnit];
  selectUnit: (id: string, unit: TreeUnit) => void
}

function AddHowToSearchDropdownBtn({ unitInfo: [id, unit], selectUnit }: AddHowToSearchDropdownBtnProps) {
  return (
    <button
      type="button"
      className={styles['dropdown-btn']}
      onClick={() => selectUnit(id, unit)}
    >
      <span className={styles.name}>{unit.name}</span>
      {unit.jobTitle && <span className={styles['job-title']}>{unit.jobTitle}</span>}
    </button>
  );
}

export default AddHowToSearchDropdownBtn;