import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@/components/Common/Modal';
import CalculateResultsModalContent from '@/components/App/Admin/Results/LoadResults/CalculateResults/CalculateResultsModal/CalculateResultsModalContent/CalculateResultsModalContent';
import Content from '@/components/Common/Modal/Content';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { startProcessor } from '@/store/actions/admin/superset/process/process';
import useNLS from '@/components/App/Admin/hooks/hooks';
import useProcessor from '@/components/App/Admin/Results/hooks/useProcessor';

function CalculateResultsModal({ isModalOpen, toggleModal }) {
  const [selectedCheckpointId, setSelectedCheckpointId] = useState('');
  const dispatch = useDispatch();
  const { supersetId, stage } = useSelector(selectSuperset);
  const { hasEvaluated } = useProcessor(supersetId, stage);

  const hideModal = () => {
    toggleModal(false);
    setSelectedCheckpointId('');
  };

  const tNLS = useNLS();
  return (
    <Modal isShowing={isModalOpen} title="Calculate results" hide={hideModal}>
      <Content>
        <CalculateResultsModalContent
          selectedCheckpointId={selectedCheckpointId}
          setSelectedCheckpointId={setSelectedCheckpointId}
        />
        <CustomButton
          styleTypeBtn="btn-blue btn-large"
          onClick={() => {
            dispatch(
              startProcessor({
                supersetId,
                checkpointId: selectedCheckpointId,
              }),
            );
            hideModal();
          }}
          // disabled={!selectedMasterSuperset}
        >
          {tNLS(hasEvaluated ? 'results.recalculate' : 'results.calculate')}
        </CustomButton>
      </Content>
    </Modal>
  );
}

CalculateResultsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default CalculateResultsModal;