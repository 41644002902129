import React from 'react';
import styles from './FilesForDownload.module.sass';
import ResultsSection from '@/components/Common/BaseTemplate/MainContentContainer/AdminSupersetContainer/ButtonsPanel/SupersetMenuModal/FilesForDownload/ResultsSection/ResultsSection';
import TemplatesSection from '@/components/Common/BaseTemplate/MainContentContainer/AdminSupersetContainer/ButtonsPanel/SupersetMenuModal/FilesForDownload/TemplatesSection/TemplatesSection';

function FilesForDownload() {
  return (
    <div className={styles['files-list']}>
      <TemplatesSection isLastFile={false} />
      <ResultsSection />
    </div>
  );
}

export default FilesForDownload;