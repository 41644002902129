import React from 'react';
import styles from './ThemeSwitcher.module.sass';
import useTheme from '@/theme/useTheme';
import { Theme } from '@/theme/themeContext';

function ThemeSwitcher() {
  const { theme, toggleTheme, singleTheme } = useTheme();

  if (singleTheme) return null;

  return (
    <button
      type="button"
      aria-label="switch theme"
      className={`${styles['header-btn-to-switch-theme']}
       icon-to_${theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT}_theme
       btn-without-style`}
      onClick={toggleTheme}
    />
  );
}

export default ThemeSwitcher;