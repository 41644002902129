import React from 'react';
import styles from './UnitsHeaderInformation.module.sass';
import UnitsInformationBlock, {
  InformationTypes,
} from '@/components/App/Admin/BUnitsSection/BUnitsSectionHeader/UnitsHeaderInformation/UnitsInformationBlock/UnitsInformationBlock';

// interface UnitsHeaderInformationProps {
//
// }

function UnitsHeaderInformation() {
  return (
    <div className={styles['units-information-block']}>
      {Object.keys(InformationTypes).map((key) => (
        <UnitsInformationBlock
          key={`b-units-header${key}`}
          type={InformationTypes[key as keyof typeof InformationTypes]}
        />
      ))}
    </div>
  );
}

export default UnitsHeaderInformation;