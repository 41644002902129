import React from 'react';
import PropTypes from 'prop-types';
import styles from './notify.module.sass';
import { getClearClassNames } from '@/helpers/helpers';

function Notify({
  children,
  type,
  onClose,
  additionalClassNames,
  withAnimation,
}) {
  const getIconClassName = (typeNoty) => {
    switch (typeNoty) {
      case 'success':
        return 'icon-successful-noty';
      case 'error':
        return 'icon-error-noty';
      case 'warning':
        return 'icon-warning';
      case 'info':
        return 'icon-info-noty';
      default:
        return 'icon-successful-noty';
    }
  };

  const iconClassName = getIconClassName(type);

  return (
    <div
      role="button"
      className={getClearClassNames([
        styles.notify,
        styles[type],
        withAnimation && styles['with-animation'],
        additionalClassNames,
      ])}
      onKeyDown={() => {}}
      onClick={onClose}
      tabIndex={0}
    >
      <span className={`${iconClassName} ${styles['icon-noty']}`} />
      {children}
    </div>
  );
}

Notify.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.bool,
  ]),
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  additionalClassNames: PropTypes.string,
  withAnimation: PropTypes.bool,
};

Notify.defaultProps = {
  children: undefined,
  additionalClassNames: '',
  withAnimation: true,
};

export default Notify;