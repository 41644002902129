import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { CreateWebinarFieldsType, CreateWebinarFielsNamesEnum } from '../../../types/webinarPageTypes';
import CreateWebinarInput from './CreateWebinarInput/CreateWebinarInput';
import CreateWebinarTextarea from './WebinarTextArea/CreateWebinarTextarea';

interface CreateWebinarFieldProps extends UseFormReturn<CreateWebinarFieldsType> {
  name: CreateWebinarFielsNamesEnum;
}
export default function CreateWebinarField({ name, ...formProps }: CreateWebinarFieldProps) {
  if (name === CreateWebinarFielsNamesEnum.Description || name === CreateWebinarFielsNamesEnum.RegisterText) {
    return (
      <CreateWebinarTextarea
        name={name}
        {...formProps}
      />
    );
  }

  return (
    <CreateWebinarInput
      name={name}
      {...formProps}
    />
  );
}