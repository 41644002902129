import React from 'react';
import styles from './ProgressContainer.module.sass';

// interface ProgressContainerProps {
//
// }

function ProgressContainer() {
  return (
    <>
      <div className={styles.mask} />
      <div className={`${styles.progress} progress-container`} />
    </>
  );
}

export default ProgressContainer;