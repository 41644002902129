import React from 'react';
import { useSelector } from 'react-redux';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import styles from './AssessmentStatus.module.sass';
import StatusElement from '@/components/App/Admin/Superset/HeaderSuperset/AssessmentStatus/StatusElement/StatusElement';
import StatusColumn from '@/components/App/Admin/Superset/HeaderSuperset/AssessmentStatus/StatusColumn/StatusColumn';

function AssessmentStatus() {
  const { assessmentStatus } = useSelector(selectSuperset);

  const isShowSecondColumn = () => {
    const { finishedInvalidated, lastActiveInvalidated, startedInvalidated } = assessmentStatus;
    return !!(
      finishedInvalidated
      || lastActiveInvalidated
      || startedInvalidated
    );
  };

  return (
    <div className={styles['assessment-status']}>
      {/* <span className={`icon-employee ${styles.icon}`} /> */}
      <div className={styles['status-table']}>
        <StatusColumn />
        {isShowSecondColumn() && <StatusColumn isInvalidated />}
      </div>
      {isShowSecondColumn() && (
        <StatusElement
          statusKey="total"
          classNames={styles.total}
          tooltipClassNames={`${styles.tooltip} ${styles.left}`}
        />
      )}
    </div>
  );
}

export default AssessmentStatus;