import React from 'react';
import Admlist from '../../Super/Admlist/Admlist';
import Articles from '../../Super/Articles/Articles';
import Cloud from '../../Super/Cloud/Cloud';
import Users from '../../Super/Users/Users';
import Webinars from './Webinars';

const superAdminRoutes = [
  {
    url: '/super/admlist', path: 'admlist', name: 'Список адмиинистраторов', element: <Admlist />,
  },
  {
    url: '/super/users', path: 'users', name: 'Пользователи', element: <Users />,
  },
  {
    url: '/super/cloud', path: 'cloud', name: 'Облако', element: <Cloud />,
  },
  {
    url: '/super/webinars', path: 'webinars/*', name: 'Вебинары', element: <Webinars />,
  },
  {
    url: '/super/articles', path: 'articles', name: 'Статьи', element: <Articles />,
  },
];

export default superAdminRoutes;