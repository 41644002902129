import { createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';

const initialState: NotifyInitialState = {
  notifications: [],
  lastNotifyId: '',
};

export const notifySlice = createSlice({
  name: 'notify',
  initialState,
  reducers: {
    addNotification: {
      reducer: (state, { payload }: PayloadAction<NotifyType>) => {
        state.lastNotifyId = payload.id;
        state.notifications.push({
          title: payload.title || '',
          message: payload.message,
          type: payload.type || 'info',
          id: payload.id,
          lifeTime: +payload.lifeTime * 1000 || 30000, // .s
          supersetId: payload.supersetId,
          supersetName: payload.supersetName || null,
        });
      },
      prepare: (notifyData: NotifyType) => {
        const id = nanoid();
        return { payload: { id, ...notifyData } };
      },
    },
    removeNotification: (state, { payload: idNotyDeleted }) => {
      state.notifications = state.notifications.filter(
        ({ id }) => id !== idNotyDeleted,
      );
    },
  },
});

const { reducer } = notifySlice;

export const { addNotification, removeNotification } = notifySlice.actions;
export default reducer;