// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-admin-item--uZY5C{position:relative}.wrap-admin-item--uZY5C .btn-item-admin--ZJu1L{display:flex;align-items:center;gap:0.625rem}.wrap-admin-item--uZY5C .btn-item-admin--ZJu1L .icon-arrow:before{font-size:0.5rem}.wrap-admin-item--uZY5C .admin-menu--PMZGd{position:absolute;width:20.625rem;bottom:2.5rem;left:0}.wrap-admin-item--uZY5C.not-interactive--JsBTa{pointer-events:none}.icon-admin-role--xbDOW{height:0.625rem;width:0.625rem;border-radius:50%}.icon-admin-role--xbDOW.red--4cNYE{background-color:#eb5757}.icon-admin-role--xbDOW.yellow--BF0te{background-color:#f2c94c}.icon-admin-role--xbDOW.green--sDSTM{background-color:#219653}.icon-admin-role--xbDOW.blue--dkY25{background-color:#2f80ed}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SupersetSettings/SectionAdmins/AdminItem/AdminItem.module.sass"],"names":[],"mappings":"AAEA,wBACE,iBAAA,CACA,+CACE,YAAA,CACA,kBAAA,CACA,YAAA,CAEE,kEACE,gBAAA,CACN,2CACE,iBAAA,CACA,eAAA,CACA,aAAA,CACA,MAAA,CACF,+CACE,mBAAA,CAEJ,wBACE,eAAA,CACA,cAAA,CACA,iBAAA,CACA,mCACE,wBAAA,CACF,sCACE,wBAAA,CACF,qCACE,wBAAA,CACF,oCACE,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-admin-item": "wrap-admin-item--uZY5C",
	"btn-item-admin": "btn-item-admin--ZJu1L",
	"admin-menu": "admin-menu--PMZGd",
	"not-interactive": "not-interactive--JsBTa",
	"icon-admin-role": "icon-admin-role--xbDOW",
	"red": "red--4cNYE",
	"yellow": "yellow--BF0te",
	"green": "green--sDSTM",
	"blue": "blue--dkY25"
};
export default ___CSS_LOADER_EXPORT___;
