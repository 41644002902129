import React, { useContext, useMemo } from 'react';
import { BUnitHowTo } from '@/store/types/admin/superset/unitsTree';
import BUnitHowToElement from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitHowToElement/BUnitHowToElement';
import BUnitContext from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import {
  DeleteRoleFunc,
  RestoreRoleFunc,
  UpdateRoleFunc,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/rolesListHooks';
import styles from './BUnitRolesGroup.module.sass';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { HowToType } from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitCardHowToSection';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';

interface BUnitRolesGroupProps {
  rolesList: BUnitHowTo;
  deletedRoles: BUnitHowTo;
  updatedRoles: BUnitHowTo;
  updateRole: UpdateRoleFunc;
  deleteRole: DeleteRoleFunc;
  restoreRole: RestoreRoleFunc;
  isNew?: boolean;
  type: HowToType;
}

function BUnitRolesGroup({
  rolesList,
  updateRole,
  deleteRole,
  restoreRole,
  deletedRoles,
  updatedRoles,
  isNew = false,
  type,
}: BUnitRolesGroupProps) {
  const { units } = useAdminSelector(selectUnitsTreeData);
  const { id } = useContext(BUnitContext);
  const tNLS = useNLS();

  const filteredRolesId = useMemo(
    () => Object.keys(rolesList).filter((unitId) => unitId in units),
    [rolesList, units],
  );

  if (!filteredRolesId.length) {
    if (isNew) return null;
    return (
      <div className={styles['no-roles']}>
        {tNLS(type === HowToType.Objects
          ? 'tree.card.roles.out.noroles'
          : 'tree.card.roles.out.noroles')}
      </div>
    );
  }

  return (
    <>
      {filteredRolesId.map((unitId) => (
        <BUnitHowToElement
          key={`${id}-${unitId}-howto-card`}
          id={unitId}
          updateRole={updateRole}
          deleteRole={deleteRole}
          restoreRole={restoreRole}
          isDeleted={!!deletedRoles[unitId]}
          isUpdated={!!updatedRoles[unitId]}
          isNew={isNew}
          {...rolesList[unitId]}
        />
      ))}
    </>
  );
}

export default BUnitRolesGroup;