import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import styles from './WrapperGroupCheckboxesSetting.module.sass';
import generalStyles from '../settingsStyle.module.sass';
import AdminCheckbox from '@/components/App/Admin/UIElements/form/AdminCheckbox';
import { setAssessmentSettings } from '@/store/actions/admin/superset/settings';
import ProhibitInteractivity from '@/components/App/Admin/SupersetSettings/ProhibitInteractivity';

function WrapperGroupCheckboxesSetting({ setting }) {
  const dispatch = useDispatch();
  const { id: supersetID } = useParams();

  const { groupChildren, name, description } = setting;

  const onCheckSetting = (settingID, value) => {
    dispatch(
      setAssessmentSettings({
        supersetID,
        form: {
          settings: [
            {
              settingId: settingID,
              value: Number(value),
            },
          ],
        },
      }),
    );
  };

  return (
    <div
      className={`${generalStyles['wrap-admin-setting']} ${styles['wrap-wrapper-group-checkboxes']}`}
    >
      <div className={generalStyles['title-setting']}>{name}</div>
      <div className={generalStyles['description-setting']}>{description}</div>

      <div className={styles['wrap-checkboxes']}>
        {groupChildren.map((childSetting) => {
          const { settingId, cases, value } = childSetting;
          const { caseText } = cases[0];

          return (
            <ProhibitInteractivity key={settingId}>
              <AdminCheckbox
                label={caseText}
                id={`setting-${settingId}`}
                name={`blocks-${settingId}`}
                onChange={() => onCheckSetting(settingId, !value)}
                checked={!!value}
              />
            </ProhibitInteractivity>
          );
        })}
      </div>
    </div>
  );
}

WrapperGroupCheckboxesSetting.propTypes = {
  setting: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    groupChildren: PropTypes.arrayOf(
      PropTypes.shape({
        settingId: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        cases: PropTypes.arrayOf(
          PropTypes.shape({
            caseText: PropTypes.string,
            caseValue: PropTypes.number,
            cond: PropTypes.arrayOf(
              PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            ),
          }),
        ),
        cond: PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        ),
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    ),
  }).isRequired,
};

export default WrapperGroupCheckboxesSetting;