import { createSlice } from '@reduxjs/toolkit';
import {
  getCSRF,
  adminSignIn,
  getAdmin,
  adminLogout,
  updateAdmin,
  adminCreateNewPassword,
} from '@/store/actions/admin';
import { AuthenticationInitialState } from '@/store/types/admin/authentication';

const initialState: AuthenticationInitialState = {
  admin: null,
  status: 'idle',
  headers: null,
};

const authenticationSlice = createSlice({
  name: 'admin/authentication',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCSRF.fulfilled, (state, { payload }) => {
        state.headers = payload;
      })
      .addCase(adminSignIn.fulfilled, (state, { payload: admin }) => {
        state.admin = admin;
        state.status = 'received';
      })
      .addCase(adminSignIn.rejected, (state) => {
        state.status = 'rejected';
      })
      .addCase(getAdmin.fulfilled, (state, { payload }) => {
        state.admin = payload;
        state.status = 'received';
      })
      .addCase(getAdmin.rejected, (state) => {
        state.status = 'rejected';
      })
      .addCase(updateAdmin.fulfilled, (state, { payload: updatedAdmin }) => {
        state.admin = { ...state.admin, ...updatedAdmin };
      })
      .addCase(adminCreateNewPassword.fulfilled, (state) => {
        delete state.admin.newUser;
      })
      .addCase(adminLogout.fulfilled, () => initialState)
      .addMatcher(
        ({ type }) => type.endsWith('adminSignIn/pending')
          || type.endsWith('getAdmin/pending'),
        (state) => {
          state.status = 'loading';
        },
      );
  },
});

export default authenticationSlice.reducer;