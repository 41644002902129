import React, {
  ReactNode,
} from 'react';
import styles from './CustomButton.module.sass';
import { styleTypesToModuleClassNamesStr } from '@/helpers/helpers';

interface CustomButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'ref'> {
  typeBtnSubmit?: boolean
  styleTypeBtn?: string;
  classNames?: string
  onClick?: () => void;
  children: ReactNode;
}

const CustomButton = React.forwardRef(
  (
    {
      typeBtnSubmit = false, styleTypeBtn = 'btn-grey', children = null, classNames = '', onClick = () => {}, ...props
    }: CustomButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const moduleClassNamesTypes = styleTypesToModuleClassNamesStr(
      styleTypeBtn,
      styles,
    );

    return (
      <button
        type={typeBtnSubmit ? 'submit' : 'button'}
        className={`${styles['custom-button']} ${moduleClassNamesTypes} ${classNames} btn-without-style`}
        onClick={onClick}
        {...props}
        ref={ref}
      >
        {children}
      </button>
    );
  },
);

export default CustomButton;