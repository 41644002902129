import { AdminState } from '@/store/admin';

const getStoreContext = (state: AdminState) => state.admin.superset.process.cuts.fourD;

export const selectHasFourD = (state: AdminState) => (
  !!Object.keys(getStoreContext(state).ratings)
);

export const selectScoreType = (state: AdminState) => (
  getStoreContext(state).scoreType
);

export const selectRatings = (state: AdminState) => getStoreContext(state).ratings;

// by scoreType
export const selectRatingsResultsByScoreType = (state: AdminState) => {
  const { ratingsResults } = getStoreContext(state);
  const scoreType = selectScoreType(state);

  return ratingsResults.map((item) => {
    const { xPerc, yPerc } = item.scores[scoreType];

    return {
      id: item.id,
      xPerc,
      yPerc,
      size: item.size,
      color: item.color,
    };
  });
};

export const selectSelectedParams = (state: AdminState) => getStoreContext(state).selectedParams;

export const selectIsThereDataForDiagram = (state: AdminState) => !!getStoreContext(state).ratingsResults;

export const selectNameSelectedOfAxes = (state: AdminState) => {
  const { xRatingID, yRatingID } = selectSelectedParams(state);
  const ratings = selectRatings(state);

  const { name: xRatingName, source: XRatingSource } = ratings.find((rating) => rating.id === xRatingID);
  const { name: yRatingName, source: YRatingSource } = ratings.find((rating) => rating.id === yRatingID);

  return {
    xRatingDataName: { name: xRatingName, source: XRatingSource },
    yRatingDataName: { name: yRatingName, source: YRatingSource },
  };
};