import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { MAP_SOURS_NUMBER_TO_SOURCE_NLS } from '@/components/App/Admin/Results/Cuts/FourD/constants';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { Option } from '@/components/App/Admin/Results/Cuts/FourD/FourDParamsForm/FourDParamsForm';
import { Rating } from '@/store/types/admin/superset/cuts/fourD';

interface MapSourceToRatings {
  [key: string]: Option[]
}

const useTransformRatingToGroupedRatingsOptions = (ratings: Rating[]) => {
  const tNLS = useNLS();
  const [ratingOptions, setRatingOptions] = useState([]);

  useEffect(() => {
    if (ratings.length > 0) {
      const mapSourceToRatings: MapSourceToRatings = {};

      ratings.forEach(({ id, name, source }) => {
        const transformedRating = { label: name, value: id, sourceType: source };

        if (!(source in mapSourceToRatings)) {
          mapSourceToRatings[source] = [transformedRating];
        } else {
          mapSourceToRatings[source].push(transformedRating);
        }
      });

      const transformedGroupRatings = Object.keys(mapSourceToRatings).map((source) => {
        const headerSourceName = parse(
          tNLS(MAP_SOURS_NUMBER_TO_SOURCE_NLS[source].header),
        );
        return (
          {
            label: headerSourceName,
            options: mapSourceToRatings[source],
          }
        );
      });

      setRatingOptions(transformedGroupRatings);
    }
  }, [ratings, tNLS]);

  return ratingOptions;
};

export default useTransformRatingToGroupedRatingsOptions;