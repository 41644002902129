import React from 'react';
import styles from './BaseWrapper.module.sass';
import Footer from '@/components/Common/BaseTemplate/Footer';

interface BaseWrapperProps {
  children: React.ReactNode;
  header?: React.ReactNode;
}

function BaseWrapper({
  children,
  header,
}: BaseWrapperProps) {
  return (
    <div
      className={`${styles.wrapper} wrapper`}
    >
      {header}
      <main className={styles.main}>{children}</main>
      <Footer />
    </div>
  );
}

export default BaseWrapper;