import React from 'react';
import { useSelector } from 'react-redux';
import styles from './LinksForOtherSurveys.module.sass';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import CopyContentBtn from '@/components/Common/CopyContentBtn';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { getProjectLink } from '@/helpers/helpers';

const LINK_TYPE_TO_NLS_KEY = {
  leader: 'respondent.leader.link',
  leaderteam: 'respondent.leaderteam.link',
};

function LinksForOtherSurveys() {
  const tNLS = useNLS();
  const { webId, extra: extraLinks } = useSelector(selectSuperset);

  return (
    <div className={styles['wrap-links-other-surveys']}>
      <div className={styles['wrap-link-other-survey']}>
        {Object.keys(extraLinks).map((surveyType) => {
          const secret = extraLinks[surveyType];
          const surveyName = tNLS(LINK_TYPE_TO_NLS_KEY[surveyType]);
          const link = `${getProjectLink(webId)}/${surveyType}/${secret}`;

          return (
            <div key={surveyType} className={styles['wrap-link-other-survey']}>
              <div className={styles['wrap-info']}>
                <div className={styles.surveyType}>{surveyName}</div>
                <a
                  className={styles['link-to-start-survey']}
                  target="_blank"
                  href={link}
                  rel="noreferrer"
                >
                  {link}
                </a>
              </div>

              <CopyContentBtn
                externalClasses={{ wrapBtn: styles['btn-copy-link'] }}
                strToCopy={link}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default LinksForOtherSurveys;