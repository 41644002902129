// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dropdown-btn--Wvv79{display:flex;flex-direction:column;align-items:baseline;font-size:1rem;border-radius:0.5rem;text-align:left}.dropdown-btn--Wvv79 .job-title--k98aE{font-size:0.75rem;color:var(--secondary-color)}.dropdown-btn--Wvv79 .job-title--k98aE:hover{cursor:pointer;background:var(--admin-hover-background-interactive-elements)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitAddHowTo/BUnitAddHowToInput/BUnitHowToSearchDropdown/AddHowToSearchDropdownBtn/AddHowToSearchDropdownBtn.module.sass"],"names":[],"mappings":"AAEA,qBACE,YAAA,CACA,qBAAA,CACA,oBAAA,CACA,cAAA,CACA,oBAAA,CACA,eAAA,CACA,uCACE,iBAAA,CACA,4BAAA,CACA,6CACE,cAAA,CACA,6DAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown-btn": "dropdown-btn--Wvv79",
	"job-title": "job-title--k98aE"
};
export default ___CSS_LOADER_EXPORT___;
