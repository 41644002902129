import React from 'react';
import PropTypes from 'prop-types';
import styles from './ControlInputNumber.module.sass';
import './themeVars.sass';

const ControlInputNumber = React.forwardRef(({ externalClasses }, inputRef) => {
  const onChangeController = (e, typeChange) => {
    e.preventDefault();
    let currentInputValue = Number(inputRef.current.value);

    if (typeChange === 'increase') {
      currentInputValue += 1;
    }

    if (typeChange === 'decrease') {
      currentInputValue -= 1;
    }

    Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value',
    ).set.call(inputRef.current, currentInputValue);

    inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
    inputRef.current.focus();
  };

  return (
    <div
      className={`${styles['controller-input-number']} ${
        externalClasses.wrapController || ''
      }`}
    >
      <button
        type="button"
        className={`btn-without-style ${styles['btn-increase-ticket']}`}
        onClick={(e) => {
          onChangeController(e, 'increase');
        }}
        onMouseDown={(e) => e.preventDefault()}
      >
        {' '}
        <span className={`${styles['icon-control']} icon-plus`} />
      </button>

      <button
        type="button"
        className={`btn-without-style ${styles['btn-reduce-ticket']}`}
        onClick={(e) => {
          onChangeController(e, 'decrease');
        }}
        onMouseDown={(e) => e.preventDefault()}
      >
        {' '}
        <span className={`${styles['icon-control']} icon-minus`} />
      </button>
    </div>
  );
});

ControlInputNumber.defaultProps = {
  externalClasses: {},
};

ControlInputNumber.propTypes = {
  externalClasses: PropTypes.shape({
    wrapController: PropTypes.string,
  }),
};

export default ControlInputNumber;