// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-form--uELrz{position:relative}.wrap-form--uELrz .external-wrap-score-type-toggler--U1aNb{position:absolute;right:0}.wrap-form--uELrz .fourD-params-form--M0uwn .select-parameter--Fw2ks{margin-bottom:1.25rem;max-width:25rem}.wrap-form--uELrz .fourD-params-form--M0uwn .select-parameter--Fw2ks .wrap-base-select__group--EUpo1{display:flex;justify-content:space-between;align-items:center}.wrap-form--uELrz .fourD-params-form--M0uwn .select-parameter--Fw2ks .wrap-base-select__group--EUpo1 .base-select__group_options_length--IWFiC{display:flex;align-items:center;justify-content:center;font-size:12px;min-width:20px;height:15px;background:#fff;border-radius:15%;padding:0 2px}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/FourD/FourDParamsForm/FourDParamsForm.module.sass"],"names":[],"mappings":"AAEA,kBACE,iBAAA,CACA,2DACE,iBAAA,CACA,OAAA,CAGA,qEACE,qBAAA,CACA,eAAA,CACA,qGACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,+IACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,cAAA,CACA,WAAA,CACA,eAAA,CACA,iBAAA,CACA,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-form": "wrap-form--uELrz",
	"external-wrap-score-type-toggler": "external-wrap-score-type-toggler--U1aNb",
	"fourD-params-form": "fourD-params-form--M0uwn",
	"select-parameter": "select-parameter--Fw2ks",
	"wrap-base-select__group": "wrap-base-select__group--EUpo1",
	"base-select__group_options_length": "base-select__group_options_length--IWFiC"
};
export default ___CSS_LOADER_EXPORT___;
