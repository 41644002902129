import React from 'react';
import PropTypes, { shape } from 'prop-types';
import styles from './Checkmark.module.sass';
import { getClearClassNames } from '@/helpers/helpers';

function Checkmark({ externalClassNames }) {
  const { checkmarkStyle = '' } = externalClassNames;

  return (
    <div className={getClearClassNames([
      styles.checkmark,
      checkmarkStyle,
    ])}
    />
  );
}

Checkmark.defaultProps = {
  externalClassNames: {},
};

Checkmark.propTypes = {
  externalClassNames: shape({
    checkmarkStyle: PropTypes.string,
  }),
};

export default Checkmark;