import React from 'react';

import styles from './ArticleToolbar.module.sass';

interface ToolbarProps {
    addBlock: (item: ArticleTextBlock) => void;
}

export const Toolbar: React.FC<ToolbarProps> = ({ addBlock }) => {

    return (
        <section className={styles['article-toolbar-controls']}>
            <button onClick={() => addBlock({
                id: '',
                type: 'text',
                content: '',
            })}>Добавить текстовый блок</button>
        </section>
    );
};
