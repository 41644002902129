// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--wsBcr{position:relative}.container--wsBcr .information--kWxu1{display:flex;flex-direction:column;justify-content:space-between;width:100%;align-items:baseline;border-top:.5px solid var(--dark-grey-line-color);border-bottom:.5px solid var(--dark-grey-line-color);padding:0.3125rem 0.3125rem 2.1875rem 0.3125rem;transition:background-color .3s ease 0s}.container--wsBcr .information--kWxu1:last-child{border-bottom:none}.container--wsBcr .information--kWxu1:hover{background:var(--hover-content-block-bg)}.container--wsBcr .information--kWxu1 .main-info--JWpfT{display:flex;justify-content:space-between;align-items:center;width:100%}.container--wsBcr .information--kWxu1 .main-info--JWpfT .title--YA9Bh{color:var(--main-color);text-decoration:none;font-size:1.25rem;font-weight:500}.container--wsBcr .information--kWxu1 .main-info--JWpfT .title--YA9Bh::after{content:\"\";position:absolute;top:0;right:0;bottom:0;left:0}.container--wsBcr .information--kWxu1 .main-info--JWpfT .date--W0gvn{font-size:0.875rem;color:var(--secondary-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/AdminMainListLinkLink/AdminMainListLink.module.sass"],"names":[],"mappings":"AAEA,kBACE,iBAAA,CACA,sCACE,YAAA,CACA,qBAAA,CACA,6BAAA,CACA,UAAA,CACA,oBAAA,CACA,iDAAA,CACA,oDAAA,CACA,+CAAA,CACA,uCAAA,CACA,iDACE,kBAAA,CACF,4CACE,wCAAA,CACF,wDACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,UAAA,CACA,sEACE,uBAAA,CACA,oBAAA,CACA,iBAAA,CACA,eAAA,CACA,6EACE,UAAA,CACA,iBAAA,CACA,KAAA,CAAA,OAAA,CAAA,QAAA,CAAA,MAAA,CACJ,qEACE,kBAAA,CACA,4BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container--wsBcr",
	"information": "information--kWxu1",
	"main-info": "main-info--JWpfT",
	"title": "title--YA9Bh",
	"date": "date--W0gvn"
};
export default ___CSS_LOADER_EXPORT___;
