// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".admin-checkbox--FczKj input{-webkit-appearance:none;appearance:none;background-color:#fff;margin:0}.admin-checkbox--FczKj input:checked+label{background-color:var(--admin-checkbox-checked-bd);color:var(--admin-checkbox-checked-color)}.admin-checkbox--FczKj input:focus+label{outline:0.125rem solid var(--admin-outline-color)}.admin-checkbox--FczKj input:disabled:checked+label{cursor:not-allowed}.admin-checkbox--FczKj label{display:inline-flex;justify-content:center;align-items:center;font-size:0.875rem;height:2.4285714286em;padding:0 1.4285714286em;cursor:pointer;background:var(--admin-checkbox-bg);color:var(--admin-checkbox-color);border-radius:6.25rem;transition:background .3s ease 0s}.admin-checkbox--FczKj label:hover{background:var(--admin-checkbox-hover-bg)}.admin-checkbox--FczKj label:active{background:var(--admin-checkbox-bg)}.admin-checkbox--FczKj.not-interactive--i-C6l label{pointer-events:none}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/UIElements/form/AdminCheckbox/AdminCheckbox.module.sass"],"names":[],"mappings":"AAGE,6BACE,uBAAA,CAAA,eAAA,CACA,qBAAA,CACA,QAAA,CACA,2CACE,iDAAA,CACA,yCAAA,CACF,yCACE,iDAAA,CACF,oDACE,kBAAA,CAEJ,6BACE,mBAAA,CACA,sBAAA,CACA,kBAAA,CACA,kBAAA,CACA,qBAAA,CACA,wBAAA,CACA,cAAA,CACA,mCAAA,CACA,iCAAA,CACA,qBAAA,CACA,iCAAA,CACA,mCACE,yCAAA,CACF,oCACE,mCAAA,CAEF,oDACE,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin-checkbox": "admin-checkbox--FczKj",
	"not-interactive": "not-interactive--i-C6l"
};
export default ___CSS_LOADER_EXPORT___;
