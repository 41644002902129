import { createSlice } from '@reduxjs/toolkit';
import {
  changeAdminRole,
  clearSuperset,
  inviteAdmin,
  loadAllDataSuperset,
  loadFields,
  loadLanguages,
  loadSuperset,
  loadSupersetAdmins,
  setSupersetFields,
  setTicketsSuperset,
  setUnitTickets,
  unsetAdmin,
  updateSuperset,
  getStorageInfo,
  getMailTemplates,
  getSupersetEvents,
  hideSupersetEvent,
  setCurrentLanguageID,
  getMailTasks,
  clearMailTasks,
} from '@/store/actions/admin';
import { SupersetInitialState } from '@/store/types/admin/superset/superset';

const initialState: SupersetInitialState = {
  superset: {},
  languages: {},
  currentLangID: null,
  fields: {},
  events: {},
  files: {},
  admins: {},
  received: false,
  error: null,
  mailTasks: null,
  mailTemplates: {},
};

const supersetSlice = createSlice({
  name: 'admin/superset',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadAllDataSuperset.fulfilled, (state) => {
        state.received = true;
      })
      .addCase(loadSuperset.fulfilled, (state, { payload }) => {
        state.superset = payload;
      })
      .addCase(clearSuperset, () => initialState)
      .addCase(loadLanguages.fulfilled, (state, { payload }) => {
        state.languages = payload;
      })
      .addCase(setCurrentLanguageID, (state, { payload: langID }) => {
        state.currentLangID = langID;
      })
      .addCase(getMailTasks.fulfilled, (state, { payload }) => {
        state.mailTasks = payload;
      })
      .addCase(clearMailTasks, (state) => {
        state.mailTasks = null;
      })
      .addCase(loadFields.fulfilled, (state, { payload }) => {
        state.fields = payload;
      })
      .addCase(setSupersetFields.fulfilled, (state, { payload }) => {
        state.superset.fields = payload;
      })
      .addCase(updateSuperset.fulfilled, (state, { payload }) => {
        state.superset = { ...state.superset, ...payload };
      })
      .addCase(getStorageInfo.fulfilled, (state, { payload }) => {
        const { langs, processorFiles, data360 } = payload;
        const uploadedFiles = {};
        if (Object.keys(langs).length > 0) uploadedFiles.templates = { ...langs };
        if (processorFiles) {
          const processorFilesToObject = {};
          processorFiles.forEach((file) => {
            processorFilesToObject[file.filename] = file.modifyDate;
          });
          uploadedFiles.processorFiles = { ...processorFilesToObject };
        }
        if (data360?.filename) uploadedFiles.data360 = { ...data360 };
        state.files = { ...uploadedFiles };
      })
      .addCase(getSupersetEvents.fulfilled, (state, { payload }) => {
        const { langId, events } = payload;
        state.events[langId] = events;
      })
      .addCase(hideSupersetEvent.fulfilled, (state, { payload }) => {
        const { langId, eventId } = payload;
        state.events[langId] = [...state.events[langId]].filter(
          (event) => event.eventId !== eventId,
        );
      })
      .addCase(getMailTemplates.fulfilled, (state, { payload }) => {
        state.mailTemplates = payload;
      })
      .addCase(inviteAdmin.fulfilled, (state, { payload: invitedAdmin }) => {
        state.admins[invitedAdmin.adminID] = invitedAdmin;
      })
      .addCase(
        setTicketsSuperset.fulfilled,
        (state, { payload: ticketsCount }) => {
          state.superset.ticketCounter += ticketsCount;
        },
      )
      .addCase(
        setUnitTickets.fulfilled,
        (state, { payload: numberTicketsAdded }) => {
          state.units = state.units.map((unit) => ({
            ...unit,
            ticketsCount: unit.ticketsCount + numberTicketsAdded[unit.ID],
          }));
        },
      )
      .addCase(loadSupersetAdmins.fulfilled, (state, { payload: admins }) => {
        state.admins = admins;
      })
      .addCase(
        changeAdminRole.fulfilled,
        (state, { payload: { changedRole, adminID } }) => {
          state.admins[adminID].role = changedRole;
        },
      )
      .addCase(unsetAdmin.fulfilled, (state, { payload: adminId }) => {
        delete state.admins[adminId];
      });
  },
});

export default supersetSlice.reducer;