import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './CopyContentBtn.module.sass';
import { addNotification } from '@/store/reducers/notify';
import { copyStrToClipboard } from '@/helpers/helpers';

const CopyContentBtn = forwardRef(
  ({ externalClasses, strToCopy, message }, nodeForCopyContent) => {
    const dispatch = useDispatch();

    const copyNodeContentToClipboard = (node) => {
      if (!node.current) return;
      const text = node.current.textContent || node.current.innerText;

      copyStrToClipboard(text);

      if (!text) return;

      dispatch(
        addNotification({
          type: 'success',
          message,
        }),
      );
    };

    const copyContentToClipboard = (str) => {
      copyStrToClipboard(str);

      dispatch(
        addNotification({
          type: 'success',
          message,
        }),
      );
    };

    return (
      <button
        type="button"
        className={`${styles['btn-copy-content']}
       ${externalClasses ? externalClasses.wrapBtn : ''}
        btn-without-style`}
        onClick={() => {
          if (nodeForCopyContent) {
            copyNodeContentToClipboard(nodeForCopyContent);
            return;
          }

          if (strToCopy) {
            copyContentToClipboard(strToCopy);
          }
        }}
      >
        {' '}
        <span className={`${styles['icon-link']} icon-link`} />
      </button>
    );
  },
);

CopyContentBtn.defaultProps = {
  externalClasses: null,
  strToCopy: null,
  message: 'The link has been copied to the clipboard',
};

CopyContentBtn.propTypes = {
  externalClasses: PropTypes.shape({
    wrapBtn: PropTypes.string,
  }),
  strToCopy: PropTypes.string,
  message: PropTypes.string,
};

export default CopyContentBtn;