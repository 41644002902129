import React from 'react';
import styles from './FormUploadingResources.module.sass';
import {
  selectErrorMessageOnUploading,
  selectStatusIsResourceUploaded,
  selectTypeOfUploadingResources,
} from '@/store/selectors/admin/superset/projects';
import UploadResourcesForm from './UpploadResourcesForm/UploadResourcesForm';
import ResultUploading
  from '@/components/App/Admin/ListSupersets/ModalCreateSuperset/FormUploadingResources/ResultUploading/ResultUploading';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import {
  OnSuccessUploadingResources,
} from '@/components/App/Admin/ListSupersets/ModalCreateSuperset/ModalCreateSypersetContent/ModalCreateSupersetContent';

interface FormUploadingResourcesProps {
  onSuccess: OnSuccessUploadingResources
}

function FormUploadingResources({ onSuccess }: FormUploadingResourcesProps) {
  const typeOfResource = useAdminSelector(selectTypeOfUploadingResources);
  const isResourceUploaded = useAdminSelector(selectStatusIsResourceUploaded);
  const errorMessageOnUploading = useAdminSelector(selectErrorMessageOnUploading);

  return (
    <div className={styles['wrap-form-uploading-resources']}>

      {errorMessageOnUploading && (
        <div className={styles['error-message-on-uploading']}>{`${errorMessageOnUploading}`}</div>
      )}

      {!isResourceUploaded && (
      <UploadResourcesForm />
      )}

      {isResourceUploaded && (
        <ResultUploading typeOfResource={typeOfResource} onSucess={onSuccess} />
      )}
    </div>
  );
}

export default FormUploadingResources;