// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".selected-count--VPY4Q{font-size:16px}.selected-count--VPY4Q .number--3RkEH{margin-right:4px}.selected-count--VPY4Q .title--9iAkW{color:var(--admin-main-font-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/BUnitSelectMenuOptionsSelectors/SelectMenuTitle/SelectMenuTitle.module.sass"],"names":[],"mappings":"AAAA,uBACE,cAAA,CACA,sCACE,gBAAA,CACF,qCACE,kCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selected-count": "selected-count--VPY4Q",
	"number": "number--3RkEH",
	"title": "title--9iAkW"
};
export default ___CSS_LOADER_EXPORT___;
