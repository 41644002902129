import React, { Dispatch, SetStateAction, useContext } from 'react';
import { BUnitCardTabsTypes } from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCard';
import BUnitCardFields from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitCardFields';
import BUnitCardHowToSection, {
  HowToType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitCardHowToSection';
import BUnitContext from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';

interface BUnitCardContentProps {
  activeTabId: BUnitCardTabsTypes;
  isHowToLoaded: boolean;
  isUpdated: boolean;
  toggleUpdated: Dispatch<SetStateAction<boolean>>
}

function BUnitCardContent({
  activeTabId, isHowToLoaded, isUpdated, toggleUpdated,
}: BUnitCardContentProps) {
  const { id } = useContext(BUnitContext);
  if (activeTabId === BUnitCardTabsTypes.Objects) {
    return (
      <BUnitCardHowToSection
        toggleUpdated={toggleUpdated}
        key={`${id}-how-to-obj`}
        isLoaded={isHowToLoaded}
        type={HowToType.Objects}
      />
    );
  }

  if (activeTabId === BUnitCardTabsTypes.Subjects) {
    return (
      <BUnitCardHowToSection
        toggleUpdated={toggleUpdated}
        key={`${id}-how-to-subj`}
        isLoaded={isHowToLoaded}
        type={HowToType.Subjects}
      />
    );
  }

  return (
    <BUnitCardFields
      toggleUpdated={toggleUpdated}
      isUnitUpdated={isUpdated}
    />
  );
}

export default BUnitCardContent;