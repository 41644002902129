import { useEffect, useRef, useState } from 'react';

const useDropdown = () => {
  const [isOpen, toggleOpen] = useState(false);
  const dropdownContainerRef = useRef(null);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (!isOpen) return;
      if (dropdownContainerRef.current.contains(e.target)) return;
      toggleOpen(false);
    };

    if (isOpen) window.addEventListener('click', pageClickEvent);

    return () => window.removeEventListener('click', pageClickEvent);
  }, [isOpen]);

  return { isOpen, toggleOpen, dropdownContainerRef };
};

export default useDropdown;