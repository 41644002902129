import React from 'react';
import PropTypes from 'prop-types';
import styles from '../CustomSelect.module.sass';

export default function DropdownList({ children }) {
  return (
    <ul className={`${styles['dropdown-list']} ${styles['input-list']}`}>
      {children}
    </ul>
  );
}

DropdownList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

DropdownList.defaultProps = {};