// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-chart-section--4qFSi{position:relative;z-index:9;padding-top:1.875rem;gap:1.25rem}.wrap-chart-section--4qFSi .wrap-chart-title--ikCjO{position:relative;display:flex;justify-content:space-between;margin-bottom:80px}.wrap-chart-section--4qFSi .wrap-chart-title--ikCjO h2{width:70%}.wrap-chart-section--4qFSi .wrap-chart-title--ikCjO h2 .item-number--H1q9P{margin-right:0.625rem}.wrap-chart-section--4qFSi .wrap-chart-title--ikCjO .controls-chart--65xnq{display:flex;align-items:center;justify-content:right;align-self:self-start;width:30%;gap:1.25rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/ChartSection/ChartSection.module.sass"],"names":[],"mappings":"AAEA,2BACE,iBAAA,CACA,SAAA,CACA,oBAAA,CACA,WAAA,CACA,oDACE,iBAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,uDACE,SAAA,CACA,2EACE,qBAAA,CACJ,2EACE,YAAA,CACA,kBAAA,CACA,qBAAA,CACA,qBAAA,CACA,SAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-chart-section": "wrap-chart-section--4qFSi",
	"wrap-chart-title": "wrap-chart-title--ikCjO",
	"item-number": "item-number--H1q9P",
	"controls-chart": "controls-chart--65xnq"
};
export default ___CSS_LOADER_EXPORT___;
