import {
  useContext, useMemo,
} from 'react';
import { useForm } from 'react-hook-form';
import BUnitContext, { BUnitContextProps } from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import {
  CardFieldType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitCardSection/BUnitCardInput/BUnitCardInput';

export type BUnitCardFormFieldsKeys = typeof CardFieldType[keyof typeof CardFieldType];

export type BUnitCardFormFields = {
  name?: string;
  email?: string;
  jobTitle?: string;
};

const useBUnitCardForm = () => {
  const { unit } = useContext(BUnitContext) as Required<BUnitContextProps>;

  const defaultFormValues = useMemo<BUnitCardFormFields>(
    () => Object.values(CardFieldType).reduce(
      (acc, key) => ({ ...acc, [key]: unit[key] }),
      {},
    ) as BUnitCardFormFields,
    [unit],
  );

  const {
    register, handleSubmit, formState, reset,
  } = useForm<BUnitCardFormFields>({
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: defaultFormValues,
  });

  return {
    register, formState, handleSubmit, reset,
  };
};

export default useBUnitCardForm;