import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from './SectionAdmins.module.sass';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import useModal from '@/hooks/modal';
import { selectAdmins } from '@/store/selectors/admin/superset/supersets';
import ModalInviteAdmin from '@/components/App/Admin/SupersetSettings/SectionAdmins/ModalInviteAdmin';
import AdminItem from '@/components/App/Admin/SupersetSettings/SectionAdmins/AdminItem';
import ConfirmUnsetAdmin from '@/components/App/Admin/SupersetSettings/SectionAdmins/ConfirmUnsetAdmin';
import ConfirmChangeAdminRole from '@/components/App/Admin/SupersetSettings/SectionAdmins/ConfirmChangeAdminRole';
import {
  FULL_ADMIN,
  OBSERVER,
  OBSERVER_WITH_ACCESS_RESULTS,
} from '@/constants/admin';
import RequireRoleChangeAccess from '@/components/App/Admin/SupersetSettings/RequireRoleChangeAccess';
import ProhibitInteractivity from '@/components/App/Admin/SupersetSettings/ProhibitInteractivity';
import useNLS from '@/components/App/Admin/hooks/hooks';

function SectionAdmins() {
  const tNLS = useNLS();
  const { id: supersetID } = useParams();

  const {
    isShowingModal: isShowingModalInviteAdmin,
    toggleStatusShowingModal: toggleModalInviteAdmin,
  } = useModal();

  const {
    isShowingModal: isShowingConfirmUnsetAdmin,
    toggleStatusShowingModal: toggleConfirmUnsetAdmin,
    onShowingModalData: unsetAdminData,
  } = useModal();

  const {
    isShowingModal: isShowingConfirmChangeAdminRole,
    toggleStatusShowingModal: toggleConfirmChangeAdminRole,
    onShowingModalData: changedAdminRole,
  } = useModal();

  const admins = useSelector(selectAdmins);

  const adminRoleToColorIndicator = {
    0: 'red',
    1: 'yellow',
    2: 'green',
    3: 'blue',
  };

  const customOption = ({ id, label, role }) => (
    <div className={styles['admin-menu-item']}>
      {id === 'remove' ? (
        <span className={styles['admin-menu-item']}>
          <span className="icon-remove-light" />
          {tNLS('admin.delete')}
        </span>
      ) : (
        <span className={styles['admin-menu-item']}>
          <span
            className={`${styles['icon-admin-role']} ${
              styles[adminRoleToColorIndicator[role]]
            }`}
          />
          {label}
        </span>
      )}
    </div>
  );

  const adminItemMenu = [
    {
      id: 'remove',
      label: tNLS('admin.delete'),
    },
    {
      id: '1',
      label: tNLS('admin.full.access'),
      role: 1,
      value: FULL_ADMIN,
    },
    {
      id: '2',
      label: tNLS('admin.observer.with.results'),
      role: 2,
      value: OBSERVER_WITH_ACCESS_RESULTS,
    },
    {
      id: '3',
      label: tNLS('admin.observer.no.results'),
      role: 3,
      value: OBSERVER,
    },
  ];

  const onClickAdminMenuItem = (adminChangedRoleData) => {
    toggleConfirmChangeAdminRole(adminChangedRoleData);
  };

  return (
    <>
      <section className={`settings-section ${styles['admins-section']}`}>
        <h3 className="title-section">{tNLS('admin.list')}</h3>

        <div className={styles['list-admins']}>
          <RequireRoleChangeAccess>
            <CustomButton
              onClick={toggleModalInviteAdmin}
              styleTypeBtn="btn-grey btn-large"
            >
              {tNLS('add.admin')}
            </CustomButton>
          </RequireRoleChangeAccess>

          {Object.keys(admins).map((adminID) => {
            const { role } = admins[adminID];
            let currentRoleLable = '';
            const copyItemsMenu = [
              ...adminItemMenu.filter(({ value: valueItemRole, label }) => {
                if (valueItemRole !== role) return true;
                currentRoleLable = label;
                return false;
              }),
            ];

            copyItemsMenu[0] = {
              ...copyItemsMenu[0],
              onClickCurrentItem: () => {
                toggleConfirmUnsetAdmin(admins[adminID]);
              },
            };

            return (
              <ProhibitInteractivity key={adminID}>
                <AdminItem
                  admin={admins[adminID]}
                  adminItemMenu={copyItemsMenu}
                  customItem={customOption}
                  onClickAdminItemMenu={onClickAdminMenuItem}
                  adminRoleToColorIndicator={adminRoleToColorIndicator}
                  currentRoleLable={currentRoleLable}
                />
              </ProhibitInteractivity>
            );
          })}
        </div>

        {!Object.keys(admins).length && (
          <div className={styles['msg-no-admins']}>
            {tNLS('admin.no.available')}
          </div>
        )}
      </section>
      <ModalInviteAdmin
        toggleModal={toggleModalInviteAdmin}
        isShowingModal={isShowingModalInviteAdmin}
        supersetID={supersetID}
      />
      <ConfirmUnsetAdmin
        toggleModal={toggleConfirmUnsetAdmin}
        isShowingModal={isShowingConfirmUnsetAdmin}
        supersetID={supersetID}
        unsetAdminData={unsetAdminData}
      />
      <ConfirmChangeAdminRole
        toggleModal={toggleConfirmChangeAdminRole}
        isShowingModal={isShowingConfirmChangeAdminRole}
        supersetID={supersetID}
        adminChangedRoleData={changedAdminRole}
      />
    </>
  );
}

export default SectionAdmins;