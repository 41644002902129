import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DataCircle from 'src/components/App/Admin/Results/Cuts/FourD/Diagram/CirclesArea/DataCircle';
import { CONTAINER_PADDING, HEIGHT_OF_SVG_CONTAINER } from '@/components/App/Admin/Results/Cuts/FourD/constants';
import { RatingResultByScoreType } from '@/store/types/admin/superset/cuts/fourD';
import {
  selectRatingsResultsByScoreType,
} from '@/store/selectors/admin/superset/process/cuts/fourD';

interface DataCirclesAreaProps {
  svgContainerWidth: number;
}

const calcCirclesAreaWidth = (svgContainerWidth: number) => svgContainerWidth - (CONTAINER_PADDING.left + CONTAINER_PADDING.right);

const calcCirclesAreaHeight = () => HEIGHT_OF_SVG_CONTAINER - (CONTAINER_PADDING.bottom + CONTAINER_PADDING.top);

function CirclesArea({ svgContainerWidth }: DataCirclesAreaProps) {
  const ratingsResults: RatingResultByScoreType[] = useSelector(selectRatingsResultsByScoreType);
  const [circlesAreaWidth] = useState(calcCirclesAreaWidth(svgContainerWidth));
  const [circlesAreaHeight] = useState(calcCirclesAreaHeight());

  const renderCircles = (
    ratings: RatingResultByScoreType[],
    areaWidth: number,
    areaHeight: number,
  ) => ratings.map((ratingItem) => (
    <DataCircle
      key={ratingItem.id}
      ratingItem={ratingItem}
      circlesAreaWidth={areaWidth}
      circlesAreaHeight={areaHeight}
    />
  ));

  return (
    <g
      transform={`translate(${CONTAINER_PADDING.left}, ${CONTAINER_PADDING.top})`}
    >

      {renderCircles(ratingsResults, circlesAreaWidth, circlesAreaHeight)}
    </g>
  );
}

export default CirclesArea;