import React from 'react';
import PropTypes from 'prop-types';
import { CREATION } from '@/constants/admin';
import styles from './SupersetStageInfo.module.sass';

function SupersetStageInfo({ currentStage, useFeedback }) {
  if (currentStage === CREATION) {
    return 'Создание проекта';
  }

  const arrayStages = [
    { stage: 1, name: 'Setup' },
    { stage: 2, name: 'Testing' },
    { stage: 3, name: 'Poll' },
  ];
  if (useFeedback !== 1) {
    arrayStages.push({ stage: 4, name: 'Feedback' });
  }
  arrayStages.push({ stage: 5, name: 'Archive' });

  const lastIndx = arrayStages.length - 1;

  return (
    <div className={styles['superset-stage']}>
      {arrayStages.map(({ stage, name }, indxStage) => {
        let strStage = `${indxStage + 1}. ${name} `;
        if (indxStage !== lastIndx) {
          strStage = strStage.concat('→ ');
        }

        if (currentStage === stage) {
          return (
            <span key={name} className={styles['selected-stage']}>
              {strStage}
            </span>
          );
        }

        return strStage;
      })}
    </div>
  );
}

SupersetStageInfo.propTypes = {
  currentStage: PropTypes.number.isRequired,
  useFeedback: PropTypes.number.isRequired,
};

export default SupersetStageInfo;