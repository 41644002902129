import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Header from '@/components/Common/BaseTemplate/Header';
import { setGlobalDispatch } from '@/axiosConfig';
import ProgressContainer from '@/components/Common/BaseTemplate/ProgressContainer/ProgressContainer';
import BaseWrapper from '@/components/Common/BaseTemplate/BaseWrapper/BaseWrapper';

interface BaseTemplateProps {
  children: React.ReactNode;
  isAdmin?: boolean
}

function BaseTemplate({ children = null, isAdmin = false }: BaseTemplateProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    setGlobalDispatch(dispatch);
    // eslint-disable-next-line
  }, []);

  return (
    <BaseWrapper
      header={<Header isAdmin={isAdmin} />}
    >
      {!isAdmin && <ProgressContainer />}
      {children}
    </BaseWrapper>
  );
}

export default BaseTemplate;