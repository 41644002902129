// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".units-tree-header--6-oHp{border-bottom:1px solid var(--admin-main-font-color)}.header--QDMYv{margin-bottom:1.875rem}.header--QDMYv .company--Hpanz{font-size:1.25rem;color:var(--admin-main-font-color)}.header--QDMYv .name--U\\+8Zp{color:var(--title-font);font-size:1.75rem;font-weight:590;display:flex;justify-content:space-between;align-items:baseline}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/BUnitsSectionHeader/BUnitsSectionHeader.module.sass"],"names":[],"mappings":"AAEA,0BACE,oDAAA,CAEF,eACE,sBAAA,CACA,+BACE,iBAAA,CACA,kCAAA,CACF,6BACE,uBAAA,CACA,iBAAA,CACA,eAAA,CACA,YAAA,CACA,6BAAA,CACA,oBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"units-tree-header": "units-tree-header--6-oHp",
	"header": "header--QDMYv",
	"company": "company--Hpanz",
	"name": "name--U+8Zp"
};
export default ___CSS_LOADER_EXPORT___;
