// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dropdown-container--CwTCR{position:relative;display:flex;justify-content:space-between;border:1px solid var(--secondary-color);padding:0.125rem 0rem 0.125rem 0.5rem;border-radius:0.5rem;color:var(--main-color)}.dropdown-container--CwTCR button{font-size:1rem}.dropdown-container--CwTCR .search-input--4hPO7{font-size:1rem;width:100%;outline:none}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitAddHowTo/BUnitAddHowToInput/BUnitAddHowToInput.module.sass"],"names":[],"mappings":"AAEA,2BACE,iBAAA,CACA,YAAA,CACA,6BAAA,CACA,uCAAA,CACA,qCAAA,CACA,oBAAA,CACA,uBAAA,CACA,kCACE,cAAA,CACF,gDACE,cAAA,CACA,UAAA,CACA,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown-container": "dropdown-container--CwTCR",
	"search-input": "search-input--4hPO7"
};
export default ___CSS_LOADER_EXPORT___;
