// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notify--LEpLu{position:fixed;display:flex;right:0;cursor:pointer;width:20rem;box-shadow:0 2px 2px 2px rgba(0,0,0,.1);background:var(--content-block-bg);color:var(--main-color);border-radius:12px;padding:0.9375rem 0.625rem;min-height:60px;margin:10px 20px;box-sizing:border-box;z-index:100000}.notify--LEpLu.with-animation--IeJQP{animation:.5s ease showNotify--M\\+nzY}.notify--LEpLu .icon-noty--TwKFD{margin-right:1rem}.notify--LEpLu .icon-noty--TwKFD:before{font-size:1.625rem}.notify--LEpLu.error--hZfz\\+ .icon-noty--TwKFD:before{color:#eb5757}.notify--LEpLu.success--v39fO .icon-noty--TwKFD:before{color:#219653}.notify--LEpLu.info--Hm6bA .icon-noty--TwKFD:before{color:#2f80ed}.notify--LEpLu.warning--7eg3A .icon-noty--TwKFD:before{color:#f2994a}.notify--LEpLu .title--mhK-9{font-family:\"Inter SemiBold\",sans-serif;font-size:18px}.notify--LEpLu .message--8wvtc{font-size:0.875rem}@keyframes showNotify--M\\+nzY{0%{right:300px}100%{right:0}}", "",{"version":3,"sources":["webpack://./src/components/Common/Notify/notify.module.sass"],"names":[],"mappings":"AAEA,eACE,cAAA,CACA,YAAA,CACA,OAAA,CACA,cAAA,CACA,WAAA,CACA,uCAAA,CACA,kCAAA,CACA,uBAAA,CACA,kBAAA,CACA,0BAAA,CACA,eAAA,CACA,gBAAA,CACA,qBAAA,CACA,cAAA,CACA,qCACE,qCAAA,CACF,iCACE,iBAAA,CACA,wCACE,kBAAA,CAGA,sDACE,aAAA,CAGF,uDACE,aAAA,CAGF,oDACE,aAAA,CAGF,uDACE,aAAA,CACN,6BACE,uCAAA,CACA,cAAA,CACF,+BACE,kBAAA,CAEJ,8BACE,GACE,WAAA,CACF,KACE,OAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notify": "notify--LEpLu",
	"with-animation": "with-animation--IeJQP",
	"showNotify": "showNotify--M+nzY",
	"icon-noty": "icon-noty--TwKFD",
	"error": "error--hZfz+",
	"success": "success--v39fO",
	"info": "info--Hm6bA",
	"warning": "warning--7eg3A",
	"title": "title--mhK-9",
	"message": "message--8wvtc"
};
export default ___CSS_LOADER_EXPORT___;
