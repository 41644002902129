import { combineReducers } from '@reduxjs/toolkit';
import superset from './superset';
import supersetProjects from './projects';
import blocks from './blocks';
import settings from './settings';
import processReducers from './process';
import addingQuestions from './addingQuestions';
import unitsTree from './unitsTree';

const supersetReducers = combineReducers({
  supersetFields: superset,
  projects: supersetProjects,
  blocks,
  settings,
  process: processReducers,
  addingQuestions,
  unitsTree,
});

export default supersetReducers;