import {
  createAction,
  createAsyncThunk,
  nanoid,
  unwrapResult,
} from '@reduxjs/toolkit';
import axiosProcessing from '@/axiosConfig';
import { AdminState } from '@/store/admin';

export const loadLogs = createAsyncThunk(
  'logs/loadLogs',
  async (form: LoadLogsActionArgs, { getState, rejectWithValue }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;

      const response = await axiosProcessing.post<Log[]>('/api/admin/getLogs', form, {
        headers,
      });

      return response.data.map((log) => ({ ...log, id: nanoid() })).reverse();
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const loadLogsCurrentPage = createAsyncThunk(
  'logs/loadLogsCurrentPage',
  async (loadParams: LoadLogsActionArgs, { dispatch, rejectWithValue }) => {
    try {
      return unwrapResult(await dispatch(loadLogs(loadParams)));
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const loadLogsNextPage = createAsyncThunk(
  'logs/loadLogsNextPage',
  async (loadParams: LoadLogsActionArgs, { dispatch, rejectWithValue }) => {
    try {
      return unwrapResult(await dispatch(loadLogs(loadParams)));
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const loadLogsPrevPage = createAsyncThunk(
  'logs/loadLogsPrevPage',
  async (loadParams: LoadLogsActionArgs, { dispatch, rejectWithValue }) => {
    try {
      return unwrapResult(await dispatch(loadLogs(loadParams)));
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const setPrevPage = createAction('logs/setPrevPage');
export const setNextPage = createAction('logs/setNextPage');