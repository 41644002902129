import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import styles from '@/components/App/Admin/SupersetSettings/SectionTemplate/SectionTemplate.module.sass';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import useModal from '@/hooks/modal';
import ModalDeleteProject from '@/components/App/Admin/SupersetSettings/SectionDeleteProject/ModalDeleteProject';
import { getSupersetInfo } from '@/store/actions/admin';
import useNLS from '@/components/App/Admin/hooks/hooks';

function SectionDeleteProject() {
  const { supersetId } = useSelector(selectSuperset);
  const tNLS = useNLS();
  const dispatch = useDispatch();
  const getDeletingInfo = () => {
    dispatch(getSupersetInfo(supersetId))
      .then(unwrapResult)
      .catch(() => null);
  };
  const { isShowingModal, toggleStatusShowingModal } = useModal();

  return (
    <>
      <section className="settings-section">
        <h3 className="title-section">{tNLS('delete.project.title')}</h3>
        <form>
          <CustomButton
            styleTypeBtn="btn-grey btn-large"
            classNames={styles['btn-upload-project-template']}
            onClick={() => {
              getDeletingInfo();
              toggleStatusShowingModal();
            }}
          >
            {tNLS('delete.project')}
          </CustomButton>
        </form>
      </section>
      {isShowingModal && (
        <ModalDeleteProject toggleModal={toggleStatusShowingModal} />
      )}
    </>
  );
}

export default SectionDeleteProject;