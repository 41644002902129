import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import styles from './FileButton.module.sass';
import { getDateInFormat } from '@/helpers/helpers';
import FlagIcon from '@/components/Common/Elements/cssIcons/FlagIcon/FlagIcon';

function FileButton({ downloadFile, fileInfo }) {
  const extByType = useMemo(
    () => ({
      template: 'xlsx',
      personalReportsZip: 'zip',
      assessment: 'xlsx',
      '360results': 'xlsx',
      presentation: 'pdf',
      answers: 'xlsx',
    }),
    [],
  );

  const tooltipId = useMemo(
    () => `${fileInfo.fileName}-${fileInfo.fileDate}`,
    [fileInfo],
  );

  return (
    <>
      <Tooltip id={tooltipId} place="right" content={fileInfo.comment} />
      <button
        type="button"
        className={`${styles['file-button']} btn-without-style`}
        onClick={downloadFile}
        data-tooltip-id={tooltipId}
      >
        <span
          className={`${styles['file-icon']} icon-${
            extByType[fileInfo.fileType] || 'file-downloads'
          }`}
        />
        <div className={`${styles['download-button']}`}>
          <div className={styles['file-info']}>
            <span className={styles['file-name']}>{fileInfo.fileName}</span>
            <div className={styles['file-additional-info']}>
              {fileInfo.fileDate && (
                <span className={styles['file-date']}>
                  {getDateInFormat(fileInfo.fileDate, 14, true)}
                </span>
              )}
              {fileInfo.fileLang && (
                <FlagIcon
                  classNames={styles['lang-icon']}
                  langId={fileInfo.fileLang}
                />
              )}
            </div>
          </div>
        </div>
      </button>
    </>
  );
}

FileButton.propTypes = {
  downloadFile: PropTypes.func.isRequired,
  fileInfo: PropTypes.shape({
    fileLang: PropTypes.string,
    fileName: PropTypes.string,
    fileType: PropTypes.string,
    fileDate: PropTypes.string,
    fileExt: PropTypes.string,
    comment: PropTypes.string,
  }).isRequired,
};

export default FileButton;