// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".languages-section--SEgzB .list-languages--uuivn{display:flex;flex-wrap:wrap;gap:0.75rem;align-items:center}.languages-section--SEgzB .list-languages--uuivn .item-lang--hfhwl .wrap-lang-checkbox--FNbk1 label{height:auto;line-height:normal;line-height:initial;padding:0.3125rem 1.25rem 0.3125rem 0.3125rem;justify-content:flex-start;min-width:8.75rem}.languages-section--SEgzB .list-languages--uuivn .item-lang--hfhwl .wrap-lang-checkbox--FNbk1 label .label-text-lang-checkbox--9wEZn{display:flex;align-items:center;gap:0.625rem}.languages-section--SEgzB .list-languages--uuivn .item-lang--hfhwl .wrap-lang-checkbox--FNbk1 label .label-text-lang-checkbox--9wEZn .icon-lang--UIwzT{height:2.1875rem;border-radius:6.25rem;border:1px solid var(--dark-grey-line-color);padding:0.0625rem}.languages-section--SEgzB .list-languages--uuivn .item-lang--hfhwl .wrap-lang-checkbox--FNbk1 label .label-text-lang-checkbox--9wEZn .wrap-lang-info--FysRA .lang-label--QetyL{font-family:\"Inter SemiBold\",sans-serif}.languages-section--SEgzB .list-languages--uuivn .item-lang--hfhwl .wrap-lang-checkbox--FNbk1 label .label-text-lang-checkbox--9wEZn .wrap-lang-info--FysRA .lang-metrics--hRUo\\+{font-size:0.75rem;margin-top:0.1875rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SupersetSettings/SectionLanguages/SectionLanguages.module.sass"],"names":[],"mappings":"AAGE,iDACE,YAAA,CACA,cAAA,CACA,WAAA,CACA,kBAAA,CAGI,oGACE,WAAA,CACA,kBAAA,CAAA,mBAAA,CACA,6CAAA,CACA,0BAAA,CACA,iBAAA,CACA,qIACE,YAAA,CACA,kBAAA,CACA,YAAA,CACA,uJACE,gBAAA,CACA,qBAAA,CACA,4CAAA,CACA,iBAAA,CAEA,+KACE,uCAAA,CACF,kLACE,iBAAA,CACA,oBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"languages-section": "languages-section--SEgzB",
	"list-languages": "list-languages--uuivn",
	"item-lang": "item-lang--hfhwl",
	"wrap-lang-checkbox": "wrap-lang-checkbox--FNbk1",
	"label-text-lang-checkbox": "label-text-lang-checkbox--9wEZn",
	"icon-lang": "icon-lang--UIwzT",
	"wrap-lang-info": "wrap-lang-info--FysRA",
	"lang-label": "lang-label--QetyL",
	"lang-metrics": "lang-metrics--hRUo+"
};
export default ___CSS_LOADER_EXPORT___;
