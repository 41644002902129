import { createSlice } from '@reduxjs/toolkit';
import {
  changeOrderQuestions,
  createQuestion,
  deleteQuestion,
  editingQuestions,
  loadAddingQuestions,
  loadAnswersForAddingQuestions,
} from '@/store/actions/admin/superset/addingQuestions';

const initialState = {
  questions: [],
  answers: null,
};

const addingQuestionsSlice = createSlice({
  name: 'admin/superset/addingQuestions',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(
        loadAnswersForAddingQuestions.fulfilled,
        (state, { payload: answers }) => {
          state.answers = answers;
        },
      )
      .addCase(createQuestion.fulfilled, (state, { payload }) => {
        state.questions = [...state.questions, payload];
      })
      .addCase(
        loadAddingQuestions.fulfilled,
        (state, { payload: questions }) => {
          state.questions = questions;
        },
      )
      // .addCase(createQuestion.fulfilled, (state, action) => {
      // })
      .addCase(
        editingQuestions.fulfilled,
        (state, { payload: changedQuestions }) => {
          changedQuestions.forEach((changedQ) => {
            const foundIndx = state.questions.findIndex(
              ({ itemId }) => changedQ.itemId === itemId,
            );

            state.questions[foundIndx] = {
              ...state.questions[foundIndx],
              ...changedQ,
            };
          });
        },
      )
      .addCase(changeOrderQuestions.fulfilled, (state, { payload }) => {
        state.questions = payload.map((changedQ) => {
          const foundItem = state.questions[
            state.questions.findIndex(
              ({ itemId }) => changedQ.itemId === itemId,
            )
          ];

          return {
            ...changedQ,
            ...foundItem,
          };
        });
      })
      .addCase(
        deleteQuestion.fulfilled,
        (state, { payload: deletedQuestionID }) => {
          state.questions = state.questions.filter(
            ({ itemId }) => itemId !== deletedQuestionID,
          );
        },
      );
  },
});

export default addingQuestionsSlice.reducer;