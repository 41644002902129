import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axiosProcessing from '@/axiosConfig';
import {
  LoadAdminSettingsActionArgs,
  SetAssessmentSettingActionArgs,
  SupersetSettings,
} from '@/store/types/admin/superset/settings';
import { AdminState } from '@/store/admin';

export const loadAdminSettings = createAsyncThunk(
  'superset/settings/loadAdminSettings',
  async (form: LoadAdminSettingsActionArgs, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<SupersetSettings>(
        `/api/admin/getAssessmentSettings/${form.supersetId}`,
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const setAssessmentSettings = createAsyncThunk(
  'superset/settings/setAssessmentSettings',
  async ({ form, supersetID }: SetAssessmentSettingActionArgs, { getState, rejectWithValue }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;

      await axiosProcessing.put(
        `/api/admin/setAssessmentSettings/${supersetID}`,
        form,
        {
          headers,
        },
      );

      return form.settings[0];
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const setDownloadableSettings = createAction<SupersetSettings>(
  'superset/settings/setDownloadableSettings',
);