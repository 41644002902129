// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-slide-toggle-content--hTLqU .inner-slide-toggle-content--XA18U:before,.wrap-slide-toggle-content--hTLqU .inner-slide-toggle-content--XA18U:after{content:\"\";display:table}", "",{"version":3,"sources":["webpack://./src/components/Common/SlideToggleContent/SlideToggleContent.module.sass"],"names":[],"mappings":"AAEI,uJAEE,UAAA,CACA,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-slide-toggle-content": "wrap-slide-toggle-content--hTLqU",
	"inner-slide-toggle-content": "inner-slide-toggle-content--XA18U"
};
export default ___CSS_LOADER_EXPORT___;
