import React from 'react';
import styles from './LinkTabs.module.sass';
import './themeVars.sass';
import {
  ButtonTabInterface,
  LinkTabInterface,
  TabsType,
} from '@/components/App/Admin/UIElements/LinkTabs/types';
import LinkTab from '@/components/App/Admin/UIElements/LinkTabs/LinkTab/LinkTab';
import ButtonTab from '@/components/App/Admin/UIElements/LinkTabs/ButtonTab/ButtonTab';

type Tab<T extends TabsType> = T extends TabsType.Buttons ? ButtonTabInterface : LinkTabInterface;

interface LinkTabsProps<T extends TabsType> {
  externalClassNames?: {
    wrapLinkTabs?: string
    itemTabLink?: string
    itemLink?: string;
  };
  type?: TabsType ;
  children: Tab<T>[];
}

function LinkTabs<T extends TabsType>({ children: tabs, externalClassNames = {}, type = TabsType.Links }: LinkTabsProps<T>) {
  const { wrapLinkTabs = '', itemTabLink = '' } = externalClassNames;

  return (
    <div className={`${styles['wrap-link-tabs']} ${wrapLinkTabs}`}>
      <ul className={styles.tabs}>
        {tabs
          .filter(({ isHide }) => !isHide)
          .map((tab) => (
            <li
              key={tab.id}
              className={`
                ${styles['item-tab']} 
                ${itemTabLink} 
                `}
            >
              {type === TabsType.Links
                ? (
                  <LinkTab {...externalClassNames}>
                    {tab as Tab<TabsType.Links>}
                  </LinkTab>
                ) : (
                  <ButtonTab {...externalClassNames}>
                    {tab as Tab<TabsType.Buttons>}
                  </ButtonTab>
                )}
            </li>
          ))}
      </ul>
    </div>
  );
}

export default LinkTabs;