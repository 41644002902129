// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".templates-carousel--tKs-0{display:flex;margin:20px 0}.templates-carousel--tKs-0 .template-container--k3x45{height:60vh;width:60vw;overflow-y:scroll;padding:6px;border:1px solid var(--secondary-color)}.select-template-section--x6me-{font-size:1.125rem;width:fit-content;height:fit-content;display:flex;align-items:baseline}.select-button--anHOT{height:32px;width:20rem;margin:4px 0 4px 8px}.select-option--murfX{height:32px}.templates-selector--pFYYn{display:flex;justify-content:space-around;align-items:center;margin:6px auto}.templates-selector--pFYYn .select-template-btn--tj-pj{width:14px;height:14px;border-radius:50%;background:var(--secondary-color)}.templates-selector--pFYYn .select-template-btn--tj-pj.active--ON9sI{background:#2f80ed}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitEmailModal/BUnitTemplatesContent/BUnitTemplatesContent.module.sass"],"names":[],"mappings":"AAIA,2BACE,YAAA,CACA,aAAA,CACA,sDACE,WAAA,CACA,UAAA,CACA,iBAAA,CACA,WAAA,CACA,uCAAA,CAEJ,gCACE,kBAAA,CACA,iBAAA,CACA,kBAAA,CACA,YAAA,CACA,oBAAA,CAEF,sBACE,WAAA,CACA,WAAA,CACA,oBAAA,CACF,sBACE,WAAA,CAEF,2BACE,YAAA,CACA,4BAAA,CACA,kBAAA,CAGA,eAAA,CACA,uDACE,UAlCa,CAmCb,WAnCa,CAoCb,iBAAA,CACA,iCAAA,CACA,qEACE,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"templates-carousel": "templates-carousel--tKs-0",
	"template-container": "template-container--k3x45",
	"select-template-section": "select-template-section--x6me-",
	"select-button": "select-button--anHOT",
	"select-option": "select-option--murfX",
	"templates-selector": "templates-selector--pFYYn",
	"select-template-btn": "select-template-btn--tj-pj",
	"active": "active--ON9sI"
};
export default ___CSS_LOADER_EXPORT___;
