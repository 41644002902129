// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".select-button--ZgLjO{height:32px;margin:0 0 1.25rem 0}.select-option---BwtZ{height:32px}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitEmailModal/BUnitEmailModalSelect.module.sass"],"names":[],"mappings":"AAEA,sBACE,WAAA,CACA,oBAAA,CACF,sBACE,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select-button": "select-button--ZgLjO",
	"select-option": "select-option---BwtZ"
};
export default ___CSS_LOADER_EXPORT___;
