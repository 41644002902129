import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import styles from './CreateWebinarInput.module.sass';
import { CreateWebinarFieldsType, CreateWebinarFielsNamesEnum } from '../../../../types/webinarPageTypes';
import { getClearClassNames } from '@/helpers/helpers';
import { WebinarInputTypeByName } from '../../../../consts/createWebinarPageConsts';

interface SelectWebinarDateInputProps {
  name: CreateWebinarFielsNamesEnum;
  register: UseFormRegister<CreateWebinarFieldsType>;
}

export default function CreateWebinarInput({
  name, register,
}: SelectWebinarDateInputProps) {
  return (
    <input
      className={getClearClassNames([
        styles.input,
        name === CreateWebinarFielsNamesEnum.Date && styles['date-input'],
        styles[WebinarInputTypeByName[name]],
      ])}
      id={`webinar-${name}`}
      type={WebinarInputTypeByName[name]}
      {...register(name)}
    />
  );
}