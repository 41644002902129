import React from 'react';
import '../DepartmentUnitControlByType.sass';
import NewBUnitButton from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/NewBUnitButton/NewBUnitButton';
import { ToggleOpenType } from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/DepartmentUnit/DepartmentUnit';

interface DepartmentUnitButtonProps {
  toggleOpen: ToggleOpenType;
}

function DepartmentUnitButton({ toggleOpen }: DepartmentUnitButtonProps) {
  const handleClick = () => {
    toggleOpen();
  };

  return (
    <NewBUnitButton
      className="department-name"
      onClick={handleClick}
    />
  );
}

export default DepartmentUnitButton;