import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import useSuperset from '@/components/App/Admin/SupersetSettings/useSuperset';
import Preloader from '@/components/App/Admin/SupersetSettings/Preloader';
import { selectLoadedStatusAllSupersetData } from '@/store/selectors/admin/superset/supersets';

function LoadSuperset({ children: pageContent }) {
  useSuperset();
  const haveReceivedData = useSelector(selectLoadedStatusAllSupersetData);

  if (!haveReceivedData) {
    return <Preloader />;
  }

  return pageContent;
}

LoadSuperset.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LoadSuperset;