// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-radio-block-settings--XWWsA .wrap-options--jb-4g{margin-top:1.25rem}.wrap-radio-block-settings--XWWsA .wrap-options--jb-4g .option-text-label--tyxgD{line-height:1.5625rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SupersetSettings/SectionAdminSettings/RadioBlockSettings/RadioBlockSettings.module.sass"],"names":[],"mappings":"AAGE,uDACE,kBAAA,CACA,iFACE,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-radio-block-settings": "wrap-radio-block-settings--XWWsA",
	"wrap-options": "wrap-options--jb-4g",
	"option-text-label": "option-text-label--tyxgD"
};
export default ___CSS_LOADER_EXPORT___;
