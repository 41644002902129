import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ProhibitInteractivity from '@/components/App/Admin/SupersetSettings/ProhibitInteractivity';
import EditableResizeableInput from '@/components/App/Admin/UIElements/form/EditableResizeableInput';
import styles from '@/components/App/Admin/SupersetSettings/SectionHeaderFields/SectionHeaderFields.module.sass';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';

function SupersetCompanyInput({ iconPencil, register }) {
  const tNLS = useNLS();
  const { company } = useSelector(selectSuperset);

  return (
    <ProhibitInteractivity>
      <EditableResizeableInput
        id="superset-company"
        name="company"
        defaultValue={company}
        icon={iconPencil}
        externalClasses={{
          wrapField: `${styles['admin-resize-input']} ${styles['wrap-company-field']}`,
        }}
        register={register}
        placeholder={tNLS('company.name')}
      />
    </ProhibitInteractivity>
  );
}

SupersetCompanyInput.propTypes = {
  iconPencil: PropTypes.element.isRequired,
  register: PropTypes.func.isRequired,
};

export default SupersetCompanyInput;