// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".units-tree--ppl6g{position:relative}.no-actors-list--z0HRj{position:relative;margin:0 0 12px 12px}.no-actors-list--z0HRj.without-parent--l6bmH{margin:0}.no-actors-list--z0HRj.with-line--EHDlq:before{content:\"\";position:absolute;width:0;left:-7px;bottom:6px;height:calc(100% + 26px);border-left:1px solid #838999}.no-actors-list--z0HRj.first-level--mk5fj{margin:0 0 12px 0}.no-actors-list--z0HRj.first-level--mk5fj:before{left:-5px}.search-units-section--sR0RI{align-items:center;justify-content:center;margin:10px 12px}.search-units-section--sR0RI .search-button--f3\\+K3{width:40px;height:40px;border:none;border-radius:8px;background:#2279fc}.search-units-section--sR0RI .search-units-input--mpq48{height:40px;width:300px;margin:0 10px 0 0;padding:4px;outline:none;font-size:16px;color:var(--unit-tree-main-color);border:.5px solid var(--secondary-color);border-radius:8px}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/UnitsTree.module.sass"],"names":[],"mappings":"AAAA,mBACE,iBAAA,CAEF,uBACE,iBAAA,CAGA,oBAAA,CAGA,6CACE,QAAA,CAEA,+CACE,UAAA,CACA,iBAAA,CACA,OAAA,CACA,SAAA,CACA,UAAA,CACA,wBAAA,CACA,6BAAA,CACJ,0CACE,iBAAA,CACA,iDACE,SAAA,CAIN,6BACE,kBAAA,CACA,sBAAA,CACA,gBAAA,CACA,oDACE,UAAA,CACA,WAAA,CACA,WAAA,CACA,iBAAA,CACA,kBAAA,CACF,wDACE,WAAA,CACA,WAAA,CACA,iBAAA,CACA,WAAA,CACA,YAAA,CACA,cAAA,CACA,iCAAA,CACA,wCAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"units-tree": "units-tree--ppl6g",
	"no-actors-list": "no-actors-list--z0HRj",
	"without-parent": "without-parent--l6bmH",
	"with-line": "with-line--EHDlq",
	"first-level": "first-level--mk5fj",
	"search-units-section": "search-units-section--sR0RI",
	"search-button": "search-button--f3+K3",
	"search-units-input": "search-units-input--mpq48"
};
export default ___CSS_LOADER_EXPORT___;
