import { FilesPlaces } from '../types/webinarFiles';

export const WebinarFilesTitlesByName = {
  [FilesPlaces.Banner]: 'Баннер',
  [FilesPlaces.Video]: 'Видео',
  [FilesPlaces.Logo]: 'Лого',
  [FilesPlaces.Speaker]: 'Спикер',
};

export const WebinarFilesInputByName = [
  FilesPlaces.Banner,
  FilesPlaces.Video,
  FilesPlaces.Logo,
  FilesPlaces.Speaker,
];

export const ExtByName = {
  [FilesPlaces.Banner]: [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/tiff',
    'image/svg+xml',
  ],
  [FilesPlaces.Video]: ['video/mp4'],
  [FilesPlaces.Logo]: [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/tiff',
    'image/svg+xml',
  ],
  [FilesPlaces.Speaker]: [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/tiff',
    'image/svg+xml',
  ],
};