import { createAsyncThunk } from '@reduxjs/toolkit';
import { protocolsSlice } from '@/store/reducers/user/protocols';
import axiosProcessing from '@/axiosConfig';

export const getPages = createAsyncThunk(
  'actor/getPages',
  async (data: GetPagesActionArgs, { rejectWithValue }) => {
    const {
      projectId, qType, secret, isFinish,
    } = data;
    try {
      const response = await axiosProcessing.get<GetPagesResponse>(
        `/api/actor/go/${projectId}/page/${qType}/${secret}`,
      );
      return { response: response.data, isFinish };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getResult = createAsyncThunk(
  'actor/result',
  async (data, { rejectWithValue }) => {
    const {
      // @ts-ignore
      projectId, empCode, lang, fsNo, normSet, fish,
    } = data;
    try {
      const response = await axiosProcessing.get(
        `/api/actor/result/${projectId}/${empCode}/${fsNo}/${normSet}/${fish}/${lang}`,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const changeCurrentPage = createAsyncThunk(
  'actor/protocols/changeCurrentPage',
  async (data: ChangeCurrentPagePayload, { rejectWithValue, dispatch }) => {
    dispatch(
      protocolsSlice.actions.toggleUserActionOnPage({ newValue: false }),
    );
    try {
      await axiosProcessing.put('/api/actor/auth', {
        currentPage: `${data.currentPage}`,
      });
      return { currentPage: data.currentPage };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getBlocks = createAsyncThunk(
  'actor/getBlocks',
  async ({ projectId }: GetBLocksPayload, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<Blocks>(`/api/actor/block/${projectId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getCustomQuestions = createAsyncThunk(
  'actor/getCustomQuestions',
  async (data, { rejectWithValue }) => {
    try {
      // @ts-ignore
      const { teamCode, langId, pageIndex } = data;
      const response = await axiosProcessing.get(
        `/api/actor/go/${teamCode}/${langId}/customQuestions`,
      );
      return { questions: response.data, pageIndex };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);