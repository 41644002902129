import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  selectAreThereSeveralTypesOfFS,
  selectFactorsCuts,
  selectFactorsCutsByItemID,
  selectHasFSWithLowPoles,
} from '@/store/selectors/admin/superset/process/cuts/factorsCuts';
import styles from './FactorsCuts.module.sass';
import FSswitcher from '@/components/App/Admin/Results/Cuts/FactorsCuts/FSswitcher';
import { PERCENTILE } from '@/components/App/Admin/Results/Cuts/constants';
import PercentileFS from '@/components/App/Admin/Results/Cuts/FactorsCuts/PercentileFS';
import AbsoluteFS from '@/components/App/Admin/Results/Cuts/FactorsCuts/AbsoluteFS';
import useSetTypeFS from '@/components/App/Admin/Results/Cuts/FactorsCuts/hooks';
import Preloader from '@/components/App/Admin/SupersetSettings/Preloader';
import TypeFSToggler from '@/components/App/Admin/Results/Cuts/FactorsCuts/TypeFSToggler';
import { getClearClassNames } from '@/helpers/helpers';

function FactorsCuts() {
  const { itemID: fsIndx } = useParams();
  const areThereSeveralTypesOfFS = useSelector((state) => selectAreThereSeveralTypesOfFS(state, fsIndx));
  const countFS = useSelector(selectFactorsCuts).length;
  const typeFS = useSetTypeFS();
  const { fsName } = useSelector((state) => selectFactorsCutsByItemID(state, fsIndx));
  const hasFSWithLowPoles = useSelector(selectHasFSWithLowPoles);

  if (!typeFS) {
    return <Preloader />;
  }

  return (
    <div className={styles['wrap-fs']}>
      <div className={styles['header-fs']}>
        {countFS > 1 && <FSswitcher />}

        {areThereSeveralTypesOfFS && <TypeFSToggler typeFS={typeFS} />}
      </div>

      <h3 className={styles['fs-name']}>{fsName}</h3>

      <div
        className={getClearClassNames([
          styles['wrap-list-of-factors'],
          hasFSWithLowPoles && styles['has-fs-with-low-poles'],
        ])}
      >
        {typeFS === PERCENTILE ? <PercentileFS /> : <AbsoluteFS />}
      </div>
    </div>
  );
}

export default FactorsCuts;