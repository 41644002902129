import { PlayerState } from '@/store/player';
import { selectActorProject } from '@/store/selectors/user/actor';

export const selectProjectName = (state: PlayerState) => {
  const projects = state.user.projects.projectMap;
  const { actor } = state.user;
  if (!actor.project) return projects[Object.keys(projects)[0]]?.title;
  return actor.project.title;
};

export const selectProjectSettings = (state: PlayerState) => {
  const actorProject = selectActorProject(state);

  if (!actorProject) return { settings: {}, stage: '' };

  const { settings, stage } = actorProject;

  return { settings, stage };
};

export const selectProjectLangId = (state: PlayerState) => {
  const { project } = state.user.actor;

  if (!project) return 'en';

  const { langId } = project;

  return langId || 'en';
};