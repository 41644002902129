// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header--HzoWe{position:sticky;width:100%;background:var(--main-bg);z-index:1000;top:0;transition:.5s;margin-bottom:50px}@media screen and (max-width: 768px){.header--HzoWe{position:static;margin-bottom:10px}}.header--HzoWe .header-content--dlFvs{position:relative;display:flex;max-width:95%;margin:0 auto;padding:0 0.9375rem;align-items:center;height:var(--header-height)}@media screen and (max-width: 425px){.header--HzoWe .header-content--dlFvs{min-height:0;justify-content:space-between}}.is-dev--iDmOA{position:absolute;padding:4px 8px;background:#3ccc76;text-align:center;font-size:16px;color:#fff;top:50px}.is-dev--iDmOA.local--SjzWH{background:#2f80ed}", "",{"version":3,"sources":["webpack://./src/components/Common/BaseTemplate/Header/HeaderContainer/HeaderContainer.module.sass"],"names":[],"mappings":"AAGA,eACE,eAAA,CACA,UAAA,CACA,yBAAA,CACA,YAAA,CACA,KAAA,CACA,cAAA,CACA,kBAAA,CACA,qCARF,eASI,eAAA,CACA,kBAAA,CAAA,CACF,sCACE,iBAAA,CACA,YAAA,CACA,aAAA,CACA,aAAA,CACA,mBAAA,CACA,kBAAA,CACA,2BAAA,CACA,qCARF,sCASI,YAAA,CACA,6BAAA,CAAA,CAEN,eACE,iBAAA,CACA,eAAA,CACA,kBAAA,CACA,iBAAA,CACA,cAAA,CACA,UAAA,CACA,QAAA,CACA,4BACE,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header--HzoWe",
	"header-content": "header-content--dlFvs",
	"is-dev": "is-dev--iDmOA",
	"local": "local--SjzWH"
};
export default ___CSS_LOADER_EXPORT___;
