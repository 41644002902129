import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import Input from '@/components/Common/form/Input';
import styles from './ChangePassword.module.sass';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import { changeAdminPassword } from '@/store/actions/admin';

function ChangePassword() {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({ mode: 'onBlur' });

  const onChangePassword = (formData) => {
    dispatch(changeAdminPassword(formData))
      .then(unwrapResult)
      .then(() => {
        reset();
      })
      .catch(() => null);
  };

  return (
    <section className={`admin-profile-section ${styles['password-section']}`}>
      <h3 className="title-section">Change Password</h3>

      <form
        onSubmit={handleSubmit(onChangePassword)}
        className={styles['form-change-password']}
      >
        <Input
          register={register}
          registerOptions={{
            required: true,
          }}
          id="admin-old-password"
          name="oldPassword"
          label="Enter the current password:"
          type="password"
          externalClasses={{ label: styles['label-field'] }}
        />

        <Input
          register={register}
          id="admin-new-password"
          registerOptions={{
            required: true,
          }}
          name="password"
          label="Enter a new password:"
          type="password"
          externalClasses={{ label: styles['label-field'] }}
        />

        <CustomButton
          styleTypeBtn="btn-blue btn-large"
          typeBtnSubmit
          disabled={!isValid}
        >
          Change Password
        </CustomButton>
      </form>
    </section>
  );
}

export default ChangePassword;