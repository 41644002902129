import React from 'react';
import { usePlayerSelector } from '@/store/hooks/playerRedux';
import styles from '@/components/Common/BaseTemplate/Header/Header.module.sass';

function DemoModeInfo() {
  const { isDemo } = usePlayerSelector((state) => state.user.actor);

  if (!isDemo) return null;

  return (
    <div className={styles['demo-mode']}>
      <span>Demo-mode</span>
    </div>
  );
}

export default DemoModeInfo;