import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Импортируем стили
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import styles from './CreateWebinarTextArea.module.sass';
import { getClearClassNames } from '@/helpers/helpers';
import { CreateWebinarFieldsType, CreateWebinarFielsNamesEnum } from '../../../../types/webinarPageTypes';

interface CreateWebinarTextareaProps {
  name: CreateWebinarFielsNamesEnum;
  setValue: UseFormSetValue<CreateWebinarFieldsType>;
  watch: UseFormWatch<CreateWebinarFieldsType>;
}

function CreateWebinarTextarea({
  name, setValue, watch,
}: CreateWebinarTextareaProps) {
  const editorHtml = watch(name) || '';

  const handleChange = (html: string) => {
    setValue(name, html);
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: [2, 3, 4, 5, false] }],
        ['bold', 'italic', 'underline'],
        ['link'],
        ['code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    },
  };

  return (
    <div className={styles['text-area']}>
      <ReactQuill
        id={`${name}-webinar`}
        value={editorHtml as string}
        onChange={handleChange}
        modules={modules}
        placeholder="Введите текст..."
        className={`${getClearClassNames([styles['text-editor'], styles[name]])}`}
      />
    </div>
  );
}

export default CreateWebinarTextarea;