import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import styles from './GeneralSection.module.sass';
import Input from '@/components/Common/form/Input';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import { selectAdmin } from '@/store/selectors/admin/authentication';
import { updateAdmin } from '@/store/actions/admin';

function GeneralSection() {
  const dispatch = useDispatch();
  const admin = useSelector(selectAdmin);
  const {
    adminId, name, email, company,
  } = admin;
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isDirty, isSubmitSuccessful },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      adminId,
      name,
      email,
      company,
    },
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ adminId });
    }
  }, [isSubmitSuccessful, reset, adminId]);

  const onSaveAdminProfile = (profileValues) => {
    dispatch(updateAdmin(profileValues));
  };

  return (
    <section className={`admin-profile-section ${styles['general-section']}`}>
      <h3 className="title-section">General settings</h3>
      <form
        className={styles['form-admin-profile']}
        onSubmit={handleSubmit(onSaveAdminProfile)}
      >
        <Input
          register={register}
          id="admin-name"
          name="name"
          label="Your name:"
          externalClasses={{ label: styles['label-field'] }}
        />
        <Input
          register={register}
          registerOptions={{
            required: true,
          }}
          id="admin-email"
          name="email"
          type="email"
          label="Your email:"
          externalClasses={{ label: styles['label-field'] }}
        />
        <Input
          register={register}
          id="admin-company"
          name="company"
          label="Company where you work:"
          externalClasses={{ label: styles['label-field'] }}
        />

        <CustomButton
          styleTypeBtn="btn-blue btn-large"
          typeBtnSubmit
          disabled={!isValid || !isDirty}
        >
          Save changes
        </CustomButton>
      </form>
    </section>
  );
}

export default GeneralSection;