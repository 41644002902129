import React from 'react';
import styles from './SuccessUploadingTemplateInfo.module.sass';
import UploadingErrorsAndWarns
  from '@/components/App/Admin/ListSupersets/ModalCreateSuperset/FormUploadingResources/ResultUploading/ResultUploadingTemplates/UploadingErrorsAndWarns/UploadingErrorsAndWarns';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectUploadResourcesInfo } from '@/store/selectors/admin/superset/projects';

interface SuccessUploadingTemplateInfoProps {
  inputValue: string;
  setInputValue: (arg: string) => void;
}

function SuccessUploadingTemplateInfo({ inputValue, setInputValue }: SuccessUploadingTemplateInfoProps) {
  const tNLS = useNLS();
  const { warns } = useAdminSelector(selectUploadResourcesInfo);
  return (
    <>
      <div className={styles['title-success']}>
        {warns.length === 0
          ? tNLS('template.upload.success')
          : tNLS('template.upload.warnings.descr')}
      </div>
      <UploadingErrorsAndWarns content={warns} />
      <div className={styles['comment-field']}>
        <label htmlFor="comment-file">{'Comment: '}</label>
        <input
          id="comment-file"
          value={inputValue}
          className={`input-without-style ${styles.input}`}
          onChange={(e) => setInputValue(e.target.value)}
          type="text"
        />
      </div>
    </>
  );
}

export default SuccessUploadingTemplateInfo;