import React, { Dispatch, SetStateAction, useState } from 'react';
import styles from './EmployeeUnitFields.module.sass';
import BUnitCheckBox from './BUnitCheckBox/BUnitCheckBox';
import NewBUnitButton from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/NewBUnitButton/NewBUnitButton';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';
import BUnitContainer from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContainer/BUnitContainer';

interface EmployeeUnitFieldsProps {
  isCardOpen: boolean;
  toggleCard: Dispatch<SetStateAction<boolean>>
}

function EmployeeUnitFields({ toggleCard, isCardOpen }: EmployeeUnitFieldsProps) {
  const checkPermission = useCheckPermission();
  const [isShowMenu, toggleMenu] = useState(false);

  return (
    <BUnitContainer
      isCardOpen={isCardOpen}
      toggleMenu={toggleMenu}
      isShowMenu={isShowMenu}
    >
      {checkPermission('group_operations') && <BUnitCheckBox />}
      <NewBUnitButton
        className={styles['employee-button']}
        onClick={() => toggleCard((prevState) => !prevState)}
      />
    </BUnitContainer>
  );
}

export default EmployeeUnitFields;