import React from 'react';
import { FieldValues, UseFormReturn, Path } from 'react-hook-form';
import styles from './FilesForUploadList.module.sass';

interface FilesForUploadListProps<T extends FieldValues> extends UseFormReturn<T> {
  name: Path<T>;
}
export default function FilesForUploadList<FormValues extends FieldValues>({
  name, ...formProps
}: FilesForUploadListProps<FormValues>) {
  const { watch, setValue } = formProps;

  const existingFiles: ReadonlyArray<File> = watch(name) || [];

  const deleteFileFromList = (fileName: string) => {
    // @ts-ignore
    setValue(name, existingFiles.filter((file) => fileName !== file.name));
  };

  if (!existingFiles.length) return null;
  return (
    <ul className={styles['files-list']}>
      {existingFiles.map((file) => (
        <li
          key={file.name}
          className={styles.file}
        >
          <span>{file.name}</span>
          <button
            type="button"
            onClick={() => deleteFileFromList(file.name)}
          >
            {' '}
            <span className="icon-remove-light" />
          </button>
        </li>
      ))}
    </ul>
  );
}