import React from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import styles from './UploadingTemplateButtons.module.sass';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import { clearUploadResourcesInfo, createProject } from '@/store/actions/admin/superset/projects';
import { addNotification } from '@/store/reducers/notify';
import { createSuperset } from '@/store/actions/admin';
import { Superset } from '@/store/types/admin/supersets';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import {
  OnSuccessUploadingResources,
} from '@/components/App/Admin/ListSupersets/ModalCreateSuperset/ModalCreateSypersetContent/ModalCreateSupersetContent';

interface UploadingTemplateButtonsProps {
  withErrors: boolean;
  templateCode: string;
  inputValue: string;
  onSuccess: OnSuccessUploadingResources
}

function UploadingTemplateButtons({
  withErrors, onSuccess, templateCode, inputValue,
}: UploadingTemplateButtonsProps) {
  const tNLS = useNLS();
  const dispatch = useAdminDispatch();
  const { supersetId } = useAdminSelector(selectSuperset) as Superset;

  const onCreateProject = () => {
    // or create a new project in created superset
    if (supersetId) {
      dispatch(
        createProject({
          templateUploadCode: templateCode,
          supersetId,
          uploadComment: inputValue,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          onSuccess();
          dispatch(
            addNotification({
              type: 'success',
              title: tNLS('uploaded.ok') as string,
              message: tNLS('file.uploaded.ok') as string,
            }),
          );
        })
        .catch(() => {});

      return;
    }

    // or create a new superset with project
    if (!supersetId) {
      dispatch(
        createSuperset({
          name: 'New Project',
          templateUploadCode: templateCode,
        }),
      )
        .then(unwrapResult)
        .then((response) => {
          dispatch(clearUploadResourcesInfo());

          const { supersetId: createdSupersetID } = response;
          onSuccess(createdSupersetID);
        })
        .catch(() => {});
    }
  };

  return (
    <div className={styles['wrap-btns']}>
      {!withErrors && (
      <CustomButton
        classNames={styles['btn-upload-template']}
        onClick={() => {
          onCreateProject();
        }}
      >
        {tNLS('template.upload.confirm')}
      </CustomButton>
      )}

      <CustomButton
        onClick={() => {
          dispatch(clearUploadResourcesInfo());
        }}
        styleTypeBtn="btn-grey btn-large"
        classNames={styles['btn-reset-form']}
      >
        {tNLS('template.upload.error.cancel')}
      </CustomButton>
    </div>
  );
}

export default UploadingTemplateButtons;