import { useCallback, useRef } from 'react';

function useDebounce(callback, delay = 500) {
  const timer = useRef();

  return useCallback((args) => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      callback(args);
    }, delay);
  }, [callback, delay]);
}

export default useDebounce;