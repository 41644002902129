import React from 'react';
import { Block } from './ArticleBlock/ArticleBlock';

import styles from './ArticleBlockList.module.sass';

interface BlockListProps {
    blocks: ArticleTextBlock[];
}

export const BlockList: React.FC<BlockListProps> = ({ blocks }) => {
    return (
        <section className={styles['article-block-list']}>
            {blocks.map((block) => (
                <Block
                    key={block.id}
                    block={block}
                    className={styles['article-block']}
                />
            ))}
        </section>
    );
};
