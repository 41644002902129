const B_UNIT_FIELDS = {
  name: 'name',
  email: 'email',
  // gender: 'gender',
  jobTitle: 'jobtitle',
  specs: 'tags',
  departments: 'dep',
  getTitleText(key, getNLS) {
    return getNLS(`tree.card.${this[key]}`);
  },
  getFieldKeys() {
    return Object.keys(this).flatMap((key) => {
      if (typeof this[key] === 'function') return [];
      return key;
    });
  },
};

export default B_UNIT_FIELDS;