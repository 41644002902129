// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".project-information-list--jQL0q{font-size:1.125rem;margin-bottom:10px}.project-information-list--jQL0q .information-list-item--CvGMI{color:var(--title-font);margin-left:6px}.files-information--EKi2B{margin-bottom:16px}.download-files-block--Fv7Q8{display:flex;flex-direction:column;gap:2px;align-items:center}.download-files-block--Fv7Q8 .modal-download-button--HQLJu{align-items:center;width:100%;padding:3px 6px;background:none;border:0;border-radius:5px;color:var(--title-font);text-align:center;font-size:1rem;font-weight:500}.download-files-block--Fv7Q8 .modal-download-button--HQLJu:hover{cursor:pointer;background:var(--admin-btn-hover);color:var(--admin-btn-hover-color)}.btn-delete-project--gvgnq{width:100%}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SupersetSettings/SectionDeleteProject/ModalDeleteProject/ModalDeleteProject.module.sass"],"names":[],"mappings":"AAEA,iCACE,kBAAA,CACA,kBAAA,CACA,+DACE,uBAAA,CACA,eAAA,CACJ,0BACE,kBAAA,CACF,6BACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,kBAAA,CACA,2DACE,kBAAA,CACA,UAAA,CAEA,eAAA,CACA,eAAA,CACA,QAAA,CACA,iBAAA,CACA,uBAAA,CACA,iBAAA,CACA,cAAA,CACA,eAAA,CACA,iEACE,cAAA,CACA,iCAAA,CACA,kCAAA,CAEN,2BACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"project-information-list": "project-information-list--jQL0q",
	"information-list-item": "information-list-item--CvGMI",
	"files-information": "files-information--EKi2B",
	"download-files-block": "download-files-block--Fv7Q8",
	"modal-download-button": "modal-download-button--HQLJu",
	"btn-delete-project": "btn-delete-project--gvgnq"
};
export default ___CSS_LOADER_EXPORT___;
