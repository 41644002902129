import React, { Dispatch, SetStateAction, useMemo } from 'react';
import styles from './EditSpecsInActionModal.module.sass';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectSelectedUnits, selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';
import { UnitId } from '@/store/types/user/units';
import ModalSpecsList
  from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitAddSpecsModal/EditSpecsInActionModal/ModalSpecsList/ModalSpecsList';
import useNLS from '@/components/App/Admin/hooks/hooks';

interface EditSpecsInActionModalProps {
  newSpecs: string[];
  editNewSpecs: Dispatch<SetStateAction<string[]>>;
  deletedSpecs: string[];
  deleteSpecs: Dispatch<SetStateAction<string[]>>;
}

function EditSpecsInActionModal({
  newSpecs, editNewSpecs, deletedSpecs, deleteSpecs,
}: EditSpecsInActionModalProps) {
  const { units } = useAdminSelector(selectUnitsTreeData);
  const selectedUnits = useAdminSelector(selectSelectedUnits);
  const tNLS = useNLS();
  const currentSpecs = useMemo(() => {
    const specsList = new Set<string>();
    selectedUnits.forEach((unitId: UnitId) => {
      const { spec } = units[unitId];
      spec.forEach((sp: string) => {
        specsList.add(sp);
      });
    });
    return [...specsList];
  }, [selectedUnits, units]);

  const props = useMemo(() => ({
    deletedSpecs, setDeletedSpecs: deleteSpecs, setNewSpecs: editNewSpecs,
  }), [deleteSpecs, deletedSpecs, editNewSpecs]);

  return (
    <>
      <div>{tNLS('tree.spec.modal.speclist')}</div>
      <ul className={styles.specs}>
        <ModalSpecsList
          isNewSpecs={false}
          specList={currentSpecs}
          {...props}
        />
        <ModalSpecsList
          isNewSpecs
          specList={newSpecs}
          {...props}
        />
      </ul>
    </>
  );
}

export default EditSpecsInActionModal;