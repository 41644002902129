// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".arrow-icon--HIB3W{display:inline-block;position:relative;width:0.625rem;height:0.625rem}.arrow-icon--HIB3W::before,.arrow-icon--HIB3W::after{content:\"\";position:absolute;background:var(--secondary-grey-text);transition:background .3s ease 0s;border-radius:2px;display:block;height:100%;width:25%}.arrow-icon--HIB3W::before{transform:rotate(45deg);left:65%}.arrow-icon--HIB3W::after{transform:rotate(-45deg);right:65%}.arrow-icon--HIB3W.bottom--Ahacw{transform:rotate(0)}.arrow-icon--HIB3W.top--Xzi\\+4{transform:rotate(180deg)}.arrow-icon--HIB3W.left--vpcy6{transform:rotate(90deg)}.arrow-icon--HIB3W.right--sZd14{transform:rotate(270deg)}", "",{"version":3,"sources":["webpack://./src/components/Common/Elements/cssIcons/ArrowIcon/ArrowIcon.module.sass"],"names":[],"mappings":"AAEA,mBACE,oBAAA,CACA,iBAAA,CACA,cAAA,CACA,eAAA,CACA,qDAEE,UAAA,CACA,iBAAA,CACA,qCAAA,CACA,iCAAA,CACA,iBAAA,CACA,aAAA,CACA,WAAA,CACA,SAAA,CACF,2BACE,uBAAA,CACA,QAAA,CACF,0BACE,wBAAA,CACA,SAAA,CACF,iCACE,mBAAA,CACF,+BACE,wBAAA,CACF,+BACE,uBAAA,CACF,gCACE,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrow-icon": "arrow-icon--HIB3W",
	"bottom": "bottom--Ahacw",
	"top": "top--Xzi+4",
	"left": "left--vpcy6",
	"right": "right--sZd14"
};
export default ___CSS_LOADER_EXPORT___;
