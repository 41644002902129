import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styles from './modal.module.sass';
import Content from '@/components/Common/Modal/Content';
import WrapBtns from '@/components/Common/Modal/WrapBtns';

function Modal({
  // eslint-disable-next-line react/prop-types
  children,
  // eslint-disable-next-line react/prop-types
  isShowing,
  // eslint-disable-next-line react/prop-types
  hide,
  // eslint-disable-next-line react/prop-types
  title,
  // eslint-disable-next-line react/prop-types
  style = {},
  types,
  externalClassNames,
}) {
  const getPartsOfModal = (childrenElems) => {
    // if passed jsx without components
    if (
      React.Children.count(childrenElems) === 1
      && childrenElems.type !== Content
      && childrenElems.type !== WrapBtns
    ) {
      return [childrenElems, null];
    }

    const partsOfModal = {
      content: null,
      btnsContainer: null,
    };

    React.Children.forEach(childrenElems, (component) => {
      if (component.type === Content) partsOfModal.content = component;
      if (component.type === WrapBtns) partsOfModal.btnsContainer = component;

      return null;
    });

    return [partsOfModal.content, partsOfModal.btnsContainer];
  };
  const [content, btnsContainer] = getPartsOfModal(children);

  const moduleClassNamesTypes = types
    .split(' ')
    .map((strClassName) => styles[strClassName])
    .join(' ');

  const {
    modalWrap = '',
    modal = '',
    contentClass = '',
    btnsWrap = '',
  } = externalClassNames;

  const onOverlayClicked = (e) => {
    if (e.target.id === 'modal-wrap') {
      hide();
    }
  };

  return isShowing
    ? ReactDOM.createPortal(
      <div
        id="modal-wrap"
        className={`${styles['wrap-modal']} ${moduleClassNamesTypes} ${modalWrap}`}
        role="presentation"
        onClick={onOverlayClicked}
      >
        <div className={`${styles.modal} ${modal}`} style={style}>
          <div className={styles.header}>
            <div className={styles.title}>
              <h3>{title}</h3>
            </div>
            <button
              type="button"
              className={styles['btn-close-modal']}
              onClick={hide}
            >
              &#10006;
            </button>
          </div>
          {content && (
          <div className={`${styles.content} ${contentClass}`}>
            {content}
          </div>
          )}
          {btnsContainer && (
          <div className={`${styles['btns-container']} ${btnsWrap}`}>
            {btnsContainer}
          </div>
          )}
        </div>
      </div>,
      document.getElementById('app'),
    )
    : null;
}

Modal.defaultProps = {
  types: '',
  externalClassNames: {},
};

Modal.propTypes = {
  types: PropTypes.string,
  externalClassNames: PropTypes.shape({
    modalWrap: PropTypes.string,
    modal: PropTypes.string,
    contentClass: PropTypes.string,
    btnsWrap: PropTypes.string,
  }),
};

export default Modal;