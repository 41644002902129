import React, { ReactNode } from 'react';
import styles from './CardSelectDropdown.module.sass';
import { getClearClassNames } from '@/helpers/helpers';

interface CardSelectDropdownProps {
  children: ReactNode[];
  classNames?: string;
  isOpen?: boolean;
}

function CardSelectDropdown({ children, classNames = '', isOpen = true }: CardSelectDropdownProps) {
  if (!isOpen) return null;

  return (
    <ul className={getClearClassNames([
      styles['dropdown-list'],
      classNames,
    ])}
    >
      {children.length ? children : <div className={styles.dash}>&mdash;</div>}
    </ul>
  );
}

export default CardSelectDropdown;