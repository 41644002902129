import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './SectionTemplate.module.sass';
import { selectAvailableTemplatesForCopy } from '@/store/selectors/admin/superset/supersets';
import SectionLanguages from '@/components/App/Admin/SupersetSettings/SectionLanguages';
import SectionBlocks from '@/components/App/Admin/SupersetSettings/SectionBlocks';
import { selectAdmin } from '@/store/selectors/admin/authentication';
import SectionAdminSettings from '@/components/App/Admin/SupersetSettings/SectionAdminSettings';
import { selectLanguagesMasterSuperset } from '@/store/selectors/admin/superset/projects';
import selectClearBlocks from '@/store/selectors/admin/superset/blocks';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import useNLS from '@/components/App/Admin/hooks/hooks';
import SectionUploadedFiles from '@/components/App/Admin/SupersetSettings/SectionUploadedFiles';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';
import ModalCreateSuperset from '@/components/App/Admin/ListSupersets/ModalCreateSuperset';

function SectionTemplate() {
  const tNLS = useNLS();
  const { canCreateNew } = useSelector(selectAdmin);
  const [isOpenModal, toggleOpen] = useState(false);
  const availableTemplates = useSelector(selectAvailableTemplatesForCopy);
  const languages = useSelector(selectLanguagesMasterSuperset);
  const blocks = useSelector(selectClearBlocks);
  const checkPermission = useCheckPermission();

  const areLanguagesLoaded = !!Object.keys(languages).length;
  const areBlocksLoaded = !!Object.keys(blocks).length;

  return (
    <>
      {checkPermission('load_templates') && (
        <section className={`settings-section ${styles['template-section']}`}>
          <h3 className="title-section">{tNLS('change.questionnaire')}</h3>

          <form>
            {(canCreateNew || availableTemplates.length === 0) && (
              <CustomButton
                styleTypeBtn="btn-grey btn-large"
                classNames={styles['btn-upload-project-template']}
                onClick={() => toggleOpen(true)}
              >
                {tNLS('template.change')}
              </CustomButton>
            )}
          </form>
        </section>
      )}
      <ModalCreateSuperset isOpen={isOpenModal} toggleOpen={toggleOpen} />
      {checkPermission('load_templates') && <SectionUploadedFiles />}
      {areLanguagesLoaded && <SectionLanguages />}
      {checkPermission('modify_blocks') && areBlocksLoaded && <SectionBlocks />}
      <SectionAdminSettings />
    </>
  );
}

export default SectionTemplate;