import React, { LabelHTMLAttributes, ReactNode } from 'react';
import styles from './UploadingInputLabel.module.sass';

interface UploadingInputLabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  text?: string | ReactNode;
}

export default function UploadingInputLabel({ text, htmlFor }: UploadingInputLabelProps) {
  return (
    <label
      className={styles['upload-file-label']}
      htmlFor={htmlFor}
    >
      {' '}
      <div>{text || 'Выберите файлы для загрузки или перетяните их мышкой'}</div>
      <span className={`${styles['upload-icon']} icon-download-file`} />
    </label>
  );
}