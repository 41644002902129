import React, { useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useForm } from 'react-hook-form';
import styles from './UploadResourcesForm.module.sass';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { uploadResources } from '@/store/actions/admin/superset/projects';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import UploadResourcesInput from './UploadResourcesInput/UploadResourcesInput';
import { selectStatusIsResourceUploaded } from '@/store/selectors/admin/superset/projects';
import Preloader from '@/components/App/Admin/SupersetSettings/Preloader';

function UploadResourcesForm() {
  const tNLS = useNLS();
  const dispatch = useAdminDispatch();
  const isResourceUploaded = useAdminSelector(selectStatusIsResourceUploaded);
  const { uploadResourcesStatus } = useAdminSelector((state) => state.admin.superset.projects);
  const {
    register, handleSubmit, watch, reset,
  } = useForm();

  const onUploadResources = (form: { resources: (string | Blob)[]; }) => {
    const formData = new FormData();
    formData.append('file', form.resources[0]);

    // @ts-ignore
    dispatch(uploadResources(formData))
      .then(unwrapResult)
      .catch(() => null);
  };

  const selectedFile = watch('resources');

  // eslint-disable-next-line
  useEffect(() => () => reset(), []);

  if (!isResourceUploaded && uploadResourcesStatus === 'pending') return <Preloader />;

  return (
    // @ts-ignore
    <form onSubmit={handleSubmit(onUploadResources)}>
      <UploadResourcesInput register={register} />
      {!!selectedFile?.length && (
      <CustomButton
        typeBtnSubmit
        styleTypeBtn="btn-grey btn-large"
        classNames={styles['btn-upload-project-template']}
      >
        {tNLS('template.upload.confirm')}
        {' '}
      </CustomButton>
      )}
    </form>
  );
}

export default UploadResourcesForm;