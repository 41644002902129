import React, { useRef, memo } from 'react';
import PropTypes from 'prop-types';

import styles from './Input.module.sass';
import './themeVars.sass';
import ControlInputNumber from '@/components/Common/form/ControlInputNumber';

const Input = memo(({
  register,
  registerOptions,
  id,
  name,
  label,
  externalClasses: {
    wrapInput,
    label: labelClassName,
    labelText,
    input: inputClass,
    wrapInputNumberController,
  },
  children,
  hidden,
  ...props
}) => {
  const { type } = props;
  const inputRef = useRef(null);

  const {
    name: nameRegister,
    ref: refRegister,
    ...restRegisterFields
  } = register
    ? register(name, registerOptions)
    : {
      ref: null,
      name,
    };

  const input = (
    <input
      id={id}
      name={nameRegister}
      ref={(e) => {
        if (refRegister) refRegister(e);
        inputRef.current = e;
      }}
      {...restRegisterFields}
      {...props}
      className={inputClass || ''}
      hidden={hidden}
    />
  );

  return (
    <div
      className={`${styles['wrap-input-field']} 
    ${wrapInput || ''}
    ${hidden ? 'visually-hidden' : ''}`}
    >
      <label
        className={`${styles['label-input']} ${type} ${labelClassName || ''}`}
        htmlFor={id}
      >
        {label && (
          <span className={`${styles['label-text']} ${labelText || ''}`}>
            {label}
          </span>
        )}

        {type === 'number' ? (
          <div
            className={`${styles['wrap-input-number-controller']}
             ${wrapInputNumberController || ''}`}
          >
            {input}
            <ControlInputNumber
              ref={inputRef}
              externalClasses={{
                wrapController: styles['input-number-controller'],
              }}
            />
          </div>
        ) : (
          input
        )}
        {children}
      </label>
    </div>
  );
});

Input.defaultProps = {
  register: null,
  registerOptions: {},
  label: null,
  externalClasses: {},
  type: 'text',
  hidden: false,
  children: null,
};

Input.propTypes = {
  register: PropTypes.func,
  registerOptions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  externalClasses: PropTypes.shape({
    wrapInput: PropTypes.string,
    label: PropTypes.string,
    labelText: PropTypes.string,
    input: PropTypes.string,
    wrapInputNumberController: PropTypes.string,
  }),
  type: PropTypes.string,
  hidden: PropTypes.bool,
  children: PropTypes.element,
};

export default Input;