import React from 'react';
import PropTypes from 'prop-types';
import { getClearClassNames } from '@/helpers/helpers';
import styles from './CheckBox.module.sass';

function CheckBox({
  id,
  name,
  label,
  onChange = () => {},
  register = null,
  registerOptions = {},
  externalClassNames = {},
  disabled = false,
  notInteractive = false,
  ...props
}) {
  const { onChange: setOnChange, ...restRegisterFields } = register
    ? register(name, registerOptions)
    : {
      onChange,
    };

  const { wrapOption = '', radioBtn = '' } = externalClassNames;

  return (
    <div
      className={getClearClassNames([
        styles['wrap-custom-checkbox'],
        wrapOption,
      ])}
    >
      <input
        id={id}
        onChange={(e) => {
          setOnChange(e);
        }}
        type="checkbox"
        name={name}
        {...restRegisterFields}
        disabled={disabled || notInteractive}
        {...props}
      />
      <label htmlFor={id}>
        <span className={getClearClassNames([styles['radio-btn'], radioBtn])} />
        <span className={styles['text-label']}>{label}</span>
      </label>
    </div>
  );
}

CheckBox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onChange: PropTypes.func,
  register: PropTypes.func,
  registerOptions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  externalClassNames: PropTypes.shape({
    wrapOption: PropTypes.string,
    radioBtn: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  notInteractive: PropTypes.bool,
};

export default CheckBox;