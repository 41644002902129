import React from 'react';
import PropTypes from 'prop-types';
import styles from './Code.module.sass';
import { addNotification } from '@/store/reducers/notify';
import { selectActorProfile } from '@/store/selectors/user/actor';
import { usePlayerDispatch, usePlayerSelector } from '@/store/hooks/playerRedux';
import { ActorProfile } from '@/store/types/user/actor';

interface CodeProps {
  isHeader: boolean;
}

export default function Code({ isHeader }: CodeProps) {
  const dispatch = usePlayerDispatch();
  const actorProfile = usePlayerSelector(selectActorProfile) as ActorProfile;

  if (!actorProfile) return <p />;

  let { code } = actorProfile;

  if (!code) return <p />;

  const addDashesToCode = (text: string) : string => `${text.slice(0, 4)}-${text.slice(4, 8)}-${text.slice(8, 12)}`;
  const copyTextToClipboard = (text: string): void => {
    navigator.clipboard.writeText(text).then(() => dispatch(
      addNotification({
        type: 'success',
        title: 'Success',
        message: 'Code has copied to clipboard!',
      }),
    ));
  };

  while (code.length < 12) code = `0${code}`;

  return (
    <span
      role="button"
      tabIndex={0}
      id="employee-inviteCode"
      onKeyDown={() => {}}
      className={`${styles['actor-code']} ${isHeader && styles['header-code']}`}
      onClick={() => copyTextToClipboard(addDashesToCode(code))}
    >
      {`${code.toString().slice(0, 4)}-`}
      {`${code.toString().slice(4, 8)}-`}
      {code.toString().slice(8, 12)}
    </span>
  );
}

Code.propTypes = {
  isHeader: PropTypes.bool,
};

Code.defaultProps = {
  isHeader: false,
};