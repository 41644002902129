import React, { useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { withErrorBoundary } from 'react-error-boundary';
import styles from './SectionHeaderFields.module.sass';
import { updateSuperset, updateSupersetName } from '@/store/actions/admin';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import FallbackSupersetSection from '@/components/App/Admin/SupersetSettings/FallbackSupersetSection';
import WebIdInput from '@/components/App/Admin/SupersetSettings/SectionHeaderFields/HeaderInputs/WebIdInput';
import SupersetCompanyInput from '@/components/App/Admin/SupersetSettings/SectionHeaderFields/HeaderInputs/SupersetCompanyInput';
import SupersetNameInput from '@/components/App/Admin/SupersetSettings/SectionHeaderFields/HeaderInputs/SupersetNameInput';
import useNLS from '@/components/App/Admin/hooks/hooks';
import IsTemplateForCopyCheckbox from '@/components/App/Admin/UIElements/IsTemplateForCopyCheckbox/IsTemplateForCopyCheckbox';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';

function SectionHeaderFields() {
  const dispatch = useDispatch();
  const {
    supersetId, company, name, webId,
  } = useSelector(selectSuperset);
  const { register, watch } = useForm({
    defaultValues: {
      company,
      name,
      webId,
    },
  });
  const tNLS = useNLS();
  const [webIdError, setWebIdError] = useState('');
  const checkPermission = useCheckPermission();

  const validateForm = (fields) => {
    const webIdRegex = /^[a-zA-Z0-9._-]+$/;
    const isWebIdValid = webIdRegex.test(fields.webId);
    if (!isWebIdValid) {
      setWebIdError(tNLS('webid.invalid'));
    } else setWebIdError('');
    const isNameValid = true;
    const isCompanyValid = true;
    return { isWebIdValid, isNameValid, isCompanyValid };
  };

  const delayTimer = useRef();

  watch((fields, { name: fieldName }) => {
    if (!fieldName) return;
    clearTimeout(delayTimer.current);
    const { isWebIdValid, isNameValid, isCompanyValid } = validateForm(fields);
    if (!(isCompanyValid && isNameValid && isWebIdValid)) return;

    delayTimer.current = setTimeout(() => {
      const formData = {
        supersetID: supersetId,
        updatedSuperset: {
          superset: {
            [fieldName]: fields[fieldName],
          },
        },
      };

      if (fieldName === 'name') {
        dispatch(updateSupersetName(formData));
      } else {
        dispatch(updateSuperset(formData));
      }
    }, 500);
  });

  const iconPencil = useMemo(() => <span className="icon-pencil" />, []);

  return (
    <section className={styles['header-fields']}>
      <form className={styles['header-form']}>
        <SupersetCompanyInput iconPencil={iconPencil} register={register} />
        <SupersetNameInput register={register} iconPencil={iconPencil} />
        <WebIdInput
          register={register}
          iconPencil={iconPencil}
          webIdError={webIdError}
        />
      </form>
      {checkPermission() && <IsTemplateForCopyCheckbox />}
    </section>
  );
}

export default withErrorBoundary(SectionHeaderFields, {
  FallbackComponent: FallbackSupersetSection,
});