import React from 'react';
import styles from '../LinkTabs.module.sass';
import { ButtonTabInterface } from '@/components/App/Admin/UIElements/LinkTabs/types';

interface ButtonTabProps {
  children: ButtonTabInterface
}

function ButtonTab({ children }: ButtonTabProps) {
  const buttonClick = () => {
    if (children.isActive) return;
    children.onClick();
  };

  return (
    <button
      type="button"
      className={`${styles.tab} ${styles['btn-tab']} ${children.isActive && 'active'}`}
      onClick={buttonClick}
    >
      <span>{children.title}</span>
    </button>
  );
}

export default ButtonTab;