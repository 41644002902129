import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { selectCurrentLangID } from '@/store/selectors/admin/superset/projects';
import { selectSelectedCriteriaInFormatToBeSent } from '@/store/selectors/admin/superset/process/cuts/cuts';
import axiosProcessing from '@/axiosConfig';

export const loadFactorCuts = createAsyncThunk(
  'superset/admin/process/cuts/factorsCuts/loadFactorCuts',
  async (form, { getState, rejectWithValue }) => {
    const state = getState();
    const { headers } = state.admin.authentication;
    const { supersetId } = selectSuperset(state);
    const langID = selectCurrentLangID(state);
    const selectedCriteriaForSendingFormat = selectSelectedCriteriaInFormatToBeSent(state);

    try {
      const response = await axiosProcessing.post(
        `/api/admin/getFactorCuts/${supersetId}`,
        {
          // data by default
          langId: langID,
          criterios: selectedCriteriaForSendingFormat,
        },
        { headers },
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const setActiveTypeFS = createAction(
  'superset/admin/process/cuts/factorsCuts/setActiveTypeFS',
);