import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import {
  clearCheckpoints,
  getCheckpoints,
} from '@/store/actions/admin/superset/process/process';
import { selectProcessorCheckpoints } from '@/store/selectors/admin/superset/process/process';
import Preloader from '@/components/App/Admin/SupersetSettings/Preloader';
import RadioButton from '@/components/Common/form/RadioButton';
import styles from './CalculateResultsModalContent.module.sass';

function CalculateResultsModalContent({
  selectedCheckpointId,
  setSelectedCheckpointId,
}) {
  const dispatch = useDispatch();
  const { supersetId } = useSelector(selectSuperset);
  const checkpoints = useSelector(selectProcessorCheckpoints);

  useEffect(() => {
    dispatch(getCheckpoints({ supersetId }));

    return () => {
      dispatch(clearCheckpoints());
    };
  }, [dispatch, supersetId]);

  const getLabelCheckpoint = (title, description) => (
    <div className={styles['wrap-option-label']}>
      {title && <span>{title}</span>}
      {description && <span className={styles.description}>{description}</span>}
    </div>
  );

  if (!checkpoints) return <Preloader />;

  return (
    <div className={styles['checkpoints-modal-content']}>
      <RadioButton
        name="Without checkpoint"
        id="default-checkpoint"
        label={getLabelCheckpoint('Without checkpoint')}
        externalClassNames={{}}
        onChange={() => setSelectedCheckpointId('')}
        checked={selectedCheckpointId === ''}
      />
      {checkpoints.map(({ id, title, description }) => (
        <RadioButton
          name={title}
          key={id}
          id={id}
          label={getLabelCheckpoint(title, description)}
          externalClassNames={{}}
          checked={id === selectedCheckpointId}
          onChange={() => setSelectedCheckpointId(id)}
        />
      ))}
    </div>
  );
}

CalculateResultsModalContent.propTypes = {
  selectedCheckpointId: PropTypes.string.isRequired,
  setSelectedCheckpointId: PropTypes.func.isRequired,
};

export default CalculateResultsModalContent;