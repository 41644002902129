import { selectCurrentLangID } from '@/store/selectors/admin/superset/projects';

const getStoreContext = (state) => state.admin.superset.process.cuts.questionsCuts;

export const selectQuestionsCuts = (state) => getStoreContext(state).cuts;

export const selectQuestionsItems = (state) => {
  const { items } = getStoreContext(state);
  const currentLangID = selectCurrentLangID(state);

  if (Object.keys(items).length === 0) {
    return [];
  }

  return items[currentLangID];
};

export const selectFirstItemQuestionsCuts = (state) => selectQuestionsItems(state)[0];

export const selectItemByID = (state, itemID) => {
  const items = selectQuestionsItems(state);
  const indxItem = items.findIndex((item) => item.id === itemID);

  return { ...items[indxItem], number: indxItem + 1 };
};