const checkSettingsFitsCondition = (conditional, settings) => {
  if (conditional.length === 0) return true;

  const conditionStack = [];
  const operators = ['>', '<', '=', '&'];
  const operatorsStrToMath = {
    '>': (a, b) => a > b,
    '<': (a, b) => a < b,
    '=': (a, b) => a === b,
    '&': (a, b) => a && b,
  };

  conditional.forEach((condElem) => {
    if (operators.includes(condElem)) {
      const operator = condElem;
      const [firstOperand, secondOperand] = conditionStack.splice(-2);

      const result = operatorsStrToMath[operator](firstOperand, secondOperand);
      conditionStack.push(result);
      return;
    }

    if (condElem[0] === '$') {
      const parentParamID = condElem.slice(1);
      const { value } = settings[parentParamID];
      conditionStack.push(value);
      return;
    }

    conditionStack.push(condElem);
  });

  return conditionStack[0];
};

export default checkSettingsFitsCondition;