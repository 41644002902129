import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ProhibitInteractivity from '@/components/App/Admin/SupersetSettings/ProhibitInteractivity';
import EditableResizeableInput from '@/components/App/Admin/UIElements/form/EditableResizeableInput';
import styles from '@/components/App/Admin/SupersetSettings/SectionHeaderFields/SectionHeaderFields.module.sass';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import useNLS from '@/components/App/Admin/hooks/hooks';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';

function WebIdInput({ register, iconPencil, webIdError = '' }) {
  const { webId } = useSelector(selectSuperset);
  const tNLS = useNLS();
  const [validationError, setValidationError] = useState('');
  const timer = useRef();
  const checkPermission = useCheckPermission();

  useEffect(() => {
    if (!validationError) return;
    timer.current = setTimeout(() => {
      setValidationError('');
    });
  }, [validationError]);

  return (
    <ProhibitInteractivity>
      <EditableResizeableInput
        id="superset-web-id"
        name="webId"
        defaultValue={webId}
        label="WebID:"
        icon={iconPencil}
        externalClasses={{
          wrapField: `${styles['admin-resize-input']} ${styles['wrap-web-id-field']}`,
        }}
        registerOptions={{
          pattern: {
            value: /^[a-zA-Z0-9._-]+$/,
            message: 'error',
          },
        }}
        validationError={webIdError}
        register={register}
        notInteractive={!checkPermission('change_webid')}
        placeholder={tNLS('project.webid')}
      />
    </ProhibitInteractivity>
  );
}

WebIdInput.propTypes = {
  register: PropTypes.func.isRequired,
  iconPencil: PropTypes.element.isRequired,
  webIdError: PropTypes.string,
};

export default WebIdInput;