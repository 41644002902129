import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Admin from './Admin';
import IndexPage from './IndexPage/IndexPage';
import './App.sass';
import '../../styles/light.scss';
import '../../styles/dark.scss';
import '../../styles/defaultValues.sass';
import RequireAuth from './Admin/RequireAuth';
import SignIn from './Admin/SignIn';
import SignUp from './Admin/SignUp';
import BaseTemplate from '@/components/Common/BaseTemplate';
import AuthByToken from '@/components/App/Admin/AuthByToken';
import RestoreAccount from '@/components/App/Admin/RestoreAccount';
import AdminCreatePassword from '@/components/App/Admin/AdminCreatePassword';
import NotificationsBar from '@/components/Common/NotificationsBar/NotificationsBar';
import SuperAdminEntry from './Admin/SuperAdmin/SuperAdminEntry';

export default function AdminApp() {
  return (
    <Suspense fallback={<div>Загрузка...</div>}>
      <BaseTemplate isAdmin>
        <NotificationsBar />
        <Routes>
          <Route path="/" element={<IndexPage />} />
          <Route
            path="/admin/*"
            element={(
              <RequireAuth>
                <Admin />
              </RequireAuth>
            )}
          />
          <Route
            path="/super/*"
            element={(
              <RequireAuth isSuper>
                <SuperAdminEntry />
              </RequireAuth>
            )}
          />
          <Route path="/admin/signIn" element={<SignIn />} />
          <Route path="/admin/signUp" element={<SignUp />} />
          <Route path="/admin/restoreAccount" element={<RestoreAccount />} />
          <Route
            path="/admin/restore/:adminId/:time/:restoreToken"
            element={<AdminCreatePassword />}
          />
          <Route
            path="/admin/invite/:adminId/:time/:inviteToken"
            element={<AuthByToken />}
          />
          <Route
            path="/admin/confirm/:adminId/:time/:inviteToken"
            element={<AuthByToken />}
          />
        </Routes>
      </BaseTemplate>
    </Suspense>
  );
}