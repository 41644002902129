import React, { useState } from 'react';
import styles from './ListSupersets.module.sass';
import useSupersets from '@/components/App/Admin/ListSupersets/useSupersets';
import FilterSupersets from '@/components/App/Admin/ListSupersets/FilterSupersets/FilterSupersets';
import useLocalStorageByAdmin from '@/components/App/Admin/BUnitsSection/UnitsTree/hooks/adminLocalStorageSaver';
import FilteredSupersetsList from '@/components/App/Admin/ListSupersets/FilteredSupersetsList/FilteredSupersetsList';
import CreateSupersetHeader from '@/components/App/Admin/ListSupersets/CreateSupersetHeader/CreateSupersetHeader';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';

export enum FilterSupersetsFlag {
  ACTIVE = 'active',
  TEMPLATES = 'templates',
  ALL = 'all',
  ARCHIVE = 'archive',
  AllAsSuper = 'super',
}

function ListSupersets() {
  useSupersets();
  const checkPermission = useCheckPermission();
  const [writeInLocal, readFromLocal] = useLocalStorageByAdmin<FilterSupersetsFlag>(
    'filter-superset-flag',
    false,
    true,
  );

  const [filterFlag, setFilterFlag] = useState<FilterSupersetsFlag>(() => {
    const flagFromLocal = readFromLocal();
    let defaultFlag: FilterSupersetsFlag = flagFromLocal || FilterSupersetsFlag.ACTIVE;
    if (!checkPermission() && defaultFlag === FilterSupersetsFlag.TEMPLATES) defaultFlag = FilterSupersetsFlag.ACTIVE;
    if (flagFromLocal !== defaultFlag) writeInLocal(defaultFlag);
    return defaultFlag;
  });

  return (
    <div className={styles['content-superset-list']}>
      <CreateSupersetHeader />
      <section className={styles['section-list-supersets']}>
        <FilterSupersets
          filterFlag={filterFlag}
          setFilterFlag={(flag) => {
            setFilterFlag(flag);
            writeInLocal(flag);
          }}
        />
        <FilteredSupersetsList filterFlag={filterFlag} />
      </section>
    </div>
  );
}

export default ListSupersets;